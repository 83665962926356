import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// App imports
import { commonMessages } from '../../../i18n';
import FavoriteButton from '../FavoriteButton';
import BpnlButton from '../BpnlButton';
// Local imports
import { HeaderButtonProps } from './types';
import classes from './index.module.scss';

export default function HeaderButtons(props: HeaderButtonProps) {
	const { isFollowed, followClick, shareClick, isFetching } = props;
	const { formatMessage } = useIntl();
	return (
		<div className={clsx(classes.headerButtons)}>
			<FavoriteButton
				isFollowed={isFollowed}
				click={followClick}
				isFetching={isFetching}
				variant={'primary'}
				title={formatMessage(commonMessages.follow)}
			/>
			{shareClick && (
				<BpnlButton
					title={formatMessage(commonMessages.share)}
					className={classes.headerButtonsShare}
					outlined
					onClick={shareClick}
					iconBefore={<Icon name={'bpnl-share'} />}
				/>
			)}
		</div>
	);
}
