import clsx from 'clsx';
import { isArray } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { FilterComparisonType, FilterQuery, useEffectOnMount, useEnum, useMountedEffect } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
import { ScrollView } from '@webolucio/web';
// BPNL imports
import { DetailedVenue, useVenues, VenueFilters } from '@bpnl/venues';
// App imports
import Search from '../../components/filters/Search';
import ServiceFilter from '../../components/filters/ServicesFilter';
import Map from '../../components/presentationals/Map';
import Title from '../../components/presentationals/Title';
import Tag from '../../components/presentationals/Tag';
import MusicStyleFilter from '../../components/filters/MusicStyleFilter';
import useLoadingFlag from '../../hooks/useLoadingFlag';
import FilterOnMobile from '../../components/presentationals/FilterOnMobile';
import FilterButton from '../../components/presentationals/FilterButton';
import useIsDesktop from '../../hooks/useIsDesktop';
import { QueryNonNullObject } from '../../types';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function MapsPage() {
	const { formatMessage } = useIntl();
	const venueTypes = useEnum<string>('venue-types');
	const services = useEnum<number>('services');
	const musicStyles = useEnum<number>('music-style-tags');

	const [initialized, setInitialized] = useState(false);
	const [activeTypeTag, setActiveTypeTag] = useState<string | null>(null);
	const [formattedFilters, setFormattedFilters] = useState<FilterQuery<VenueFilters>>([]);
	const [activeFilters, setActiveFilters] = useState<Record<string, QueryNonNullObject<VenueFilters>>>({});
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [openFilter, setOpenFilter] = useState(false);
	const isDesktop = useIsDesktop();

	const { venues, isVenuesFetching, venueCount } = useVenues({
		filter: formattedFilters as FilterQuery<DetailedVenue>,
		search: searchTerm,
		queryKey: 'map',
		limit: 9999,
	});

	const handleSearch = useCallback((value) => {
		setSearchTerm(value);
	}, []);

	const handleFilter = useCallback((newFilters, source) => {
		setActiveFilters((prevFilters) => {
			return Object.keys(prevFilters)?.length ? { ...prevFilters, [source]: newFilters } : { [source]: newFilters };
		});
	}, []);

	const handleFilterModalOpen = useCallback(() => {
		setOpenFilter((prev) => !prev);
	}, []);

	useLoadingFlag(!initialized && ((venueCount === 0 && isVenuesFetching) || !venueTypes || !services || !musicStyles));

	useEffect(() => {
		if (!(venueCount === 0 && isVenuesFetching) && venueTypes && services && musicStyles) {
			setInitialized(true);
		}
	}, [venueCount, isVenuesFetching, venueTypes, services, musicStyles]);

	useMountedEffect(() => {
		const newFilters: FilterQuery = [];
		const tagIDs: number[] = [];
		Object.values(activeFilters)
			.flat()
			.forEach((filter) => {
				if (!filter.value || (isArray(filter.value) && !filter.value.length)) {
					return;
				} else if (filter.field === 'tag_id') {
					const tagID = parseInt(filter.value.toString());
					if (!isNaN(tagID)) {
						tagIDs.push(tagID);
					}
				} else {
					newFilters.push(filter);
				}
			});
		if (tagIDs.length) {
			newFilters.push({ field: 'tag_id', condition_type: FilterComparisonType.IN, value: tagIDs.flat() });
		}
		setFormattedFilters(newFilters);
	}, [activeFilters]);

	useEffectOnMount(() => {
		document.body.style.setProperty('--no-scroll', '1');

		return () => {
			document.body.style.removeProperty('--no-scroll');
		};
	});

	const venuesToDisplay = venues.filter((v) => v.address && v.address.coordinates);

	return (
		<>
			<ScrollView classes={{ container: classes.aside }}>
				<Search onChange={handleSearch} />
				<FilterButton onClick={() => handleFilterModalOpen()} />
				<FilterOnMobile isMobile={!isDesktop} open={openFilter} close={handleFilterModalOpen}>
					<Title className={classes.tagTitle} title={formatMessage(messages.type)} variant="block" />
					<div>
						{venueTypes?.map((tag) => (
							<Tag
								className={clsx(classes.tag, tag.id === activeTypeTag && classes.active)}
								beforeIcon={tag.id === 'restaurant' ? <Icon name="bpnl-beer" /> : <Icon name="bpnl-music" />}
								key={`venue-type-${tag.id}`}
								onClick={() => {
									if (activeTypeTag === tag.id) {
										setActiveTypeTag(null);
										handleFilter({}, 'venue-types');
										return;
									}

									setActiveTypeTag(tag.id);
									handleFilter(
										{
											field: 'type',
											condition_type: FilterComparisonType.EQUAL,
											value: tag.id,
										},
										'venue-types',
									);
								}}
								label={tag.label}
								variant="secondary"
							/>
						))}
					</div>
					<ServiceFilter onChange={handleFilter} />
					<MusicStyleFilter onChange={handleFilter} />
				</FilterOnMobile>
			</ScrollView>
			<Map venues={venuesToDisplay} />
		</>
	);
}
