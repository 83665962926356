import clsx from 'clsx';
import { BaseComponentProps } from '@webolucio/core';
import { useMyAccount } from '@bpnl/account';
import Image from '../Image';
import classes from './index.module.scss';

export default function ProfileImage({ className }: BaseComponentProps) {
	const { myAccount } = useMyAccount();

	if (!myAccount) {
		return null;
	}

	return (
		<div className={clsx(classes.profileImage, className)}>
			{myAccount.profile_image ? (
				<Image className={classes.image} file={myAccount.profile_image} />
			) : (
				<span className={classes.initials}>
					<svg viewBox="0 0 100 100">
						<text x="50%" y="53%" alignmentBaseline="middle" textAnchor="middle" fontSize="40">
							{myAccount.language_id === 'hu'
								? (myAccount.last_name.at(0) || '') + (myAccount.first_name.at(0) || '')
								: (myAccount.first_name.at(0) || '') + (myAccount.last_name.at(0) || '')}
						</text>
					</svg>
				</span>
			)}
		</div>
	);
}
