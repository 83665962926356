import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { BackendAsset, useAsset, webolucioConfig } from '@webolucio/core';
import BpnlLogo from '../../../assets/images/logo.png';
import { ImageProps } from './types';

export default function Image(props: ImageProps) {
	const { alt, className, file, lazy = true, src: externalSrc, thumbnail = false } = props;

	const { src, isProtected } = useAsset({ lazy: false, mode: 'base64', value: file || ({} as BackendAsset) });

	const browserSupportsLazyLoading = 'loading' in HTMLImageElement.prototype;
	const { ref, inView } = useInView({
		triggerOnce: true,
		rootMargin: '200px 0px',
		initialInView: false,
		skip: browserSupportsLazyLoading && !lazy,
	});

	if (isProtected && !src) {
		return null;
	}

	if (externalSrc) {
		return (
			<div className={clsx(className || undefined, 'image')}>
				<img alt={alt || undefined} loading="lazy" ref={ref} src={externalSrc} />
			</div>
		);
	}

	if (!file) {
		return (
			<div className={clsx(className || undefined, 'image')}>
				<img alt={webolucioConfig.appName} src={BpnlLogo} style={{ marginLeft: '20%', maxWidth: '60%', objectFit: 'contain' }} />
			</div>
		);
	}

	if (isProtected && src) {
		return (
			<div className={clsx(className || undefined, 'image')}>
				<img alt={alt || undefined} loading="lazy" ref={ref} src={src} />
			</div>
		);
	}

	return (
		<picture className={clsx(className || undefined, 'image')} ref={ref}>
			{Object.values(file.sources).map((source, key) => (
				<source
					key={`picture-source-${key}`}
					srcSet={`${source.url}${source.width ? ` ${source.width}w` : ''}`}
					height={source.height || undefined}
					width={source.width || undefined}
					type={source.mime_type}
				/>
			))}
			{inView || browserSupportsLazyLoading ? (
				<img alt={alt || file.name || file.original_name || undefined} loading="lazy" src={thumbnail ? file.tmb_url : file.url} />
			) : null}
		</picture>
	);
}
