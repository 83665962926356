import clsx from 'clsx';
import { Fragment, useCallback } from 'react';
// Webolucio imports
import { Listable } from '@webolucio/core';
// App imports
import Image from '../../presentationals/Image';
import HorizontalSlider from '../HorizontalSlider';
// Local imports
import classes from './index.module.scss';
import { BpnlCarouselItemProps, BpnlCarouselProps } from './types';

export default function BpnlCarousel<Item extends Listable<number>>(props: BpnlCarouselProps<Item>) {
	const {
		items,
		autoplay = false,
		autoplaySpeed = 5000,
		swipe = false,
		infinite = false,
		renderItem: externalRenderItem,
		classes: externalClasses = {},
		...rest
	} = props;

	const renderItem = useCallback(
		(slide: BpnlCarouselItemProps<Item>, idx: number) => (
			<Fragment key={`carousel-item-${idx}`}>
				<div className={clsx(classes.carouselItem, externalClasses.carouselItem)}>
					{slide.image ? (
						<Image className={clsx(classes.carouselImage, externalClasses.carouselImage)} file={slide.image} />
					) : (
						<div className={classes.emptyItem} />
					)}
					{externalRenderItem ? (
						<div className={clsx(classes.carouselContent, externalClasses.carouselContent)}>{externalRenderItem(slide.item, idx)}</div>
					) : null}
				</div>
			</Fragment>
		),
		[externalClasses.carouselContent, externalClasses.carouselImage, externalClasses.carouselContent, externalRenderItem],
	);

	return (
		<HorizontalSlider
			dots={true}
			autoplay={autoplay}
			slidesToShowMobile={1}
			className={externalClasses.container}
			infinite={infinite}
			swipe={swipe}
			{...rest}
		>
			{items.map(renderItem)}
		</HorizontalSlider>
	);
}
