import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { FilterComparisonType, useEnum } from '@webolucio/core';
import { ScrollView } from '@webolucio/web';
// BPNL imports
import { VenueFilters } from '@bpnl/venues';
// App imports
import Title from '../../presentationals/Title';
import CheckboxList from '../../presentationals/CheckboxList';
// Local imports
import { FilterProps } from '../types';
import messages from './messages';
import classes from './index.module.scss';

export default function ServiceFilter({ onChange }: FilterProps<VenueFilters>) {
	const { formatMessage } = useIntl();
	const services = useEnum<number>('services');
	const [selectedServices, setSelectedServices] = useState<Array<number>>([]);

	const handleChange = useCallback(
		(values) => {
			if (values.length === 0) {
				onChange && onChange([], 'service_id');
			} else {
				onChange &&
				onChange(
						[
							{
								field: 'service_id',
								condition_type: FilterComparisonType.IN,
								value: values,
							},
						],
						'service_id',
					);
			}
			setSelectedServices(values);
		},
		[onChange, setSelectedServices],
	);

	return (
		<div>
			{services && (
				<div>
					<Title title={formatMessage(messages.filterTitle)} />
					<ScrollView
						classes={{ container: classes.scrollView, scrollerContainer: classes.scrollerContainer, scrollerElement: classes.scrollerElement }}
					>
						<CheckboxList options={services || []} onChange={(values) => handleChange(values)} value={selectedServices} />
					</ScrollView>
				</div>
			)}
		</div>
	);
}
