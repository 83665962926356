import clsx from 'clsx';
import Logo from '../../../assets/logo.svg';
import classes from './index.module.scss';

export default function BpnlLogo({ size = 'large' }: { size?: 'small' | 'medium' | 'large' }) {
	return (
		<div className={clsx(classes.bpnlLogo, classes[size])}>
			<Logo />
		</div>
	);
}
