import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
// Webolucio imports
import { FilterQuery, SortOrder } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { DetailedPerformer, performersMessages, usePerformers } from '@bpnl/performers';
// App imports
import PerformerCard from '../../presentationals/PerformerCard';
import PerformerCardSkeleton from '../../presentationals/PerformerCard/Skeleton';
// Local imports
import classes from './index.module.scss';
import { PerformerGridProps } from './types';

const CARDS_TO_SHOW = 18;

export default function PerformerGrid(props: PerformerGridProps) {
	const { className, emptyMessage, filter, limit = CARDS_TO_SHOW, mode, name, offset, search, sortBy, sortOrder } = props;

	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const { formatMessage } = useIntl();

	const { performers, isPerformersFetching, performerCount, nextPage } = usePerformers({
		mode,
		queryKey: name,
		filter: filter as FilterQuery<DetailedPerformer>,
		search,
		sortBy: sortBy || 'name',
		sortOrder: sortOrder || SortOrder.ASC,
		offset,
		limit,
	});

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextPage()) {
					setPendingNextPage(false);
				} else {
					if (isPerformersFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isPerformersFetching, nextPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	useEffect(() => {
		if (!isPerformersFetching && pendingNextPage) {
			nextPage();
			setPendingNextPage(false);
		}
	}, [isPerformersFetching, nextPage, pendingNextPage]);

	return performerCount === 0 && !isPerformersFetching ? (
		<p className={clsx(classes.emptyMessage, 'performer-grid-empty-message', className)}>
			{typeof emptyMessage === 'string' ? emptyMessage : formatMessage(emptyMessage || performersMessages.noPerformers)}
		</p>
	) : (
		<div className={clsx(classes.performerGrid, className)}>
			{!performers.length && isPerformersFetching
				? Array.from({ length: CARDS_TO_SHOW }).map((_, idx) => <PerformerCardSkeleton key={`performer-grid-skeleton-${idx}`} />)
				: performers
						.map((performer, idx) => (
							<PerformerCard
								key={`${name}-grid-card-${performer.id}`}
								performerId={performer.id}
								ref={idx === performers.length - 7 || (performers.length - 7 < 0 && idx + 1 === performers.length) ? itemRef : undefined}
							/>
						))
						.concat(
							performerCount > performers.length
								? [
										<div className={classes.loader} key="performer-grid-loader">
											<LoadOverlay size="medium" />
										</div>,
									]
								: [],
						)}
		</div>
	);
}
