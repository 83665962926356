import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { ClickAwayListener, useLogout } from '@webolucio/web';
// BPNL imports
import { myAccountMessages } from '@bpnl/account';
// Local imports
import { ProfileMenuPanelProps } from './types';
import classes from './index.module.scss';

export default function ProfileMenuPanel({ className, onClose, open }: ProfileMenuPanelProps) {
	const { formatMessage } = useIntl();
	const { logout } = useLogout();
	const navigate = useNavigate();

	if (!open) {
		return null;
	}

	return (
		<ClickAwayListener onClickAway={onClose}>
			<div className={clsx(classes.profileMenuPanel, className)}>
				<ul className={classes.menuList}>
					<li className={classes.menuItem} onClick={() => navigate('/my-account')}>
						{formatMessage(myAccountMessages.personalData)}
					</li>
					<li className={classes.menuItem} onClick={logout}>
						{formatMessage(myAccountMessages.logout)}
					</li>
				</ul>
			</div>
		</ClickAwayListener>
	);
}
