import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, debounce, put } from '@redux-saga/core/effects';
// External imports
import { eventsActions } from '@bpnl/events';
import { venuesActions } from '@bpnl/venues';
import { organizersActions } from '@bpnl/organizers';
import { performersActions } from '@bpnl/performers';
// Local imports
import { followApi } from './api';
import { followActions } from './slice';
function postFollowSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    10,
                    ,
                    12
                ]);
                return [
                    4,
                    call(followApi.postFollow, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(followActions.postFollowFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(followActions.postFollowSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    4,
                    put(eventsActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: true,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 5:
                _state.sent();
                return [
                    4,
                    put(venuesActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: true,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    4,
                    put(organizersActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: true,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 7:
                _state.sent();
                return [
                    4,
                    put(performersActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: true,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 8:
                _state.sent();
                return [
                    4,
                    put(followActions.removeSelected(action.payload))
                ];
            case 9:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 10:
                err = _state.sent();
                return [
                    4,
                    put(followActions.postFollowFailure([
                        'clientError'
                    ]))
                ];
            case 11:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 12:
                return [
                    2
                ];
        }
    });
}
function deleteFollowSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    10,
                    ,
                    12
                ]);
                console.log('action payload', action.payload);
                return [
                    4,
                    call(followApi.deleteFollow, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                console.log('nem ok', response);
                return [
                    4,
                    put(followActions.deleteFollowFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(followActions.deleteFollowSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    4,
                    put(eventsActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: false,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 5:
                _state.sent();
                return [
                    4,
                    put(venuesActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: false,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    4,
                    put(organizersActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: false,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 7:
                _state.sent();
                return [
                    4,
                    put(performersActions.setFollowed({
                        id: action.payload.id,
                        isFollowed: false,
                        countFollowers: response.data.count_followers,
                        type: action.payload.type
                    }))
                ];
            case 8:
                _state.sent();
                return [
                    4,
                    put(followActions.removeSelected(action.payload))
                ];
            case 9:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 10:
                err = _state.sent();
                console.log('clientError', err);
                return [
                    4,
                    put(followActions.deleteFollowFailure([
                        'clientError'
                    ]))
                ];
            case 11:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 12:
                return [
                    2
                ];
        }
    });
}
export var followSagas = {
    postFollowSaga: postFollowSaga,
    deleteFollowSaga: deleteFollowSaga
};
export default function followSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        debounce(1000, followActions.postFollowRequest.type, postFollowSaga),
                        debounce(1000, followActions.deleteFollowRequest.type, deleteFollowSaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
