import clsx from 'clsx';
// Local imports
import { IndicatorProps } from './types';
import classes from './index.module.scss';
import { useCallback } from 'react';

export default function Indicator(props: IndicatorProps) {
	const { steps, active, onClick } = props;

	const handleClick = useCallback((step) => onClick && onClick(step), [onClick]);

	return (
		<div className={classes.container}>
			{[...Array(steps)].map((step, idx) => (
				<div
					key={`Dot${idx}`}
					className={clsx(classes.dot, active === idx + 1 && classes.active, onClick && classes.clickable)}
					onClick={() => handleClick(idx + 1)} />
			))}
		</div>
	);
}
