// Webolucio imports
import { ApiService } from '@webolucio/core';
// Local imports
import { DataSet } from './types';
// Role
var getVenueList = function(param) {
    var _param_dataSet = param.dataSet, dataSet = _param_dataSet === void 0 ? DataSet.Full : _param_dataSet, filter = param.filter, offset = param.offset, limit = param.limit, sortBy = param.sortBy, sortOrder = param.sortOrder, search = param.search, search_in_fields = param.search_in_fields;
    return ApiService.getService().get("/venues", {
        dataSet: dataSet,
        filter: filter,
        offset: offset,
        limit: limit,
        orderby_field: sortBy,
        orderby_dir: sortOrder,
        search: search,
        search_in_fields: search_in_fields
    });
};
var getVenueContext = function() {
    return ApiService.getService().get("/venues/table-context");
};
var getVenue = function(id) {
    return ApiService.getService().get("/venues/".concat(id));
};
export var venuesApi = {
    getVenueList: getVenueList,
    getVenueContext: getVenueContext,
    getVenue: getVenue
};
