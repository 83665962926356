import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.RegistrationTypePage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Registration',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Create account!',
	},
	subtitle: {
		id: `${scope}.subtitle`,
		defaultMessage: 'Personalised recommendations, save your favourite events and locations!',
	},
	withEmail: {
		id: `${scope}.withEmail`,
		defaultMessage: 'Continue with email',
	},
	withFacebook: {
		id: `${scope}.withFacebook`,
		defaultMessage: 'Continue with Facebook',
	},
	withGoogle: {
		id: `${scope}.withGoogle`,
		defaultMessage: 'Continue with Google',
	},
	withApple: {
		id: `${scope}.withApple`,
		defaultMessage: 'Continue with Apple',
	},
});
