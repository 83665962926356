import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.Tickets';

export default defineMessages({
	soldOut: {
		id: `${scope}.soldOut`,
		defaultMessage: 'Sold out',
	},
	currency: {
		id: `${scope}.currency`,
		defaultMessage: 'HUF {amount}',
	}
});
