import clsx from 'clsx';
import { useCallback, useState } from 'react';
// Webolucio imports
import { FilterComparisonType, SortOrder } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
// BPNL imports
import { UNREAD_NOTIFICATIONS_QUERY_KEY, useNotifications } from '@bpnl/notifications';
// Local imports
import classes from './index.module.scss';
import NotificationsPanel from './NotificationsPanel';

export default function Notifications() {
	const [notificationsPanelOpen, setNotificationsPanelOpen] = useState(false);
	const { notificationsCount: unreadNotificationsCount } = useNotifications({
		queryKey: UNREAD_NOTIFICATIONS_QUERY_KEY,
		filter: [
			{
				field: 'is_read',
				condition_type: FilterComparisonType.EQUAL,
				value: false,
			},
		],
		limit: 100,
		sortBy: 'created_at',
		sortOrder: SortOrder.DESC,
	});

	const handleToggleNotificationsPanel = useCallback(() => {
		setNotificationsPanelOpen((prev) => !prev);
	}, []);

	return (
		<>
			<div className={clsx(classes.notifications, unreadNotificationsCount && classes.hasUnread)} onClick={handleToggleNotificationsPanel}>
				<Icon name="bpnl-notification" />
				{unreadNotificationsCount > 0 ? (
					<span className={classes.notificationsCount}>{unreadNotificationsCount > 99 ? 99 : unreadNotificationsCount}</span>
				) : null}
			</div>
			<NotificationsPanel className={classes.notificationsPanel} open={notificationsPanelOpen} onClose={handleToggleNotificationsPanel} />
		</>
	);
}
