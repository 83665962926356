import clsx from 'clsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { APIProvider, AdvancedMarker, InfoWindow, Map as GoogleMap, useAdvancedMarkerRef, MapCameraChangedEvent } from '@vis.gl/react-google-maps';
import { Link } from 'react-router-dom';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// BPNL imports
import { encodePoint, getPointByDistance } from '@bpnl/common';
// App imports
import useGeolocation from '../../../hooks/useGeolocation';
import Image from '../Image';
// Local imports
import { zoomLevels } from './config';
import classes from './index.module.scss';
import { Circle } from './Circle';
import { Polygon } from './Polygon';
import { MapProps } from './types';

const libraries = ['marker'];

export default function Map(props: MapProps) {
	const { variant = 'page', venues, venue } = props;
	const [positionRadius, setPositionRadius] = useState(15);
	const [selectedVenue, setSelectedVenue] = useState<number | null>(null);
	const [zoomLevel, setZoomLevel] = useState<number>(15);

	const [markerRef, marker] = useAdvancedMarkerRef();
	const center = {
		lat: 47.4959431,
		lng: 19.070815,
	};

	const mapRef = useRef<google.maps.Map | null>(null);
	const location = useGeolocation({ watch: true });

	/*const handleBoundsChanged = useCallback(() => {
		if (mapRef.current) {
			const bounds = mapRef.current.getBounds();
		}
	}, [location?.accuracy]);*/

	const locationStyles = useMemo(() => {
		const styles = getComputedStyle(document.body);
		return {
			fillColor: styles.getPropertyValue('--map-position-fill'),
			strokeColor: styles.getPropertyValue('--map-position-stroke'),
			draggable: false,
			clickable: false,
			editable: false,
			fillOpacity: 1,
			strokeWeight: 2,
			zIndex: 101,
		};
	}, []);

	const handleClose = useCallback(() => setSelectedVenue(null), []);

	const handleZoomChanged = useCallback((e: MapCameraChangedEvent) => {
		setZoomLevel(e.detail.zoom);
	}, []);

	return (
		<APIProvider apiKey="AIzaSyB_MrJUH_jPeC_GQDCTfCl3DyiCrlxbJUU" libraries={libraries}>
			<div className={clsx(classes.mapContainer, classes[variant])}>
				<GoogleMap
					className={classes.map}
					defaultZoom={15}
					defaultCenter={
						venue && venue.address && venue.address.coordinates
							? {
									lat: venue.address.coordinates.lat,
									lng: venue.address.coordinates.lng,
								}
							: location
								? { lat: location.latitude, lng: location.longitude }
								: center
					}
					disableDefaultUI
					fullscreenControl={false}
					mapTypeControl={false}
					onZoomChanged={handleZoomChanged}
					streetViewControl={false}
					zoomControl={false}
					mapId="fced8d488760a0d3"
				>
					{venue && venue.address && venue.address.coordinates ? (
						<AdvancedMarker
							position={{ lat: venue.address.coordinates.lat, lng: venue.address.coordinates.lng }}
							key={`info-${venue.id}`}
							className={clsx(classes.marker, venue.type)}
						>
							{venue.type === 'restaurant' ? <Icon name={'bpnl-beer'} /> : <Icon name={'bpnl-music'} />}
							<span>{venue.short_name || venue.name}</span>
						</AdvancedMarker>
					) : (
						<>
							{venues &&
								venues
									.filter((item, index) => item.address?.coordinates)
									.map((item, index) => (
										<>
											<AdvancedMarker
												position={{ lat: item.address!.coordinates!.lat, lng: item.address!.coordinates!.lng }}
												key={`map-marker-${index}`}
												className={clsx(classes.marker, item.type)}
												onClick={() => setSelectedVenue(item.id)}
												ref={markerRef}
											>
												{item.type === 'restaurant' ? <Icon name="bpnl-beer" /> : <Icon name="bpnl-music" />}
												{zoomLevel > zoomLevels.short && zoomLevel <= zoomLevels.long && item.short_name && (
													<span className={classes.markerDetails}>{item.short_name}</span>
												)}
												{zoomLevel > zoomLevels.long && zoomLevel <= zoomLevels.detailed && (
													<span className={classes.markerDetails}>{item.name}</span>
												)}
												{zoomLevel > zoomLevels.detailed && (
													<div className={clsx(classes.markerDetails, classes.doubleText)}>
														<span>{item.name}</span>
														<span>{item.address?.address}</span>
													</div>
												)}
											</AdvancedMarker>
											{selectedVenue === item.id && (
												<InfoWindow
													position={{ lat: item.address!.coordinates!.lat, lng: item.address!.coordinates!.lng }}
													onClose={handleClose}
													className={classes.venueWindowContent}
												>
													<div className={classes.venueImage}>
														<Image file={item.default_medium?.file || (item.media.length && item.media[0].file) || null} />
													</div>
													<Link to={`/venues/${item.id}`} className={classes.venueInfos}>
														<p>{item.name}</p>
														<Icon name="bpnl-arrow-right" />
													</Link>
												</InfoWindow>
											)}
										</>
									))}
							{location && typeof location.heading === 'number' ? (
								<Polygon
									encodedPaths={[
										encodePoint(getPointByDistance(location.latitude, location.longitude, 2 * positionRadius, location.heading)),
										encodePoint(getPointByDistance(location.latitude, location.longitude, positionRadius, location.heading + 90)),
										encodePoint(getPointByDistance(location.latitude, location.longitude, positionRadius, location.heading - 90)),
									]}
									{...locationStyles}
								/>
							) : null}
							{location && location.latitude && location.longitude ? (
								<Circle
									key={`pos-circle-${positionRadius}`}
									center={{ lng: location.longitude, lat: location.latitude }}
									radius={positionRadius}
									{...locationStyles}
								/>
							) : null}
						</>
					)}
				</GoogleMap>
			</div>
		</APIProvider>
	);
}
