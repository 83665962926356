import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { createSlice } from '@reduxjs/toolkit';
export var initialMessagesState = {
    data: [],
    deleting: false,
    errors: null,
    fetching: false,
    saving: false,
    selected: null,
    totalCount: 0,
    validating: false
};
var messagesSlice = createSlice({
    name: 'messages',
    initialState: initialMessagesState,
    reducers: {
        setSelected: function setSelected(state, action) {
            state.selected = state.data.find(function(item) {
                return item.id === action.payload;
            }) || initialMessagesState.selected;
        },
        clearSelected: function clearSelected(state) {
            state.selected = initialMessagesState.selected;
        },
        createMessageRequest: function createMessageRequest(state, action) {
            state.saving = true;
            state.errors = initialMessagesState.errors;
        },
        createMessageFailure: function createMessageFailure(state, action) {
            state.saving = initialMessagesState.saving;
            state.errors = action.payload;
        },
        createMessageSuccess: function createMessageSuccess(state, action) {
            state.saving = initialMessagesState.saving;
            state.data.push(action.payload);
            state.selected = action.payload;
        },
        createValidateMessageRequest: function createValidateMessageRequest(state, action) {
            state.validating = true;
            state.errors = initialMessagesState.errors;
        },
        createValidateMessageFailure: function createValidateMessageFailure(state, action) {
            state.validating = initialMessagesState.validating;
            state.errors = action.payload;
        },
        createValidateMessageSuccess: function createValidateMessageSuccess(state) {
            state.validating = initialMessagesState.validating;
        },
        getMessageListRequest: function getMessageListRequest(state, action) {
            state.fetching = true;
        },
        getMessageListFailure: function getMessageListFailure(state, action) {
            state.fetching = initialMessagesState.fetching;
            state.errors = action.payload;
        },
        getMessageListSuccess: function getMessageListSuccess(state, action) {
            state.errors = initialMessagesState.errors;
            state.fetching = initialMessagesState.fetching;
            state.data = action.payload.data || initialMessagesState.data;
            state.totalCount = action.payload.totalCount || initialMessagesState.totalCount;
        },
        getMessageRequest: function getMessageRequest(state, action) {
            state.fetching = true;
        },
        getMessageFailure: function getMessageFailure(state, action) {
            state.fetching = initialMessagesState.fetching;
            state.errors = action.payload;
        },
        getMessageSuccess: function getMessageSuccess(state, action) {
            state.errors = initialMessagesState.errors;
            state.fetching = initialMessagesState.fetching;
            state.data = state.data.map(function(item) {
                return item.id === action.payload.id ? _object_spread({}, item, action.payload) : item;
            });
            state.selected = action.payload;
        },
        noOpUpdate: function noOpUpdate(state, action) {},
        deleteMessageRequest: function deleteMessageRequest(state, action) {
            state.deleting = true;
        },
        deleteMessageFailure: function deleteMessageFailure(state, action) {
            state.deleting = initialMessagesState.deleting;
            state.errors = action.payload;
        },
        deleteMessageSuccess: function deleteMessageSuccess(state, action) {
            state.errors = initialMessagesState.errors;
            state.deleting = initialMessagesState.deleting;
            state.data = state.data.filter(function(item) {
                return action.payload.ids.indexOf(item.id) === -1;
            });
            if (state.selected && action.payload.ids.indexOf(state.selected.id) > -1) {
                state.selected = initialMessagesState.selected;
            }
        }
    }
});
export var messagesActions = messagesSlice.actions, messagesReducer = messagesSlice.reducer, messagesSliceKey = messagesSlice.name;
