import classes from './index.module.scss';

export default function EventCardSkeleton() {
	return (
		<div className={classes.eventCardSkeleton}>
			<div className={classes.image}></div>
			<div className={classes.content}>
				<div className={classes.row}></div>
				<div className={classes.row}></div>
				<div className={classes.row}></div>
			</div>
		</div>
	);
}
