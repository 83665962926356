import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
// BPNL imports
import { DEFAULT_PAGE_SIZE } from '@bpnl/common';
import { useFollowedPerformerEvents } from '@bpnl/events';
// App imports
import HorizontalSlider from '../HorizontalSlider';
import EventCard from '../../presentationals/EventCard';
import EventCardSkeleton from '../../presentationals/EventCard/Skeleton';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import { FollowedPerformerEventSliderProps } from './types';

const CARDS_TO_SHOW = 6;

export default function FollowedPerformerEventSlider(props: FollowedPerformerEventSliderProps) {
	const { className, limit = DEFAULT_PAGE_SIZE } = props;

	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const { formatMessage } = useIntl();

	const { followedPerformerEvents, isFollowedPerformerEventsFetching, nextFollowedPerformerEventsPage, eventCount } =
		useFollowedPerformerEvents(limit);

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextFollowedPerformerEventsPage()) {
					setPendingNextPage(false);
				} else {
					if (isFollowedPerformerEventsFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isFollowedPerformerEventsFetching, nextFollowedPerformerEventsPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	useEffect(() => {
		if (!isFollowedPerformerEventsFetching && pendingNextPage) {
			nextFollowedPerformerEventsPage();
			setPendingNextPage(false);
		}
	}, [isFollowedPerformerEventsFetching, nextFollowedPerformerEventsPage, pendingNextPage]);

	return (
		<div className={clsx(classes.eventSlider, 'followed-performer-event-slider', className)} ref={rootRef}>
			<h2 className={clsx(classes.title, 'followed-performer-event-slider-title')}>{formatMessage(messages.title)}</h2>
			<HorizontalSlider slidesToShow={CARDS_TO_SHOW} swipe>
				{!followedPerformerEvents.length && isFollowedPerformerEventsFetching
					? Array.from({ length: CARDS_TO_SHOW }).map((_, idx) => <EventCardSkeleton key={`followed-performer-event-skeleton-${idx}`} />)
					: followedPerformerEvents
							.map((event, idx) => (
								<EventCard
									key={`followed-performer-event-slider-card-${event.id}`}
									eventId={event.id}
									ref={idx === followedPerformerEvents.length - 2 ? itemRef : undefined}
									variant="narrow"
								/>
							))
							.concat(eventCount > followedPerformerEvents.length ? [<EventCardSkeleton key="followed-performer-event-loading-skeleton" />] : [])}
			</HorizontalSlider>
		</div>
	);
}
