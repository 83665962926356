import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { uniq } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';
// BPNL imports
import { QueryStoreMode } from '@bpnl/common';
// Local imports
import { NOTIFICATIONS_ENTITY_KEY, UNREAD_NOTIFICATIONS_QUERY_KEY } from './config';
export var initialNotificationsState = {
    data: {},
    queries: {}
};
var notificationsSlice = createSlice({
    name: NOTIFICATIONS_ENTITY_KEY,
    initialState: initialNotificationsState,
    reducers: {
        getNotificationListRequest: {
            prepare: function prepare(payload) {
                if (!payload.queryKey) {
                    payload.queryKey = 'default';
                }
                return {
                    payload: payload
                };
            },
            reducer: function reducer(state, action) {
                var queryKey = action.payload.queryKey || 'default';
                var mode = action.payload.mode || QueryStoreMode.Concat;
                if (!state.queries[queryKey]) {
                    state.queries[queryKey] = {
                        data: [],
                        errors: null,
                        fetching: false,
                        mode: mode,
                        totalCount: 0
                    };
                }
                state.queries[queryKey].fetching = true;
                if (action.payload.mode) {
                    state.queries[queryKey].mode = action.payload.mode;
                }
                if (action.payload.reset) {
                    state.queries[queryKey].data = [];
                    state.queries[queryKey].totalCount = 0;
                }
            }
        },
        getNotificationListFailure: function getNotificationListFailure(state, action) {
            var queryKey = action.payload.queryKey || 'default';
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        getNotificationListSuccess: function getNotificationListSuccess(state, action) {
            var _state_queries_queryKey, _action_payload_data, _action_payload_data1, _action_payload_data2;
            var queryKey = action.payload.queryKey || 'default';
            var mode = ((_state_queries_queryKey = state.queries[queryKey]) === null || _state_queries_queryKey === void 0 ? void 0 : _state_queries_queryKey.mode) || QueryStoreMode.Concat;
            (_action_payload_data = action.payload.data) === null || _action_payload_data === void 0 ? void 0 : _action_payload_data.forEach(function(item) {
                state.data[item.id] = item;
            });
            state.queries[queryKey] = {
                data: mode === QueryStoreMode.Concat ? uniq(state.queries[queryKey].data.concat(((_action_payload_data1 = action.payload.data) === null || _action_payload_data1 === void 0 ? void 0 : _action_payload_data1.map(function(item) {
                    return item.id;
                })) || [])) : ((_action_payload_data2 = action.payload.data) === null || _action_payload_data2 === void 0 ? void 0 : _action_payload_data2.map(function(item) {
                    return item.id;
                })) || [],
                errors: null,
                fetching: false,
                mode: mode,
                totalCount: action.payload.totalCount || 0
            };
        },
        getNotificationRequest: function getNotificationRequest(state, action) {
            var queryKey = "single-".concat(action.payload.id);
            if (!state.queries[queryKey]) {
                state.queries[queryKey] = {
                    data: [],
                    errors: null,
                    fetching: false,
                    mode: QueryStoreMode.Overwrite,
                    totalCount: 0
                };
            }
            state.queries[queryKey].fetching = true;
        },
        getNotificationFailure: function getNotificationFailure(state, action) {
            var queryKey = "single-".concat(action.payload.id);
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        getNotificationSuccess: function getNotificationSuccess(state, action) {
            var queryKey = "single-".concat(action.payload.id);
            if (!action.payload) {
                state.queries[queryKey].fetching = false;
            } else {
                state.data[action.payload.id] = action.payload;
                state.queries[queryKey] = {
                    data: [
                        action.payload.id
                    ],
                    errors: null,
                    fetching: false,
                    mode: QueryStoreMode.Overwrite,
                    totalCount: 1
                };
            }
        },
        patchNotificationRequest: function patchNotificationRequest(state, action) {
            var queryKey = "single-".concat(action.payload.id, "-patch");
            if (!state.queries[queryKey]) {
                state.queries[queryKey] = {
                    data: [
                        action.payload.id
                    ],
                    errors: null,
                    fetching: true,
                    mode: QueryStoreMode.Overwrite,
                    totalCount: 1
                };
            }
            state.queries[queryKey].fetching = true;
        },
        patchNotificationFailure: function patchNotificationFailure(state, action) {
            var queryKey = "single-".concat(action.payload.id, "-patch");
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        patchNotificationSuccess: function patchNotificationSuccess(state, action) {
            var queryKey = "single-".concat(action.payload.id, "-patch");
            state.data[action.payload.id] = action.payload;
            if (state.queries[queryKey]) {
                delete state.queries[queryKey];
            }
        },
        markNotificationAsRead: function markNotificationAsRead(state, action) {
            if (state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY]) {
                state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY].data = state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY].data.filter(function(id) {
                    return id !== action.payload.id;
                });
                state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY].totalCount -= 1;
            }
            if (state.data[action.payload.id]) {
                state.data[action.payload.id].is_read = true;
            }
        },
        syncNotification: function syncNotification(state, action) {},
        shiftNewNotification: function shiftNewNotification(state, action) {
            if (state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY]) {
                state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY].data = uniq([
                    action.payload.id
                ].concat(_to_consumable_array(state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY].data)));
                state.queries[UNREAD_NOTIFICATIONS_QUERY_KEY].totalCount += 1;
            }
            if (state.queries['default']) {
                state.queries['default'].data = uniq([
                    action.payload.id
                ].concat(_to_consumable_array(state.queries['default'].data)));
                state.queries['default'].totalCount += 1;
            }
        }
    }
});
export var notificationsActions = notificationsSlice.actions, notificationsReducer = notificationsSlice.reducer, notificationsSliceKey = notificationsSlice.name;
