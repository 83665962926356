import { useCallback, useEffect, useState } from 'react';
import { ApiService } from '@webolucio/core';

export interface UseGeolocationProps extends PositionOptions {
	watch?: boolean;
}

export default function useGeolocation({
	watch = false,
	maximumAge = 0,
	timeout = 5000,
	enableHighAccuracy = true,
}: UseGeolocationProps): GeolocationCoordinates | null {
	const [location, setLocation] = useState<GeolocationCoordinates | null>(null);

	const handleSuccess = useCallback((position: GeolocationPosition) => {
		setLocation(position.coords);
		ApiService.getService().setHeader('X-Bpnl-Location', `${position.coords.latitude},${position.coords.longitude}`);
	}, []);

	const handleError = useCallback((positionError: GeolocationPositionError) => {
		console.warn('Error getting location:', positionError.message);
		if (positionError.code === positionError.PERMISSION_DENIED || positionError.code === positionError.POSITION_UNAVAILABLE) {
			setLocation(null);
		}
	}, []);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
				if (permissionStatus.state !== 'denied') {
					if (watch) {
						const watchId = navigator.geolocation.watchPosition(handleSuccess, handleError, {
							enableHighAccuracy,
							timeout,
							maximumAge,
						});
						return () => navigator.geolocation.clearWatch(watchId);
					} else {
						navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
							enableHighAccuracy,
							timeout,
							maximumAge,
						});
					}
				}
			});
		} else {
			console.warn('Geolocation is not supported in your browser.');
		}
	}, [handleError, handleSuccess]);

	return location;
}
