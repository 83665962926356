import { format, isBefore, isAfter, isToday, isTomorrow, isThisWeek } from 'date-fns';
import { hu, enGB } from 'date-fns/locale';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { LocalStorageService, useLocale } from '@webolucio/core';
// Local imports
import { VISITED_EVENTS_STORED } from './constants';
import commonMessages from './messages';
import { getVisitedEntityKey } from './utils';
export function useVisitEntity(param) {
    var name = param.name, id = param.id, userId = param.userId;
    useEffect(function() {
        if (!id) {
            return;
        }
        if (id && userId) {
            LocalStorageService.getService().getItem(getVisitedEntityKey(name, userId)).then(function(visitedEntities) {
                if (!visitedEntities) {
                    visitedEntities = '[]';
                }
                if (typeof visitedEntities !== 'string') {
                    console.error('Failed to get visited entities for ' + name);
                    return;
                }
                var visitedEntitiesArray;
                try {
                    visitedEntitiesArray = JSON.parse(visitedEntities);
                } catch (e) {
                    visitedEntitiesArray = [];
                }
                var duplicates = visitedEntitiesArray.filter(function(item, index, self) {
                    return self.indexOf(item) === index;
                });
                var last = visitedEntitiesArray.lastIndexOf(id);
                if (!visitedEntitiesArray.includes(id)) {
                    visitedEntitiesArray.push(id);
                } else {
                    visitedEntitiesArray = duplicates.filter(function(item) {
                        return visitedEntitiesArray.indexOf(item) !== last;
                    });
                    visitedEntitiesArray.push(id);
                }
                LocalStorageService.getService().setItem(getVisitedEntityKey(name, userId), JSON.stringify(visitedEntitiesArray.slice(-VISITED_EVENTS_STORED))).then(function(success) {
                    if (!success) {
                        console.error('Failed to save visited entities for ' + name);
                    }
                });
            });
        }
    }, [
        id,
        name,
        userId
    ]);
}
export function useDateBreakdown(startDate, endDate) {
    var formatMessage = useIntl().formatMessage;
    var locale = useLocale().locale;
    return useMemo(function() {
        if (!startDate || !endDate) {
            return null;
        }
        var start = new Date(startDate);
        var end = new Date(endDate);
        if (isNaN(start.valueOf()) || isNaN(end.valueOf())) {
            return null;
        }
        var year = start.getFullYear().toString();
        var month = (start.getMonth() + 1).toString().padStart(2, '0');
        var monthLong;
        var day = start.getDate().toString().padStart(2, '0');
        var dayOfWeek;
        var hour = start.getHours().toString().padStart(2, '0');
        var minute = start.getMinutes().toString().padStart(2, '0');
        var startTime = format(start, 'HH:mm', {
            locale: locale === 'hu' ? hu : enGB
        });
        var endTime = format(end, 'HH:mm', {
            locale: locale === 'hu' ? hu : enGB
        });
        var shortDate;
        var longDate;
        var isHappeningNowResult = false;
        var isTodayResult = false;
        var isTomorrowResult = false;
        var isThisWeekResult = false;
        switch(start.getDay()){
            case 0:
                dayOfWeek = formatMessage(commonMessages.sunday);
                break;
            case 1:
                dayOfWeek = formatMessage(commonMessages.monday);
                break;
            case 2:
                dayOfWeek = formatMessage(commonMessages.tuesday);
                break;
            case 3:
                dayOfWeek = formatMessage(commonMessages.wednesday);
                break;
            case 4:
                dayOfWeek = formatMessage(commonMessages.thursday);
                break;
            case 5:
                dayOfWeek = formatMessage(commonMessages.friday);
                break;
            case 6:
                dayOfWeek = formatMessage(commonMessages.saturday);
                break;
            default:
                dayOfWeek = '';
                break;
        }
        switch(start.getMonth()){
            case 0:
                monthLong = formatMessage(commonMessages.january);
                break;
            case 1:
                monthLong = formatMessage(commonMessages.february);
                break;
            case 2:
                monthLong = formatMessage(commonMessages.march);
                break;
            case 3:
                monthLong = formatMessage(commonMessages.april);
                break;
            case 4:
                monthLong = formatMessage(commonMessages.may);
                break;
            case 5:
                monthLong = formatMessage(commonMessages.june);
                break;
            case 6:
                monthLong = formatMessage(commonMessages.july);
                break;
            case 7:
                monthLong = formatMessage(commonMessages.august);
                break;
            case 8:
                monthLong = formatMessage(commonMessages.september);
                break;
            case 9:
                monthLong = formatMessage(commonMessages.october);
                break;
            case 10:
                monthLong = formatMessage(commonMessages.november);
                break;
            case 11:
                monthLong = formatMessage(commonMessages.december);
                break;
            default:
                monthLong = '';
                break;
        }
        switch(true){
            case isBefore(start, Date.now()) && isAfter(end, Date.now()):
                shortDate = formatMessage(commonMessages.nowHappening);
                longDate = formatMessage(commonMessages.nowHappening);
                isHappeningNowResult = true;
                break;
            case isToday(start):
                shortDate = formatMessage(commonMessages.today) + ', ' + startTime;
                longDate = formatMessage(commonMessages.today) + ', ' + startTime;
                isTodayResult = true;
                break;
            case isTomorrow(start):
                shortDate = formatMessage(commonMessages.tomorrow) + ', ' + startTime;
                longDate = formatMessage(commonMessages.tomorrow) + ', ' + startTime;
                isTomorrowResult = true;
                break;
            case isThisWeek(start, {
                locale: locale === 'hu' ? hu : enGB
            }):
                shortDate = dayOfWeek + ', ' + startTime;
                longDate = dayOfWeek + ', ' + startTime;
                isThisWeekResult = true;
                break;
            default:
                if (startDate === endDate) {
                    // If the two dates are the same, we don't need to display the end date
                    shortDate = format(start, 'LLL d. HH:mm', {
                        locale: locale === 'hu' ? hu : enGB
                    });
                    longDate = format(start, 'y. LLLL dd. EEEE, HH:mm', {
                        locale: locale === 'hu' ? hu : enGB
                    });
                } else {
                    shortDate = start.getDay() + 1 < end.getDay() ? format(start, 'LLL d.', {
                        locale: locale === 'hu' ? hu : enGB
                    }) + ' - ' + format(end, 'LLL d.', {
                        locale: locale === 'hu' ? hu : enGB
                    }) : format(start, 'LLL d. HH:mm', {
                        locale: locale === 'hu' ? hu : enGB
                    }) + ' - ' + endTime;
                    longDate = start.getDay() + 1 < end.getDay() ? format(start, 'y. LLLL dd. EEEE, HH:mm', {
                        locale: locale === 'hu' ? hu : enGB
                    }) + ' - ' + format(end, 'y. LLLL dd. EEEE, HH:mm', {
                        locale: locale === 'hu' ? hu : enGB
                    }) : format(start, 'y. LLLL dd. EEEE, HH:mm', {
                        locale: locale === 'hu' ? hu : enGB
                    }) + ' - ' + endTime;
                }
                break;
        }
        return {
            year: year,
            month: month,
            monthLong: monthLong,
            day: day,
            dayOfWeek: dayOfWeek,
            hour: hour,
            minute: minute,
            shortDate: shortDate,
            longDate: longDate,
            isHappeningNow: isHappeningNowResult,
            isToday: isTodayResult,
            isTomorrow: isTomorrowResult,
            isThisWeek: isThisWeekResult
        };
    }, [
        startDate,
        endDate,
        formatMessage,
        locale
    ]);
}
