import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// Local imports
import messages from './messages';
import classes from './index.module.scss';
import { SearchProps } from './types';
import Title from "../../presentationals/Title";

export default function Search({ onChange }: SearchProps) {
	const { formatMessage } = useIntl();

	const [value, setValue] = useState<string>('');

	const handleSearchTermChange = useCallback((e) => {
		setValue(e.target.value);
		onChange && onChange(e.target.value);
	}, []);

	const handleClear = useCallback(() => {
		setValue('');
		onChange && onChange('');
	}, []);

	return (
		<div>
			<Title title={formatMessage(messages.search)} />
			<div className={clsx(classes.inputContainer, value && classes.focused)}>
				<Icon name="search" className={classes.searchIcon} />
				<input value={value} type="text" onChange={handleSearchTermChange} placeholder={formatMessage(messages.inputPlaceholder)} className={classes.searchField} />
				{value && <Icon name="x" className={classes.clearIcon} onClick={handleClear} />}
			</div>
		</div>
	);
}
