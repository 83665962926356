import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { FilterComparisonType, useEnum } from '@webolucio/core';
// BPNL imports
import { EventFilters } from '@bpnl/events';
import { VenueFilters } from '@bpnl/venues';
import { useDistricts } from '@bpnl/common';
// App imports
import TagList from '../../presentationals/TagList';
import Title from '../../presentationals/Title';
// Local imports
import messages from './messages';
import { FilterProps } from '../types';

export default function LocationFilter({ onChange }: FilterProps<EventFilters | VenueFilters>) {
	const { formatMessage } = useIntl();

	const districts = useDistricts();
	const locations = useEnum<number>('region-tags', 'userspace');

	const [selectedLocations, setSelectedLocations] = useState<Array<number>>([]);
	const [selectedDistricts, setSelectedDistricts] = useState<Array<number>>([]);

	const handleChangedLocations = useCallback(
		(values) => {
			if (values.length === 0) {
				onChange && onChange([], 'location');
			} else {
				onChange &&
					onChange(
						[
							{
								field: 'tag_id',
								condition_type: FilterComparisonType.IN,
								value: values,
							},
						],
						'location',
					);
			}
			setSelectedLocations(values);
		},
		[onChange],
	);

	const handleChangedDistricts = useCallback(
		(values) => {
			if (values.length === 0) {
				onChange && onChange([], 'district');
			} else {
				onChange &&
					onChange(
						[
							{
								field: 'tag_id',
								condition_type: FilterComparisonType.IN,
								value: values,
							},
						],
						'district',
					);
			}
			setSelectedDistricts(values);
		},
		[onChange],
	);

	return (
		<div>
			<Title title={formatMessage(messages.filterTitle)} />
			<TagList options={locations || []} onChange={(values) => handleChangedLocations(values)} value={selectedLocations} multiple />
			<h3>{formatMessage(messages.district)}</h3>
			<TagList options={districts || []} onChange={(values) => handleChangedDistricts(values)} value={selectedDistricts} multiple />
		</div>
	);
}
