import { defineMessages } from 'react-intl';
export var scope = 'bpnl.notifications';
var messages = defineMessages({
    notification: {
        id: "".concat(scope, ".notification"),
        defaultMessage: 'Notification'
    },
    notifications: {
        id: "".concat(scope, ".notifications"),
        defaultMessage: 'Notifications'
    }
});
export default messages;
