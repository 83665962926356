import { useIntl } from 'react-intl';
// Webolucio imports
import { Icon } from '@webolucio/icons';
import { Modal } from '@webolucio/web';
// App imports
import { commonMessages } from '../../../i18n';
// Local imports
import { FilterOnMobileProps } from './types';

export default function FilterOnMobile(props: FilterOnMobileProps) {
	const { isMobile, open, close, children } = props;

	const { formatMessage } = useIntl();

	return (
		<div>
			{isMobile ? (
				<Modal open={open} onClose={close} headerIcon={<Icon name="filter" />} title={formatMessage(commonMessages.filter)}>
					{children}
				</Modal>
			) : (
				children
			)}
		</div>
	);
}
