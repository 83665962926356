import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, put, takeLeading } from '@redux-saga/core/effects';
// BPNL imports
import { takeEveryUnique } from '@bpnl/common';
// Local imports
import { organizersApi } from './api';
import { organizersActions } from './slice';
function getOrganizerListSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(organizersApi.getOrganizerList, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(organizersActions.getOrganizerListFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(organizersActions.getOrganizerListSuccess(_object_spread_props(_object_spread({}, response.data), {
                        queryKey: action.payload.queryKey
                    })))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(organizersActions.getOrganizerListFailure({
                        errors: [
                            'clientError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function getOrganizerSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(organizersApi.getOrganizer, action.payload.id)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(organizersActions.getOrganizerFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(organizersActions.getOrganizerSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(organizersActions.getOrganizerFailure({
                        errors: [
                            'clientError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
export var organizersSagas = {
    getOrganizerListSaga: getOrganizerListSaga,
    getOrganizerSaga: getOrganizerSaga
};
export default function organizersSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeEveryUnique(organizersActions.getOrganizerListRequest.type, getOrganizerListSaga, 'queryKey'),
                        takeLeading(organizersActions.getOrganizerRequest.type, getOrganizerSaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
