import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.ForgotPasswordPage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Forgot password',
	},
	userNameInputPlaceholder: {
		id: `${scope}.userNameInputPlaceholder`,
		defaultMessage: 'E-mail address',
	},
	successMessage: {
		id: `${scope}.successMessage`,
		defaultMessage: 'The password reset link is sent.',
	},
	captchaError: {
		id: `${scope}.captchaError`,
		defaultMessage: 'The captcha is not valid.',
	},
	btnSendPasswordReset: {
		id: `${scope}.btnSendPasswordReset`,
		defaultMessage: 'Send password reset link',
	},
	btnBackToLogin: {
		id: `${scope}.btnBackToLogin`,
		defaultMessage: 'Back to login',
	},
	stepsTitle: {
		id: `${scope}.stepsTitle`,
		defaultMessage: 'Forgot your password?',
	},
	stepsSubtitle: {
		id: `${scope}.stepsSubtitle`,
		defaultMessage: 'You can reset it in a few steps:',
	},
	stepOne: {
		id: `${scope}.stepOne`,
		defaultMessage: 'Fill in your e-mail address and click on the "{btnName}" button.',
	},
	stepTwo: {
		id: `${scope}.stepTwo`,
		defaultMessage: 'An e-mail with a link to reset your password will be sent to you.',
	},
	stepThree: {
		id: `${scope}.stepThree`,
		defaultMessage: 'Click on the link in the e-mail and follow the instructions.',
	},
});
