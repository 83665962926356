import { throttle } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

export default function useIsDesktop() {
	const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

	const handleResize = useCallback(() => {
		setIsDesktop(window.innerWidth >= 1024);
	}, []);

	useEffect(() => {
		const throttleResize = throttle(handleResize, 300);
		window.addEventListener('resize', throttleResize);
		return () => window.removeEventListener('resize', throttleResize);
	}, [handleResize]);

	return isDesktop;
}
