import { defineMessages } from 'react-intl';
export var scope = 'bpnl.venues';
var messages = defineMessages({
    venue: {
        id: "".concat(scope, ".venue"),
        defaultMessage: 'Venue'
    },
    venues: {
        id: "".concat(scope, ".venues"),
        defaultMessage: 'Venues'
    },
    noVenues: {
        id: "".concat(scope, ".noVenues"),
        defaultMessage: 'There are no venues to show.'
    },
    reportTitle: {
        id: "".concat(scope, ".reportTitle"),
        defaultMessage: "Can't find the venue you're looking for?"
    }
});
export default messages;
