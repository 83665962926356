import { useIntl } from 'react-intl';
import clsx from 'clsx';
//APP imports
import BpnlButton from '../BpnlButton';
import { commonMessages } from '../../../i18n';
//Local imports
import { FilterButtonProps } from './types';
import classes from './index.module.scss';

export default function FilterButton(props: FilterButtonProps) {
	const { onClick } = props;
	const { formatMessage } = useIntl();
	return (
		<div>
			<BpnlButton onClick={onClick} size={'small'} iconBefore={'filter'} className={clsx(classes.filterButton)}>
				{formatMessage(commonMessages.filter)}
			</BpnlButton>
		</div>
	);
}
