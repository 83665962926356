import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { uniq } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';
// BPNL imports
import { FollowType, QueryStoreMode } from '@bpnl/common';
// Local imports
import { EVENT_ENTITY_KEY } from './config';
export var initialEventsState = {
    data: {},
    queries: {},
    searchedItems: [],
    singleItemFetching: false,
    singleItemErrors: null
};
var eventsSlice = createSlice({
    name: EVENT_ENTITY_KEY,
    initialState: initialEventsState,
    reducers: {
        getVisitedEvents: function getVisitedEvents(state) {},
        updateEvent: function updateEvent(state, action) {
            state.data[action.payload.id] = action.payload;
        },
        storeVisitedEvents: function storeVisitedEvents(state, action) {
            var _state_queries_action_payload_key;
            state.queries[action.payload.key] = {
                data: action.payload.orderedIds,
                errors: null,
                fetching: false,
                mode: QueryStoreMode.Overwrite,
                totalCount: ((_state_queries_action_payload_key = state.queries[action.payload.key]) === null || _state_queries_action_payload_key === void 0 ? void 0 : _state_queries_action_payload_key.totalCount) || action.payload.orderedIds.length
            };
        },
        getSearchedEvents: function getSearchedEvents(state) {},
        initSearchedEvents: function initSearchedEvents(state, action) {
            state.searchedItems = action.payload;
        },
        storeSearchedEvent: function storeSearchedEvent(state, action) {
            var term = action.payload.trim();
            state.searchedItems = state.searchedItems.includes(term) ? state.searchedItems : _to_consumable_array(state.searchedItems).concat([
                term
            ]);
        },
        deleteSearchedEvent: function deleteSearchedEvent(state, action) {
            var term = action.payload.trim();
            state.searchedItems = state.searchedItems.filter(function(item) {
                return item !== term;
            });
        },
        getEventListRequest: {
            prepare: function(payload) {
                if (!payload.queryKey) {
                    payload.queryKey = 'default';
                }
                return {
                    payload: payload
                };
            },
            reducer: function(state, action) {
                var queryKey = action.payload.queryKey || 'default';
                var mode = action.payload.mode || QueryStoreMode.Concat;
                if (!state.queries[queryKey]) {
                    state.queries[queryKey] = {
                        data: [],
                        errors: null,
                        fetching: false,
                        mode: mode,
                        totalCount: 0
                    };
                }
                state.queries[queryKey].fetching = true;
                if (action.payload.mode) {
                    state.queries[queryKey].mode = action.payload.mode;
                }
                if (action.payload.reset) {
                    state.queries[queryKey].data = [];
                    state.queries[queryKey].totalCount = 0;
                }
            }
        },
        getEventListFailure: function getEventListFailure(state, action) {
            var queryKey = action.payload.queryKey || 'default';
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        getEventListSuccess: function getEventListSuccess(state, action) {
            var _state_queries_queryKey, _action_payload_data, _action_payload_data1, _action_payload_data2;
            var queryKey = action.payload.queryKey || 'default';
            var mode = ((_state_queries_queryKey = state.queries[queryKey]) === null || _state_queries_queryKey === void 0 ? void 0 : _state_queries_queryKey.mode) || QueryStoreMode.Concat;
            (_action_payload_data = action.payload.data) === null || _action_payload_data === void 0 ? void 0 : _action_payload_data.forEach(function(item) {
                state.data[item.id] = item;
            });
            state.queries[queryKey] = {
                data: mode === QueryStoreMode.Concat ? uniq(state.queries[queryKey].data.concat(((_action_payload_data1 = action.payload.data) === null || _action_payload_data1 === void 0 ? void 0 : _action_payload_data1.map(function(item) {
                    return item.id;
                })) || [])) : ((_action_payload_data2 = action.payload.data) === null || _action_payload_data2 === void 0 ? void 0 : _action_payload_data2.map(function(item) {
                    return item.id;
                })) || [],
                errors: null,
                fetching: false,
                mode: mode,
                totalCount: action.payload.totalCount || 0
            };
        },
        getEventRequest: function getEventRequest(state, action) {
            state.singleItemFetching = true;
        },
        getEventFailure: function getEventFailure(state, action) {
            state.singleItemFetching = initialEventsState.singleItemFetching;
            state.singleItemErrors = action.payload;
        },
        getEventSuccess: function getEventSuccess(state, action) {
            state.singleItemErrors = initialEventsState.singleItemErrors;
            state.singleItemFetching = initialEventsState.singleItemFetching;
            state.data[action.payload.id] = action.payload;
        },
        setFollowed: function setFollowed(state, action) {
            if (action.payload.type === FollowType.Event) {
                if (state.data[action.payload.id]) {
                    state.data[action.payload.id].is_followed = action.payload.isFollowed;
                    state.data[action.payload.id].count_followers = action.payload.countFollowers;
                }
            }
        }
    }
});
export var eventsActions = eventsSlice.actions, eventsReducer = eventsSlice.reducer, eventsSliceKey = eventsSlice.name;
