import { createSlice } from '@reduxjs/toolkit';
export var initialTextsState = {
    errors: null,
    fetching: false,
    totalCount: 0,
    selected: null
};
var textsSlice = createSlice({
    name: 'texts',
    initialState: initialTextsState,
    reducers: {
        getTextRequest: function getTextRequest(state, action) {
            state.fetching = true;
        },
        getTextFailure: function getTextFailure(state, action) {
            state.fetching = initialTextsState.fetching;
            state.errors = action.payload;
        },
        getTextSuccess: function getTextSuccess(state, action) {
            state.errors = initialTextsState.errors;
            state.fetching = initialTextsState.fetching;
            state.selected = action.payload;
        }
    }
});
export var textsActions = textsSlice.actions, textsReducer = textsSlice.reducer, textsSliceKey = textsSlice.name;
