import { defineMessages } from 'react-intl';
export var scope = 'bpnl.events';
var messages = defineMessages({
    event: {
        id: "".concat(scope, ".event"),
        defaultMessage: 'Event'
    },
    eventPlural: {
        id: "".concat(scope, ".eventPlural"),
        defaultMessage: 'Events'
    },
    events: {
        id: "".concat(scope, ".events"),
        defaultMessage: 'Events'
    },
    noEvents: {
        id: "".concat(scope, ".noEvents"),
        defaultMessage: 'There are no events to show.'
    },
    featured: {
        id: "".concat(scope, ".featured"),
        defaultMessage: 'Featured'
    },
    reportTitle: {
        id: "".concat(scope, ".reportTitle"),
        defaultMessage: "Can't find the event you're looking for?"
    }
});
export default messages;
