import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, debounce, put } from '@redux-saga/core/effects';
// Local imports
import { textsApi } from './api';
import { textsActions } from './slice';
function getTextSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(textsApi.getText, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(textsActions.getTextFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(textsActions.getTextSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(textsActions.getTextFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
export var textsSagas = {
    getTextSaga: getTextSaga
};
export default function textsSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        debounce(1000, textsActions.getTextRequest.type, getTextSaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
