import { useIntl } from 'react-intl';
import commonMessages from './messages';
export var useDistricts = function() {
    var formatMessage = useIntl().formatMessage;
    return [
        {
            id: 1,
            label: formatMessage(commonMessages.i)
        },
        {
            id: 2,
            label: formatMessage(commonMessages.ii)
        },
        {
            id: 3,
            label: formatMessage(commonMessages.iii)
        },
        {
            id: 4,
            label: formatMessage(commonMessages.iv)
        },
        {
            id: 5,
            label: formatMessage(commonMessages.v)
        },
        {
            id: 6,
            label: formatMessage(commonMessages.vi)
        },
        {
            id: 7,
            label: formatMessage(commonMessages.vii)
        },
        {
            id: 8,
            label: formatMessage(commonMessages.viii)
        },
        {
            id: 9,
            label: formatMessage(commonMessages.ix)
        },
        {
            id: 10,
            label: formatMessage(commonMessages.x)
        },
        {
            id: 11,
            label: formatMessage(commonMessages.xi)
        },
        {
            id: 12,
            label: formatMessage(commonMessages.xii)
        },
        {
            id: 13,
            label: formatMessage(commonMessages.xiii)
        },
        {
            id: 14,
            label: formatMessage(commonMessages.xiv)
        },
        {
            id: 15,
            label: formatMessage(commonMessages.xv)
        },
        {
            id: 16,
            label: formatMessage(commonMessages.xvi)
        },
        {
            id: 17,
            label: formatMessage(commonMessages.xvii)
        },
        {
            id: 18,
            label: formatMessage(commonMessages.xviii)
        },
        {
            id: 19,
            label: formatMessage(commonMessages.xix)
        },
        {
            id: 20,
            label: formatMessage(commonMessages.xx)
        },
        {
            id: 21,
            label: formatMessage(commonMessages.xxi)
        },
        {
            id: 22,
            label: formatMessage(commonMessages.xxii)
        },
        {
            id: 23,
            label: formatMessage(commonMessages.xxiii)
        }
    ];
};
