import clsx from 'clsx';
import { Outlet } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { registrationReducer, registrationSaga, registrationSliceKey } from '@bpnl/registration';
// App imports
import EntryBackground from '../../../assets/images/bg-entry.jpg';
import Image from '../../presentationals/Image';
import Header from '../Header';
// Local imports
import classes from './index.module.scss';

export default function AuthTemplate() {
	useInjectReducer({ key: registrationSliceKey, reducer: registrationReducer });
	useInjectSaga({ key: registrationSliceKey, saga: registrationSaga });
	return (
		<>
			<Header className={classes.header} />
			<div className={classes.background}>
				<Image file={null} src={EntryBackground} alt="background" />
			</div>
			<main className={clsx(classes.content, 'auth-content')}>
				<Outlet />
				<LoadOverlay classes={{ container: classes.overlay }} fixed />
			</main>
		</>
	);
}
