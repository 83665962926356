import clsx from 'clsx';
import { MouseEvent } from 'react';
// Webolucio imports
import { WebolucioRouteObject } from '@webolucio/web';
// Local imports
import NavigationItem from './NavigationItem';
import classes from './index.module.scss';

export default function MainNavigation({
	className,
	routes,
	onItemClick,
}: {
	className?: string;
	routes: WebolucioRouteObject[];
	onItemClick: (e: MouseEvent<HTMLAnchorElement>) => void;
}) {
	return (
		<nav className={clsx(classes.navigation, className, 'navigation', 'main')}>
			{routes
				.filter((r) => !r.handle?.skipInNavigation)
				.map((route) => (
					<NavigationItem route={route} key={route.id || route.path || 'unknown'} onClick={onItemClick} />
				))}
		</nav>
	);
}
