import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
// BPNL imports
import { useText } from '@bpnl/texts';
// App imports
import useLoadingFlag from '../../hooks/useLoadingFlag';
// Local imports
import messages from './messages';

export default function StaticContentPage() {
	const { formatMessage } = useIntl();
	const { str_id } = useParams();

	const { isTextsFetching, selectedText } = useText({ str_id: str_id! });

	useLoadingFlag(isTextsFetching)

	return (
		selectedText ? (
			<>
				<h1>{selectedText.name}</h1>
				<div dangerouslySetInnerHTML={{ __html: selectedText.body }} />
			</>
		) : (
			<>
				<h1>{formatMessage(messages.contentNotFound)}</h1>
			</>
		)
	);
}
