import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, put, race, select, takeEvery, takeLeading } from '@redux-saga/core/effects';
// Webolucio imports
import { takeOnly } from '@webolucio/core';
// BPNL imports
import { takeEveryUnique, takeLeadingUnique } from '@bpnl/common';
// Local imports
import { notificationsApi } from './api';
import { notificationsActions } from './slice';
import { selectNotification } from './selectors';
function getNotificationListSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(notificationsApi.getNotificationList, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(notificationsActions.getNotificationListFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(notificationsActions.getNotificationListSuccess(_object_spread_props(_object_spread({}, response.data), {
                        queryKey: action.payload.queryKey
                    })))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(notificationsActions.getNotificationListFailure({
                        errors: [
                            'clientError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function getNotificationSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(notificationsApi.getNotification, action.payload.id)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(notificationsActions.getNotificationFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(notificationsActions.getNotificationSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(notificationsActions.getNotificationFailure({
                        errors: [
                            'clientError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function patchNotificationSaga(action) {
    var notification, sameData, notificationProperty, response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    10,
                    ,
                    12
                ]);
                if (!!action.payload.background) return [
                    3,
                    5
                ];
                return [
                    4,
                    select(selectNotification)
                ];
            case 1:
                notification = _state.sent()(action.payload.id);
                if (!(notification === null)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(notificationsActions.patchNotificationFailure({
                        errors: [
                            'clientError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                // Short-circuit if the notification would not change
                sameData = true;
                for(var notificationProperty in action.payload.data){
                    if (notification[notificationProperty] !== action.payload.data[notificationProperty]) {
                        sameData = false;
                        break;
                    }
                }
                if (!sameData) return [
                    3,
                    5
                ];
                return [
                    4,
                    put(notificationsActions.patchNotificationSuccess(notification))
                ];
            case 4:
                return [
                    2,
                    _state.sent()
                ];
            case 5:
                return [
                    4,
                    call(notificationsApi.patchNotification, action.payload.id, action.payload.data)
                ];
            case 6:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    8
                ];
                return [
                    4,
                    put(notificationsActions.patchNotificationFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 7:
                return [
                    2,
                    _state.sent()
                ];
            case 8:
                return [
                    4,
                    put(notificationsActions.patchNotificationSuccess(response.data))
                ];
            case 9:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 10:
                err = _state.sent();
                return [
                    4,
                    put(notificationsActions.patchNotificationFailure({
                        errors: [
                            'clientError'
                        ],
                        id: action.payload.id
                    }))
                ];
            case 11:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 12:
                return [
                    2
                ];
        }
    });
}
function syncNotificationSaga(action) {
    var _ref, success, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    put(notificationsActions.getNotificationRequest({
                        id: action.payload.id
                    }))
                ];
            case 1:
                _state.sent();
                return [
                    4,
                    race({
                        success: takeOnly(notificationsActions.getNotificationSuccess.type, function(a) {
                            return a.payload.id === action.payload.id;
                        }),
                        error: takeOnly(notificationsActions.getNotificationFailure.type, function(a) {
                            return a.payload.id === action.payload.id;
                        })
                    })
                ];
            case 2:
                _ref = _state.sent(), success = _ref.success, error = _ref.error;
                if (!success) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(notificationsActions.shiftNewNotification({
                        id: action.payload.id
                    }))
                ];
            case 3:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 4:
                console.error('Failed to sync notification', action.payload.id, (error === null || error === void 0 ? void 0 : error.payload.errors) || 'unknown error');
                _state.label = 5;
            case 5:
                return [
                    2
                ];
        }
    });
}
export var notificationsSagas = {
    getNotificationListSaga: getNotificationListSaga,
    getNotificationSaga: getNotificationSaga,
    patchNotificationSaga: patchNotificationSaga,
    syncNotificationSaga: syncNotificationSaga
};
export default function notificationsSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeEveryUnique(notificationsActions.getNotificationListRequest.type, getNotificationListSaga, 'queryKey'),
                        takeLeading(notificationsActions.getNotificationRequest.type, getNotificationSaga),
                        takeEvery(notificationsActions.patchNotificationRequest.type, patchNotificationSaga),
                        takeLeadingUnique(notificationsActions.syncNotification.type, syncNotificationSaga, 'id')
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
