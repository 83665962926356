import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialTextsState, textsSliceKey } from './slice';
var selectDomain = function(state) {
    return state[textsSliceKey] || initialTextsState;
};
export var selectTextsIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.fetching;
});
export var selectTextsErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.errors;
});
export var selectSelectedText = createSelector([
    selectDomain
], function(storeState) {
    return storeState.selected;
});
