import { useCallback, useMemo } from 'react';
// Local imports
import { YoutubeSongCardProps } from './types';
import classes from './index.module.scss';
import { Link } from 'react-router-dom';

export default function YoutubeSongCard(props: YoutubeSongCardProps) {
	const { title, image_url, link, media_length_string } = props;

	const youtubeCard = useMemo(
		() => (
			<div className={classes.card}>
				<div className={classes.thumbnail}>
					{image_url && <img alt={title} src={image_url} />}
					<span className={classes.length}>{media_length_string}</span>
				</div>
				<p>{title}</p>
			</div>
		),
		[],
	);

	return link ? (
		<Link to={link} target="_blank" rel="noopener noreferrer">
			{youtubeCard}
		</Link>
	) : (
		youtubeCard
	);
}
