import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { cloneDeep } from 'lodash-es';
import { all, call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
// Webolucio imports
import { debounceCollect, FilterComparisonType } from '@webolucio/core';
// BPNL imports
import { findNumericSequences, FollowType, getStoredSearchHistory, storeSearchHistory, takeLatestUnique } from '@bpnl/common';
import { myAccountActions, selectMyAccount } from '@bpnl/account';
// Local imports
import { venuesApi } from './api';
import { VENUE_ENTITY_KEY } from './config';
import { selectSearchedVenues, selectVenuesData, selectVenuesQueryResults } from './selectors';
import { venuesActions } from './slice';
function getVenueListSaga(action) {
    var queries, queryKey, query, request, sequences, singleLengthSequences, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, sequence, response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    6,
                    ,
                    8
                ]);
                return [
                    4,
                    select(selectVenuesQueryResults)
                ];
            case 1:
                queries = _state.sent();
                queryKey = action.payload.queryKey || 'default';
                query = queries[queryKey];
                request = cloneDeep(action.payload);
                if (request.queryKey === 'map') {
                    request.filter = request.filter || [];
                    // Remove ids from the query that are already fetched
                    if (query.data.length > 100) {
                        sequences = findNumericSequences(_to_consumable_array(query.data));
                        singleLengthSequences = new Set();
                        _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                        try {
                            for(_iterator = sequences[Symbol.iterator](); !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                                sequence = _step.value;
                                if (sequence.end - sequence.start > 1) {
                                    request.filter.push({
                                        field: 'id',
                                        condition_type: FilterComparisonType.NOT_BETWEEN,
                                        value: [
                                            sequence.start,
                                            sequence.end
                                        ]
                                    });
                                } else {
                                    singleLengthSequences.add(sequence.start);
                                    singleLengthSequences.add(sequence.end);
                                }
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally{
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return != null) {
                                    _iterator.return();
                                }
                            } finally{
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }
                        if (singleLengthSequences.size) {
                            request.filter.push({
                                field: 'id',
                                condition_type: FilterComparisonType.NOT_IN,
                                value: Array.from(singleLengthSequences)
                            });
                        }
                    } else if (query.data.length) {
                        request.filter.push({
                            field: 'id',
                            condition_type: FilterComparisonType.NOT_IN,
                            value: query.data
                        });
                    } else if (request.filter.length === 0) {
                        // Unset filter if there was no filter to begin with and we also don't have any ids to exclude
                        request.filter = undefined;
                    }
                }
                return [
                    4,
                    call(venuesApi.getVenueList, request)
                ];
            case 2:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(venuesActions.getVenueListFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 3:
                return [
                    2,
                    _state.sent()
                ];
            case 4:
                return [
                    4,
                    put(venuesActions.getVenueListSuccess(_object_spread_props(_object_spread({}, response.data), {
                        queryKey: action.payload.queryKey
                    })))
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    8
                ];
            case 6:
                err = _state.sent();
                return [
                    4,
                    put(venuesActions.getVenueListFailure({
                        errors: [
                            'clientError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 7:
                _state.sent();
                return [
                    3,
                    8
                ];
            case 8:
                return [
                    2
                ];
        }
    });
}
function getVenueSaga(actions) {
    var requestedIds, filteredActions, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, action, response, err, err1;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    12,
                    ,
                    14
                ]);
                requestedIds = new Set();
                filteredActions = actions.reduce(function(acc, a) {
                    if (!requestedIds.has(a.payload.id)) {
                        acc.push(a);
                        requestedIds.add(a.payload.id);
                    }
                    return acc;
                }, []);
                _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    9,
                    10,
                    11
                ]);
                _iterator = filteredActions[Symbol.iterator]();
                _state.label = 2;
            case 2:
                if (!!(_iteratorNormalCompletion = (_step = _iterator.next()).done)) return [
                    3,
                    8
                ];
                action = _step.value;
                return [
                    4,
                    call(venuesApi.getVenue, action.payload.id)
                ];
            case 3:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    5
                ];
                return [
                    4,
                    put(venuesActions.getVenueFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 4:
                return [
                    2,
                    _state.sent()
                ];
            case 5:
                return [
                    4,
                    put(venuesActions.getVenueSuccess(response.data))
                ];
            case 6:
                _state.sent();
                _state.label = 7;
            case 7:
                _iteratorNormalCompletion = true;
                return [
                    3,
                    2
                ];
            case 8:
                return [
                    3,
                    11
                ];
            case 9:
                err = _state.sent();
                _didIteratorError = true;
                _iteratorError = err;
                return [
                    3,
                    11
                ];
            case 10:
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
                return [
                    7
                ];
            case 11:
                return [
                    3,
                    14
                ];
            case 12:
                err1 = _state.sent();
                return [
                    4,
                    put(venuesActions.getVenueFailure([
                        'clientError'
                    ]))
                ];
            case 13:
                _state.sent();
                return [
                    3,
                    14
                ];
            case 14:
                return [
                    2
                ];
        }
    });
}
function maybeUpdateLinkedEntityFollowState(action) {
    var venues, _tmp, _tmp1, _i, id, changed, venue, relatedVenueKey;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectVenuesData)
                ];
            case 1:
                venues = _state.sent();
                if (!(action.payload.type === FollowType.Venue)) return [
                    3,
                    6
                ];
                _tmp = [];
                for(_tmp1 in venues)_tmp.push(_tmp1);
                _i = 0;
                _state.label = 2;
            case 2:
                if (!(_i < _tmp.length)) return [
                    3,
                    5
                ];
                id = _tmp[_i];
                changed = false;
                venue = _object_spread({}, venues[id]);
                if (venue.related_venues && venue.related_venues.length) {
                    venue.related_venues = _to_consumable_array(venue.related_venues);
                    for(var relatedVenueKey in venue.related_venues){
                        if (venue.related_venues[relatedVenueKey].id === action.payload.id) {
                            venue.related_venues[relatedVenueKey] = _object_spread_props(_object_spread({}, venue.related_venues[relatedVenueKey]), {
                                is_followed: action.payload.isFollowed,
                                count_followers: action.payload.countFollowers
                            });
                            changed = true;
                        }
                    }
                }
                if (!changed) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(venuesActions.updateVenue(venue))
                ];
            case 3:
                _state.sent();
                _state.label = 4;
            case 4:
                _i++;
                return [
                    3,
                    2
                ];
            case 5:
                return [
                    2
                ];
            case 6:
                return [
                    2
                ];
        }
    });
}
function populateSearchHistorySaga() {
    var account, searchTerms;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    getStoredSearchHistory(VENUE_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0)
                ];
            case 2:
                searchTerms = _state.sent();
                return [
                    4,
                    put(venuesActions.initSearchedVenues(searchTerms))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function syncSearchHistorySaga(action) {
    var account, searchTerms;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    select(selectSearchedVenues)
                ];
            case 2:
                searchTerms = _state.sent();
                return [
                    4,
                    storeSearchHistory(VENUE_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0, searchTerms)
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export var venuesSagas = {
    getVenueListSaga: getVenueListSaga,
    getVenueSaga: getVenueSaga,
    populateSearchHistorySaga: populateSearchHistorySaga,
    syncSearchHistorySaga: syncSearchHistorySaga
};
export default function venuesSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeLatestUnique(venuesActions.getVenueListRequest.type, getVenueListSaga, 'queryKey'),
                        debounceCollect(1000, venuesActions.getVenueRequest.type, getVenueSaga),
                        takeEvery(venuesActions.setFollowed.type, maybeUpdateLinkedEntityFollowState),
                        takeLatest(myAccountActions.getMyAccountSuccess.type, populateSearchHistorySaga),
                        takeLatest(venuesActions.storeSearchedVenue.type, syncSearchHistorySaga),
                        takeLatest(venuesActions.deleteSearchedVenue.type, syncSearchHistorySaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
