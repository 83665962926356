import { memoize } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialPerformersState, performersSliceKey } from './slice';
var selectDomain = function(state) {
    return state[performersSliceKey] || initialPerformersState;
};
export var selectPerformersData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectPerformersQueryResults = createSelector([
    selectDomain
], function(storeState) {
    return storeState.queries;
});
export var selectGetPerformerIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.singleItemFetching;
});
export var selectPerformerListIsFetching = createSelector([
    selectPerformersQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.fetching) || false;
    });
});
export var selectGetPerformerErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.singleItemErrors;
});
export var selectPerformerListErrors = createSelector([
    selectPerformersQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.errors) || null;
    });
});
export var selectPerformersCount = createSelector([
    selectPerformersQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.totalCount) || 0;
    });
});
export var selectPerformersList = createSelector([
    selectPerformersData,
    selectPerformersQueryResults
], function(performers, queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return (((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.data) || []).reduce(function(acc, id) {
            var performer = performers[id];
            if (performer) acc.push(performer);
            return acc;
        }, []);
    });
});
export var selectPerformer = createSelector([
    selectPerformersData
], function(performers) {
    return memoize(function(id) {
        return performers[id] || null;
    });
});
export var selectSearchedPerformers = createSelector([
    selectDomain
], function(storeState) {
    return storeState.searchedItems;
});
