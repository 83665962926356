import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Local imports
import followSaga from './saga';
import { selectFollowErrors, selectFollowSavingItems } from './selectors';
import { followActions, followReducer, followSliceKey } from './slice';
export function useFollow() {
    useInjectReducer({
        key: followSliceKey,
        reducer: followReducer
    });
    useInjectSaga({
        key: followSliceKey,
        saga: followSaga
    });
    var dispatch = useDispatch();
    var followErrors = useSelector(selectFollowErrors);
    var followSavingItems = useSelector(selectFollowSavingItems);
    var submitFollow = useCallback(function(data) {
        return dispatch(followActions.postFollowRequest(data));
    }, [
        dispatch
    ]);
    var deleteFollow = useCallback(function(data) {
        return dispatch(followActions.deleteFollowRequest(data));
    }, [
        dispatch
    ]);
    return {
        followSavingItems: followSavingItems,
        followErrors: followErrors,
        submitFollow: submitFollow,
        deleteFollow: deleteFollow
    };
}
