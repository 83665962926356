import { defineMessages } from 'react-intl';
export var scope = 'bpnl.myAccount';
var messages = defineMessages({
    settings: {
        id: "".concat(scope, ".settings"),
        defaultMessage: 'Settings'
    },
    personalData: {
        id: "".concat(scope, ".personalData"),
        defaultMessage: 'Personal data'
    },
    languageSettings: {
        id: "".concat(scope, ".languageSettings"),
        defaultMessage: 'App language'
    },
    notifications: {
        id: "".concat(scope, ".notifications"),
        defaultMessage: 'Notifications'
    },
    profile: {
        id: "".concat(scope, ".profile"),
        defaultMessage: 'Profile'
    },
    support: {
        id: "".concat(scope, ".support"),
        defaultMessage: 'Support'
    },
    aboutUs: {
        id: "".concat(scope, ".aboutUs"),
        defaultMessage: 'About us'
    },
    supportCenter: {
        id: "".concat(scope, ".supportCenter"),
        defaultMessage: 'Support center'
    },
    feedback: {
        id: "".concat(scope, ".feedback"),
        defaultMessage: 'Feedback'
    },
    giveFeedback: {
        id: "".concat(scope, ".giveFeedback"),
        defaultMessage: 'Give feedback'
    },
    rateApp: {
        id: "".concat(scope, ".rateApp"),
        defaultMessage: 'Rate the application'
    },
    legal: {
        id: "".concat(scope, ".legal"),
        defaultMessage: 'Legal'
    },
    termsOfService: {
        id: "".concat(scope, ".termsOfService"),
        defaultMessage: 'Terms of service'
    },
    privacyPolicy: {
        id: "".concat(scope, ".privacyPolicy"),
        defaultMessage: 'Privacy policy'
    },
    legalNotice: {
        id: "".concat(scope, ".legalNotice"),
        defaultMessage: 'Legal notice'
    },
    logout: {
        id: "".concat(scope, ".logout"),
        defaultMessage: 'Logout'
    }
});
export default messages;
