// Webolucio imports
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';

export const registrationTasteRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	path: 'taste',
};
