import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _object_without_properties } from "@swc/helpers/_/_object_without_properties";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { formatISO, startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { FilterComparisonType, SortOrder, useEffectOnMount, usePrevious, useRequest } from '@webolucio/core';
// BPNL imports
import { DEFAULT_PAGE_SIZE, getVisitedEntityKey } from '@bpnl/common';
import { usePerformers } from '@bpnl/performers';
// Local imports
import { EVENT_ENTITY_KEY } from './config';
import eventsSaga from './saga';
import { selectEvent, selectEventListErrors, selectEventListIsFetching, selectEventsCount, selectEventsList, selectGetEventErrors, selectGetEventIsFetching, selectSearchedEvents } from './selectors';
import { eventsActions, eventsReducer, eventsSliceKey } from './slice';
import { selectMyAccount } from '@bpnl/account';
export function useEvents(_param) {
    var _param_queryKey = _param.queryKey, queryKey = _param_queryKey === void 0 ? 'default' : _param_queryKey, mode = _param.mode, preventFetchOnMount = _param.preventFetchOnMount, externalRequest = _object_without_properties(_param, [
        "queryKey",
        "mode",
        "preventFetchOnMount"
    ]);
    useInjectReducer({
        key: eventsSliceKey,
        reducer: eventsReducer
    });
    useInjectSaga({
        key: eventsSliceKey,
        saga: eventsSaga
    });
    var dispatch = useDispatch();
    var prevExternalRequest = usePrevious(externalRequest);
    var tmp = externalRequest.offset, externalOffset = tmp === void 0 ? 0 : tmp, tmp1 = externalRequest.limit, externalLimit = tmp1 === void 0 ? DEFAULT_PAGE_SIZE : tmp1, tmp2 = externalRequest.sortBy, externalSortBy = tmp2 === void 0 ? 'distance' : tmp2, tmp3 = externalRequest.sortOrder, externalSortOrder = tmp3 === void 0 ? SortOrder.ASC : tmp3;
    var _useState = _sliced_to_array(useState(Math.floor(externalOffset / externalLimit)), 2), page = _useState[0], setPage = _useState[1];
    var _useRequest = useRequest(_object_spread_props(_object_spread({}, externalRequest), {
        offset: externalOffset,
        limit: externalLimit,
        sortBy: externalSortBy,
        sortOrder: externalSortOrder
    })), request = _useRequest.request, handleChangeRowsPerPage = _useRequest.handleChangeRowsPerPage, handleChangeFilter = _useRequest.handleChangeFilter, handleChangeSorting = _useRequest.handleChangeSorting, handleChangeSearch = _useRequest.handleChangeSearch, handleChangePage = _useRequest.handleChangePage;
    var prevRequest = usePrevious(request);
    var events = useSelector(selectEventsList)(queryKey);
    var isEventsFetching = useSelector(selectEventListIsFetching)(queryKey);
    var eventsErrors = useSelector(selectEventListErrors)(queryKey);
    var eventCount = useSelector(selectEventsCount)(queryKey);
    var refreshEvents = useCallback(function() {
        var reset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        setPage(0);
        handleChangePage(0);
        dispatch(eventsActions.getEventListRequest(_object_spread_props(_object_spread({}, request), {
            offset: 0,
            reset: reset,
            queryKey: queryKey
        })));
    }, [
        dispatch,
        handleChangePage,
        queryKey,
        request
    ]);
    var nextPage = useCallback(function() {
        if (!isEventsFetching && (request.offset || 0) + 1 < eventCount) {
            setPage(page + 1);
            handleChangePage(page + 1);
            return true;
        }
        return false;
    }, [
        eventCount,
        handleChangePage,
        isEventsFetching,
        page,
        request.offset
    ]);
    useEffect(function() {
        if (!isEqual(prevRequest, request)) {
            if (prevRequest === undefined) {
                // Mount
                !preventFetchOnMount && dispatch(eventsActions.getEventListRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey
                })));
                return;
            }
            var offset = request.offset, rest = _object_without_properties(request, [
                "offset"
            ]);
            var prevOffset = prevRequest.offset, prevRest = _object_without_properties(prevRequest, [
                "offset"
            ]);
            if (!isEqual(rest, prevRest)) {
                dispatch(eventsActions.getEventListRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey
                })));
                return;
            }
            dispatch(eventsActions.getEventListRequest(_object_spread_props(_object_spread({}, request), {
                queryKey: queryKey
            })));
        }
    }, [
        dispatch,
        handleChangePage,
        mode,
        prevRequest,
        preventFetchOnMount,
        queryKey,
        request
    ]);
    useEffect(function() {
        if (prevExternalRequest !== undefined) {
            if (!isEqual(prevExternalRequest === null || prevExternalRequest === void 0 ? void 0 : prevExternalRequest.filter, externalRequest === null || externalRequest === void 0 ? void 0 : externalRequest.filter)) {
                setPage(0);
                handleChangePage(0);
                handleChangeFilter(externalRequest.filter);
            }
            if (prevExternalRequest.sortBy !== externalRequest.sortBy || prevExternalRequest.sortOrder !== externalRequest.sortOrder) {
                setPage(0);
                handleChangePage(0);
                handleChangeSorting(externalRequest.sortBy || 'distance', externalRequest.sortOrder || SortOrder.ASC);
            }
            if (prevExternalRequest.search !== externalRequest.search || prevExternalRequest.search_in_fields !== externalRequest.search_in_fields) {
                setPage(0);
                handleChangePage(0);
                handleChangeSearch({
                    phrase: externalRequest.search || '',
                    field: externalRequest.search_in_fields || null
                });
            }
        }
    }, [
        externalRequest.filter,
        externalRequest.search,
        externalRequest.search_in_fields,
        externalRequest.sortBy,
        externalRequest.sortOrder,
        handleChangeFilter,
        handleChangePage,
        handleChangeSearch,
        handleChangeSorting,
        prevExternalRequest
    ]);
    return {
        events: events,
        isEventsFetching: isEventsFetching,
        handleChangeRowsPerPage: handleChangeRowsPerPage,
        handleChangeFilter: handleChangeFilter,
        handleChangeSorting: handleChangeSorting,
        handleChangeSearch: handleChangeSearch,
        handleChangePage: handleChangePage,
        nextPage: nextPage,
        refreshEvents: refreshEvents,
        eventsErrors: eventsErrors,
        eventCount: eventCount
    };
}
export function useEvent(param) {
    var id = param.id;
    useInjectReducer({
        key: eventsSliceKey,
        reducer: eventsReducer
    });
    useInjectSaga({
        key: eventsSliceKey,
        saga: eventsSaga
    });
    var dispatch = useDispatch();
    var eventsErrors = useSelector(selectGetEventErrors);
    var isEventsFetching = useSelector(selectGetEventIsFetching);
    var selectedEvent = useSelector(selectEvent)(id);
    var refreshEvent = useCallback(function() {
        dispatch(eventsActions.getEventRequest({
            id: id
        }));
    }, [
        dispatch,
        id
    ]);
    useEffect(function() {
        if (!selectedEvent && id) {
            dispatch(eventsActions.getEventRequest({
                id: id
            }));
        }
    }, [
        dispatch,
        id,
        selectedEvent
    ]);
    return {
        isEventsFetching: isEventsFetching,
        selectedEvent: selectedEvent,
        eventsErrors: eventsErrors,
        refreshEvent: refreshEvent
    };
}
export function usePerformerEvents(param) {
    var includePast = param.includePast, performerId = param.performerId;
    var prevPerformerId = usePrevious(performerId);
    var eventData = useEvents({
        queryKey: "performer-".concat(performerId),
        filter: includePast ? [
            {
                field: 'performer_id',
                condition_type: FilterComparisonType.EQUAL,
                value: performerId
            }
        ] : [
            {
                field: 'performer_id',
                condition_type: FilterComparisonType.EQUAL,
                value: performerId
            },
            {
                field: 'date_start',
                condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
                value: formatISO(startOfDay(new Date()))
            }
        ]
    });
    useEffect(function() {
        if (prevPerformerId === undefined || prevPerformerId === performerId || !performerId) {
            return;
        }
        eventData.handleChangeFilter(includePast ? [
            {
                field: 'performer_id',
                condition_type: FilterComparisonType.EQUAL,
                value: performerId
            }
        ] : [
            {
                field: 'performer_id',
                condition_type: FilterComparisonType.EQUAL,
                value: performerId
            },
            {
                field: 'date_start',
                condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
                value: formatISO(startOfDay(new Date()))
            }
        ]);
    }, [
        eventData,
        includePast,
        performerId,
        prevPerformerId
    ]);
    return eventData;
}
export function useVenueEvents(param) {
    var includePast = param.includePast, venueId = param.venueId;
    return useEvents({
        queryKey: "venue-".concat(venueId),
        filter: includePast ? [
            {
                field: 'venue_id',
                condition_type: FilterComparisonType.EQUAL,
                value: venueId
            }
        ] : [
            {
                field: 'venue_id',
                condition_type: FilterComparisonType.EQUAL,
                value: venueId
            },
            {
                field: 'date_start',
                condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
                value: formatISO(startOfDay(new Date()))
            }
        ],
        sortBy: 'date_start',
        sortOrder: SortOrder.ASC
    });
}
export function useVisitedEvents() {
    useInjectReducer({
        key: eventsSliceKey,
        reducer: eventsReducer
    });
    useInjectSaga({
        key: eventsSliceKey,
        saga: eventsSaga
    });
    var dispatch = useDispatch();
    var account = useSelector(selectMyAccount);
    useEffectOnMount(function() {
        dispatch(eventsActions.getVisitedEvents());
    });
    var visitedEvents = useSelector(selectEventsList)(getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0));
    var isVisitedEventsFetching = useSelector(selectEventListIsFetching)(getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0));
    var visitedEventsErrors = useSelector(selectEventListErrors)(getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0));
    var refreshVisitedEvents = useCallback(function() {
        dispatch(eventsActions.getVisitedEvents());
    }, [
        dispatch
    ]);
    return {
        visitedEvents: visitedEvents,
        isVisitedEventsFetching: isVisitedEventsFetching,
        visitedEventsErrors: visitedEventsErrors,
        refreshVisitedEvents: refreshVisitedEvents
    };
}
export function useSearchedEvents(searchTerm) {
    useInjectReducer({
        key: eventsSliceKey,
        reducer: eventsReducer
    });
    useInjectSaga({
        key: eventsSliceKey,
        saga: eventsSaga
    });
    var dispatch = useDispatch();
    useEffectOnMount(function() {
        dispatch(eventsActions.getSearchedEvents());
    });
    var storeSearchedEvent = useCallback(function(term) {
        dispatch(eventsActions.storeSearchedEvent(term));
    }, [
        dispatch
    ]);
    var deleteSearchedEvent = useCallback(function(term) {
        dispatch(eventsActions.deleteSearchedEvent(term));
    }, [
        dispatch
    ]);
    var allSearchedEvents = useSelector(selectSearchedEvents);
    var filteredEvents = searchTerm ? allSearchedEvents.filter(function(item) {
        return item.includes(searchTerm);
    }) : allSearchedEvents;
    var searchedEvents = filteredEvents.slice(-8).reverse();
    return {
        searchedEvents: searchedEvents,
        deleteSearchedEvent: deleteSearchedEvent,
        storeSearchedEvent: storeSearchedEvent
    };
}
export function useFollowedPerformerEvents(limit) {
    var _usePerformers = usePerformers({
        queryKey: 'followed',
        filter: [
            {
                field: 'is_followed',
                condition_type: FilterComparisonType.EQUAL,
                value: true
            }
        ]
    }), performers = _usePerformers.performers, isPerformersFetching = _usePerformers.isPerformersFetching, performersErrors = _usePerformers.performersErrors;
    var _useEvents = useEvents({
        queryKey: 'followedPerformerEvents',
        limit: typeof limit !== 'number' ? DEFAULT_PAGE_SIZE : limit,
        filter: [
            {
                field: 'performer_id',
                condition_type: FilterComparisonType.IN,
                value: performers.map(function(p) {
                    return p.id;
                })
            },
            {
                field: 'date_start',
                condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
                value: formatISO(startOfDay(new Date()))
            }
        ]
    }), events = _useEvents.events, isEventsFetching = _useEvents.isEventsFetching, eventsErrors = _useEvents.eventsErrors, nextPage = _useEvents.nextPage, refreshEvents = _useEvents.refreshEvents, rest = _object_without_properties(_useEvents, [
        "events",
        "isEventsFetching",
        "eventsErrors",
        "nextPage",
        "refreshEvents"
    ]);
    return _object_spread_props(_object_spread({}, rest), {
        isFollowedPerformerEventsFetching: isPerformersFetching || isEventsFetching,
        followedPerformerEvents: events,
        followedPerformerEventsErrors: performersErrors || eventsErrors,
        refreshFollowedPerformerEvents: refreshEvents,
        nextFollowedPerformerEventsPage: nextPage
    });
}
