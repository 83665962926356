import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { uniq } from 'lodash-es';
import { all, call, put, race, select, takeEvery, takeLatest, takeLeading } from '@redux-saga/core/effects';
// Webolucio imports
import { debounceCollect, FilterComparisonType, takeOnly } from '@webolucio/core';
// BPNL imports
import { FollowType, getStoredSearchHistory, getVisitedEntities, getVisitedEntityKey, storeSearchHistory, takeEveryUnique } from '@bpnl/common';
import { myAccountActions, selectMyAccount } from '@bpnl/account';
// Local imports
import { eventsApi } from './api';
import { EVENT_ENTITY_KEY } from './config';
import { selectEventsData, selectSearchedEvents } from './selectors';
import { eventsActions } from './slice';
function getEventListSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(eventsApi.getEventList, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(eventsActions.getEventListFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(eventsActions.getEventListSuccess(_object_spread_props(_object_spread({}, response.data), {
                        queryKey: action.payload.queryKey
                    })))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(eventsActions.getEventListFailure({
                        errors: [
                            'clientError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function getEventSaga(actions) {
    var requestedIds, filteredActions, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, action, response, err, err1;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    12,
                    ,
                    14
                ]);
                requestedIds = new Set();
                filteredActions = actions.reduce(function(acc, a) {
                    if (!requestedIds.has(a.payload.id)) {
                        acc.push(a);
                        requestedIds.add(a.payload.id);
                    }
                    return acc;
                }, []);
                _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                _state.label = 1;
            case 1:
                _state.trys.push([
                    1,
                    9,
                    10,
                    11
                ]);
                _iterator = filteredActions[Symbol.iterator]();
                _state.label = 2;
            case 2:
                if (!!(_iteratorNormalCompletion = (_step = _iterator.next()).done)) return [
                    3,
                    8
                ];
                action = _step.value;
                return [
                    4,
                    call(eventsApi.getEvent, action.payload.id)
                ];
            case 3:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    5
                ];
                return [
                    4,
                    put(eventsActions.getEventFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 4:
                return [
                    2,
                    _state.sent()
                ];
            case 5:
                return [
                    4,
                    put(eventsActions.getEventSuccess(response.data))
                ];
            case 6:
                _state.sent();
                _state.label = 7;
            case 7:
                _iteratorNormalCompletion = true;
                return [
                    3,
                    2
                ];
            case 8:
                return [
                    3,
                    11
                ];
            case 9:
                err = _state.sent();
                _didIteratorError = true;
                _iteratorError = err;
                return [
                    3,
                    11
                ];
            case 10:
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
                return [
                    7
                ];
            case 11:
                return [
                    3,
                    14
                ];
            case 12:
                err1 = _state.sent();
                return [
                    4,
                    put(eventsActions.getEventFailure([
                        'clientError'
                    ]))
                ];
            case 13:
                _state.sent();
                return [
                    3,
                    14
                ];
            case 14:
                return [
                    2
                ];
        }
    });
}
function getVisitedEventsSaga() {
    var account, visitedEvents, _ref, success, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    call(getVisitedEntities, EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0)
                ];
            case 2:
                visitedEvents = uniq.apply(void 0, [
                    _state.sent()
                ]);
                if (!(!visitedEvents || !Array.isArray(visitedEvents) || visitedEvents.length === 0)) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(eventsActions.storeVisitedEvents({
                        key: getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0),
                        orderedIds: []
                    }))
                ];
            case 3:
                _state.sent();
                _state.label = 4;
            case 4:
                return [
                    4,
                    put(eventsActions.getEventListRequest({
                        queryKey: getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0),
                        filter: [
                            {
                                field: 'id',
                                condition_type: FilterComparisonType.IN,
                                value: visitedEvents
                            }
                        ]
                    }))
                ];
            case 5:
                _state.sent();
                return [
                    4,
                    race({
                        success: takeOnly(eventsActions.getEventListSuccess.type, function(action) {
                            return action.payload.queryKey === getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0);
                        }),
                        error: takeOnly(eventsActions.getEventListFailure.type, function(action) {
                            return action.payload.queryKey === getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0);
                        })
                    })
                ];
            case 6:
                _ref = _state.sent(), success = _ref.success, error = _ref.error;
                if (error) {
                    console.error('Error fetching visited events:', error.payload.errors);
                    return [
                        2
                    ];
                }
                if (!success.payload.data) {
                    return [
                        2
                    ];
                }
                return [
                    4,
                    put(eventsActions.storeVisitedEvents({
                        key: getVisitedEntityKey(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0),
                        orderedIds: visitedEvents
                    }))
                ];
            case 7:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function maybeUpdateLinkedEntityFollowState(action) {
    var events, _tmp, _tmp1, _i, id, changed, event, relatedEventKey, _tmp2, _tmp3, _i1, id1, changed1, event1, organizerKey, _tmp4, _tmp5, _i2, id2, changed2, event2, performerKey, _tmp6, _tmp7, _i3, id3, changed3, event3, venueKey;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectEventsData)
                ];
            case 1:
                events = _state.sent();
                if (!(action.payload.type === FollowType.Event)) return [
                    3,
                    6
                ];
                _tmp = [];
                for(_tmp1 in events)_tmp.push(_tmp1);
                _i = 0;
                _state.label = 2;
            case 2:
                if (!(_i < _tmp.length)) return [
                    3,
                    5
                ];
                id = _tmp[_i];
                changed = false;
                event = _object_spread({}, events[id]);
                if (event.related_events && event.related_events.length) {
                    event.related_events = _to_consumable_array(event.related_events);
                    for(var relatedEventKey in event.related_events){
                        if (event.related_events[relatedEventKey].id === action.payload.id) {
                            event.related_events[relatedEventKey] = _object_spread_props(_object_spread({}, event.related_events[relatedEventKey]), {
                                is_followed: action.payload.isFollowed,
                                count_followers: action.payload.countFollowers
                            });
                            changed = true;
                        }
                    }
                }
                if (!changed) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(eventsActions.updateEvent(event))
                ];
            case 3:
                _state.sent();
                _state.label = 4;
            case 4:
                _i++;
                return [
                    3,
                    2
                ];
            case 5:
                return [
                    2
                ];
            case 6:
                if (!(action.payload.type === FollowType.Organizer)) return [
                    3,
                    11
                ];
                _tmp2 = [];
                for(_tmp3 in events)_tmp2.push(_tmp3);
                _i1 = 0;
                _state.label = 7;
            case 7:
                if (!(_i1 < _tmp2.length)) return [
                    3,
                    10
                ];
                id1 = _tmp2[_i1];
                changed1 = false;
                event1 = _object_spread({}, events[id1]);
                if (event1.organizers && event1.organizers.length) {
                    event1.organizers = _to_consumable_array(event1.organizers);
                    for(var organizerKey in event1.organizers){
                        if (event1.organizers[organizerKey].id === action.payload.id) {
                            event1.organizers[organizerKey] = _object_spread_props(_object_spread({}, event1.organizers[organizerKey]), {
                                is_followed: action.payload.isFollowed,
                                count_followers: action.payload.countFollowers
                            });
                            changed1 = true;
                        }
                    }
                }
                if (!changed1) return [
                    3,
                    9
                ];
                return [
                    4,
                    put(eventsActions.updateEvent(event1))
                ];
            case 8:
                _state.sent();
                _state.label = 9;
            case 9:
                _i1++;
                return [
                    3,
                    7
                ];
            case 10:
                return [
                    2
                ];
            case 11:
                if (!(action.payload.type === FollowType.Performer)) return [
                    3,
                    16
                ];
                _tmp4 = [];
                for(_tmp5 in events)_tmp4.push(_tmp5);
                _i2 = 0;
                _state.label = 12;
            case 12:
                if (!(_i2 < _tmp4.length)) return [
                    3,
                    15
                ];
                id2 = _tmp4[_i2];
                changed2 = false;
                event2 = _object_spread({}, events[id2]);
                if (event2.performers && event2.performers.length) {
                    event2.performers = _to_consumable_array(event2.performers);
                    for(var performerKey in event2.performers){
                        if (event2.performers[performerKey].id === action.payload.id) {
                            event2.performers[performerKey] = _object_spread_props(_object_spread({}, event2.performers[performerKey]), {
                                is_followed: action.payload.isFollowed,
                                count_followers: action.payload.countFollowers
                            });
                            changed2 = true;
                        }
                    }
                }
                if (!changed2) return [
                    3,
                    14
                ];
                return [
                    4,
                    put(eventsActions.updateEvent(event2))
                ];
            case 13:
                _state.sent();
                _state.label = 14;
            case 14:
                _i2++;
                return [
                    3,
                    12
                ];
            case 15:
                return [
                    2
                ];
            case 16:
                if (!(action.payload.type === FollowType.Venue)) return [
                    3,
                    21
                ];
                _tmp6 = [];
                for(_tmp7 in events)_tmp6.push(_tmp7);
                _i3 = 0;
                _state.label = 17;
            case 17:
                if (!(_i3 < _tmp6.length)) return [
                    3,
                    20
                ];
                id3 = _tmp6[_i3];
                changed3 = false;
                event3 = _object_spread({}, events[id3]);
                if (event3.venues && event3.venues.length) {
                    event3.venues = _to_consumable_array(event3.venues);
                    for(var venueKey in event3.venues){
                        if (event3.venues[venueKey].id === action.payload.id) {
                            event3.venues[venueKey] = _object_spread_props(_object_spread({}, event3.venues[venueKey]), {
                                is_followed: action.payload.isFollowed,
                                count_followers: action.payload.countFollowers
                            });
                            changed3 = true;
                        }
                    }
                }
                if (!changed3) return [
                    3,
                    19
                ];
                return [
                    4,
                    put(eventsActions.updateEvent(event3))
                ];
            case 18:
                _state.sent();
                _state.label = 19;
            case 19:
                _i3++;
                return [
                    3,
                    17
                ];
            case 20:
                return [
                    2
                ];
            case 21:
                return [
                    2
                ];
        }
    });
}
function populateSearchHistorySaga() {
    var account, searchTerms;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    getStoredSearchHistory(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0)
                ];
            case 2:
                searchTerms = _state.sent();
                return [
                    4,
                    put(eventsActions.initSearchedEvents(searchTerms))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function syncSearchHistorySaga(action) {
    var account, searchTerms;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    select(selectSearchedEvents)
                ];
            case 2:
                searchTerms = _state.sent();
                return [
                    4,
                    storeSearchHistory(EVENT_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0, searchTerms)
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export var eventsSagas = {
    getEventListSaga: getEventListSaga,
    getEventSaga: getEventSaga,
    maybeUpdateLinkedEntityFollowState: maybeUpdateLinkedEntityFollowState,
    populateSearchHistorySaga: populateSearchHistorySaga,
    syncSearchHistorySaga: syncSearchHistorySaga
};
export default function eventsSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeEveryUnique(eventsActions.getEventListRequest.type, getEventListSaga, 'queryKey'),
                        takeLeading(eventsActions.getVisitedEvents.type, getVisitedEventsSaga),
                        debounceCollect(1000, eventsActions.getEventRequest.type, getEventSaga),
                        takeEvery(eventsActions.setFollowed.type, maybeUpdateLinkedEntityFollowState),
                        takeLatest(myAccountActions.getMyAccountSuccess.type, populateSearchHistorySaga),
                        takeLatest(eventsActions.storeSearchedEvent.type, syncSearchHistorySaga),
                        takeLatest(eventsActions.deleteSearchedEvent.type, syncSearchHistorySaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
