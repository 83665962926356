// Webolucio imports
import { ApiService } from '@webolucio/core';
// Role
var getSocialPostList = function(param) {
    var filter = param.filter, offset = param.offset, limit = param.limit, sortBy = param.sortBy, sortOrder = param.sortOrder, search = param.search, search_in_fields = param.search_in_fields;
    return ApiService.getService().get("/social-posts", {
        filter: filter,
        offset: offset,
        limit: limit,
        orderby_field: sortBy,
        orderby_dir: sortOrder,
        search: search,
        search_in_fields: search_in_fields
    });
};
var getSocialPostContext = function() {
    return ApiService.getService().get("/social-posts/table-context");
};
var getSocialPost = function(id) {
    return ApiService.getService().get("/social-posts/".concat(id));
};
var getSocialPostsEvent = function(param, eventId) {
    var filter = param.filter, offset = param.offset, limit = param.limit, sortBy = param.sortBy, sortOrder = param.sortOrder, search = param.search, search_in_fields = param.search_in_fields;
    return ApiService.getService().get("/social-posts-event/".concat(eventId), {
        filter: filter,
        offset: offset,
        limit: limit,
        orderby_field: sortBy,
        orderby_dir: sortOrder,
        search: search,
        search_in_fields: search_in_fields
    });
};
export var socialPostsApi = {
    getSocialPost: getSocialPost,
    getSocialPostList: getSocialPostList,
    getSocialPostContext: getSocialPostContext,
    getSocialPostsEvent: getSocialPostsEvent
};
