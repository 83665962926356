import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, debounce, put, takeLatest } from '@redux-saga/core/effects';
// Local imports
import { myAccountApi } from './api';
import { myAccountActions } from './slice';
function getMyAccountSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(myAccountApi.getMyAccount)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(myAccountActions.getMyAccountFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(myAccountActions.getMyAccountSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(myAccountActions.getMyAccountFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function updateMyAccountSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    3,
                    ,
                    5
                ]);
                return [
                    4,
                    call(myAccountApi.updateMyAccount, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!response.ok || response.data === undefined) {
                    return [
                        2,
                        put(myAccountActions.updateMyAccountFailure(response.errors || [
                            'apiError'
                        ]))
                    ];
                }
                return [
                    4,
                    put(myAccountActions.updateMyAccountSuccess(response.data))
                ];
            case 2:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 3:
                err = _state.sent();
                return [
                    4,
                    put(myAccountActions.updateMyAccountFailure([
                        'clientError'
                    ]))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
function patchMyAccountSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    3,
                    ,
                    5
                ]);
                return [
                    4,
                    call(myAccountApi.patchMyAccount, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!response.ok || response.data === undefined) {
                    return [
                        2,
                        put(myAccountActions.patchMyAccountFailure(response.errors || [
                            'apiError'
                        ]))
                    ];
                }
                return [
                    4,
                    put(myAccountActions.patchMyAccountSuccess(response.data))
                ];
            case 2:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 3:
                err = _state.sent();
                return [
                    4,
                    put(myAccountActions.patchMyAccountFailure([
                        'clientError'
                    ]))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    5
                ];
            case 5:
                return [
                    2
                ];
        }
    });
}
export var myAccountSagas = {
    getMyAccountSaga: getMyAccountSaga,
    updateMyAccountSaga: updateMyAccountSaga,
    patchMyAccountSaga: patchMyAccountSaga
};
export default function myAccountSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeLatest(myAccountActions.getMyAccountRequest.type, getMyAccountSaga),
                        debounce(500, myAccountActions.updateMyAccountRequest.type, updateMyAccountSaga),
                        debounce(500, myAccountActions.patchMyAccountRequest.type, patchMyAccountSaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
