export var QueryStoreMode;
(function(QueryStoreMode) {
    QueryStoreMode["Overwrite"] = "overwrite";
    QueryStoreMode["Concat"] = "concat";
})(QueryStoreMode || (QueryStoreMode = {}));
export var FollowType;
(function(FollowType) {
    FollowType["Venue"] = "venue";
    FollowType["Organizer"] = "organizer";
    FollowType["Performer"] = "performer";
    FollowType["Event"] = "event";
})(FollowType || (FollowType = {}));
