import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.ReportBlock';

export default defineMessages({
	defaultTitle: {
		id: `${scope}.defaultTitle`,
		defaultMessage: 'Found incorrect information?',
	},
	buttonTitle: {
		id: `${scope}.buttonTitle`,
		defaultMessage: 'Let us know!',
	},
});
