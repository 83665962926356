import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { useEffectOnMount, usePrevious } from '@webolucio/core';
import { FieldEvent } from '@webolucio/form';
import { Form, HiddenField, TextAreaField, TextField } from '@webolucio/form-web';
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { useMyAccount } from '@bpnl/account';
import { MessageInput, messagesReducer, messagesSaga, messagesSliceKey, useMessage } from '@bpnl/messages';
// External imports
import BpnlButton from '../../components/presentationals/BpnlButton';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function FeedbackPage() {
	useInjectReducer({ key: messagesSliceKey, reducer: messagesReducer });
	useInjectSaga({ key: messagesSliceKey, saga: messagesSaga });

	const { state } = useLocation();
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	const { myAccount, isMyAccountFetching } = useMyAccount();
	const { createMessage, messageErrors, isMessageSaving } = useMessage();
	const prevIsMessageSaving = usePrevious(isMessageSaving);

	const [sentSuccessfully, setSentSuccessfully] = useState<boolean>(false);

	const handleSubmit = useCallback(
		(e: FieldEvent<MessageInput>) => {
			createMessage(e.target.value);
		},
		[createMessage],
	);

	// Redirect to login page if not logged in
	useEffectOnMount(() => {
		if (!isMyAccountFetching && !myAccount) {
			navigate('/login?redirect=/feedback');
		}
	});

	useEffect(() => {
		if (!messageErrors && !isMessageSaving && prevIsMessageSaving !== undefined && prevIsMessageSaving !== isMessageSaving) {
			setSentSuccessfully(true);
			setTimeout(() => {
				setSentSuccessfully(false);
				navigate('/');
			}, 2000);
		}
	}, [isMessageSaving, messageErrors, navigate, prevIsMessageSaving]);

	return isMyAccountFetching ? (
		<LoadOverlay />
	) : (
		<div className={classes.formContainer}>
			<h1>{formatMessage(messages.title)}</h1>
			<Form<MessageInput>
				classes={{ formContent: classes.form }}
				errors={Array.isArray(messageErrors) ? undefined : messageErrors || undefined}
				initialValues={{ type: state?.type || 'general', subject: '', message: '' }}
				isSaving={isMessageSaving}
				isValidating={isMessageSaving}
				onSubmit={handleSubmit}
				partial={false}
			>
				<HiddenField name="type" initialValue={state?.type || 'general'} />
				<TextField classes={{ formControl: { formControlLabel: classes.label } }} name="subject" label={formatMessage(messages.subject)} />
				<TextAreaField
					classes={{ formControl: { formControlLabel: classes.label }, textArea: { textarea: classes.textArea } }}
					name="message"
					label={formatMessage(messages.message)}
				/>
				{isMessageSaving ? (
					<LoadOverlay transparent />
				) : sentSuccessfully ? (
					<p className={classes.success}>{formatMessage(messages.successMessage)}</p>
				) : (
					<BpnlButton title={formatMessage(messages.send)} type="submit" />
				)}
			</Form>
		</div>
	);
}
