import { addMonths, format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// Webolucio imports
import { useLocale } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
// Local imports
import classes from './index.module.scss';
import { BpnlCalendarProps } from './types';

export function formatCalendarDate(date: Date) {
	return format(date, 'yyyy-MM-dd');
}

export default function BpnlCalendar(props: BpnlCalendarProps) {
	const { ranges, onChange } = props;
	const { locale } = useLocale();

	const renderNavBar = (currentFocusedDate, changeShownDate) => {
		const month = format(currentFocusedDate, 'MMMM', { locale: locale === 'hu' ? hu : undefined });
		const year = format(currentFocusedDate, 'yyyy');
		return (
			<div className={classes.navBar}>
				<Icon name="bpnl-arrow-left" onClick={() => changeShownDate(addMonths(currentFocusedDate, -1))} className={classes.arrowBtn} />
				<span>
					{year}. {month}
				</span>
				<Icon name="bpnl-arrow-right" onClick={() => changeShownDate(addMonths(currentFocusedDate, 1))} className={classes.arrowBtn} />
			</div>
		);
	};

	return (
		<DateRange
			editableDateInputs={false}
			onChange={onChange}
			ranges={ranges}
			moveRangeOnFirstSelection={false}
			showSelectionPreview={false}
			showMonthAndYearPickers={false}
			showDateDisplay={false}
			weekStartsOn={1}
			navigatorRenderer={renderNavBar}
			locale={locale === 'hu' ? hu : undefined}
			rangeColors={['#3BA10A']}
		/>
	);
}
