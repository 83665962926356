import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
// Webolucio imports
import { FilterQuery, SortOrder } from '@webolucio/core';
// BPNL imports
import { DEFAULT_PAGE_SIZE } from '@bpnl/common';
import { DetailedPerformer, usePerformers } from '@bpnl/performers';
// App imports
import HorizontalSlider from '../HorizontalSlider';
import PerformerCard from '../../presentationals/PerformerCard';
import PerformerCardSkeleton from '../../presentationals/PerformerCard/Skeleton';
// Local imports
import classes from './index.module.scss';
import { PerformerSliderProps } from './types';

const CARDS_TO_SHOW = 6;

export default function PerformerSlider(props: PerformerSliderProps) {
	const { className, emptyMessage, filter, limit = DEFAULT_PAGE_SIZE, mode, name, offset, search, sortBy, sortOrder, title } = props;

	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const { formatMessage } = useIntl();

	const { performers, isPerformersFetching, performerCount, nextPage } = usePerformers({
		mode,
		queryKey: name,
		filter: filter as FilterQuery<DetailedPerformer>,
		search,
		sortBy: sortBy || 'count_followers',
		sortOrder: sortOrder || SortOrder.DESC,
		offset,
		limit,
	});

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextPage()) {
					setPendingNextPage(false);
				} else {
					if (isPerformersFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isPerformersFetching, nextPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	useEffect(() => {
		if (!isPerformersFetching && pendingNextPage) {
			nextPage();
			setPendingNextPage(false);
		}
	}, [isPerformersFetching, nextPage, pendingNextPage]);

	return (
		<div className={clsx(classes.performerSlider, 'performer-slider')} ref={rootRef}>
			{title ? <h2 className={clsx(classes.title, 'performer-slider-title')}>{typeof title === 'string' ? title : formatMessage(title)}</h2> : null}
			<HorizontalSlider slidesToShow={CARDS_TO_SHOW} swipe>
				{!performers.length && isPerformersFetching
					? Array.from({ length: CARDS_TO_SHOW }).map((_, idx) => <PerformerCardSkeleton key={`performer-skeleton-${idx}`} />)
					: performers
							.map((performer, idx) => (
								<PerformerCard
									key={`${name}-slider-card-${performer.id}`}
									performerId={performer.id}
									ref={idx === performers.length - 2 ? itemRef : undefined}
								/>
							))
							.concat(performerCount > performers.length ? [<PerformerCardSkeleton key="performer-loading-skeleton" />] : [])}
			</HorizontalSlider>
		</div>
	);
}
