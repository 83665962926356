import '@webolucio/core/security';
import './augmentation';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
// Webolucio imports
import { ApiService, initializeCore, LocaleService, StoreService } from '@webolucio/core';
import { initializeIcons } from '@webolucio/icons';
import { initializeWeb } from '@webolucio/web';
import { initializeForm } from '@webolucio/form';
import { initializeFormWeb } from '@webolucio/form-web';
// Local imports
import App from './app';
import { rollbar } from './app/rollbar';
// Styles entry point
import './styles.scss';

initializeCore();
initializeIcons();
initializeWeb();
initializeForm();
initializeFormWeb();

ApiService.getService().setHeader('X-Client-Version', '0.14');
ApiService.getService().setHeader('X-Client-Platform', 'web');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

LocaleService.getService()
	.loadTranslations()
	.then(() =>
		root.render(
			<StrictMode>
				<RollbarProvider instance={rollbar}>
					<RollbarErrorBoundary>
						<Provider store={StoreService.getService().getStore()}>
							<App />
						</Provider>
					</RollbarErrorBoundary>
				</RollbarProvider>
			</StrictMode>,
		),
	);
