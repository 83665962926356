import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialFollowState, followSliceKey } from './slice';
var selectDomain = function(state) {
    return state[followSliceKey] || initialFollowState;
};
export var selectFollowSavingItems = createSelector([
    selectDomain
], function(storeState) {
    return storeState.savingItems;
});
export var selectFollowErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.errors;
});
