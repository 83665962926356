import clsx from 'clsx';
import { BaseComponentProps } from '@webolucio/core';
import classes from './index.module.scss';

export default function VenueCardSkeleton(props: BaseComponentProps) {
	return (
		<div className={clsx(classes.venueCardSkeleton, props.className)}>
			<div className={classes.image}></div>
			<div className={classes.content}>
				<div className={classes.row}></div>
				<div className={classes.row}></div>
			</div>
		</div>
	);
}
