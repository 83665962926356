// Webolucio imports
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';
import messages from './messages';

export const followingRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	path: '/following',
	handle: {
		skipInNavigation: true,
		title: messages.title,
	} as BpnlRouteHandle,
};
