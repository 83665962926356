import Image from '../Image';
import classes from './index.module.scss';
import { VenueServiceProps } from './type';
import clsx from "clsx";

export default function ServicesData(props: VenueServiceProps) {
	const { services, variant = 'card' } = props;

	if (!services || services.length === 0) {
		return null;
	}

	return (
		<div className={clsx(classes.VenueServiceIcons, classes[variant])}>
			{services.map((item, index) => (
				<div key={`service-${index}`} className={classes.item}>
					<Image alt={item.name} file={item.icon} />
					{variant === 'page' && <span>{item.name}</span>}
				</div>
			))}
		</div>
	);
}
