import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.VenueInfoCards';

export default defineMessages({
	open: {
		id: `${scope}.open`,
		defaultMessage: 'Open',
	},
	opening: {
		id: `${scope}.opening`,
		defaultMessage: 'Opening',
	},
	close: {
		id: `${scope}.close`,
		defaultMessage: 'Close',
	},
	closed: {
		id: `${scope}.closed`,
		defaultMessage: 'Closed',
	},
	less: {
		id: `${scope}.less`,
		defaultMessage: 'Less',
	},
	details: {
		id: `${scope}.details`,
		defaultMessage: 'Details',
	},
});
