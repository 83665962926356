import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.PerformerPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Performer',
	},
	tagsTitle: {
		id: `${scope}.tagsTitle`,
		defaultMessage: 'Music styles',
	},
	similarPerformers: {
		id: `${scope}.similarPerformers`,
		defaultMessage: 'Similar performers ',
	}
});
