import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { usePrevious } from '@webolucio/core';
// Local imports
import { defaultMessageItem } from './config';
import { messagesActions, messagesReducer, messagesSliceKey } from './slice';
import messagesSaga from './saga';
import { selectMessageById, selectMessagesErrors, selectMessagesIsFetching, selectMessagesIsSaving, selectMessagesIsValidating, selectSelectedMessage } from './selectors';
export function useMessage(id) {
    useInjectReducer({
        key: messagesSliceKey,
        reducer: messagesReducer
    });
    useInjectSaga({
        key: messagesSliceKey,
        saga: messagesSaga
    });
    var dispatch = useDispatch();
    // SELECTORS
    var isMessageFetching = useSelector(selectMessagesIsFetching);
    var messageErrors = useSelector(selectMessagesErrors);
    var isMessageValidating = useSelector(selectMessagesIsValidating);
    var isMessageSaving = useSelector(selectMessagesIsSaving);
    var message = useSelector(selectSelectedMessage);
    var messageById = useSelector(selectMessageById)(id || 0);
    var prevId = usePrevious(id);
    var createMessage = useCallback(function(message) {
        dispatch(messagesActions.createMessageRequest(message));
    }, [
        dispatch
    ]);
    var createValidateMessage = useCallback(function(message) {
        dispatch(messagesActions.createValidateMessageRequest(message));
    }, [
        dispatch
    ]);
    useEffect(function() {
        if (id && prevId !== id && !isMessageFetching && messageById === null) {
            dispatch(messagesActions.getMessageRequest({
                id: id
            }));
        }
    }, [
        dispatch,
        id,
        isMessageFetching,
        prevId,
        messageById
    ]);
    useEffect(function() {
        if (id && prevId !== id && messageById !== null && message === null) {
            dispatch(messagesActions.setSelected(id));
        }
    }, [
        dispatch,
        id,
        isMessageFetching,
        prevId,
        message,
        messageById
    ]);
    return {
        message: id || (message === null || message === void 0 ? void 0 : message.id) ? message : defaultMessageItem,
        isWaitingForMessage: id && !messageErrors && message === null,
        isMessageFetching: isMessageFetching,
        messageErrors: messageErrors,
        isMessageValidating: isMessageValidating,
        isMessageSaving: isMessageSaving,
        createMessage: createMessage,
        createValidateMessage: createValidateMessage
    };
}
