import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.RegistrationBasicDataPage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Registration',
	},
	intro: {
		id: `${scope}.intro`,
		defaultMessage: 'Enter your general information!',
	},
	firstName: {
		id: `${scope}.firstName`,
		defaultMessage: 'First Name',
	},
	lastName: {
		id: `${scope}.lastName`,
		defaultMessage: 'Last Name',
	},
	username: {
		id: `${scope}.username`,
		defaultMessage: 'Username',
	},
	email: {
		id: `${scope}.email`,
		defaultMessage: 'Email Address',
	},
	password: {
		id: `${scope}.password`,
		defaultMessage: 'Password',
	},
	passwordConfirm: {
		id: `${scope}.passwordConfirm`,
		defaultMessage: 'Confirm Password',
	},
});
