import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setDate } from 'date-fns';
// Webolucio imports
import { LocaleService, useEnum } from '@webolucio/core';
import { LoadOverlay, PageMetas } from '@webolucio/web';
import { FieldEvent } from '@webolucio/form';
import { DateTimeField, Form } from '@webolucio/form-web';
// BPNL imports
import { FrontendUserInput } from '@bpnl/common';
import { useRegistration, useValidateRegistrationFields } from '@bpnl/registration';
// App imports
import { commonMessages } from '../../i18n';
import BpnlButton from '../../components/presentationals/BpnlButton';
import Indicator from '../../components/presentationals/Indicator';
import TagListField from '../../components/fields/TagListField';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function RegistrationAgePage() {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const genders = useEnum<number>('genders', 'userspace');
	const { registration, registrationErrors, patchRegistrationData, isRegistrationSaving } = useRegistration();

	// Set date of birth to 18 years ago and first day of the year
	const dateBirth = useMemo<string | null>(
		() => setDate(new Date(new Date().getFullYear() - 18, new Date(new Date().setMonth(0)).getMonth(), 1), 1).toISOString(),
		[],
	);

	const validateRegistration = useValidateRegistrationFields({
		callback: (isValid) => {
			if (isValid) {
				if (searchParams.get('fromLogin')) {
					navigate('/registration/taste');
				} else {
					navigate('/registration/type');
				}
			}
		},
		fields: ['date_birth', 'gender'],
		shouldClearErrors: true,
	});

	const handleNext = useCallback(() => {
		LocaleService.getService().locale.then((locale) => patchRegistrationData({ language_id: locale }));
		validateRegistration();
	}, [patchRegistrationData, validateRegistration]);

	const handleFormChange = useCallback(
		(e: FieldEvent<Partial<FrontendUserInput>>) => {
			patchRegistrationData(e.target.value);
		},
		[patchRegistrationData],
	);

	return (
		<>
			<PageMetas title={formatMessage(messages.pageTitle)} />
			<h1 className="heading">{formatMessage(messages.pageTitle)}</h1>
			<Indicator steps={3} active={1} />
			<Form<FrontendUserInput>
				errors={(!Array.isArray(registrationErrors) && registrationErrors) || undefined}
				isSaving={isRegistrationSaving}
				isValidating={isRegistrationSaving}
				initialValues={registration}
				partial
				onChange={handleFormChange}
				onSubmit={() => {}}
				formProps={{ className: classes.formContainer }}
			>
				<div className={classes.stepTextContainer}>
					<h2 className="heading">{formatMessage(messages.intro)}</h2>
					<h3 className="sub-heading">{formatMessage(messages.restriction)}</h3>
				</div>
				<div className={classes.ageContainer}>
					<DateTimeField
						name="date_birth"
						type="date"
						label={formatMessage(messages.dateOfBirth)}
						classes={{
							datePicker: {
								container: classes.datePicker,
								pickersContainer: classes.pickersContainer,
								calendar: {
									header: classes.calendarHeader,
									content: classes.calendarContent,
									yearPicker: { container: classes.yearPickerContainer, content: classes.yearPickerContent, item: classes.yearPickerItem },
								},
							},
							formControl: { formControlLabel: classes.label, formControlLabelHelperContainer: classes.labelContainer },
						}}
						value={dateBirth}
					/>
					{genders?.length ? (
						<TagListField<number>
							name="gender"
							options={genders || []}
							label={formatMessage(messages.gender)}
							classes={{
								tagList: {
									container: classes.tagList,
								},
								formControl: {
									formControlLabel: classes.label,
									formControlLabelHelperContainer: classes.labelContainer,
								},
							}}
						/>
					) : (
						<div className={classes.tagListPlaceholder}>
							<LoadOverlay size="small" transparent />
						</div>
					)}
					<div className="horizontal-divider" />
					<BpnlButton width="fullWidth" size="medium" type="button" onClick={handleNext} className={classes.nextButton}>
						{formatMessage(commonMessages.next)}
					</BpnlButton>
				</div>
			</Form>
		</>
	);
}
