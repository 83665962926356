// Webolucio imports
import { ApiService } from '@webolucio/core';
// Role
var getText = function(param) {
    var str_id = param.str_id;
    return ApiService.getService().get("/texts/".concat(str_id));
};
export var textsApi = {
    getText: getText
};
