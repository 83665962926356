import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { FilterComparisonType, useEnum } from '@webolucio/core';
import { ScrollView } from '@webolucio/web';
// BPNL imports
import { EventFilters } from '@bpnl/events';
import { VenueFilters } from '@bpnl/venues';
// App imports
import Title from '../../presentationals/Title';
import TagList from '../../presentationals/TagList';
import { FilterProps } from '../types';
// Local imports
import messages from './messages';
import classes from './index.module.scss';

export default function MusicStyleFilter({ onChange }: FilterProps<EventFilters | VenueFilters>) {
	const { formatMessage } = useIntl();
	const [selectedMusicStyles, setSelectedMusicStyles] = useState<Array<number>>([]);
	const musicStyle = useEnum<number>('music-style-tags');

	const handleChange = useCallback(
		(values) => {
			if (values.length === 0) {
				onChange && onChange([], 'music-style');
			} else {
				onChange &&
					onChange(
						[
							{
								field: 'tag_id',
								condition_type: FilterComparisonType.IN,
								value: values,
							},
						],
						'music-style',
					);
			}
			setSelectedMusicStyles(values);
		},
		[onChange, selectedMusicStyles],
	);
	return (
		<div>
			<Title title={formatMessage(messages.filterTitle)} />
			<ScrollView classes={{ container: classes.scrollView, scrollerContainer: classes.scrollerContainer, scrollerElement: classes.scrollerElement }}>
				<TagList options={musicStyle || []} onChange={(values) => handleChange(values)} value={selectedMusicStyles} multiple />
			</ScrollView>
		</div>
	);
}
