import { memoize } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialSocialPostsState, socialPostsSliceKey } from './slice';
var selectDomain = function(state) {
    return state[socialPostsSliceKey] || initialSocialPostsState;
};
export var selectSocialPostsData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectSocialPostsResults = createSelector([
    selectDomain
], function(storeState) {
    return storeState.queries;
});
export var selectSocialPostListIsFetching = createSelector([
    selectSocialPostsResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.fetching) || false;
    });
});
export var selectSocialPostListErrors = createSelector([
    selectSocialPostsResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.errors) || null;
    });
});
export var selectSocialPostListCount = createSelector([
    selectSocialPostsResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.totalCount) || 0;
    });
});
export var selectSocialPostsList = createSelector([
    selectSocialPostsData,
    selectSocialPostsResults
], function(posts, queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return (((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.data) || []).reduce(function(acc, id) {
            var event = posts[id];
            if (event) acc.push(event);
            return acc;
        }, []);
    });
});
export var selectSocialPost = createSelector([
    selectSocialPostsData
], function(posts) {
    return memoize(function(id) {
        return posts[id] || null;
    });
});
export var selectSocialPostIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_queryKey;
        var queryKey = "single-".concat(id);
        return ((_storeState_queries_queryKey = storeState.queries[queryKey]) === null || _storeState_queries_queryKey === void 0 ? void 0 : _storeState_queries_queryKey.fetching) || false;
    });
});
export var selectSocialPostErrors = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_queryKey;
        var queryKey = "single-".concat(id);
        return ((_storeState_queries_queryKey = storeState.queries[queryKey]) === null || _storeState_queries_queryKey === void 0 ? void 0 : _storeState_queries_queryKey.errors) || null;
    });
});
