import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { usePrevious } from '@webolucio/core';
// BPNL imports
import { myAccountReducer, myAccountSaga, myAccountSliceKey } from '@bpnl/account';
// Local imports
import registrationSaga from './saga';
import { selectIsLoggedInWithSocial, selectRegistrationData, selectRegistrationErrors, selectRegistrationIsSaving, selectRegistrationIsValidating } from './selectors';
import { registrationActions, registrationReducer, registrationSliceKey } from './slice';
export function useRegistration() {
    useInjectReducer({
        key: registrationSliceKey,
        reducer: registrationReducer
    });
    useInjectSaga({
        key: registrationSliceKey,
        saga: registrationSaga
    });
    useInjectReducer({
        key: myAccountSliceKey,
        reducer: myAccountReducer
    });
    useInjectSaga({
        key: myAccountSliceKey,
        saga: myAccountSaga
    });
    var dispatch = useDispatch();
    var registrationErrors = useSelector(selectRegistrationErrors);
    var isRegistrationSaving = useSelector(selectRegistrationIsSaving);
    var isRegistrationValidating = useSelector(selectRegistrationIsValidating);
    var registration = useSelector(selectRegistrationData);
    var isLoggedInWithSocial = useSelector(selectIsLoggedInWithSocial);
    var patchRegistrationData = useCallback(function(data) {
        dispatch(registrationActions.patchRegistrationData(data));
    }, [
        dispatch
    ]);
    var submitRegistration = useCallback(function() {
        if (isLoggedInWithSocial) {
            dispatch(registrationActions.finishSocialRegistration());
        } else {
            dispatch(registrationActions.postRegistrationPublicRequest());
        }
    }, [
        dispatch,
        isLoggedInWithSocial
    ]);
    var validateRegistration = useCallback(function() {
        dispatch(registrationActions.validateRegistrationPublicRequest());
    }, [
        dispatch
    ]);
    var clearErrors = useCallback(function() {
        return dispatch(registrationActions.clearErrors());
    }, [
        dispatch
    ]);
    return {
        clearErrors: clearErrors,
        isRegistrationSaving: isRegistrationSaving,
        isRegistrationValidating: isRegistrationValidating,
        patchRegistrationData: patchRegistrationData,
        registration: registration,
        registrationErrors: registrationErrors,
        submitRegistration: submitRegistration,
        validateRegistration: validateRegistration
    };
}
export function useValidateRegistrationFields(props) {
    var callback = props.callback, fields = props.fields, _props_shouldClearErrors = props.shouldClearErrors, shouldClearErrors = _props_shouldClearErrors === void 0 ? true : _props_shouldClearErrors;
    var _useRegistration = useRegistration(), clearErrors = _useRegistration.clearErrors, registrationErrors = _useRegistration.registrationErrors, isRegistrationValidating = _useRegistration.isRegistrationValidating, validateRegistration = _useRegistration.validateRegistration;
    var prevRegistrationValidating = usePrevious(isRegistrationValidating);
    useEffect(function() {
        if (prevRegistrationValidating && !isRegistrationValidating) {
            if (!registrationErrors) {
                callback(true);
                return;
            }
            if (typeof registrationErrors !== 'object') {
                shouldClearErrors && clearErrors();
                callback(false);
                return;
            }
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var field = _step.value;
                    if (registrationErrors[field]) {
                        callback(false);
                        return;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
            shouldClearErrors && clearErrors();
            callback(true);
        }
    }, [
        callback,
        clearErrors,
        fields,
        isRegistrationValidating,
        prevRegistrationValidating,
        registrationErrors,
        shouldClearErrors
    ]);
    return validateRegistration;
}
