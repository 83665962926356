import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.RegistrationTastePage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Registration',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Find parties and venues based on your favourite music style!',
	},
	subtitle: {
		id: `${scope}.subtitle`,
		defaultMessage: 'Select the styles you like:',
	},
});
