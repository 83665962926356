import { defineMessages } from 'react-intl';
export var scope = 'bpnl.performers';
var messages = defineMessages({
    performer: {
        id: "".concat(scope, ".performer"),
        defaultMessage: 'Performer'
    },
    performers: {
        id: "".concat(scope, ".performers"),
        defaultMessage: 'Performers'
    },
    noPerformers: {
        id: "".concat(scope, ".noPerformers"),
        defaultMessage: 'There are no performers to show.'
    },
    reportTitle: {
        id: "".concat(scope, ".reportTitle"),
        defaultMessage: "Can't find the performer you're looking for?"
    }
});
export default messages;
