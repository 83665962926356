import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { createSlice } from '@reduxjs/toolkit';
export var initialRegistrationState = {
    errors: null,
    forgotPasswordFetching: false,
    resetPasswordFetching: false,
    registrationSaving: false,
    registrationValidating: false,
    data: {
        id: 0,
        uuid: '',
        password: '',
        password_confirm: '',
        email: '',
        first_name: '',
        last_name: '',
        gender: null,
        date_birth: null,
        profile_image: null,
        language_id: '',
        tags: []
    },
    socialData: {
        additionalData: null,
        authResponse: null,
        isAuthenticating: false,
        provider: null,
        socialToken: null
    }
};
var registrationSlice = createSlice({
    name: 'registration',
    initialState: initialRegistrationState,
    reducers: {
        patchRegistrationData: function patchRegistrationData(state, action) {
            state.data = _object_spread({}, state.data, action.payload);
        },
        clearErrors: function clearErrors(state) {
            state.errors = initialRegistrationState.errors;
        },
        validateRegistrationPublicRequest: function validateRegistrationPublicRequest(state) {
            state.registrationValidating = true;
        },
        validateRegistrationFailure: function validateRegistrationFailure(state, action) {
            state.registrationValidating = initialRegistrationState.registrationValidating;
            state.errors = action.payload;
        },
        validateRegistrationSuccess: function validateRegistrationSuccess(state) {
            state.errors = initialRegistrationState.errors;
            state.registrationValidating = initialRegistrationState.registrationValidating;
        },
        postRegistrationPublicRequest: function postRegistrationPublicRequest(state) {
            state.registrationSaving = true;
        },
        postRegistrationFailure: function postRegistrationFailure(state, action) {
            state.registrationSaving = initialRegistrationState.registrationSaving;
            state.errors = action.payload;
        },
        postRegistrationSuccess: function postRegistrationSuccess(state, action) {
            state.errors = initialRegistrationState.errors;
            state.registrationSaving = initialRegistrationState.registrationSaving;
            state.data = action.payload;
        },
        forgotPasswordPublicRequest: function forgotPasswordPublicRequest(state, action) {
            state.forgotPasswordFetching = true;
        },
        forgotPasswordFailure: function forgotPasswordFailure(state, action) {
            state.forgotPasswordFetching = initialRegistrationState.forgotPasswordFetching;
            state.errors = action.payload;
        },
        forgotPasswordSuccess: function forgotPasswordSuccess(state) {
            state.errors = initialRegistrationState.errors;
            state.forgotPasswordFetching = initialRegistrationState.forgotPasswordFetching;
        },
        resetPasswordPublicRequest: function resetPasswordPublicRequest(state, action) {
            state.resetPasswordFetching = true;
        },
        resetPasswordFailure: function resetPasswordFailure(state, action) {
            state.resetPasswordFetching = initialRegistrationState.resetPasswordFetching;
            state.errors = action.payload;
        },
        resetPasswordSuccess: function resetPasswordSuccess(state) {
            state.errors = initialRegistrationState.errors;
            state.resetPasswordFetching = initialRegistrationState.resetPasswordFetching;
        },
        setSocialProvider: function setSocialProvider(state, action) {
            state.socialData.provider = action.payload;
            state.socialData.socialToken = null;
        },
        loginWithSocialPublicRequest: function loginWithSocialPublicRequest(state, action) {
            state.socialData.isAuthenticating = true;
            state.socialData.socialToken = action.payload.token;
            if (action.payload.additional_data) {
                state.socialData.additionalData = action.payload.additional_data;
            }
        },
        loginWithSocialFailure: function loginWithSocialFailure(state, action) {
            state.socialData.isAuthenticating = initialRegistrationState.socialData.isAuthenticating;
            state.errors = action.payload;
        },
        loginWithSocialSuccess: function loginWithSocialSuccess(state, action) {
            state.socialData.isAuthenticating = initialRegistrationState.socialData.isAuthenticating;
            state.socialData.authResponse = action.payload;
        },
        finishSocialRegistration: function finishSocialRegistration(state) {},
        clearSocialData: function clearSocialData(state) {
            state.socialData = initialRegistrationState.socialData;
        }
    }
});
export var registrationActions = registrationSlice.actions, registrationReducer = registrationSlice.reducer, registrationSliceKey = registrationSlice.name;
