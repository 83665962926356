import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
// Webolucio imports
import { ApiService, useEnum, usePrevious } from '@webolucio/core';
import { notificationsActions, ScrollView } from '@webolucio/web';
import { FieldEvent } from '@webolucio/form';
import { DateTimeField, EmailField, Form, TextField } from '@webolucio/form-web';
// BPNL imports
import { FrontendUserBase } from '@bpnl/common';
import { myAccountActions, myAccountMessages, useMyAccount } from '@bpnl/account';
// App imports
import BpnlButton from '../../components/presentationals/BpnlButton';
import ProfileImage from '../../components/presentationals/ProfileImage';
import TagListField from '../../components/fields/TagListField';
import useLoadingFlag from '../../hooks/useLoadingFlag';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function MyAccountPage() {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const { myAccount, myAccountErrors, isMyAccountSaving, isMyAccountFetching } = useMyAccount();
	const prevMyAccountSaving = usePrevious(isMyAccountSaving);

	const genders = useEnum<number>('genders', 'userspace');
	const tags = useEnum<number>('music-style-tags', 'userspace');

	useLoadingFlag((myAccount === null && isMyAccountFetching) || genders === null || tags === null);

	const handleSubmit = useCallback(
		(e: FieldEvent<FrontendUserBase>) => {
			dispatch(myAccountActions.patchMyAccountRequest(e.target.value));
		},
		[dispatch],
	);

	useEffect(() => {
		if (prevMyAccountSaving && !isMyAccountSaving && !myAccountErrors) {
			dispatch(notificationsActions.addNotification({ message: formatMessage(messages.saveSuccess), variant: 'success' }));
		}
	}, [isMyAccountSaving, myAccountErrors, prevMyAccountSaving]);

	if (!myAccount) {
		return null;
	}

	return (
		<div className={classes.myAccountPage}>
			<h1 className={classes.title}>{formatMessage(myAccountMessages.personalData)}</h1>
			<Form<FrontendUserBase>
				classes={{ formContent: classes.form }}
				initialValues={myAccount}
				onSubmit={handleSubmit}
				isSaving={isMyAccountSaving}
				isValidating={isMyAccountSaving}
				errors={!myAccountErrors || Array.isArray(myAccountErrors) ? undefined : myAccountErrors}
				partial
			>
				<div className={classes.section}>
					{ApiService.getService().locale === 'hu' ? (
						<>
							<TextField
								classes={{ formControl: { formControlLabelHelperContainer: classes.labelContainer, formControlLabel: classes.label } }}
								name="last_name"
								label={formatMessage(messages.dataFieldLastName)}
								placeholder={formatMessage(messages.dataFieldLastName)}
							/>
							<TextField
								classes={{ formControl: { formControlLabelHelperContainer: classes.labelContainer, formControlLabel: classes.label } }}
								name="first_name"
								label={formatMessage(messages.dataFieldFirstName)}
								placeholder={formatMessage(messages.dataFieldFirstName)}
							/>
						</>
					) : (
						<>
							<TextField
								classes={{ formControl: { formControlLabelHelperContainer: classes.labelContainer, formControlLabel: classes.label } }}
								name="first_name"
								label={formatMessage(messages.dataFieldFirstName)}
								placeholder={formatMessage(messages.dataFieldFirstName)}
							/>
							<TextField
								classes={{ formControl: { formControlLabelHelperContainer: classes.labelContainer, formControlLabel: classes.label } }}
								name="last_name"
								label={formatMessage(messages.dataFieldLastName)}
								placeholder={formatMessage(messages.dataFieldLastName)}
							/>
						</>
					)}
					<EmailField
						classes={{ formControl: { formControlLabelHelperContainer: classes.labelContainer, formControlLabel: classes.label } }}
						name="email"
						label={formatMessage(messages.dataFieldEmail)}
						placeholder={formatMessage(messages.dataFieldEmail)}
					/>
					<TagListField<number>
						name="gender"
						options={genders || []}
						label={formatMessage(messages.dataFieldGender)}
						classes={{
							formControl: {
								formControl: classes.tagListField,
								adornmentContainer: classes.tagListContainer,
								formControlLabel: classes.label,
								formControlLabelHelperContainer: classes.labelContainer,
							},
						}}
					/>
					<DateTimeField
						name="date_birth"
						type="date"
						label={formatMessage(messages.dataFieldDateOfBirth)}
						classes={{
							datePicker: {
								container: classes.datePicker,
								pickersContainer: classes.pickersContainer,
								calendar: {
									header: classes.calendarHeader,
									content: classes.calendarContent,
									yearPicker: { container: classes.yearPickerContainer, content: classes.yearPickerContent, item: classes.yearPickerItem },
								},
							},
							formControl: { formControlLabel: classes.label, formControlLabelHelperContainer: classes.labelContainer },
						}}
						value={myAccount?.date_birth}
					/>
				</div>
				<div className={clsx(classes.section, classes.profileImageSection)}>
					<ProfileImage className={classes.profileImage} />
					<p className={classes.name}>
						{ApiService.getService().locale === 'hu'
							? myAccount.last_name + ' ' + myAccount.first_name
							: myAccount.first_name + ' ' + myAccount.last_name}
					</p>
					<p className={classes.email}>{myAccount.email}</p>
					<BpnlButton className={classes.submitButton} width="fullWidth" size="medium" type="submit" title={formatMessage(messages.saveChanges)} />
				</div>
				<div className={clsx(classes.section, classes.tasteSection)}>
					<ScrollView
						classes={{ container: classes.scrollView, scrollerContainer: classes.scrollerContainer, scrollerElement: classes.scrollerElement }}
					>
						<TagListField<number>
							multiple
							label={formatMessage(messages.dataFieldTaste)}
							name="tags"
							options={tags || []}
							useEmbeddedIds
							classes={{
								formControl: {
									formControl: classes.tasteField,
									formControlLabel: classes.label,
									formControlLabelHelperContainer: classes.labelContainer,
								},
							}}
						/>
					</ScrollView>
				</div>
			</Form>
		</div>
	);
}
