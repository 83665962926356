import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.EventPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Event',
	},
	tickets: {
		id: `${scope}.tickets`,
		defaultMessage: 'Tickets',
	},
	organizer: {
		id: `${scope}.organizer`,
		defaultMessage: 'Organizer',
	},
	venue: {
		id: `${scope}.venue`,
		defaultMessage: 'Venue',
	},
	moreEvent: {
		id: `${scope}.moreEvent`,
		defaultMessage: 'More events',
	},
	goToPurchase: {
		id: `${scope}.goToPurchase`,
		defaultMessage: 'Go to the official ticket purchase »',
	},
	moreEventsAtTheVenue: {
		id: `${scope}.moreEventsAtTheVenue`,
		defaultMessage: 'More events at the venue',
	},
	moreEventsByTheOrganizer: {
		id: `${scope}.moreEventsByTheOrganizer`,
		defaultMessage: 'More events by the organizer',
	},
	performers: {
		id: `${scope}.performers`,
		defaultMessage: 'Performers',
	},
	dressCode: {
		id: `${scope}.dressCode`,
		defaultMessage: 'Dress Code',
	},
	ticketswap: {
		id: `${scope}.ticketswap`,
		defaultMessage: 'ticketswap',
	},
	fromPerformer: {
		id: `${scope}.fromPerformer`,
		defaultMessage: 'From the performer',
	},
});
