import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
// Webolucio imports
import { FilterQuery, SortOrder } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { DetailedVenue, venuesMessages, useVenues } from '@bpnl/venues';
// App imports
import VenueCard from '../../presentationals/VenueCard';
import VenueCardSkeleton from '../../presentationals/VenueCard/Skeleton';
// Local imports
import classes from './index.module.scss';
import { VenueGridProps } from './types';

const CARDS_TO_SHOW = 15;

export default function VenueGrid(props: VenueGridProps) {
	const { className, emptyMessage, filter, limit = CARDS_TO_SHOW, mode, name, offset, search, sortBy, sortOrder } = props;

	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const { formatMessage } = useIntl();

	const { venues, isVenuesFetching, venueCount, nextPage } = useVenues({
		mode,
		queryKey: name,
		filter: filter as FilterQuery<DetailedVenue>,
		search,
		sortBy: sortBy || 'distance',
		sortOrder: sortOrder || SortOrder.ASC,
		offset,
		limit,
	});

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextPage()) {
					setPendingNextPage(false);
				} else {
					if (isVenuesFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isVenuesFetching, nextPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	useEffect(() => {
		if (!isVenuesFetching && pendingNextPage) {
			nextPage();
			setPendingNextPage(false);
		}
	}, [isVenuesFetching, nextPage, pendingNextPage]);

	return venueCount === 0 && !isVenuesFetching ? (
		<p className={clsx(classes.emptyMessage, 'venue-grid-empty-message', className)}>
			{typeof emptyMessage === 'string' ? emptyMessage : formatMessage(emptyMessage || venuesMessages.noVenues)}
		</p>
	) : (
		<div className={clsx(classes.venueGrid, className)}>
			{!venues.length && isVenuesFetching
				? Array.from({ length: CARDS_TO_SHOW }).map((_, idx) => <VenueCardSkeleton key={`venue-grid-skeleton-${idx}`} />)
				: venues
						.map((venue, idx) => (
							<VenueCard
								key={`${name}-grid-card-${venue.id}`}
								venueId={venue.id}
								ref={idx === venues.length - 4 || (venues.length - 4 < 0 && idx + 1 === venues.length) ? itemRef : undefined}
							/>
						))
						.concat(
							venueCount > venues.length
								? [
										<div className={classes.loader} key="venue-grid-loader">
											<LoadOverlay size="medium" />
										</div>,
									]
								: [],
						)}
		</div>
	);
}
