import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { useEffectOnMount } from '@webolucio/core';
// Local imports
import myAccountSaga from './saga';
import { selectMyAccount, selectMyAccountIsFetching, selectMyAccountErrors, selectMyAccountIsSaving } from './selectors';
import { myAccountActions, myAccountReducer, myAccountSliceKey } from './slice';
export function useMyAccount() {
    useInjectReducer({
        key: myAccountSliceKey,
        reducer: myAccountReducer
    });
    useInjectSaga({
        key: myAccountSliceKey,
        saga: myAccountSaga
    });
    var dispatch = useDispatch();
    var myAccount = useSelector(selectMyAccount);
    var isMyAccountFetching = useSelector(selectMyAccountIsFetching);
    var isMyAccountSaving = useSelector(selectMyAccountIsSaving);
    var myAccountErrors = useSelector(selectMyAccountErrors);
    useEffectOnMount(function() {
        if (!myAccount) {
            dispatch(myAccountActions.getMyAccountRequest());
        }
    });
    var getMyAccount = useCallback(function() {
        if (!isMyAccountFetching) {
            dispatch(myAccountActions.getMyAccountRequest());
        }
    }, [
        dispatch,
        isMyAccountFetching
    ]);
    return {
        myAccount: myAccount,
        isMyAccountFetching: isMyAccountFetching,
        isMyAccountSaving: isMyAccountSaving,
        myAccountErrors: myAccountErrors,
        getMyAccount: getMyAccount
    };
}
