import { useMemo } from 'react';
// Webolucio imports
import { BaseComponentProps } from '@webolucio/core';
// BPNL imports
import { useEvent } from '@bpnl/events';
import { useVenue } from '@bpnl/venues';
import { usePerformer } from '@bpnl/performers';
import { useOrganizer } from '@bpnl/organizers';
// App imports
import Image from '../Image';
// Assets
import BpnlLogo from '../../../assets/logo.svg';

export enum ThumbnailTypes {
	Event = 'events',
	Venue = 'venues',
	Organizer = 'organizers',
	Performer = 'performers',
}

function EventThumbnail({ id }: { id: number }) {
	const { selectedEvent, isEventsFetching } = useEvent({ id });

	if (!selectedEvent) {
		return isEventsFetching ? null : <BpnlLogo />;
	}

	if (selectedEvent.default_medium === null) {
		return <BpnlLogo />;
	}

	return <Image file={selectedEvent.default_medium.file} thumbnail />;
}

function VenueThumbnail({ id }: { id: number }) {
	const { selectedVenue, isVenuesFetching } = useVenue({ id });

	if (!selectedVenue) {
		return isVenuesFetching ? null : <BpnlLogo />;
	}

	if (selectedVenue.default_medium === null) {
		return <BpnlLogo />;
	}

	return <Image file={selectedVenue.default_medium.file} thumbnail />;
}

function PerformerThumbnail({ id }: { id: number }) {
	const { performer, isPerformersFetching } = usePerformer({ id });

	if (!performer) {
		return isPerformersFetching ? null : <BpnlLogo />;
	}

	if (performer.default_medium === null) {
		return <BpnlLogo />;
	}

	return <Image file={performer.default_medium.file} thumbnail />;
}

function OrganizerThumbnail({ id }: { id: number }) {
	const { selectedOrganizer, isOrganizersFetching } = useOrganizer({ id });

	if (!selectedOrganizer) {
		return isOrganizersFetching ? null : <BpnlLogo />;
	}

	if (selectedOrganizer.default_medium === null) {
		return <BpnlLogo />;
	}

	return <Image file={selectedOrganizer.default_medium.file} thumbnail />;
}

export function Thumbnail({ link, className }: { link: string | null } & BaseComponentProps) {
	if (link === null) {
		return (
			<div className={className}>
				<BpnlLogo />
			</div>
		);
	}

	const linkParts = link.split('/');

	if (linkParts.length !== 2 || !linkParts[0] || !linkParts[1] || isNaN(parseInt(linkParts[1]))) {
		return (
			<div className={className}>
				<BpnlLogo />
			</div>
		);
	}

	const entity = linkParts[0] as ThumbnailTypes;
	const id = parseInt(linkParts[1]);

	switch (entity) {
		case ThumbnailTypes.Event:
			return (
				<div className={className}>
					<EventThumbnail id={id} />
				</div>
			);
		case ThumbnailTypes.Venue:
			return (
				<div className={className}>
					<VenueThumbnail id={id} />
				</div>
			);
		case ThumbnailTypes.Performer:
			return (
				<div className={className}>
					<PerformerThumbnail id={id} />
				</div>
			);
		case ThumbnailTypes.Organizer:
			return (
				<div className={className}>
					<OrganizerThumbnail id={id} />
				</div>
			);
		default:
			return (
				<div className={className}>
					<BpnlLogo />
				</div>
			);
	}
}
