import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.RegistrationAgePage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Registration',
	},
	intro: {
		id: `${scope}.intro`,
		defaultMessage: 'Enter your age and gender!',
	},
	restriction: {
		id: `${scope}.restriction`,
		defaultMessage: 'The app can only be used by people over 18!',
	},
	dateOfBirth: {
		id: `${scope}.dateOfBirth`,
		defaultMessage: 'Your date of birth:',
	},
	gender: {
		id: `${scope}.gender`,
		defaultMessage: 'Choose your gender:',
	},
});
