import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialRegistrationState, registrationSliceKey } from './slice';
var selectDomain = function(state) {
    return state[registrationSliceKey] || initialRegistrationState;
};
export var selectSocialData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.socialData;
});
export var selectSocialAuthenticating = createSelector([
    selectSocialData
], function(socialData) {
    return socialData.isAuthenticating;
});
export var selectIsLoggedInWithSocial = createSelector([
    selectSocialData
], function(socialData) {
    return socialData.authResponse !== null;
});
export var selectSocialAuthData = createSelector([
    selectSocialData
], function(socialData) {
    return socialData.authResponse;
});
export var selectRegistrationIsSaving = createSelector([
    selectDomain
], function(storeState) {
    return storeState.registrationSaving;
});
export var selectRegistrationIsValidating = createSelector([
    selectDomain
], function(storeState) {
    return storeState.registrationValidating;
});
export var selectRegistrationErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.errors;
});
export var selectRegistrationData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectForgotPasswordIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.forgotPasswordFetching;
});
export var selectResetPasswordIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.resetPasswordFetching;
});
