// Webolucio imports
import { webolucioConfig } from '@webolucio/core';
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';

export const myAccountRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	routePermission: `api.${webolucioConfig.services.api.scope}.my-account.read`,
	actionPermissions: {
		read: `api.${webolucioConfig.services.api.scope}.my-account.read`,
	},
	path: '/my-account',
	handle: {
		skipInNavigation: true,
	} as BpnlRouteHandle,
};
