// Webolucio imports
import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { ApiService, webolucioConfig } from '@webolucio/core';
// Role
var postRegistration = function(params) {
    return ApiService.getService().post("/registration", params);
};
var validateRegistration = function(params) {
    return ApiService.getService().post("/registration/validate", params);
};
var forgotPassword = function(param) {
    var email = param.email, captcha_response = param.captcha_response;
    return ApiService.getService().post("/forgot-password", {
        email: email,
        captcha_response: captcha_response,
        scope: webolucioConfig.scope
    });
};
var resetPassword = function(params) {
    return ApiService.getService().post("/reset-password", _object_spread_props(_object_spread({}, params), {
        scope: webolucioConfig.scope
    }));
};
var resetPasswordValidate = function(params) {
    return ApiService.getService().post("/reset-password/validate", _object_spread_props(_object_spread({}, params), {
        scope: webolucioConfig.scope
    }));
};
var accessToken = function(param) {
    var clientId = param.clientId, redirectUri = param.redirectUri, grantType = param.grantType, socialAccessToken = param.socialAccessToken, additionalData = param.additionalData, scope = param.scope;
    return ApiService.getService().api.post(webolucioConfig.modules.auth.apiPaths.token, {
        grant_type: grantType,
        client_id: clientId,
        redirect_uri: redirectUri,
        social_access_token: socialAccessToken,
        additional_data: additionalData,
        scope: scope
    });
};
export var registrationApi = {
    accessToken: accessToken,
    postRegistration: postRegistration,
    validateRegistration: validateRegistration,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    resetPasswordValidate: resetPasswordValidate
};
