import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.DiscoverPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Discover',
	},
	dailyEvents: {
		id: `${scope}.dailyEvents`,
		defaultMessage: "Today's events",
	},
	noDailyEvents: {
		id: `${scope}.noDailyEvents`,
		defaultMessage: "We couldn't find any events for today",
	},
	weeklyEvents: {
		id: `${scope}.weeklyEvents`,
		defaultMessage: 'This week',
	},
	noWeeklyEvents: {
		id: `${scope}.noWeeklyEvents`,
		defaultMessage: "We couldn't find any events for this week",
	},
	popularPerformers: {
		id: `${scope}.popularPerformers`,
		defaultMessage: 'Popular performers',
	},
	recentlyViewed: {
		id: `${scope}.recentlyViewed`,
		defaultMessage: 'Recently viewed',
	},
	noVisitedEvents: {
		id: `${scope}.noVisitedEvents`,
		defaultMessage: 'You have not visited any events yet',
	},
	noPopularPerformer: {
		id: `${scope}.noPopularPerformer`,
		defaultMessage: 'Nobody is popular yet',
	},
	carouselMoreButton: {
		id: `${scope}.carouselMoreButton`,
		defaultMessage: 'See details »',
	},
	mapCtaTitle: {
		id: `${scope}.mapCtaTitle`,
		defaultMessage: 'Budapest at night, on a single map!',
	},
	mapCtaSubtitle: {
		id: `${scope}.mapCtaSubtitle`,
		defaultMessage: 'Try our map search engine!',
	},
	mapCtaButton: {
		id: `${scope}.mapCtaButton`,
		defaultMessage: 'Jump to the map »',
	},
});
