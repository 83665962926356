import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _object_without_properties } from "@swc/helpers/_/_object_without_properties";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { SortOrder, usePrevious, useRequest } from '@webolucio/core';
// BPNL imports
import { DEFAULT_PAGE_SIZE } from '@bpnl/common';
// Local imports
import organizersSaga from './saga';
import { selectOrganizer, selectOrganizerErrors, selectOrganizerIsFetching, selectOrganizerListCount, selectOrganizerListErrors, selectOrganizerListIsFetching, selectOrganizersList } from './selectors';
import { organizersActions, organizersReducer, organizersSliceKey } from './slice';
export function useOrganizers(_param) {
    var _param_queryKey = _param.queryKey, queryKey = _param_queryKey === void 0 ? 'default' : _param_queryKey, externalRequest = _object_without_properties(_param, [
        "queryKey"
    ]);
    useInjectReducer({
        key: organizersSliceKey,
        reducer: organizersReducer
    });
    useInjectSaga({
        key: organizersSliceKey,
        saga: organizersSaga
    });
    var dispatch = useDispatch();
    var prevExternalRequest = usePrevious(externalRequest);
    var tmp = externalRequest.offset, externalOffset = tmp === void 0 ? 0 : tmp, tmp1 = externalRequest.limit, externalLimit = tmp1 === void 0 ? DEFAULT_PAGE_SIZE : tmp1, tmp2 = externalRequest.sortBy, externalSortBy = tmp2 === void 0 ? 'name' : tmp2, tmp3 = externalRequest.sortOrder, externalSortOrder = tmp3 === void 0 ? SortOrder.ASC : tmp3;
    var _useState = _sliced_to_array(useState(Math.floor(externalOffset / externalLimit)), 2), page = _useState[0], setPage = _useState[1];
    var _useRequest = useRequest(_object_spread_props(_object_spread({}, externalRequest), {
        offset: externalOffset,
        limit: externalLimit,
        sortBy: externalSortBy,
        sortOrder: externalSortOrder
    })), request = _useRequest.request, handleChangeRowsPerPage = _useRequest.handleChangeRowsPerPage, handleChangeFilter = _useRequest.handleChangeFilter, handleChangeSorting = _useRequest.handleChangeSorting, handleChangeSearch = _useRequest.handleChangeSearch, handleChangePage = _useRequest.handleChangePage;
    var prevRequest = usePrevious(request);
    var organizers = useSelector(selectOrganizersList)(queryKey);
    var isOrganizersFetching = useSelector(selectOrganizerListIsFetching)(queryKey);
    var organizersErrors = useSelector(selectOrganizerListErrors)(queryKey);
    var organizersCount = useSelector(selectOrganizerListCount)(queryKey);
    var refreshOrganizers = useCallback(function() {
        var reset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        setPage(0);
        handleChangePage(0);
        dispatch(organizersActions.getOrganizerListRequest(_object_spread_props(_object_spread({}, request), {
            offset: 0,
            reset: reset,
            queryKey: queryKey
        })));
    }, [
        dispatch,
        handleChangePage,
        queryKey,
        request
    ]);
    var nextPage = useCallback(function() {
        if (!isOrganizersFetching && (request.offset || 0) + 1 < organizersCount) {
            setPage(page + 1);
            handleChangePage(page + 1);
            return true;
        }
        return false;
    }, [
        organizersCount,
        handleChangePage,
        isOrganizersFetching,
        page,
        request.offset
    ]);
    useEffect(function() {
        if (!isEqual(prevRequest, request)) {
            if (prevRequest === undefined) {
                // Mount
                dispatch(organizersActions.getOrganizerListRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey
                })));
                return;
            }
            var offset = request.offset, rest = _object_without_properties(request, [
                "offset"
            ]);
            var prevOffset = prevRequest.offset, prevRest = _object_without_properties(prevRequest, [
                "offset"
            ]);
            if (!isEqual(rest, prevRest)) {
                dispatch(organizersActions.getOrganizerListRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey
                })));
                return;
            }
            dispatch(organizersActions.getOrganizerListRequest(_object_spread_props(_object_spread({}, request), {
                queryKey: queryKey
            })));
        }
    }, [
        dispatch,
        handleChangePage,
        prevRequest,
        queryKey,
        request
    ]);
    useEffect(function() {
        if (prevExternalRequest !== undefined) {
            if (!isEqual(prevExternalRequest === null || prevExternalRequest === void 0 ? void 0 : prevExternalRequest.filter, externalRequest === null || externalRequest === void 0 ? void 0 : externalRequest.filter)) {
                setPage(0);
                handleChangePage(0);
                handleChangeFilter(externalRequest.filter);
            }
            if (prevExternalRequest.sortBy !== externalRequest.sortBy || prevExternalRequest.sortOrder !== externalRequest.sortOrder) {
                setPage(0);
                handleChangePage(0);
                handleChangeSorting(externalRequest.sortBy || 'name', externalRequest.sortOrder || SortOrder.ASC);
            }
            if (prevExternalRequest.search !== externalRequest.search || prevExternalRequest.search_in_fields !== externalRequest.search_in_fields) {
                setPage(0);
                handleChangePage(0);
                handleChangeSearch({
                    phrase: externalRequest.search || '',
                    field: externalRequest.search_in_fields || null
                });
            }
        }
    }, [
        externalRequest.filter,
        externalRequest.search,
        externalRequest.search_in_fields,
        externalRequest.sortBy,
        externalRequest.sortOrder,
        handleChangeFilter,
        handleChangePage,
        handleChangeSearch,
        handleChangeSorting,
        prevExternalRequest
    ]);
    return {
        organizers: organizers,
        isOrganizersFetching: isOrganizersFetching,
        handleChangeRowsPerPage: handleChangeRowsPerPage,
        handleChangeFilter: handleChangeFilter,
        handleChangeSorting: handleChangeSorting,
        handleChangeSearch: handleChangeSearch,
        handleChangePage: handleChangePage,
        nextPage: nextPage,
        refreshOrganizers: refreshOrganizers,
        organizersErrors: organizersErrors,
        organizersCount: organizersCount
    };
}
export function useOrganizer(param) {
    var id = param.id;
    useInjectReducer({
        key: organizersSliceKey,
        reducer: organizersReducer
    });
    useInjectSaga({
        key: organizersSliceKey,
        saga: organizersSaga
    });
    var dispatch = useDispatch();
    var organizersErrors = useSelector(selectOrganizerErrors)(id);
    var isOrganizersFetching = useSelector(selectOrganizerIsFetching)(id);
    var selectedOrganizer = useSelector(selectOrganizer)(id);
    var refreshOrganizer = useCallback(function() {
        if (isOrganizersFetching) {
            dispatch(organizersActions.getOrganizerRequest({
                id: id
            }));
            return true;
        }
        return false;
    }, [
        dispatch,
        id,
        isOrganizersFetching
    ]);
    useEffect(function() {
        if (!selectedOrganizer && id) {
            dispatch(organizersActions.getOrganizerRequest({
                id: id
            }));
        }
    }, [
        dispatch,
        id,
        selectedOrganizer
    ]);
    return {
        isOrganizersFetching: isOrganizersFetching,
        selectedOrganizer: selectedOrganizer,
        organizersErrors: organizersErrors,
        refreshOrganizer: refreshOrganizer
    };
}
