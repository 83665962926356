import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
// BPNL imports
import { commonMessages } from '../../../i18n';
// App imports
import BpnlButton from '../BpnlButton';
// Local imports
import classes from './index.module.scss';
import { CollapseTextProps } from './types';

const defaultMessage = (
	<>
		<FormattedMessage {...commonMessages.more} /> <FormattedMessage {...commonMessages.description} />
	</>
);

export default function CollapsableText(props: CollapseTextProps) {
	const { content, className } = props;
	const shortText = content ? (content.length > 400 ? `${content.substring(0, 400)}...` : null) : null;
	const [isCollapsed, setIsCollapsed] = useState<boolean>(!!shortText);
	const handleToggleCollapsed = useCallback(() => {
		setIsCollapsed((prevState) => !prevState);
	}, []);

	return (
		<div className={clsx(classes.Collapse, className)}>
			<div className={clsx(classes.CollapseContent, !isCollapsed && classes.CollapseContentActive)}>
				<p>{isCollapsed ? shortText || content : content}</p>
			</div>
			{shortText && (
				<BpnlButton
					onClick={handleToggleCollapsed}
					title={defaultMessage}
					outlined
					iconAfter={'bpnl-arrow-down-double'}
					iconClassName={clsx(classes.Icon, isCollapsed && classes.IconActive)}
				/>
			)}
		</div>
	);
}
