import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { SortOrder } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
// App imports
import SortingList from '../SortingList';
import BpnlButton from '../BpnlButton';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import { ClickAwayListener } from '@webolucio/web';

export default function Sorting({ onSorting }) {
	const { formatMessage } = useIntl();
	const [selected, setSelected] = useState<string | null>('default');
	const [showSorting, setShowSorting] = useState<boolean>(false);

	const options = useMemo(
		() => [
			{ id: 'default', label: formatMessage(messages.default) },
			{ id: 'mostPopular', label: formatMessage(messages.mostPopular) },
			{ id: 'closest', label: formatMessage(messages.closest) },
			{ id: 'asc', label: formatMessage(messages.asc) },
			{ id: 'desc', label: formatMessage(messages.desc) },
		],
		[formatMessage],
	);

	function handlePress() {
		switch (selected) {
			case 'mostPopular':
				onSorting('count_followers', SortOrder.DESC);
				break;
			case 'closest':
				onSorting('distance', SortOrder.ASC);
				break;
			case 'asc':
				onSorting('name', SortOrder.ASC);
				break;
			case 'desc':
				onSorting('name', SortOrder.DESC);
				break;
			case 'default':
			default:
				onSorting('date_start', SortOrder.ASC);
				break;
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	if (!options || !options.length) {
		return null;
	}

	return (
		<div className={clsx(classes.sorting)}>
			<BpnlButton
				title={formatMessage(messages.filterTitle)}
				iconBefore={<Icon name={'bpnl-sorting'} />}
				size={'small'}
				onClick={() => setShowSorting((prevShowSorting) => !prevShowSorting)}
			/>
			{showSorting && (
				<ClickAwayListener
					touchEvent="touchstart"
					onClickAway={() => {
						console.log('click away');
						setShowSorting(false);
					}}
				>
					{/* This div is here because an absolutely positioned child element won't work with ClickAwayListener */}
					<div>
						<SortingList
							options={options}
							onChange={(value) => {
								setSelected(value);
								handlePress();
								setShowSorting(false);
							}}
							value={selected}
							clsName={clsx(classes.sortingItem)}
						/>
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
}
