// Webolucio imports
import { ApiService } from '@webolucio/core';
// Role
var getMyAccount = function() {
    return ApiService.getService().get("/my-account");
};
var updateMyAccount = function(user) {
    return ApiService.getService().put("/my-account", user);
};
var patchMyAccount = function(user) {
    return ApiService.getService().patch("/my-account", user);
};
export var myAccountApi = {
    getMyAccount: getMyAccount,
    updateMyAccount: updateMyAccount,
    patchMyAccount: patchMyAccount
};
