import { memoize } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialOrganizersState, organizersSliceKey } from './slice';
var selectDomain = function(state) {
    return state[organizersSliceKey] || initialOrganizersState;
};
export var selectOrganizersData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectOrganizersResults = createSelector([
    selectDomain
], function(storeState) {
    return storeState.queries;
});
export var selectOrganizerListIsFetching = createSelector([
    selectOrganizersResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.fetching) || false;
    });
});
export var selectOrganizerListErrors = createSelector([
    selectOrganizersResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.errors) || null;
    });
});
export var selectOrganizerListCount = createSelector([
    selectOrganizersResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.totalCount) || 0;
    });
});
export var selectOrganizersList = createSelector([
    selectOrganizersData,
    selectOrganizersResults
], function(organizers, queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return (((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.data) || []).reduce(function(acc, id) {
            var event = organizers[id];
            if (event) acc.push(event);
            return acc;
        }, []);
    });
});
export var selectOrganizer = createSelector([
    selectOrganizersData
], function(organizers) {
    return memoize(function(id) {
        return organizers[id] || null;
    });
});
export var selectOrganizerIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_queryKey;
        var queryKey = "single-".concat(id);
        return ((_storeState_queries_queryKey = storeState.queries[queryKey]) === null || _storeState_queries_queryKey === void 0 ? void 0 : _storeState_queries_queryKey.fetching) || false;
    });
});
export var selectOrganizerErrors = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_queryKey;
        var queryKey = "single-".concat(id);
        return ((_storeState_queries_queryKey = storeState.queries[queryKey]) === null || _storeState_queries_queryKey === void 0 ? void 0 : _storeState_queries_queryKey.errors) || null;
    });
});
