import { useCallback } from 'react';
import { useIntl } from 'react-intl';
// App imports
import AppleStore from '../../assets/images/apple_store.png';
import GooglePlayStore from '../../assets/images/google_playstore.png';
import Logo from '../../assets/logo.svg';
import Image from '../../components/presentationals/Image';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function DiscoverPage() {
	const { formatMessage } = useIntl();

	const handleAndroidDownload = useCallback(() => {
		console.log('HANDLING PLAY STORE NAVIGATION'); // TODO navigate to Play Store link
	}, []);

	const handleIOSDownload = useCallback(() => {
		console.log('HANDLING APP STORE NAVIGATION'); // TODO navigate to App Store link
	}, []);

	return (
		<div className={classes.pageWrapper}>
			<div className={classes.logo}>
				<Logo />
			</div>
			<div className={classes.store}>
				<div onClick={handleAndroidDownload}>
					<Image file={null} src={AppleStore} alt="background" />
				</div>
				<div onClick={handleIOSDownload}>
					<Image file={null} src={GooglePlayStore} alt="background" />
				</div>
			</div>
			<p className={classes.text}>{formatMessage(messages.text)}</p>
		</div>
	);
}
