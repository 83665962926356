import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
// Webolucio imports
import { FilterComparisonType, QueryListObject, useEnum } from '@webolucio/core';
import { ScrollView } from '@webolucio/web';
// BPNL imports
import { EventFilters } from '@bpnl/events';
// App imports
import Title from '../../presentationals/Title';
import CheckboxList from '../../presentationals/CheckboxList';
// Local imports
import messages from './messages';
import classes from './index.module.scss';
import { FilterProps } from '../types';

export default function VenueFilter({ initialFilter, onChange }: FilterProps<EventFilters>) {
	const { formatMessage } = useIntl();

	console.log('initialFilter', initialFilter);
	const venues = useEnum<number>('venues');

	const [selectedVenues, setSelectedVenues] = useState<Array<number>>(
		initialFilter && 'value' in initialFilter
			? Array.isArray(initialFilter.value)
				? (initialFilter.value as number[])
				: ([initialFilter.value] as number[])
			: [],
	);
	console.log('selectedVenues', selectedVenues);

	const handleChange = useCallback(
		(values: number[]) => {
			if (values.length === 0) {
				onChange && onChange([], 'venue_id');
			} else {
				onChange &&
					onChange(
						[
							{
								field: 'venue_id',
								condition_type: FilterComparisonType.IN,
								value: values,
							},
						],
						'venue_id',
					);
			}
			setSelectedVenues(values);
		},
		[onChange, selectedVenues],
	);

	return (
		<div>
			{venues && (
				<div>
					<Title title={formatMessage(messages.filterTitle)} />
					<ScrollView
						classes={{ container: classes.scrollView, scrollerContainer: classes.scrollerContainer, scrollerElement: classes.scrollerElement }}
					>
						<CheckboxList options={venues || []} onChange={handleChange} value={selectedVenues} />
					</ScrollView>
				</div>
			)}
		</div>
	);
}
