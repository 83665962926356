import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.LoginPage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Login',
	},
	btnCreateAnAccount: {
		id: `${scope}.btnCreateAnAccount`,
		defaultMessage: 'Create an account',
	},
	login: {
		id: `${scope}.login`,
		defaultMessage: 'Login',
	},
	userNameInputPlaceholder: {
		id: `${scope}.userNameInputPlaceholder`,
		defaultMessage: 'Email address',
	},
	passwordInputPlaceholder: {
		id: `${scope}.passwordInputPlaceholder`,
		defaultMessage: 'Password',
	},
	forgotPasswordText: {
		id: `${scope}.forgotPasswordText`,
		defaultMessage: 'Forgot password?',
	},
	or: {
		id: `${scope}.or`,
		defaultMessage: 'or',
	},
	loginWithFacebook: {
		id: `${scope}.loginWithFacebook`,
		defaultMessage: 'Login with Facebook',
	},
	loginWithGoogle: {
		id: `${scope}.loginWithGoogle`,
		defaultMessage: 'Login with Google',
	},
	loginWithApple: {
		id: `${scope}.loginWithApple`,
		defaultMessage: 'Login with Apple',
	},
	noAccount: {
		id: `${scope}.noAccount`,
		defaultMessage: 'Don\'t have a Nightlife account yet?',
	},
	fewClicks: {
		id: `${scope}.fewClicks`,
		defaultMessage: 'A few clicks and you have the following benefits:',
	},
	getBonuses: {
		id: `${scope}.getBonuses`,
		defaultMessage: 'Get unique Nightlife-only promotions, free passes, welcome drinks!',
	},
	savePlaces: {
		id: `${scope}.savePlaces`,
		defaultMessage: 'Save your favourite places, events',
	},
	receiveNotifications: {
		id: `${scope}.receiveNotifications`,
		defaultMessage: 'Receive notifications about events that interest you',
	},
	registration: {
		id: `${scope}.registration`,
		defaultMessage: 'Registration »',
	},
});
