import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// Webolucio imports
import { useEffectOnMount, useLocale } from '@webolucio/core';
import { PageMetas } from '@webolucio/web';
import { FieldEvent } from '@webolucio/form';
import { Form, PasswordField, TextField } from '@webolucio/form-web';
// BPNL imports
import { FrontendUserInput } from '@bpnl/common';
import { useRegistration, useValidateRegistrationFields } from '@bpnl/registration';
// App imports
import { commonMessages } from '../../i18n';
import BpnlButton from '../../components/presentationals/BpnlButton';
import Indicator from '../../components/presentationals/Indicator';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function RegistrationBasicDataPage() {
	const { locale } = useLocale();
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const { registration, registrationErrors, patchRegistrationData, isRegistrationSaving } = useRegistration();

	const validateRegistration = useValidateRegistrationFields({
		callback: (isValid) => {
			if (isValid) {
				navigate('/registration/taste');
			}
		},
		fields: ['first_name', 'last_name', 'email', 'password', 'password_confirm'],
		shouldClearErrors: true,
	});

	const handleNext = useCallback(() => {
		validateRegistration();
	}, [validateRegistration]);

	const handleFormChange = useCallback(
		(e: FieldEvent<Partial<FrontendUserInput>>) => {
			patchRegistrationData(e.target.value);
		},
		[patchRegistrationData],
	);

	useEffectOnMount(() => {
		if (!registration.date_birth) navigate('/registration');
	});

	return (
		<>
			<PageMetas title={formatMessage(messages.pageTitle)} />
			<h1 className="heading">{formatMessage(messages.pageTitle)}</h1>
			<Indicator steps={3} active={2} />
			<Form<FrontendUserInput>
				errors={(!Array.isArray(registrationErrors) && registrationErrors) || undefined}
				isSaving={isRegistrationSaving}
				isValidating={isRegistrationSaving}
				initialValues={registration}
				partial
				onChange={handleFormChange}
				onSubmit={() => {}}
				formProps={{ className: classes.formContainer }}
			>
				<div className={classes.stepTextContainer}>
					<h2 className="heading">{formatMessage(messages.intro)}</h2>
				</div>
				<div className={classes.baseDataFieldContainer}>
					{locale === 'hu' ? (
						<>
							<TextField
								name="last_name"
								placeholder={formatMessage(messages.lastName)}
								label={formatMessage(messages.lastName)}
								classes={{
									formControl: {
										formControlLabel: classes.label,
										formControlLabelHelperContainer: classes.labelContainer,
									},
								}}
							/>
							<TextField
								name="first_name"
								placeholder={formatMessage(messages.firstName)}
								label={formatMessage(messages.firstName)}
								classes={{
									formControl: {
										formControlLabel: classes.label,
										formControlLabelHelperContainer: classes.labelContainer,
									},
								}}
							/>
						</>
					) : (
						<>
							<TextField
								name="first_name"
								placeholder={formatMessage(messages.firstName)}
								label={formatMessage(messages.firstName)}
								classes={{
									formControl: {
										formControlLabel: classes.label,
										formControlLabelHelperContainer: classes.labelContainer,
									},
								}}
							/>
							<TextField
								name="last_name"
								placeholder={formatMessage(messages.lastName)}
								label={formatMessage(messages.lastName)}
								classes={{
									formControl: {
										formControlLabel: classes.label,
										formControlLabelHelperContainer: classes.labelContainer,
									},
								}}
							/>
						</>
					)}
					<TextField
						name="email"
						placeholder={formatMessage(messages.email)}
						label={formatMessage(messages.email)}
						classes={{
							formControl: {
								formControlLabel: classes.label,
								formControlLabelHelperContainer: classes.labelContainer,
							},
						}}
					/>
					<PasswordField
						name="password"
						placeholder={formatMessage(messages.password)}
						label={formatMessage(messages.password)}
						classes={{
							formControl: {
								formControlLabel: classes.label,
								formControlLabelHelperContainer: classes.labelContainer,
							},
						}}
					/>
					<PasswordField
						name="password_confirm"
						placeholder={formatMessage(messages.passwordConfirm)}
						label={formatMessage(messages.passwordConfirm)}
						classes={{
							formControl: {
								formControlLabel: classes.label,
								formControlLabelHelperContainer: classes.labelContainer,
							},
						}}
					/>
					<div className="horizontal-divider" />
					<BpnlButton width="fullWidth" size="medium" type="button" onClick={handleNext} className={classes.nextButton}>
						{formatMessage(commonMessages.next)}
					</BpnlButton>
				</div>
			</Form>
		</>
	);
}
