import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.Sorting';

export default defineMessages({
	filterTitle: {
		id: `${scope}.filterTitle`,
		defaultMessage: 'Sort by',
	},
	default: {
		id: `${scope}.default`,
		defaultMessage: 'Default',
	},
	mostPopular: {
		id: `${scope}.mostPopular`,
		defaultMessage: 'Most popular',
	},
	closest: {
		id: `${scope}.closest`,
		defaultMessage: 'Closest to you',
	},
	asc: {
		id: `${scope}.asc`,
		defaultMessage: 'Alphabetically ascending',
	},
	desc: {
		id: `${scope}.desc`,
		defaultMessage: 'Alphabetically descending',
	},
});
