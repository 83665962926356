import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.EventHeader';

export default defineMessages({
	fromPrice: {
		id: `${scope}.fromPrice`,
		defaultMessage: 'From {amount} Ft',
	},
	free: {
		id: `${scope}.free`,
		defaultMessage: 'Free',
	}
});
