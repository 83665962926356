import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Local imports
import textsSaga from './saga';
import { selectSelectedText, selectTextsErrors, selectTextsIsFetching } from './selectors';
import { textsActions, textsReducer, textsSliceKey } from './slice';
export function useText(param) {
    var str_id = param.str_id;
    useInjectReducer({
        key: textsSliceKey,
        reducer: textsReducer
    });
    useInjectSaga({
        key: textsSliceKey,
        saga: textsSaga
    });
    var dispatch = useDispatch();
    var textsErrors = useSelector(selectTextsErrors);
    var isTextsFetching = useSelector(selectTextsIsFetching);
    var selectedText = useSelector(selectSelectedText);
    useEffect(function() {
        if (str_id) {
            dispatch(textsActions.getTextRequest({
                str_id: str_id
            }));
        }
    }, [
        dispatch,
        str_id
    ]);
    return {
        isTextsFetching: isTextsFetching,
        selectedText: selectedText,
        textsErrors: textsErrors
    };
}
