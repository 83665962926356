import { uniq } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';
// BPNL imports
import { FollowType, QueryStoreMode } from '@bpnl/common';
// Local imports
import { ORGANIZERS_ENTITY_KEY } from './config';
export var initialOrganizersState = {
    data: {},
    queries: {}
};
var organizersSlice = createSlice({
    name: ORGANIZERS_ENTITY_KEY,
    initialState: initialOrganizersState,
    reducers: {
        getOrganizerListRequest: {
            prepare: function prepare(payload) {
                if (!payload.queryKey) {
                    payload.queryKey = 'default';
                }
                return {
                    payload: payload
                };
            },
            reducer: function reducer(state, action) {
                var queryKey = action.payload.queryKey || 'default';
                var mode = action.payload.mode || QueryStoreMode.Concat;
                if (!state.queries[queryKey]) {
                    state.queries[queryKey] = {
                        data: [],
                        errors: null,
                        fetching: false,
                        mode: mode,
                        totalCount: 0
                    };
                }
                state.queries[queryKey].fetching = true;
                if (action.payload.mode) {
                    state.queries[queryKey].mode = action.payload.mode;
                }
                if (action.payload.reset) {
                    state.queries[queryKey].data = [];
                    state.queries[queryKey].totalCount = 0;
                }
            }
        },
        getOrganizerListFailure: function getOrganizerListFailure(state, action) {
            var queryKey = action.payload.queryKey || 'default';
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        getOrganizerListSuccess: function getOrganizerListSuccess(state, action) {
            var _state_queries_queryKey, _action_payload_data, _action_payload_data1, _action_payload_data2;
            var queryKey = action.payload.queryKey || 'default';
            var mode = ((_state_queries_queryKey = state.queries[queryKey]) === null || _state_queries_queryKey === void 0 ? void 0 : _state_queries_queryKey.mode) || QueryStoreMode.Concat;
            (_action_payload_data = action.payload.data) === null || _action_payload_data === void 0 ? void 0 : _action_payload_data.forEach(function(item) {
                state.data[item.id] = item;
            });
            state.queries[queryKey] = {
                data: mode === QueryStoreMode.Concat ? uniq(state.queries[queryKey].data.concat(((_action_payload_data1 = action.payload.data) === null || _action_payload_data1 === void 0 ? void 0 : _action_payload_data1.map(function(item) {
                    return item.id;
                })) || [])) : ((_action_payload_data2 = action.payload.data) === null || _action_payload_data2 === void 0 ? void 0 : _action_payload_data2.map(function(item) {
                    return item.id;
                })) || [],
                errors: null,
                fetching: false,
                mode: mode,
                totalCount: action.payload.totalCount || 0
            };
        },
        getOrganizerRequest: function getOrganizerRequest(state, action) {
            var queryKey = "single-".concat(action.payload.id);
            if (!state.queries[queryKey]) {
                state.queries[queryKey] = {
                    data: [],
                    errors: null,
                    fetching: false,
                    mode: QueryStoreMode.Overwrite,
                    totalCount: 0
                };
            }
            state.queries[queryKey].fetching = true;
        },
        getOrganizerFailure: function getOrganizerFailure(state, action) {
            var queryKey = "single-".concat(action.payload.id);
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        getOrganizerSuccess: function getOrganizerSuccess(state, action) {
            var queryKey = "single-".concat(action.payload.id);
            if (!action.payload) {
                state.queries[queryKey].fetching = false;
            } else {
                state.data[action.payload.id] = action.payload;
                state.queries[queryKey] = {
                    data: [
                        action.payload.id
                    ],
                    errors: null,
                    fetching: false,
                    mode: QueryStoreMode.Overwrite,
                    totalCount: 1
                };
            }
        },
        updateOrganizer: function updateOrganizer(state, action) {
            state.data[action.payload.id] = action.payload;
        },
        setFollowed: function setFollowed(state, action) {
            if (action.payload.type === FollowType.Organizer) {
                if (state.data[action.payload.id]) {
                    state.data[action.payload.id].is_followed = action.payload.isFollowed;
                    state.data[action.payload.id].count_followers = action.payload.countFollowers;
                }
            }
        }
    }
});
export var organizersActions = organizersSlice.actions, organizersReducer = organizersSlice.reducer, organizersSliceKey = organizersSlice.name;
