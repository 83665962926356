import clsx from 'clsx';
import { LoadOverlayProps } from '@webolucio/web';
import BpnlLogo from '../../components/presentationals/BpnlLogo';
// Local imports
import classes from './index.module.scss';

function LoadOverlay(props: LoadOverlayProps) {
	const { classes: externalClasses = {}, fixed = false, size = 'large', transparent } = props;

	return (
		<div className={clsx(classes.loadOverlayContainer, externalClasses.container, fixed && classes.fixed)}>
			<div
				className={clsx(
					classes.loadOverlay,
					{
						[classes.transparent]: transparent,
					},
					externalClasses.overlay,
				)}
			/>
			<BpnlLogo size={typeof size !== 'string' ? undefined : size} />
		</div>
	);
}

export default LoadOverlay;
