import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.ResetPasswordPage';

export default defineMessages({
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'Reset password',
	},
	password: {
		id: `${scope}.password`,
		defaultMessage: 'Password',
	},
	passwordConfirm: {
		id: `${scope}.passwordConfirm`,
		defaultMessage: 'Confirm password',
	},
	captchaError: {
		id: `${scope}.captchaError`,
		defaultMessage: 'The captcha is not valid.',
	},
	successMessage: {
		id: `${scope}.successMessage`,
		defaultMessage: 'Password changed successfully. We will redirect you to the login page in a few seconds.',
	},
	btnChangePassword: {
		id: `${scope}.btnChangePassword`,
		defaultMessage: 'Change password',
	},
	stepsTitle: {
		id: `${scope}.stepsTitle`,
		defaultMessage: 'Reset your password',
	},
	stepsSubtitle: {
		id: `${scope}.stepsSubtitle`,
		defaultMessage: 'Please follow these steps:',
	},
	stepOne: {
		id: `${scope}.stepOne`,
		defaultMessage: 'Fill in the password fields',
	},
	stepTwo: {
		id: `${scope}.stepTwo`,
		defaultMessage: 'Make sure the passwords match and are strong enough',
	},
	stepThree: {
		id: `${scope}.stepThree`,
		defaultMessage: 'Click on the "{btnName}" button',
	},
});
