import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Modal } from '@webolucio/web';
import { Icon } from '@webolucio/icons';
import { DressCodeProps } from './types';
import messages from './messages';
import classes from './index.module.scss';
import BpnlButton from '../BpnlButton';

export default function DressCode(props: DressCodeProps) {
	const { dresscode } = props;
	const { formatMessage } = useIntl();
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const handleOpen = useCallback(() => {
		setIsOpenModal(!isOpenModal);
	}, [isOpenModal]);
	return (
		<>
			<Modal open={isOpenModal} title={formatMessage(messages.title)} onClose={handleOpen} classes={{ modal: clsx(classes.modal) }}>
				{dresscode &&
					dresscode.map((item, index) => (
						<div key={`dresscode-${index}`} className={classes.modalContent}>
							<h2 className={classes.title}>{item.name}</h2>
							<h3 className={classes.description}>{item.description}</h3>
							{item.description_gender1 && (
								<div className={classes.content}>
									<div className={classes.titleDescription}>
										<Icon name={'bpnl-guidance-men-restroom'} />
										<span>{formatMessage(messages.forMen)}</span>
									</div>
									<p>{item.description_gender1}</p>
								</div>
							)}
							{item.description_gender2 && (
								<div className={classes.content}>
									<div className={classes.titleDescription}>
										<Icon name={'bpnl-guidance-women-restroom'} />
										<span>{formatMessage(messages.forWoman)}</span>
									</div>
									<p>{item.description_gender2}</p>
								</div>
							)}
						</div>
					))}
			</Modal>
			{dresscode.length ?
				dresscode.map((item, index) => (
					<div key={`dresscode-${index}`} className={classes.dresscode}>
						<div className={classes.dresscodeName}>{item.name}</div>
						<BpnlButton variant={'link'} onClick={handleOpen}>
							{formatMessage(messages.whatMeans)}
						</BpnlButton>
					</div>
				)): null}
		</>
	);
}
