import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.FollowedPerformerEventSlider';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: "Your favorite performers' events",
	},
});
