import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
// Webolucio imports
import { useEffectOnMount } from '@webolucio/core';
import { PageMetas } from '@webolucio/web';
import { Icon } from '@webolucio/icons';
// BPNL imports
import { useRegistration } from '@bpnl/registration';
// App imports
import BpnlButton from '../../components/presentationals/BpnlButton';
import Indicator from '../../components/presentationals/Indicator';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

export default function RegistrationTypePage() {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const { registration } = useRegistration();

	useEffectOnMount(() => {
		if (!registration.date_birth) navigate('/registration');
	});

	return (
		<>
			<PageMetas title={formatMessage(messages.pageTitle)} />
			<h1 className="heading">{formatMessage(messages.pageTitle)}</h1>
			<Indicator steps={3} active={2} />
			<div className={classes.formContainer}>
				<div className={classes.stepTextContainer}>
					<h2 className="heading">{formatMessage(messages.title)}</h2>
					<h3 className={clsx(classes.subHeading, 'sub-heading')}>{formatMessage(messages.subtitle)}</h3>
				</div>
				<div className={classes.createAccountButtonContainer}>
					<BpnlButton
						iconBefore="bpnl-login-with-email"
						title={formatMessage(messages.withEmail)}
						width="fullWidth"
						size="medium"
						type="button"
						onClick={() => navigate('/registration/basic-data')}
					/>
					<BpnlButton iconBefore="bpnl-facebook" title={formatMessage(messages.withFacebook)} width="fullWidth" size="medium" />
					<BpnlButton iconBefore="bpnl-google" title={formatMessage(messages.withGoogle)} width="fullWidth" size="medium" />
					<BpnlButton iconBefore="bpnl-apple" title={formatMessage(messages.withApple)} width="fullWidth" size="medium" />
				</div>
			</div>
		</>
	);
}
