import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.VenuePage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Venue',
	}
});
