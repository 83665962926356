// Webolucio imports
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';

export const feedbackRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	path: '/feedback',
	handle: {
		skipInNavigation: true,
	} as BpnlRouteHandle,
};
