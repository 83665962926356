import { BadgeProps } from './types';
import classes from './index.module.scss';
import clsx from 'clsx';
import {Icon, PossibleIconName} from '@webolucio/icons';

export default function Badge(props: BadgeProps) {
	const { text, iconName } = props;

	return (
		<div className={clsx(classes.badge)}>
			{iconName ? <Icon name={iconName as PossibleIconName} className={classes.icon} /> : null}
			<span>{text}</span>
		</div>
	);
}
