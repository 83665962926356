import clsx from 'clsx';
// Webolucio imports
import { Icon, PossibleIconName } from '@webolucio/icons';
import { Button } from '@webolucio/web';
// Local imports
import classes from './index.module.scss';
import { BpnlButtonProps } from './types';

export default function BpnlButton(props: BpnlButtonProps) {
	const { iconBefore, iconAfter, title, onClick, className, outlined, children, variant, width, size, iconClassName, ...rest } = props;

	return (
		<Button className={clsx(classes.BpnlButton, className, variant, width, classes[`size-${size}`])} onClick={onClick} outlined={outlined} {...rest}>
			{iconBefore ? typeof iconBefore === 'string' ? <Icon name={iconBefore as PossibleIconName} className={iconClassName} /> : iconBefore : null}
			{title || children}
			{iconAfter ? typeof iconAfter === 'string' ? <Icon name={iconAfter as PossibleIconName} className={iconClassName} /> : iconAfter : null}
		</Button>
	);
}
