import { Enum, useEffectOnMount } from '@webolucio/core';
import { CheckboxListProps } from './types';
import { useCallback, useState } from 'react';
import { Checkbox } from '@webolucio/form-web';
import classes from './index.module.scss';
export default function CheckboxList<Value extends string | number = number, Option extends Enum<Value> = Enum<Value>>(
	props: CheckboxListProps<Value, Option>,
) {
	const { initialValue, options, onChange, value } = props;
	const [useInitialValue, setUseInitialValue] = useState<boolean>(initialValue !== undefined);

	const handleSelect = useCallback(
		(selected: Value) => () => {
			const newValue = [...(value as Value[])];
			if (newValue.includes(selected)) {
				const index = (value as Value[]).indexOf(selected);
				newValue.splice(index, 1);
				onChange(newValue as Value & Value[]);
			} else {
				onChange([...newValue, selected] as Value & Value[]);
			}
		},
		[onChange, value],
	);

	useEffectOnMount(() => {
		setUseInitialValue(false);
	});

	return (
		<div>
			{options.map((item, idx) => (
				<Checkbox
					key={`Checkbox${idx}`}
					label={item.label}
					onClick={handleSelect(item.id)}
					name={item.label}
					checked={(value as Value[]).includes(item.id)}
					classes={{checkboxInputLabel: classes.checkboxInputLabel}}
				/>
			))}
		</div>
	);
}
