import { useCallback, useState } from 'react';
import clsx from 'clsx';
// Webolucio imports
import { Enum, useEffectOnMount } from '@webolucio/core';
// App imports
import Tag from '../Tag';
// Local imports
import { TagListProps } from './types';
import classes from './index.module.scss';

export default function TagList<Value extends string | number = number, Option extends Enum<Value> = Enum<Value>>(
	props: TagListProps<Value, Option>,
) {
	const { options, multiple, initialValue, value, onChange, classes: externalClasses = {} } = props;

	const [useInitValue, setUseInitValue] = useState(initialValue !== undefined);

	const handleSelect = useCallback(
		(selected) => {
			if (multiple) {
				const newValue = [...(value as Value[])];
				if (newValue.includes(selected)) {
					const index = (value as Value[]).indexOf(selected);
					newValue.splice(index, 1);
					onChange(newValue as Value & Value[]);
				} else {
					onChange([...newValue, selected] as Value & Value[]);
				}
			} else {
				if (value !== selected) {
					onChange(selected as Value & Value[]);
				} else {
					onChange(null);
				}
			}
		},
		[multiple, onChange, value],
	);

	useEffectOnMount(() => {
		setUseInitValue(false);
	});

	if (!options) {
		return;
	}

	return (
		<div className={clsx(classes.TagList, 'tagList', externalClasses.container)}>
			{options &&
				options.map((item, index) => {
					return (
						<Tag
							label={item.label}
							key={`Tag${index}`}
							onClick={() => handleSelect(item.id)}
							active={
								useInitValue
									? Array.isArray(initialValue)
										? initialValue.includes(item.id)
										: initialValue === item.id
									: Array.isArray(value)
										? value.includes(item.id)
										: value === item.id
							}
						/>
					);
				})}
		</div>
	);
}
