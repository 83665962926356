import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { FilterComparisonType, useMountedEffect } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// App imports
import { selectMyAccount } from '@bpnl/account';
import { useVisitEntity } from '@bpnl/common';
import { usePerformerEvents } from '@bpnl/events';
import { usePerformer } from '@bpnl/performers';
import { SocialPost, SocialPostTypes, useSocialPosts } from '@bpnl/social-posts';
// External imports
import CollapsableText from '../../components/presentationals/CollapsableText';
import FollowingEvent from '../../components/presentationals/FollowingEvent';
import PerformerCard from '../../components/presentationals/PerformerCard';
import PerformerHeader from '../../components/presentationals/PerformerHeader';
import SpotifySongCard from '../../components/presentationals/SpotifySongCard';
import Tag from '../../components/presentationals/Tag';
import Title from '../../components/presentationals/Title';
import YoutubeSongCard from '../../components/presentationals/YoutubeSongCard';
import { commonMessages } from '../../i18n';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import JsonHtml from '../../components/presentationals/JsonHtml';

export default function PerformerPage() {
	const { id } = useParams<{ id: string }>();
	const performerId = Number(id);
	const navigate = useNavigate();
	const { performer: selectedPerformer, isPerformersFetching } = usePerformer({ id: performerId });
	const { events: relatedEvents, isEventsFetching } = usePerformerEvents({ performerId: performerId });
	const { formatMessage } = useIntl();
	const account = useSelector(selectMyAccount);

	const {
		socialPosts: youtubePosts,
		isSocialPostsFetching: isYoutubePostsFetching,
		refreshSocialPosts: refreshYoutubePosts,
	} = useSocialPosts({
		filter: [
			{
				field: 'performer_id' as keyof SocialPost,
				condition_type: FilterComparisonType.EQUAL,
				value: performerId,
			},
			{
				field: 'type' as keyof SocialPost,
				condition_type: FilterComparisonType.EQUAL,
				value: SocialPostTypes.YouTube,
			},
		],
		queryKey: `performer-${performerId}-youtube`,
		limit: 3,
	});

	const {
		socialPosts: spotifyPosts,
		isSocialPostsFetching: isSpotifyPostsFetching,
		refreshSocialPosts: refreshSpotifyPosts,
		socialPostsCount: spotifyPostsCount,
	} = useSocialPosts({
		filter: [
			{
				field: 'performer_id' as keyof SocialPost,
				condition_type: FilterComparisonType.EQUAL,
				value: performerId,
			},
			{
				field: 'type' as keyof SocialPost,
				condition_type: FilterComparisonType.EQUAL,
				value: SocialPostTypes.Spotify,
			},
		],
		queryKey: `performer-${performerId}-spotify`,
		limit: 5,
	});

	useVisitEntity({ id: performerId, name: 'performer', userId: account?.id });

	useMountedEffect(() => {
		if (!selectedPerformer && !isPerformersFetching) {
			navigate('/404', { replace: true });
		}
	}, [selectedPerformer, isPerformersFetching]);

	if (!selectedPerformer) {
		return <LoadOverlay />;
	}

	return selectedPerformer ? (
		<>
			<PerformerHeader
				id={performerId}
				name={selectedPerformer.name}
				followers={selectedPerformer.count_followers}
				isFollowed={selectedPerformer.is_followed}
				thumbnail={selectedPerformer.default_medium}
			/>
			<div className={clsx(classes.Content)}>
				<div className={clsx(classes.ContentLeft)}>
					{relatedEvents && relatedEvents.length ? (
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.nextEvents)} />
							{relatedEvents.map((item, idx) => (
								<FollowingEvent event={item} key={idx} />
							))}
						</div>
					) : null}
					{selectedPerformer.description ? (
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.description)} />
							{selectedPerformer.description_components ? (
								<JsonHtml json={selectedPerformer.description_components} classes={{ paragraph: classes.descriptionText }} />
							) : (
								<CollapsableText content={selectedPerformer.description} />
							)}
						</div>
					) : null}
					{selectedPerformer.music_style_tags.length ? (
						<div className={classes.block}>
							<Title title={formatMessage(messages.tagsTitle)} />
							<div className={classes.taglist}>
								{selectedPerformer.music_style_tags.map((tag) => {
									return <Tag label={tag.name} key={`performer-tag-${tag.id}`} />;
								})}
							</div>
						</div>
					) : null}
					{selectedPerformer.related_performers.length ? (
						<div className={classes.block}>
							<Title title={formatMessage(messages.similarPerformers)} />
							<div className={classes.performerList}>
								{selectedPerformer.related_performers.map((performer) => (
									<PerformerCard performerId={performer.id} key={`performer-${performer.id}`} />
								))}
							</div>
						</div>
					) : null}
				</div>
				{youtubePosts.length || spotifyPosts.length ? (
					<div className={classes.ContentRight}>
						{isSpotifyPostsFetching || spotifyPosts.length !== 0 ? (
							<>
								<Title title="Spotify" />
								<div className={classes.socialPostsContainer}>
									{spotifyPosts.map((post) => (
										<SpotifySongCard key={post.remote_id} {...post} />
									))}
								</div>
							</>
						) : null}
						{isYoutubePostsFetching || youtubePosts.length !== 0 ? (
							<>
								<Title title="Youtube" />
								<div className={classes.socialPostsContainer}>
									{youtubePosts.map((post) => (
										<YoutubeSongCard key={post.remote_id} {...post} />
									))}
								</div>
							</>
						) : null}
					</div>
				) : null}
			</div>
		</>
	) : (
		<LoadOverlay />
	);
}
