import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
// BPNL imports
import { FollowType, getStoredSearchHistory, storeSearchHistory, takeEveryUnique } from '@bpnl/common';
import { myAccountActions, selectMyAccount } from '@bpnl/account';
// Local imports
import { performersApi } from './api';
import { PERFORMER_ENTITY_KEY } from './config';
import { selectPerformersData, selectSearchedPerformers } from './selectors';
import { performersActions } from './slice';
function getPerformerListSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(performersApi.getPerformerList, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(performersActions.getPerformerListFailure({
                        errors: response.errors || [
                            'apiError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(performersActions.getPerformerListSuccess(_object_spread_props(_object_spread({}, response.data), {
                        queryKey: action.payload.queryKey
                    })))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(performersActions.getPerformerListFailure({
                        errors: [
                            'clientError'
                        ],
                        queryKey: action.payload.queryKey
                    }))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function getPerformerSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(performersApi.getPerformer, action.payload.id)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(performersActions.getPerformerFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(performersActions.getPerformerSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(performersActions.getPerformerFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function maybeUpdateLinkedEntityFollowState(action) {
    var performers, _tmp, _tmp1, _i, id, changed, performer, relatedPerformerKey;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectPerformersData)
                ];
            case 1:
                performers = _state.sent();
                if (!(action.payload.type === FollowType.Performer)) return [
                    3,
                    6
                ];
                _tmp = [];
                for(_tmp1 in performers)_tmp.push(_tmp1);
                _i = 0;
                _state.label = 2;
            case 2:
                if (!(_i < _tmp.length)) return [
                    3,
                    5
                ];
                id = _tmp[_i];
                changed = false;
                performer = _object_spread({}, performers[id]);
                if (performer.related_performers && performer.related_performers.length) {
                    performer.related_performers = _to_consumable_array(performer.related_performers);
                    for(var relatedPerformerKey in performer.related_performers){
                        if (performer.related_performers[relatedPerformerKey].id === action.payload.id) {
                            performer.related_performers[relatedPerformerKey] = _object_spread_props(_object_spread({}, performer.related_performers[relatedPerformerKey]), {
                                is_followed: action.payload.isFollowed,
                                count_followers: action.payload.countFollowers
                            });
                            changed = true;
                        }
                    }
                }
                if (!changed) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(performersActions.updatePerformer(performer))
                ];
            case 3:
                _state.sent();
                _state.label = 4;
            case 4:
                _i++;
                return [
                    3,
                    2
                ];
            case 5:
                return [
                    2
                ];
            case 6:
                return [
                    2
                ];
        }
    });
}
function populateSearchHistorySaga() {
    var account, searchTerms;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    getStoredSearchHistory(PERFORMER_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0)
                ];
            case 2:
                searchTerms = _state.sent();
                return [
                    4,
                    put(performersActions.initSearchPerformers(searchTerms))
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function syncSearchHistorySaga(action) {
    var account, searchTerms;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectMyAccount)
                ];
            case 1:
                account = _state.sent();
                return [
                    4,
                    select(selectSearchedPerformers)
                ];
            case 2:
                searchTerms = _state.sent();
                return [
                    4,
                    storeSearchHistory(PERFORMER_ENTITY_KEY, (account === null || account === void 0 ? void 0 : account.id) || 0, searchTerms)
                ];
            case 3:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
export var performersSagas = {
    getPerformerListSaga: getPerformerListSaga,
    getPerformerSaga: getPerformerSaga,
    maybeUpdateLinkedEntityFollowState: maybeUpdateLinkedEntityFollowState,
    populateSearchHistorySaga: populateSearchHistorySaga,
    syncSearchHistorySaga: syncSearchHistorySaga
};
export default function performersSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        takeEveryUnique(performersActions.getPerformerListRequest.type, getPerformerListSaga, 'queryKey'),
                        takeEvery(performersActions.getPerformerRequest.type, getPerformerSaga),
                        takeEvery(performersActions.setFollowed.type, maybeUpdateLinkedEntityFollowState),
                        takeLatest(myAccountActions.getMyAccountSuccess.type, populateSearchHistorySaga),
                        takeLatest(performersActions.storeSearchedPerformer.type, syncSearchHistorySaga),
                        takeLatest(performersActions.deleteSearchedPerformer.type, syncSearchHistorySaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
