import { useDateBreakdown } from '@bpnl/common';
import { NameOfDateProps } from './types';

export default function NameOfDate(props: NameOfDateProps) {
	const { start, end, long = false } = props;
	const date = useDateBreakdown(start, end);

	if (!date) {
		return null;
	}

	return long ? date.longDate : date.shortDate;
}
