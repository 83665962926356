import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.Search';

export default defineMessages({
	inputPlaceholder: {
		id: `${scope}.inputPlaceholder`,
		defaultMessage: 'What are you looking for?',
	},
	search: {
		id: `${scope}.search`,
		defaultMessage: 'Search',
	},
});
