import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addDays, endOfISOWeek, formatISO, startOfDay } from 'date-fns';
// Webolucio imports
import { FilterComparisonType, FilterQuery, selectAuthUserData, SortOrder } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
// BPNL imports
import { CAROUSEL_EVENT_COUNT, QueryStoreMode } from '@bpnl/common';
import { DetailedEvent, eventsMessages, useEvents, useVisitedEvents } from '@bpnl/events';
// App imports
import MapCtaBackground from '../../assets/images/map-cta.jpg';
import BpnlButton from '../../components/presentationals/BpnlButton';
import Image from '../../components/presentationals/Image';
import BpnlCarousel from '../../components/containers/BpnlCarousel';
import EventSlider from '../../components/containers/EventSlider';
import FollowedPerformerEventSlider from '../../components/containers/FollowedPerformerEventSlider';
import PerformerSlider from '../../components/containers/PerformerSlider';
import HorizontalSlider from '../../components/containers/HorizontalSlider';
import EventCard from '../../components/presentationals/EventCard';
import NameOfDate from '../../components/presentationals/NameOfDate';
import VenueRow from '../../components/presentationals/VenueRow';
import ReportBlock from '../../components/containers/ReportBlock';
import useLoadingFlag from '../../hooks/useLoadingFlag';
// Local imports
import classes from './index.module.scss';
import messages from './messages';

const todayFilter: FilterQuery = [
	{ field: 'date_start', condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL, value: formatISO(startOfDay(new Date())) },
	{ field: 'date_start', condition_type: FilterComparisonType.LESS_THAN_OR_EQUAL, value: formatISO(startOfDay(new Date())) },
];

const thisWeekFilter: FilterQuery = [
	{ field: 'date_start', condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL, value: formatISO(addDays(startOfDay(new Date()), 1)) },
	{ field: 'date_start', condition_type: FilterComparisonType.LESS_THAN_OR_EQUAL, value: formatISO(endOfISOWeek(startOfDay(new Date()))) },
];

export default function DiscoverPage() {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	const {
		events: featuredEvents,
		isEventsFetching: isFeaturedEventsFetching,
		eventCount: featuredEventCount,
	} = useEvents({
		queryKey: 'featuredCarousel',
		filter: [
			{ field: 'is_featured', condition_type: FilterComparisonType.EQUAL, value: true },
			{ field: 'date_start', condition_type: FilterComparisonType.GREATER_THAN, value: formatISO(startOfDay(new Date())) },
		],
		sortBy: 'date_start',
		offset: 0,
		limit: CAROUSEL_EVENT_COUNT,
		mode: QueryStoreMode.Overwrite,
	});

	useLoadingFlag(featuredEventCount === 0 && isFeaturedEventsFetching);

	const { visitedEvents } = useVisitedEvents();

	const userData = useSelector(selectAuthUserData);

	const handleMapCTAClick = useCallback(() => {
		navigate('/map');
	}, [navigate]);

	const renderCarouselItem = useCallback(
		(event: DetailedEvent) => (
			<div className={classes.mainInfos}>
				<div className={classes.date}>
					<Icon name="bpnl-date" />
					<span>
						<NameOfDate start={event.date_start} end={event.date_end} long />
					</span>
				</div>
				<h2 className={classes.title}>
					<Link to={`/events/${event.id}`}>{event.name}</Link>
				</h2>
				{event.venues.length ? (
					<VenueRow
						className={classes.venue}
						id={event.venues[0].id}
						name={event.venues[0].name}
						thumbnail={event.venues[0].default_medium || undefined}
						variant="basicLarge"
					/>
				) : null}
				<Link className={classes.moreInfoBtn} to={`/events/${event.id}`}>
					{formatMessage(messages.carouselMoreButton)}
				</Link>
			</div>
		),
		[formatMessage],
	);

	return (
		<div className={classes.discoverPage}>
			<BpnlCarousel<DetailedEvent>
				items={featuredEvents.map((event) => ({ image: event.default_medium?.file || null, item: event }))}
				classes={{
					container: classes.carousel,
					carouselItem: classes.carouselItem,
					carouselImage: classes.carouselImage,
					carouselContent: classes.carouselContent,
				}}
				autoplay
				swipe
				renderItem={renderCarouselItem}
			/>
			<EventSlider
				emptyMessage={formatMessage(messages.noDailyEvents)}
				cardsToShow={5}
				filter={todayFilter}
				name="dailyEvents"
				sortBy="count_followers"
				sortOrder={SortOrder.ASC}
				title={formatMessage(messages.dailyEvents)}
			/>
			<EventSlider
				emptyMessage={formatMessage(messages.noWeeklyEvents)}
				filter={thisWeekFilter}
				name="weeklyEvents"
				sortBy="date_start"
				sortOrder={SortOrder.DESC}
				title={formatMessage(messages.weeklyEvents)}
			/>
			{userData?.has_followed_performer_event && <FollowedPerformerEventSlider />}
			<div className={classes.performerSection}>
				<PerformerSlider
					emptyMessage={formatMessage(messages.noPopularPerformer)}
					name="popularPerformers"
					sortBy="count_followers"
					sortOrder={SortOrder.DESC}
					title={formatMessage(messages.popularPerformers)}
				/>
			</div>
			{visitedEvents.length ? (
				<div className={classes.visitedEventsSection}>
					<h2>{formatMessage(messages.recentlyViewed)}</h2>
					<HorizontalSlider slidesToShow={6} swipe>
						{visitedEvents.map((event) => (
							<EventCard key={`visited-event-slider-card-${event.id}`} eventId={event.id} variant="narrow" />
						))}
					</HorizontalSlider>
				</div>
			) : null}
			<div className={classes.mapCTA}>
				<Image className={classes.mapCTAImage} src={MapCtaBackground} file={null} />
				<div className={classes.mapCTAContent}>
					<Icon className={classes.mapCTAContentIcon} name="bpnl-map-location" />
					<h2 className={classes.mapCTAContentTitle}>{formatMessage(messages.mapCtaTitle)}</h2>
					<h3 className={classes.mapCTAContentSubtitle}>{formatMessage(messages.mapCtaSubtitle)}</h3>
					<BpnlButton className={classes.mapCTAContentButton} onClick={handleMapCTAClick} title={formatMessage(messages.mapCtaButton)} />
				</div>
			</div>
			<ReportBlock
				className={classes.reportBlock}
				title={formatMessage(eventsMessages.reportTitle)}
				onClick={() => navigate('/feedback', { state: { type: 'event' } })}
			/>
		</div>
	);
}
