import clsx from 'clsx';
import classes from './index.module.scss';
import { TagProps } from './types';

export default function Tag(props: TagProps) {
	const { variant = 'primary', className, beforeIcon, afterIcon, active, label, onClick, size = 'medium' } = props;

	return (
		<div
			className={clsx(
				classes.tag,
				className,
				'tag',
				classes[variant],
				variant,
				classes[size],
				size,
				onClick && clsx(classes.clickable, 'clickable'),
				active && classes.active,
			)}
			onClick={onClick}
		>
			{beforeIcon ? <div className={clsx(classes.icon, 'icon', classes.iconBefore, 'icon-before')}>{beforeIcon}</div> : null}
			<span className={clsx(classes.label, 'label')}>{label}</span>
			{afterIcon ? <div className={clsx(classes.icon, 'icon', classes.iconAfter, 'icon-after')}>{afterIcon}</div> : null}
		</div>
	);
}
