import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.MapsPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Maps',
	},
	type: {
		id: `${scope}.type`,
		defaultMessage: 'Venue type',
	}
});
