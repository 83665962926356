import clsx from 'clsx';
import { formatISO, startOfDay } from 'date-fns';
import { useIntl } from 'react-intl';
// Webolucio imports
import { FilterComparisonType, FilterQuery, SortOrder, ucFirst } from '@webolucio/core';
import { TabPanel, Tabs } from '@webolucio/web';
// BPNL imports
import { EventFilters, eventsMessages } from '@bpnl/events';
import { PerformerFilters, performersMessages } from '@bpnl/performers';
import { VenueFilters, venuesMessages } from '@bpnl/venues';
// App imports
import EventGrid from '../../components/containers/EventGrid';
import PerformerGrid from '../../components/containers/PerfomerGrid';
import VenueGrid from '../../components/containers/VenueGrid';
import useLoadingFlag from '../../hooks/useLoadingFlag';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import Title from "../../components/presentationals/Title";

const nextFollowedEventsFilter: FilterQuery<EventFilters> = [
	{
		field: 'is_followed',
		condition_type: FilterComparisonType.EQUAL,
		value: true,
	},
	{
		field: 'date_start',
		condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
		value: formatISO(startOfDay(new Date())),
	},
];

const prevFollowedEventsFilter: FilterQuery<EventFilters> = [
	{
		field: 'is_followed',
		condition_type: FilterComparisonType.EQUAL,
		value: true,
	},
	{
		field: 'date_start',
		condition_type: FilterComparisonType.LESS_THAN,
		value: formatISO(startOfDay(new Date())),
	},
];

const followedVenuesFilter: FilterQuery<VenueFilters> = [
	{
		field: 'is_followed',
		condition_type: FilterComparisonType.EQUAL,
		value: true,
	},
];

const followedPerformerFilter: FilterQuery<PerformerFilters> = [
	{
		field: 'is_followed',
		condition_type: FilterComparisonType.EQUAL,
		value: true,
	},
];

export default function FollowingPage() {
	const { formatMessage } = useIntl();

	useLoadingFlag(false);

	return (
		<>
			<Title title={formatMessage(messages.title)} variant={'page'} />
			<Tabs classes={{ container: classes.followingPage, headers: classes.tabHeader }}>
				<TabPanel name="events" label={ucFirst(formatMessage(eventsMessages.events))}>
					<h2 className={classes.title}>{formatMessage(messages.nextEvents)}</h2>
					<EventGrid className={classes.grid} filter={nextFollowedEventsFilter} name="nextFollowed" sortBy="date_start" sortOrder={SortOrder.ASC} />
					<h2 className={clsx(classes.title, classes.pastEventsTitle)}>{formatMessage(messages.pastEvents)}</h2>
					<EventGrid className={classes.grid} filter={prevFollowedEventsFilter} name="prevFollowed" sortBy="date_start" sortOrder={SortOrder.DESC} />
				</TabPanel>
				<TabPanel name="venues" label={ucFirst(formatMessage(venuesMessages.venues))}>
					<h2 className={classes.title}>{formatMessage(messages.venues)}</h2>
					<VenueGrid className={classes.grid} filter={followedVenuesFilter} name="followed" sortBy="distance" sortOrder={SortOrder.ASC} />
				</TabPanel>
				<TabPanel name="performers" label={ucFirst(formatMessage(performersMessages.performers))}>
					<h2 className={classes.title}>{formatMessage(messages.performers)}</h2>
					<PerformerGrid className={classes.performerGrid} filter={followedPerformerFilter} name="followed" sortBy="name" sortOrder={SortOrder.ASC} />
				</TabPanel>
			</Tabs>
		</>
	);
}
