import { memoize } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialEventsState, eventsSliceKey } from './slice';
var selectDomain = function(state) {
    return state[eventsSliceKey] || initialEventsState;
};
export var selectEventsData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectEventsQueryResults = createSelector([
    selectDomain
], function(storeState) {
    return storeState.queries;
});
export var selectGetEventIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.singleItemFetching;
});
export var selectEventListIsFetching = createSelector([
    selectEventsQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.fetching) || false;
    });
});
export var selectGetEventErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.singleItemErrors;
});
export var selectEventListErrors = createSelector([
    selectEventsQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.errors) || null;
    });
});
export var selectEventsCount = createSelector([
    selectEventsQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.totalCount) || 0;
    });
});
export var selectEventsList = createSelector([
    selectEventsData,
    selectEventsQueryResults
], function(events, queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return (((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.data) || []).reduce(function(acc, id) {
            var event = events[id];
            if (event) acc.push(event);
            return acc;
        }, []);
    });
});
export var selectEvent = createSelector([
    selectEventsData
], function(events) {
    return memoize(function(id) {
        return events[id] || null;
    });
});
export var selectSearchedEvents = createSelector([
    selectDomain
], function(storeState) {
    return storeState.searchedItems;
});
