import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
// Webolucio imports
import { FilterQuery, SortOrder } from '@webolucio/core';
// BPNL imports
import { DEFAULT_PAGE_SIZE } from '@bpnl/common';
import { DetailedEvent, eventsMessages, useEvents } from '@bpnl/events';
// App imports
import HorizontalSlider from '../HorizontalSlider';
import EventCard from '../../presentationals/EventCard';
import EventCardSkeleton from '../../presentationals/EventCard/Skeleton';
// Local imports
import classes from './index.module.scss';
import { EventSliderProps } from './types';

export default function EventSlider(props: EventSliderProps) {
	const { cardsToShow = 6, className, emptyMessage, filter, limit = DEFAULT_PAGE_SIZE, mode, name, offset, search, sortBy, sortOrder, title } = props;

	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const { formatMessage } = useIntl();

	const { events, isEventsFetching, eventCount, nextPage } = useEvents({
		mode,
		queryKey: name,
		filter: filter as FilterQuery<DetailedEvent>,
		search,
		sortBy: sortBy || 'date_start',
		sortOrder: sortOrder || SortOrder.ASC,
		offset,
		limit,
	});

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextPage()) {
					setPendingNextPage(false);
				} else {
					if (isEventsFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isEventsFetching, nextPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	useEffect(() => {
		if (!isEventsFetching && pendingNextPage) {
			nextPage();
			setPendingNextPage(false);
		}
	}, [isEventsFetching, nextPage, pendingNextPage]);

	return (
		<div className={clsx(classes.eventSlider, 'event-slider', className)} ref={rootRef}>
			{title ? <h2 className={clsx(classes.title, 'event-slider-title')}>{typeof title === 'string' ? title : formatMessage(title)}</h2> : null}
			{eventCount === 0 && !isEventsFetching ? (
				<p className={clsx(classes.emptyMessage, 'event-slider-empty-message')}>
					{typeof emptyMessage === 'string' ? emptyMessage : formatMessage(emptyMessage || eventsMessages.noEvents)}
				</p>
			) : (
				<HorizontalSlider slidesToShow={cardsToShow} slidesToShowMobile={2} swipe>
					{!events.length && isEventsFetching
						? Array.from({ length: cardsToShow }).map((_, idx) => <EventCardSkeleton key={`event-skeleton-${idx}`} />)
						: events
								.map((event, idx) => (
									<EventCard
										key={`${name}-slider-card-${event.id}`}
										eventId={event.id}
										ref={idx === events.length - 2 ? itemRef : undefined}
										variant="narrow"
									/>
								))
								.concat(eventCount > events.length ? [<EventCardSkeleton key="event-loading-skeleton" />] : [])}
				</HorizontalSlider>
			)}
		</div>
	);
}
