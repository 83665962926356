import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Image from '../../presentationals/Image';
import { GalleryProps } from './types';
import classes from './index.module.scss';

export default function Gallery(props: GalleryProps) {
	const { images } = props;

	const [nav1, setNav1] = useState<any>(null);
	const [nav2, setNav2] = useState<any>(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.mainSlider}>
				{/*@ts-ignore*/}
				<Slider slidesToShow={1} asNavFor={nav2} ref={(slider) => (sliderRef1 = slider)}>
					{images.map((image, index) => (
						<Image file={image.file} key={index} className={classes.image} />
					))}
				</Slider>
			</div>
			<div className={classes.thumbnails}>
				<Slider
					asNavFor={nav1}
					//@ts-ignore
					ref={(slider) => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					{images.map((image, index) => (
						<Image file={image.file} key={index} className={classes.thumbnail} />
					))}
				</Slider>
			</div>
		</div>
	);
}
