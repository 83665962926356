import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.VenueFilter';

export default defineMessages({
	filterTitle: {
		id: `${scope}.filterTitle`,
		defaultMessage: 'Venue',
	},
});
