import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
// Webolucio imports
import { trimSlashes, webolucioConfig } from '@webolucio/core';
import { notificationsActions } from '@webolucio/web';
// BPNL imports
import { FollowType, useDateBreakdown } from '@bpnl/common';
import { useFollow } from '@bpnl/follow';
// App imports
import FancyDate from '../FancyDate';
import Image from '../Image';
import Badge from '../Badge';
import HeaderButtons from '../HeaderButtons';
import { commonMessages } from '../../../i18n';
// Local imports
import { EventHeaderProps } from './types';
import classes from './index.module.scss';
import messages from './messages';

export default function EventHeader(props: EventHeaderProps) {
	const { event } = props;
	const { formatMessage } = useIntl();
	const { deleteFollow, submitFollow, followSavingItems } = useFollow();
	const [isSaving, setIsSaving] = useState(false);
	const dispatch = useDispatch();

	const date = useDateBreakdown(event.date_start, event.date_end);

	const isFree = event.is_free ? `${formatMessage(messages.free)}` : '';
	const ticketMinPrice = event.ticket_min_price ? `${formatMessage(messages.fromPrice, { amount: event.ticket_min_price })}` : '';
	const ageRestriction = event.age_restriction ? (event.age_restriction >= 18 ? `${event.age_restriction}+` : `${event.age_restriction}`) : '';

	const handleFollow = useCallback(() => {
		if (event.is_followed) {
			deleteFollow({ id: event.id, type: FollowType.Event });
		} else {
			submitFollow({ id: event.id, type: FollowType.Event });
		}
	}, [event.id, event.is_followed, deleteFollow, submitFollow]);

	const handleCopyToClipboard = useCallback(() => {
		return navigator.clipboard.writeText(`${trimSlashes(webolucioConfig.baseUrl)}/events/${event.id}`).then(() => {
			dispatch(notificationsActions.addNotification({ message: formatMessage(commonMessages.clipboard), variant: 'success' }));
		});
	}, [dispatch, event.id, formatMessage]);

	useEffect(() => {
		setIsSaving(followSavingItems?.includes(`event-${event.id}`));
	}, [followSavingItems, event.id]);

	return (
		<div className={clsx(classes.EventHeader)}>
			{event.default_medium ? (
				<div className={classes.EventHeaderImage}>
					<Image file={event.default_medium ? event.default_medium.file : null} />
				</div>
			) : null}

			{event.date_start || event.name ? (
				<div className={classes.EventHeaderContent}>
					<FancyDate start={event.date_start} />
					<div className={clsx(classes.EventHeaderTitle)}>
						<div className={clsx(classes.date)}>{date ? date.longDate : null}</div>
						<h1>{event.name}</h1>
						<div className={clsx(classes.badges)}>
							{event.venues && event.venues.map((venue) => <Badge iconName={'bpnl-pin'} text={venue.name} key={venue.id} />)}
							{isFree && <Badge iconName={'bpnl-ticket-outline'} text={isFree} />}
							{ticketMinPrice && <Badge iconName={'bpnl-ticket-outline'} text={ticketMinPrice} />}
							{ageRestriction && <Badge iconName={'bpnl-men'} text={ageRestriction} />}
						</div>
					</div>
					{event.is_followed !== undefined ? (
						<HeaderButtons isFollowed={event.is_followed} isFetching={isSaving} followClick={handleFollow} shareClick={handleCopyToClipboard} />
					) : null}
				</div>
			) : null}
		</div>
	);
}
