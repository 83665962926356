import { useCallback, useMemo } from 'react';
// Local imports
import { SpotifySongCardProps } from './types';
import classes from './index.module.scss';
import { Link } from 'react-router-dom';

export default function SpotifySongCard(props: SpotifySongCardProps) {
	const { title, image_url, link, media_length_string } = props;

	const spotifyCard = useMemo(
		() => (
			<div className={classes.card}>
				{image_url && <img className={classes.thumbnail} alt={title} src={image_url} />}
				<p>{title}</p>
				<p>{media_length_string}</p>
			</div>
		),
		[],
	);

	return link ? (
		<Link to={link} target="_blank" rel="noopener noreferrer">
			{spotifyCard}
		</Link>
	) : (
		spotifyCard
	);
}
