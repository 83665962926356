import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.DressCode';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Dresscode',
	},
	forWoman: {
		id: `${scope}.forWoman`,
		defaultMessage: 'For Woman',
	},
	forMen:{
		id: `${scope}.forMen`,
		defaultMessage: 'For Men',
	},
	whatMeans: {
		id: `${scope}.whatMeans`,
		defaultMessage: 'What does it mean?',
	}
});
