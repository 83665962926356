import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.LocationFilter';

export default defineMessages({
	filterTitle: {
		id: `${scope}.filterTitle`,
		defaultMessage: 'Location',
	},
	numberOfChosen: {
		id: `${scope}.numberOfChosen`,
		defaultMessage: 'chosen',
	},
	location: {
		id: `${scope}.location`,
		defaultMessage: 'Region',
	},
	district: {
		id: `${scope}.district`,
		defaultMessage: 'District:',
	},
});
