import { ApiService } from '@webolucio/core';
var createMessage = function(message) {
    return ApiService.getService().post('/contact-messages', message);
};
var createValidateMessage = function(message) {
    return ApiService.getService().post('/contact-messages/validate', message);
};
var getMessageList = function(param) {
    var filter = param.filter, offset = param.offset, limit = param.limit, sortBy = param.sortBy, sortOrder = param.sortOrder, search = param.search, search_in_fields = param.search_in_fields;
    return ApiService.getService().get("/contact-messages", {
        filter: filter,
        offset: offset,
        limit: limit,
        orderby_field: sortBy,
        orderby_dir: sortOrder,
        search: search,
        search_in_fields: search_in_fields
    });
};
var getMessage = function(id) {
    return ApiService.getService().get("/contact-messages/".concat(id));
};
var deleteMessage = function(id) {
    return ApiService.getService().delete("/contact-messages/".concat(id));
};
var bulkDeleteMessage = function(ids) {
    return ApiService.getService().delete("/contact-messages/", {
        id: ids
    });
};
export var messagesApi = {
    createMessage: createMessage,
    createValidateMessage: createValidateMessage,
    getMessageList: getMessageList,
    getMessage: getMessage,
    deleteMessage: deleteMessage,
    bulkDeleteMessage: bulkDeleteMessage
};
