import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { useMountedEffect } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { useVisitEntity } from '@bpnl/common';
import { eventsMessages, useVenueEvents } from '@bpnl/events';
import { useVenue } from '@bpnl/venues';
import { selectMyAccount } from '@bpnl/account';
// App imports
import { commonMessages } from '../../i18n';
import Title from '../../components/presentationals/Title';
import VenueHeader from '../../components/presentationals/VenueHeader';
import CollapsableText from '../../components/presentationals/CollapsableText';
import VenueData from '../../components/presentationals/VenueData';
import ServicesData from '../../components/presentationals/VenueServices';
import FollowingEvent from '../../components/presentationals/FollowingEvent';
import HorizontalSlider from '../../components/containers/HorizontalSlider';
import ReportBlock from '../../components/containers/ReportBlock';
import VenueCard from '../../components/presentationals/VenueCard';
// Local imports
import classes from './index.module.scss';
import JsonHtml from '../../components/presentationals/JsonHtml';

export default function VenuePage() {
	const { id } = useParams<{ id: string }>();
	const venueId = Number(id);
	const navigate = useNavigate();
	const { selectedVenue, isVenuesFetching } = useVenue({ id: venueId });
	const { events } = useVenueEvents({ venueId: venueId });
	const { formatMessage } = useIntl();
	const account = useSelector(selectMyAccount);

	useVisitEntity({ id: venueId, name: 'venue', userId: account?.id });

	useMountedEffect(() => {
		if (!selectedVenue && !isVenuesFetching) {
			navigate('/404', { replace: true });
		}
	}, [selectedVenue, isVenuesFetching]);

	if (!selectedVenue) {
		return <LoadOverlay />;
	}

	return (
		<>
			<VenueHeader
				id={venueId}
				name={selectedVenue.name}
				followers={selectedVenue.count_followers}
				isFollowed={selectedVenue.is_followed}
				thumbnails={selectedVenue.media}
			/>
			<div className={clsx(classes.Content)}>
				<div className={clsx(classes.ContentLeft)}>
					{selectedVenue.description && (
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.description)} />
							{selectedVenue.description_components ? (
								<JsonHtml json={selectedVenue.description_components} classes={{ paragraph: classes.descriptionText }} />
							) : (
								<CollapsableText content={selectedVenue.description} />
							)}
						</div>
					)}
					{events && events.length ? (
						<div className={classes.block}>
							<Title title={'következő események'} />
							{events.map((item, idx) => (
								<FollowingEvent event={item} key={idx} />
							))}
						</div>
					) : null}
					{selectedVenue.services.length ? (
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.services)} />
							<ServicesData services={selectedVenue.services} variant={'page'} />
						</div>
					) : null}
				</div>
				{selectedVenue?.address && (
					<div className={clsx(classes.ContentRight)}>
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.info)} />
							<VenueData venue={selectedVenue} />
						</div>
					</div>
				)}
			</div>

			{selectedVenue.related_venues.length ? (
				<>
					<Title title={formatMessage(commonMessages.relatedVenues)} variant={'cards'} />
					<HorizontalSlider slidesToShow={5} swipe>
						{selectedVenue.related_venues.map((item, index) => (
							<VenueCard venueId={item.id} key={`related-venue-${index}`} />
						))}
					</HorizontalSlider>
				</>
			) : null}
			<ReportBlock
				className={classes.reportBlock}
				title={formatMessage(eventsMessages.reportTitle)}
				onClick={() => navigate('/feedback', { state: { type: 'event' } })}
			/>
		</>
	);
}
