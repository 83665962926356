import { createRef, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { usePrevious } from '@webolucio/core';
import { notificationsActions, PageMetas, ReCaptcha, ReCaptchaRef } from '@webolucio/web';
import { FieldEvent } from '@webolucio/form';
import { Form, TextField } from '@webolucio/form-web';
// BPNL imports
import {
	registrationActions,
	selectForgotPasswordIsFetching,
	selectRegistrationErrors,
	ForgotPasswordInput,
	registrationSliceKey,
	registrationReducer,
	registrationSaga,
} from '@bpnl/registration';
// App imports
import BPNLLogo from '../../assets/logo.svg';
import BpnlButton from '../../components/presentationals/BpnlButton';
// Local imports
import messages from './messages';
import classes from './index.module.scss';

const initialValues: ForgotPasswordInput = { email: '', captcha_response: null };

export default function ForgotPasswordPage() {
	// INJECTORS
	useInjectReducer({ key: registrationSliceKey, reducer: registrationReducer });
	useInjectSaga({ key: registrationSliceKey, saga: registrationSaga });

	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	const recaptchaRef = createRef<ReCaptchaRef>();

	const errors = useSelector(selectRegistrationErrors);
	const isSubmitting = useSelector(selectForgotPasswordIsFetching);

	const prevSubmitting = usePrevious(isSubmitting);

	const updateToken = useCallback<() => Promise<string | null>>(() => {
		if (!recaptchaRef.current) {
			return Promise.resolve(null);
		}
		return recaptchaRef.current.execute();
	}, [recaptchaRef]);

	const handleSubmit = useCallback(
		(e: FieldEvent<ForgotPasswordInput>) => {
			updateToken().then((captchaResponse) => {
				if (captchaResponse) {
					dispatch(registrationActions.forgotPasswordPublicRequest({ ...e.target.value, captcha_response: captchaResponse }));
				} else {
					dispatch(notificationsActions.addNotification({ message: formatMessage(messages.captchaError), variant: 'error' }));
				}
			});
		},
		[dispatch, formatMessage, updateToken],
	);

	useEffect(() => {
		if (prevSubmitting && !isSubmitting && !errors) {
			dispatch(notificationsActions.addNotification({ message: formatMessage(messages.successMessage), variant: 'success' }));
		}
	}, [dispatch, errors, formatMessage, isSubmitting, prevSubmitting]);

	// RENDERERS
	return (
		<>
			<PageMetas title={formatMessage(messages.pageTitle)} />
			<div className={classes.content}>
				<div className={classes.loginSection}>
					<Form
						formProps={{ className: classes.formContainer }}
						initialValues={initialValues}
						errors={Array.isArray(errors) ? undefined : errors || undefined}
						isSaving={isSubmitting}
						isValidating={isSubmitting}
						onSubmit={handleSubmit}
					>
						<h1 className={classes.heading}>{formatMessage(messages.pageTitle)}</h1>
						<TextField name="email" placeholder={formatMessage(messages.userNameInputPlaceholder)} />
						<ReCaptcha ref={recaptchaRef} action="forgotPassword" />
						<BpnlButton className={classes.loginButton} width="fullWidth" size="medium" type="submit">
							{formatMessage(messages.btnSendPasswordReset)}
						</BpnlButton>
						<BpnlButton outlined size="medium" type="button" onClick={() => navigate('/login', { replace: true })}>
							{formatMessage(messages.btnBackToLogin)}
						</BpnlButton>
					</Form>
				</div>
				<div className={classes.noAccSection}>
					<div className={classes.logo}>
						<BPNLLogo />
					</div>
					<h2 className={classes.heading}>{formatMessage(messages.stepsTitle)}</h2>
					<h3 className={classes.subHeading}>{formatMessage(messages.stepsSubtitle)}</h3>
					<ul>
						<li>{formatMessage(messages.stepOne, { btnName: formatMessage(messages.btnSendPasswordReset) })}</li>
						<li>{formatMessage(messages.stepTwo)}</li>
						<li>{formatMessage(messages.stepThree)}</li>
					</ul>
				</div>
			</div>
		</>
	);
}
