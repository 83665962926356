import { forwardRef, useCallback, useEffect, useState, ForwardedRef } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// BPNL imports
import { useFollow } from '@bpnl/follow';
import { FollowType } from '@bpnl/common';
import { useVenue } from '@bpnl/venues';
import { eventsMessages } from '@bpnl/events';
// App imports
import Image from '../Image';
import LinkOverlay from '../LinkOverlay';
import VenueData from '../VenueData';
import VenueRow from '../VenueRow';
import ServicesData from '../VenueServices';
import FavoriteButton from '../FavoriteButton';
// Local imports
import classes from './index.module.scss';
import VenueCardSkeleton from './Skeleton';

function VenueCard(props: VenueCardProps, ref: ForwardedRef<HTMLDivElement>) {
	const { className, venueId } = props;
	const [isSaving, setIsSaving] = useState(false);

	const { formatMessage } = useIntl();
	const { selectedVenue } = useVenue({ id: venueId });
	const { deleteFollow, submitFollow, followSavingItems } = useFollow();

	const handleFollow = useCallback(() => {
		if (selectedVenue?.is_followed) {
			deleteFollow({ id: venueId, type: FollowType.Venue });
		} else {
			submitFollow({ id: venueId, type: FollowType.Venue });
		}
	}, [venueId, selectedVenue?.is_followed, deleteFollow, submitFollow]);

	useEffect(() => {
		if (followSavingItems?.includes(`venue-${venueId}`)) {
			setIsSaving(true);
		} else {
			setIsSaving(false);
		}
	}, [followSavingItems, venueId]);

	if (!selectedVenue) {
		return <VenueCardSkeleton />;
	}

	return (
		<div className={clsx(classes.venueCard, className)} ref={ref}>
			<div className="cover-image">
				<Image file={selectedVenue.default_medium?.file || (selectedVenue.media.length && selectedVenue.media[0].file) || null} />
				<FavoriteButton
					isFollowed={selectedVenue?.is_followed}
					click={handleFollow}
					isFetching={isSaving}
					className={clsx(classes.venueCardFollow)}
				/>
			</div>
			<div className={classes.content}>
				<LinkOverlay to={`/venues/${venueId}`} />
				{selectedVenue && (
					<VenueRow
						className={classes.venueRow}
						name={selectedVenue.name}
						variant="followers"
						id={selectedVenue.id}
						followers={selectedVenue.count_followers}
						thumbnail={selectedVenue?.default_medium || undefined}
					/>
				)}
				<VenueData venue={selectedVenue} variant={'card'} />
				{selectedVenue.count_events ? (
					<div className={clsx(classes.date)}>
						<Icon name="bpnl-date" />
						<span>
							{selectedVenue.count_events}{' '}
							{selectedVenue.count_events > 1
								? formatMessage(eventsMessages.eventPlural).toLowerCase()
								: formatMessage(eventsMessages.event).toLowerCase()}
						</span>
					</div>
				) : (
					<div className={classes.venuePlaceholder}></div>
				)}
				<ServicesData services={selectedVenue.services} />
			</div>
		</div>
	);
}

export default forwardRef<HTMLDivElement, VenueCardProps>(VenueCard);
