import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _object_without_properties } from "@swc/helpers/_/_object_without_properties";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { SortOrder, useEffectOnMount, usePrevious, useRequest } from '@webolucio/core';
// BPNL imports
import { DEFAULT_PAGE_SIZE } from '@bpnl/common';
// Local imports
import venuesSaga from './saga';
import { selectGetVenueErrors, selectGetVenueIsFetching, selectSearchedVenues, selectVenue, selectVenueIsOpen, selectVenueListErrors, selectVenueListIsFetching, selectVenuesCount, selectVenuesList } from './selectors';
import { venuesActions, venuesReducer, venuesSliceKey } from './slice';
export function useVenues(_param) {
    var _param_queryKey = _param.queryKey, queryKey = _param_queryKey === void 0 ? 'default' : _param_queryKey, mode = _param.mode, externalRequest = _object_without_properties(_param, [
        "queryKey",
        "mode"
    ]);
    useInjectReducer({
        key: venuesSliceKey,
        reducer: venuesReducer
    });
    useInjectSaga({
        key: venuesSliceKey,
        saga: venuesSaga
    });
    var dispatch = useDispatch();
    var prevExternalRequest = usePrevious(externalRequest);
    var _useState = _sliced_to_array(useState(false), 2), forceConcat = _useState[0], setForceConcat = _useState[1];
    var tmp = externalRequest.offset, externalOffset = tmp === void 0 ? 0 : tmp, tmp1 = externalRequest.limit, externalLimit = tmp1 === void 0 ? DEFAULT_PAGE_SIZE : tmp1, tmp2 = externalRequest.sortBy, externalSortBy = tmp2 === void 0 ? 'distance' : tmp2, tmp3 = externalRequest.sortOrder, externalSortOrder = tmp3 === void 0 ? SortOrder.ASC : tmp3;
    var _useState1 = _sliced_to_array(useState(Math.floor(externalOffset / externalLimit)), 2), page = _useState1[0], setPage = _useState1[1];
    var _useRequest = useRequest(_object_spread_props(_object_spread({}, externalRequest), {
        offset: externalOffset,
        limit: externalLimit,
        sortBy: externalSortBy,
        sortOrder: externalSortOrder
    })), request = _useRequest.request, handleChangeRowsPerPage = _useRequest.handleChangeRowsPerPage, handleChangeRequestFilter = _useRequest.handleChangeFilter, handleChangeSorting = _useRequest.handleChangeSorting, handleChangeSearch = _useRequest.handleChangeSearch, handleChangePage = _useRequest.handleChangePage;
    var prevRequest = usePrevious(request);
    var venues = useSelector(selectVenuesList)(queryKey);
    var isVenuesFetching = useSelector(selectVenueListIsFetching)(queryKey);
    var venuesErrors = useSelector(selectVenueListErrors)(queryKey);
    var venueCount = useSelector(selectVenuesCount)(queryKey);
    var refreshVenues = useCallback(function() {
        setPage(0);
        handleChangePage(0);
        dispatch(venuesActions.getVenueListRequest(_object_spread_props(_object_spread({}, request), {
            mode: mode,
            offset: 0,
            reset: true,
            queryKey: queryKey
        })));
    }, [
        dispatch,
        handleChangePage,
        mode,
        queryKey,
        request
    ]);
    var nextPage = useCallback(function() {
        if (!isVenuesFetching && (request.offset || 0) + 1 < venueCount) {
            setPage(page + 1);
            handleChangePage(page + 1);
            return true;
        }
        return false;
    }, [
        handleChangePage,
        isVenuesFetching,
        page,
        request.offset,
        venueCount
    ]);
    var handleChangeFilter = useCallback(function(filter, forceConcat) {
        if (forceConcat) {
            setForceConcat(true);
        }
        handleChangeRequestFilter(filter);
    }, [
        handleChangeRequestFilter
    ]);
    useEffect(function() {
        if (!isEqual(prevRequest, request)) {
            if (prevRequest === undefined) {
                // Mount
                dispatch(venuesActions.getVenueListRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey
                })));
                return;
            }
            var offset = request.offset, rest = _object_without_properties(request, [
                "offset"
            ]);
            var prevOffset = prevRequest.offset, prevRest = _object_without_properties(prevRequest, [
                "offset"
            ]);
            if (!isEqual(rest, prevRest)) {
                dispatch(venuesActions.getVenueListRequest(_object_spread_props(_object_spread({}, request), {
                    mode: mode,
                    reset: !forceConcat,
                    queryKey: queryKey
                })));
                return;
            }
            dispatch(venuesActions.getVenueListRequest(_object_spread_props(_object_spread({}, request), {
                mode: mode,
                queryKey: queryKey
            })));
        }
        setForceConcat(false);
    }, [
        dispatch,
        forceConcat,
        handleChangePage,
        mode,
        prevRequest,
        queryKey,
        request
    ]);
    useEffect(function() {
        if (prevExternalRequest !== undefined) {
            if (!isEqual(prevExternalRequest === null || prevExternalRequest === void 0 ? void 0 : prevExternalRequest.filter, externalRequest === null || externalRequest === void 0 ? void 0 : externalRequest.filter)) {
                setPage(0);
                handleChangePage(0);
                handleChangeRequestFilter(externalRequest.filter);
            }
            if (prevExternalRequest.sortBy !== externalRequest.sortBy || prevExternalRequest.sortOrder !== externalRequest.sortOrder) {
                setPage(0);
                handleChangePage(0);
                handleChangeSorting(externalRequest.sortBy || 'distance', externalRequest.sortOrder || SortOrder.ASC);
            }
            if (prevExternalRequest.search !== externalRequest.search || prevExternalRequest.search_in_fields !== externalRequest.search_in_fields) {
                setPage(0);
                handleChangePage(0);
                handleChangeSearch({
                    phrase: externalRequest.search || '',
                    field: externalRequest.search_in_fields || null
                });
            }
        }
    }, [
        externalRequest.filter,
        externalRequest.search,
        externalRequest.search_in_fields,
        externalRequest.sortBy,
        externalRequest.sortOrder,
        handleChangePage,
        handleChangeRequestFilter,
        handleChangeSearch,
        handleChangeSorting,
        prevExternalRequest
    ]);
    return {
        venues: venues,
        isVenuesFetching: isVenuesFetching,
        handleChangeRowsPerPage: handleChangeRowsPerPage,
        handleChangeFilter: handleChangeFilter,
        handleChangeSorting: handleChangeSorting,
        handleChangeSearch: handleChangeSearch,
        handleChangePage: handleChangePage,
        nextPage: nextPage,
        refreshVenues: refreshVenues,
        venuesErrors: venuesErrors,
        venueCount: venueCount
    };
}
export function useVenue(param) {
    var id = param.id;
    useInjectReducer({
        key: venuesSliceKey,
        reducer: venuesReducer
    });
    useInjectSaga({
        key: venuesSliceKey,
        saga: venuesSaga
    });
    var dispatch = useDispatch();
    var venuesErrors = useSelector(selectGetVenueErrors);
    var isVenuesFetching = useSelector(selectGetVenueIsFetching);
    var selectedVenue = useSelector(selectVenue)(id);
    var refreshVenue = useCallback(function() {
        if (isVenuesFetching) {
            dispatch(venuesActions.getVenueRequest({
                id: id
            }));
            return true;
        }
        return false;
    }, [
        dispatch,
        id,
        isVenuesFetching
    ]);
    useEffect(function() {
        if (!selectedVenue && id) {
            dispatch(venuesActions.getVenueRequest({
                id: id
            }));
        }
    }, [
        dispatch,
        id,
        selectedVenue
    ]);
    return {
        isVenuesFetching: isVenuesFetching,
        selectedVenue: selectedVenue,
        venuesErrors: venuesErrors,
        refreshVenue: refreshVenue
    };
}
export function useVenueOpen(param) {
    var id = param.id;
    useInjectReducer({
        key: venuesSliceKey,
        reducer: venuesReducer
    });
    useInjectSaga({
        key: venuesSliceKey,
        saga: venuesSaga
    });
    var isVenueOpen = useSelector(selectVenueIsOpen)(id, new Date());
    return {
        isVenueOpen: isVenueOpen
    };
}
export function useSearchedVenues(searchTerm) {
    useInjectReducer({
        key: venuesSliceKey,
        reducer: venuesReducer
    });
    useInjectSaga({
        key: venuesSliceKey,
        saga: venuesSaga
    });
    var dispatch = useDispatch();
    useEffectOnMount(function() {
        dispatch(venuesActions.getSearchedVenues());
    });
    var storeSearchedVenue = useCallback(function(term) {
        dispatch(venuesActions.storeSearchedVenue(term));
    }, [
        dispatch
    ]);
    var deleteSearchedVenue = useCallback(function(term) {
        dispatch(venuesActions.deleteSearchedVenue(term));
    }, [
        dispatch
    ]);
    var allSearchedVenues = useSelector(selectSearchedVenues);
    var filteredVenues = searchTerm ? allSearchedVenues.filter(function(item) {
        return item.includes(searchTerm);
    }) : allSearchedVenues;
    var searchedVenues = filteredVenues.slice(-8).reverse();
    return {
        searchedVenues: searchedVenues,
        deleteSearchedVenue: deleteSearchedVenue,
        storeSearchedVenue: storeSearchedVenue
    };
}
