import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// Webolucio imports
import { useEffectOnMount, useEnum, usePrevious } from '@webolucio/core';
import { PageMetas, ScrollView } from '@webolucio/web';
import { FieldEvent } from '@webolucio/form';
import { Form } from '@webolucio/form-web';
// BPNL imports
import { FrontendUserInput } from '@bpnl/common';
import { useRegistration, useValidateRegistrationFields } from '@bpnl/registration';
// App imports
import { commonMessages } from '../../i18n';
import BpnlButton from '../../components/presentationals/BpnlButton';
import Indicator from '../../components/presentationals/Indicator';
import TagListField from '../../components/fields/TagListField';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import useLoadingFlag from '../../hooks/useLoadingFlag';

export default function RegistrationTastePage() {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const tags = useEnum<number>('music-style-tags', 'userspace');
	const { registration, registrationErrors, patchRegistrationData, submitRegistration, isRegistrationSaving } = useRegistration();
	const prevRegistrationSaving = usePrevious(isRegistrationSaving);
	useLoadingFlag(!tags?.length);

	const validateRegistration = useValidateRegistrationFields({
		callback: (isValid) => {
			if (isValid) {
				submitRegistration();
			}
		},
		fields: ['tags'],
		shouldClearErrors: true,
	});

	const handleNext = useCallback(() => {
		validateRegistration();
	}, [validateRegistration]);

	const handleFormChange = useCallback(
		(e: FieldEvent<Partial<FrontendUserInput>>) => {
			patchRegistrationData(e.target.value);
		},
		[patchRegistrationData],
	);

	useEffect(() => {
		if (prevRegistrationSaving && !isRegistrationSaving && !registrationErrors) {
			navigate('/');
		}
	}, [isRegistrationSaving, prevRegistrationSaving, registrationErrors]);

	useEffectOnMount(() => {
		if (!registration.date_birth) navigate('/registration');
	});

	return (
		<>
			<PageMetas title={formatMessage(messages.pageTitle)} />
			<h1 className="heading">{formatMessage(messages.pageTitle)}</h1>
			<Indicator steps={3} active={3} />
			<Form<FrontendUserInput>
				errors={(!Array.isArray(registrationErrors) && registrationErrors) || undefined}
				isSaving={isRegistrationSaving}
				isValidating={isRegistrationSaving}
				initialValues={registration}
				partial
				onChange={handleFormChange}
				onSubmit={() => {}}
				formProps={{ className: classes.formContainer }}
			>
				<div className={classes.stepTextContainer}>
					<h2 className="heading">{formatMessage(messages.title)}</h2>
					<h3 className="sub-heading">{formatMessage(messages.subtitle)}</h3>
				</div>
				<div className={classes.tasteContainer}>
					<ScrollView
						classes={{ container: classes.scrollView, scrollerContainer: classes.scrollerContainer, scrollerElement: classes.scrollerElement }}
					>
						<TagListField<number>
							multiple
							name="tags"
							options={tags || []}
							useEmbeddedIds
							classes={{
								tagList: {
									container: classes.tagList,
								},
								formControl: {
									formControlLabel: classes.label,
									formControlLabelHelperContainer: classes.labelContainer,
								},
							}}
						/>
					</ScrollView>
					<BpnlButton
						title={formatMessage(commonMessages.next)}
						width="fullWidth"
						size="medium"
						type="button"
						onClick={handleNext}
						className={classes.nextButton}
					/>
				</div>
			</Form>
		</>
	);
}
