import { defineMessages } from 'react-intl';
export var scope = 'bpnl.follow';
var messages = defineMessages({
    follow: {
        id: "".concat(scope, ".follow"),
        defaultMessage: 'Follow'
    },
    follower: {
        id: "".concat(scope, ".follower"),
        defaultMessage: 'Follower'
    },
    followerPlural: {
        id: "".concat(scope, ".followerPlural"),
        defaultMessage: 'Followers'
    },
    followers: {
        id: "".concat(scope, ".followers"),
        defaultMessage: 'Followers'
    }
});
export default messages;
