import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import clsx from 'clsx';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// App imports
// Local imports
import { HorizontalSliderProps } from './types';
import classes from './index.module.scss';
import { useEffect, useRef, useState } from 'react';

function NextArrow(props) {
	const { onClick } = props;
	return <Icon name="bpnl-arrow-right" onClick={onClick} className={clsx(classes.nextArrow, classes.arrow)} />;
}

function PrevArrow(props) {
	const { onClick } = props;
	return <Icon name="bpnl-arrow-left" onClick={onClick} className={clsx(classes.prevArrow, classes.arrow)} />;
}

export default function HorizontalSlider(props: HorizontalSliderProps) {
	const {
		children,
		className,
		autoplay = false,
		autoplaySpeed = 5000,
		dots = false,
		infinite = false,
		swipe = false,
		swipeToSlide = true,
		slidesToScroll = 1,
		slidesToShow = 1,
		slidesToShowMobile = 2,
	} = props;

	const ref = useRef<Slider>(null);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [isDragging, setIsDragging] = useState(false);

	const sliderSettings = {
		mobileFirst: true,
		autoplay: autoplay,
		autoplaySpeed: autoplaySpeed,
		customPaging: (i) => <div className={classes.indicator} />,
		dots: dots,
		infinite: infinite,
		speed: 500,
		swipe: swipe,
		swipeToSlide: swipeToSlide,
		slidesToScroll: slidesToScroll,
		slidesToShow: slidesToShow,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		className: clsx(classes.slider, isDragging && classes.swiping, className),
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: slidesToShowMobile || 1.5,
					dots: dots,
					arrows: false,
					infinite: false,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: slidesToShowMobile,
					dots: dots,
					arrows: false,
					infinite: false,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: slidesToShowMobile,
					dots: dots,
					arrows: false,
					infinite: false,
				},
			},
		],
	};

	useEffect(() => {
		const mouseMoveCB = () => {
			if (isMouseDown) {
				setIsDragging(true);
			}
		};

		ref.current?.innerSlider?.list?.addEventListener('mousemove', mouseMoveCB);

		return () => {
			ref.current?.innerSlider?.list?.removeEventListener('mousemove', mouseMoveCB);
		};
	}, [isMouseDown]);

	useEffect(() => {
		const mouseDownCB = () => {
			setIsMouseDown(true);
		};
		const mouseUpCB = () => {
			if (isDragging) {
				setIsDragging(false);
			}
			setIsMouseDown(false);
		};

		ref.current?.innerSlider?.list?.addEventListener('mousedown', mouseDownCB);
		ref.current?.innerSlider?.list?.addEventListener('mouseup', mouseUpCB);

		return () => {
			ref.current?.innerSlider?.list?.removeEventListener('mousedown', mouseDownCB);
			ref.current?.innerSlider?.list?.removeEventListener('mouseup', mouseUpCB);
		};
	}, [isDragging]);

	return (
		<Slider {...sliderSettings} ref={ref}>
			{children}
		</Slider>
	);
}
