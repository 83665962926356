import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Webolucio imports
// BPNL imports
import { FollowType } from '@bpnl/common';
import { followMessages } from '@bpnl/follow';
// App imports
import Image from '../Image';
import LikeButton from '../LikeButton';
import LinkOverlay from '../LinkOverlay';
// Local imports
import classes from './index.module.scss';
import { OrganizerRowProps } from './types';

export default function OrganizerRow(props: OrganizerRowProps) {
	const { className, id, isFollowed, followers, name, thumbnail, variant } = props;

	const { formatMessage } = useIntl();

	return (
		<div className={clsx(classes.organizerRow, 'organizer-row', className, classes[variant], variant)}>
			{thumbnail ? <Image className={classes.organizerRowImage} file={thumbnail.file} thumbnail /> : null}
			<div className={clsx(classes.organizerRowContent, 'organizer-row-content')}>
				<span className={clsx(classes.name, 'organizer-row-name')}>{name}</span>
				{!variant.startsWith('basic') ? (
					<div className={clsx(classes.organizerRowMeta, 'organizer-row-meta')}>
						<span className={clsx(classes.followerCount, 'organizer-row-followers')}>
							{(followers || 0) +
								` ${(followers || 0) > 1 ? formatMessage(followMessages.followerPlural).toLowerCase() : formatMessage(followMessages.follower).toLowerCase()}`}
						</span>
						{variant === 'followersAndButton' && id && isFollowed !== undefined ? (
							<>
								<LinkOverlay to={`/organizers/${id}`} />
								<LikeButton id={id} type={FollowType.Organizer} isFollowed={isFollowed} />
							</>
						) : null}
					</div>
				) : id ? (
					<LinkOverlay to={`/organizers/${id}`} />
				) : null}
			</div>
		</div>
	);
}
