import { all, call, putResolve, select, takeLatest } from '@redux-saga/core/effects';
import { getToken } from 'firebase/messaging';
// Webolucio imports
import {
	authActions,
	LocalStorageService,
	selectIsAuthenticated,
	webolucioConfig,
	refreshToken as getRefreshToken,
	ApiType,
	ApiService,
	UtilityService,
} from '@webolucio/core';
// BPNL imports
import { myAccountActions } from '@bpnl/account';
// Local imports
import { rollbar } from './rollbar';
import { messaging, vapidKey } from './firebaseConfig';

function* updateRollbarUserPermissions(action: ReturnType<typeof authActions.userDataSuccess>) {
	yield call(rollbar.configure.bind(rollbar), { payload: { person: { id: null, permissions: action.payload.permissions } } });
}

function* updateRollbarUserData(action: ReturnType<typeof myAccountActions.getMyAccountSuccess>) {
	yield call(rollbar.configure.bind(rollbar), {
		payload: {
			person: {
				id: action.payload.id,
				email: action.payload.email,
				language_id: action.payload.language_id,
			},
		},
	});
}

function* registerForPushNotifications() {

	//requesting permission using Notification API
	const permission = yield Notification.requestPermission();
	if (permission === 'granted') {
		const fcmToken = yield getToken(messaging, {
			vapidKey: vapidKey,
		});

		const registerClientResponse: ApiType<never> = yield ApiService.getService().api.post(webolucioConfig.modules.auth.apiPaths.registerClient, {
			__csrf: UtilityService.getService().getCsrf(),
			client_id: yield LocalStorageService.getService().getClientId(),
			push_token: fcmToken,
			device_info: {
				browser: UtilityService.getService().getPlatformInformation(),
			},
			locale: ApiService.getService().locale,
			platform: 'web',
			scope: webolucioConfig.services.api.scope,
		});

		if (registerClientResponse.status === 201) {
			console.warn("New client created for user, this shouldn't be happening!");
		}

		//We can send token to server
		console.log('Token generated : ', fcmToken);
	} else if (permission === 'denied') {
		//notifications are blocked
		alert('You denied for the notification');
	}
}

function* silentLoginSaga(action: ReturnType<typeof myAccountActions.silentLogin>) {
	const isAuthenticated = yield select(selectIsAuthenticated);

	if (isAuthenticated) {
		return;
	}

	const localStorageService = LocalStorageService.getService();
	const refreshToken = yield localStorageService.getRefreshToken();
	const clientId = yield localStorageService.getClientId();

	if (!refreshToken || !clientId) {
		return;
	}
	const refreshTokenResponse = yield call(getRefreshToken, { clientId, refreshToken, scope: webolucioConfig.scope });

	if (!refreshTokenResponse.ok) {
		return;
	}

	return yield putResolve(authActions.authorizationSuccess(refreshTokenResponse.data));
}

export default function* appSaga() {
	return yield all([
		takeLatest(authActions.userDataSuccess.type, updateRollbarUserPermissions),
		takeLatest(myAccountActions.getMyAccountSuccess.type, updateRollbarUserData),
		takeLatest(myAccountActions.silentLogin.type, silentLoginSaga),
		takeLatest(authActions.userDataSuccess.type, registerForPushNotifications),
	]);
}
