import { useIntl } from 'react-intl';
import clsx from 'clsx';
import VenueThumbnails from '../../presentationals/VenueThumbnails';
import HorizontalSlider from '../HorizontalSlider';
import { NavSliderProps } from './types';
import messages from './messages';
import classes from './index.module.scss';

export default function NavSlider(props: NavSliderProps) {
	const { items, className } = props;
	const { formatMessage } = useIntl();
	const images = items.filter((images) => !images.is_default);
	const thumbnailsCount = images ? images.length - 4 : 0;

	return (
		<>
			{images?.length ? (
				<div className={clsx(classes.navSlider, className)}>
					<div className={classes.navSliderTitle}>{formatMessage(messages.title)}</div>
					<HorizontalSlider slidesToShow={4} swipe infinite>
						{images.map((image, index) => (
							<VenueThumbnails thumbnail={image} key={index} />
						))}
					</HorizontalSlider>
					{thumbnailsCount > 0 && <span className={classes.navSliderCount}>{`+${thumbnailsCount}`}</span>}
				</div>
			) : null}
		</>
	);
}
