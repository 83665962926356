import { defineMessages } from 'react-intl';
export var scope = 'bpnl.socialPosts';
var messages = defineMessages({
    socialPost: {
        id: "".concat(scope, ".socialPost"),
        defaultMessage: 'Social post'
    },
    socialPosts: {
        id: "".concat(scope, ".socialPosts"),
        defaultMessage: 'Social posts'
    }
});
export default messages;
