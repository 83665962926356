import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { addDays } from 'date-fns';
// Webolucio imports
import { FilterComparisonType, useMountedEffect } from '@webolucio/core';
// BPNL imports
import { EventFilters } from '@bpnl/events';
// App imports
import Title from '../../presentationals/Title';
import BpnlCalendar, { formatCalendarDate } from '../../presentationals/BpnlCalendar';
import { BpnlCalendarProps } from '../../presentationals/BpnlCalendar/types';
import Tag from '../../presentationals/Tag';
import { FilterProps } from '../types';
// Local imports
import messages from './messages';
import classes from './index.module.scss';
import { Icon } from '@webolucio/icons';
import BpnlButton from '../../presentationals/BpnlButton';

export default function DateFilter({ onChange }: FilterProps<EventFilters>) {
	const { formatMessage } = useIntl();

	const [ranges, setRanges] = useState<BpnlCalendarProps['ranges']>([
		{
			startDate: new Date(),
			endDate: null,
			key: 'selection',
		},
	]);

	const handleSelect = useCallback(
		(item) => {
			setRanges([item.selection]);
		},
		[setRanges],
	);

	const handleSetToday = useCallback(() => {
		const today = new Date();
		setRanges([
			{
				startDate: today,
				endDate: today,
				key: 'selection',
			},
		]);
	}, []);

	const handleSetTomorrow = useCallback(() => {
		const tomorrow = addDays(new Date(), 1);
		setRanges([
			{
				startDate: tomorrow,
				endDate: tomorrow,
				key: 'selection',
			},
		]);
	}, []);

	const handleSetWeekend = useCallback(() => {
		const dayOfWeek = new Date().getDay();
		const dayDifference = 6 - dayOfWeek;

		let saturday: Date, sunday: Date;
		// If it is Sunday, we set the current weekend, else we set the next (in case of Saturday, next is the current weekend)
		if (dayOfWeek === 0) {
			saturday = addDays(new Date(), -1);
			sunday = new Date();
		} else {
			saturday = addDays(new Date(), dayDifference);
			sunday = addDays(new Date(), dayDifference + 1);
		}

		setRanges([
			{
				startDate: saturday,
				endDate: sunday,
				key: 'selection',
			},
		]);
	}, []);

	const handleReset = useCallback(() => {
		setRanges([
			{
				startDate: new Date(),
				endDate: null,
				key: 'selection',
			},
		]);
	}, []);

	const submitSelection = useCallback(() => {
		console.log('ranges', ranges);

		if (ranges[0].startDate) {
			const formattedStartDate = formatCalendarDate(ranges[0].startDate);
			const formattedEndDate = ranges[0].endDate && formatCalendarDate(ranges[0].endDate);

			if (!formattedEndDate) {
				onChange &&
					onChange(
						[
							{
								field: 'date_start',
								condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
								value: formattedStartDate,
							},
						],
						'date',
					);
			} else {
				onChange &&
					onChange(
						[
							{
								field: 'date_start',
								condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
								value: formattedStartDate,
							},
							{
								field: 'date_start',
								condition_type: FilterComparisonType.LESS_THAN_OR_EQUAL,
								value: formattedEndDate,
							},
						],
						'date',
					);
			}
		} else {
			onChange && onChange([], 'date');
		}
	}, [ranges]);

	useMountedEffect(() => {
		submitSelection();
	}, [ranges]);

	return (
		<div>
			<Title title={formatMessage(messages.filterTitle)} />
			<div className={classes.container}>
				<div className={classes.tags}>
					<Tag label={formatMessage(messages.dateToday)} onClick={handleSetToday} />
					<Tag label={formatMessage(messages.dateTomorrow)} onClick={handleSetTomorrow} />
					<Tag label={formatMessage(messages.dateWeekend)} onClick={handleSetWeekend} />
					<BpnlButton className={classes.reset} onClick={handleReset}>
						<Icon name="filter-x" />
					</BpnlButton>
				</div>
				<BpnlCalendar ranges={ranges} onChange={handleSelect} />
			</div>
		</div>
	);
}
