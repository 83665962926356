import clsx from 'clsx';
import { formatISO, startOfDay } from 'date-fns';
// App imports
import Image from '../Image';
import LinkOverlay from '../LinkOverlay';
import NameOfDate from '../NameOfDate';
// Local imports
import classes from './index.module.scss';
import { FollowingEventPrpos } from './types';

export default function FollowingEvent(props: FollowingEventPrpos) {
	const { event } = props;
	const eventStartToday = formatISO(startOfDay(new Date())) === formatISO(startOfDay(new Date(event.date_start)));
	const nowHappening = new Date() >= new Date(event.date_start) && new Date() <= new Date(event.date_end);

	return (
		<div className={classes.followingEvent}>
			<LinkOverlay to={`/events/${event.id}`} />
			{event.default_medium ? <Image file={event.default_medium.file} /> : null}
			<div className={classes.event}>
				<span className={clsx(classes.eventStart, eventStartToday && (nowHappening ? classes.eventStartNow : classes.eventStartToday))}>
					<NameOfDate start={event.date_start} end={event.date_end} />
				</span>
				<span className={classes.eventTitle}>{event.name}</span>
			</div>
		</div>
	);
}
