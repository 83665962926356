import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, debounce, put, putResolve, race, select, take, takeLatest } from '@redux-saga/core/effects';
// Webolucio imports
import { LocalStorageService, registerClient, UtilityService, webolucioConfig, authActions, ApiService } from '@webolucio/core';
// BPNL imports
import { myAccountActions } from '@bpnl/account';
// Local imports
import { registrationApi } from './api';
import { selectRegistrationData, selectSocialAuthData, selectSocialData } from './selectors';
import { registrationActions } from './slice';
function postRegistrationSaga(action) {
    var response, _tmp, socialData, registration, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    10,
                    ,
                    12
                ]);
                _tmp = [
                    registrationApi.postRegistration
                ];
                return [
                    4,
                    select(selectRegistrationData)
                ];
            case 1:
                return [
                    4,
                    call.apply(void 0, _tmp.concat([
                        _state.sent()
                    ]))
                ];
            case 2:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(registrationActions.postRegistrationFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 3:
                return [
                    2,
                    _state.sent()
                ];
            case 4:
                return [
                    4,
                    select(selectSocialData)
                ];
            case 5:
                socialData = _state.sent();
                if (!!socialData.provider) return [
                    3,
                    8
                ];
                return [
                    4,
                    select(selectRegistrationData)
                ];
            case 6:
                registration = _state.sent();
                return [
                    4,
                    put(authActions.loginRequest({
                        username: registration.email,
                        password: registration.password
                    }))
                ];
            case 7:
                _state.sent();
                _state.label = 8;
            case 8:
                return [
                    4,
                    put(registrationActions.postRegistrationSuccess(response.data))
                ];
            case 9:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 10:
                err = _state.sent();
                return [
                    4,
                    put(registrationActions.postRegistrationFailure([
                        'clientError'
                    ]))
                ];
            case 11:
                _state.sent();
                return [
                    3,
                    12
                ];
            case 12:
                return [
                    2
                ];
        }
    });
}
function validateRegistrationSaga(action) {
    var response, _tmp, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    6,
                    ,
                    8
                ]);
                _tmp = [
                    registrationApi.validateRegistration
                ];
                return [
                    4,
                    select(selectRegistrationData)
                ];
            case 1:
                return [
                    4,
                    call.apply(void 0, _tmp.concat([
                        _state.sent()
                    ]))
                ];
            case 2:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    4
                ];
                return [
                    4,
                    put(registrationActions.validateRegistrationFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 3:
                return [
                    2,
                    _state.sent()
                ];
            case 4:
                return [
                    4,
                    put(registrationActions.validateRegistrationSuccess())
                ];
            case 5:
                _state.sent();
                return [
                    3,
                    8
                ];
            case 6:
                err = _state.sent();
                return [
                    4,
                    put(registrationActions.postRegistrationFailure([
                        'clientError'
                    ]))
                ];
            case 7:
                _state.sent();
                return [
                    3,
                    8
                ];
            case 8:
                return [
                    2
                ];
        }
    });
}
function forgotPasswordSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(registrationApi.forgotPassword, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(registrationActions.forgotPasswordFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(registrationActions.forgotPasswordSuccess())
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(registrationActions.forgotPasswordFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function resetPasswordSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(registrationApi.resetPassword, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(registrationActions.resetPasswordFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(registrationActions.resetPasswordSuccess())
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(registrationActions.resetPasswordFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function getAccessTokenWithSocialTokenSaga(action) {
    var socialData, clientId, clientRegistrationResponse, accessTokenResponse, _ref, success, error;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectSocialData)
                ];
            case 1:
                socialData = _state.sent();
                return [
                    4,
                    LocalStorageService.getService().getClientId()
                ];
            case 2:
                clientId = _state.sent();
                if (!clientId || !socialData.provider || !socialData.socialToken) {
                    console.warn('Missing clientId, provider or token', clientId, socialData.provider, socialData.socialToken);
                    return [
                        2
                    ];
                }
                return [
                    4,
                    call(registerClient, {
                        csrf: UtilityService.getService().getCsrf(),
                        clientId: clientId,
                        deviceInfo: {
                            browser: UtilityService.getService().getPlatformInformation()
                        }
                    })
                ];
            case 3:
                clientRegistrationResponse = _state.sent();
                if (!!clientRegistrationResponse.ok) return [
                    3,
                    5
                ];
                return [
                    4,
                    put(registrationActions.postRegistrationFailure(clientRegistrationResponse.errors || [
                        'apiError'
                    ]))
                ];
            case 4:
                return [
                    2,
                    _state.sent()
                ];
            case 5:
                return [
                    4,
                    call(registrationApi.accessToken, {
                        additionalData: socialData.additionalData,
                        clientId: clientId,
                        grantType: socialData.provider,
                        redirectUri: webolucioConfig.baseUrl + webolucioConfig.modules.auth.frontendPaths.login,
                        socialAccessToken: socialData.socialToken,
                        scope: webolucioConfig.scope
                    })
                ];
            case 6:
                accessTokenResponse = _state.sent();
                if (!(!accessTokenResponse.ok || accessTokenResponse.data === undefined || !accessTokenResponse.data.access_token || !accessTokenResponse.data.refresh_token)) return [
                    3,
                    8
                ];
                return [
                    4,
                    put(registrationActions.loginWithSocialFailure(accessTokenResponse.errors || [
                        'apiError'
                    ]))
                ];
            case 7:
                return [
                    2,
                    _state.sent()
                ];
            case 8:
                if (!!accessTokenResponse.data.is_user_created) return [
                    3,
                    11
                ];
                return [
                    4,
                    putResolve(registrationActions.clearSocialData())
                ];
            case 9:
                _state.sent();
                return [
                    4,
                    putResolve(authActions.authorizationSuccess(accessTokenResponse.data))
                ];
            case 10:
                _state.sent();
                return [
                    2
                ];
            case 11:
                ApiService.getService().authorization = accessTokenResponse.data.access_token;
                return [
                    4,
                    LocalStorageService.getService().setToken(accessTokenResponse.data)
                ];
            case 12:
                _state.sent();
                return [
                    4,
                    put(myAccountActions.getMyAccountRequest())
                ];
            case 13:
                _state.sent();
                return [
                    4,
                    race({
                        success: take(myAccountActions.getMyAccountSuccess.type),
                        error: take(myAccountActions.getMyAccountFailure.type)
                    })
                ];
            case 14:
                _ref = _state.sent(), success = _ref.success, error = _ref.error;
                if (!success) return [
                    3,
                    16
                ];
                return [
                    4,
                    put(registrationActions.patchRegistrationData({
                        tags: success.payload.tags
                    }))
                ];
            case 15:
                _state.sent();
                _state.label = 16;
            case 16:
                return [
                    4,
                    put(registrationActions.loginWithSocialSuccess(accessTokenResponse.data))
                ];
            case 17:
                _state.sent();
                return [
                    2
                ];
        }
    });
}
function finishSocialRegistrationSaga() {
    var registration, _ref, success, error, socialAuth;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    select(selectRegistrationData)
                ];
            case 1:
                registration = _state.sent();
                return [
                    4,
                    put(myAccountActions.patchMyAccountRequest({
                        tags: registration.tags,
                        date_birth: registration.date_birth,
                        language_id: registration.language_id,
                        gender: registration.gender
                    }))
                ];
            case 2:
                _state.sent();
                return [
                    4,
                    race({
                        success: take(myAccountActions.patchMyAccountSuccess.type),
                        error: take(myAccountActions.patchMyAccountFailure.type)
                    })
                ];
            case 3:
                _ref = _state.sent(), success = _ref.success, error = _ref.error;
                if (error) {
                    console.error('Error while patching my account', error.payload);
                }
                return [
                    4,
                    select(selectSocialAuthData)
                ];
            case 4:
                socialAuth = _state.sent();
                return [
                    4,
                    putResolve(registrationActions.clearSocialData())
                ];
            case 5:
                _state.sent();
                if (!socialAuth) return [
                    3,
                    7
                ];
                return [
                    4,
                    putResolve(authActions.authorizationSuccess(socialAuth))
                ];
            case 6:
                _state.sent();
                _state.label = 7;
            case 7:
                return [
                    2
                ];
        }
    });
}
export var registrationSagas = {
    getAccessTokenWithSocialTokenSaga: getAccessTokenWithSocialTokenSaga,
    postRegistrationSaga: postRegistrationSaga,
    validateRegistrationSaga: validateRegistrationSaga,
    forgotPasswordSaga: forgotPasswordSaga,
    resetPasswordSaga: resetPasswordSaga
};
export default function registrationSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        debounce(1000, registrationActions.postRegistrationPublicRequest.type, postRegistrationSaga),
                        debounce(1000, registrationActions.validateRegistrationPublicRequest.type, validateRegistrationSaga),
                        debounce(1000, registrationActions.forgotPasswordPublicRequest.type, forgotPasswordSaga),
                        debounce(1000, registrationActions.resetPasswordPublicRequest.type, resetPasswordSaga),
                        takeLatest(registrationActions.loginWithSocialPublicRequest.type, getAccessTokenWithSocialTokenSaga),
                        takeLatest(registrationActions.finishSocialRegistration.type, finishSocialRegistrationSaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
