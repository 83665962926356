import { ReactNode, useEffect, useState } from 'react';
import { authReducer, authSliceKey, checkAccess, Permissions, selectAuthUserData } from '@webolucio/core';
import { useInjectReducer } from 'redux-injectors';
import { useSelector } from 'react-redux';

export interface PermissionGateProps {
	permissions: Exclude<Permissions['actionPermissions'], undefined>;
	children: ReactNode;
	strict?: boolean;
}

export function PermissionGate(props: PermissionGateProps) {
	useInjectReducer({
		key: authSliceKey,
		reducer: authReducer,
	});
	const { permissions, children, strict = false } = props;
	const [permit, setPermit] = useState(false);

	const userData = useSelector(selectAuthUserData);

	useEffect(() => {
		if (userData === undefined) {
			return;
		}

		let permissionsObject: Record<string, string> = {};
		let permissionsArray: string[] = [];
		if (strict) {
			if (typeof permissions === 'string') {
				permissionsObject[0] = permissions;
			} else if (Array.isArray(permissions)) {
				permissions.forEach((permission, index) => {
					permissionsObject[index] = permission;
				});
			} else {
				permissionsObject = permissions;
			}
		} else {
			if (typeof permissions === 'string') {
				permissionsArray.push(permissions);
			} else if (Array.isArray(permissions)) {
				permissionsArray = permissions;
			} else {
				permissionsArray = Object.values(permissions);
			}
		}

		const result = checkAccess(strict ? permissionsObject : permissionsArray, userData);

		if (strict) {
			setPermit(Object.values(result.permissions).every((permission) => permission));
		} else {
			setPermit(result.result);
		}
	}, [permissions, strict, userData]);

	return permit ? children : null;
}
