import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.MyAccountPage';

export default defineMessages({
	dataFieldFirstName: {
		id: `${scope}.dataFieldFirstName`,
		defaultMessage: 'First name',
	},
	dataFieldLastName: {
		id: `${scope}.dataFieldLastName`,
		defaultMessage: 'Last name',
	},
	dataFieldEmail: {
		id: `${scope}.dataFieldEmail`,
		defaultMessage: 'Email',
	},
	dataFieldDateOfBirth: {
		id: `${scope}.dataFieldDateOfBirth`,
		defaultMessage: 'Date of birth',
	},
	dataFieldGender: {
		id: `${scope}.dataFieldGender`,
		defaultMessage: 'Gender',
	},
	dataFieldTaste: {
		id: `${scope}.dataFieldTaste`,
		defaultMessage: 'Liked styles',
	},
	saveChanges: {
		id: `${scope}.saveChanges`,
		defaultMessage: 'Save changes',
	},
	saveSuccess: {
		id: `${scope}.saveSuccess`,
		defaultMessage: 'Account data saved successfully',
	},
});
