import { createSlice } from '@reduxjs/toolkit';
export var initialMyAccountState = {
    errors: null,
    fetching: false,
    data: null,
    saving: false
};
var myAccountSlice = createSlice({
    name: 'account',
    initialState: initialMyAccountState,
    reducers: {
        getMyAccountRequest: function getMyAccountRequest(state, action) {
            state.fetching = true;
        },
        getMyAccountFailure: function getMyAccountFailure(state, action) {
            state.fetching = initialMyAccountState.fetching;
            state.errors = action.payload;
        },
        getMyAccountSuccess: function getMyAccountSuccess(state, action) {
            state.errors = initialMyAccountState.errors;
            state.fetching = initialMyAccountState.fetching;
            state.data = action.payload;
        },
        updateMyAccountRequest: function updateMyAccountRequest(state, action) {
            state.saving = true;
            state.errors = initialMyAccountState.errors;
        },
        updateMyAccountFailure: function updateMyAccountFailure(state, action) {
            state.saving = initialMyAccountState.saving;
            state.errors = action.payload;
        },
        updateMyAccountSuccess: function updateMyAccountSuccess(state, action) {
            state.saving = initialMyAccountState.saving;
            state.data = action.payload;
        },
        patchMyAccountRequest: function patchMyAccountRequest(state, action) {
            state.saving = true;
            state.errors = initialMyAccountState.errors;
        },
        patchMyAccountFailure: function patchMyAccountFailure(state, action) {
            state.saving = initialMyAccountState.saving;
            state.errors = action.payload;
        },
        patchMyAccountSuccess: function patchMyAccountSuccess(state, action) {
            state.saving = initialMyAccountState.saving;
            state.data = action.payload;
        },
        silentLogin: function silentLogin(state, action) {}
    }
});
export var myAccountActions = myAccountSlice.actions, myAccountReducer = myAccountSlice.reducer, myAccountSliceKey = myAccountSlice.name;
