import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// BPNL imports
import { useFollow } from '@bpnl/follow';
// Local imports
import classes from './index.module.scss';
import { LikeButtonProps } from './types';

export default function LikeButton({ isFollowed, id, type }: LikeButtonProps) {
	const [isSaving, setIsSaving] = useState(false);
	const { followSavingItems, submitFollow, deleteFollow } = useFollow();

	const handleToggleFollow = useCallback(() => {
		if (isFollowed) {
			deleteFollow({ id, type });
		} else {
			submitFollow({ id, type });
		}
	}, [isFollowed, id, type]);

	useEffect(() => {
		if (followSavingItems?.includes(`${type}-${id}`)) {
			setIsSaving(true);
		} else {
			setIsSaving(false);
		}
	}, [followSavingItems, id, type]);

	return (
		<span className={clsx(classes.likeButton, isFollowed && classes.active)} onClick={handleToggleFollow}>
			<Icon name="bpnl-like" />
		</span>
	);
}
