import clsx from 'clsx';
// Webolucio imports
import { webolucioConfig } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
import { PermissionGate } from '../../../modules/PermissionGate';
// External imports
import BpnlButton from '../BpnlButton';
// Local imports
import classes from './index.module.scss';
import { FavoriteButtonProps } from './type';

function FavoriteButton(props: FavoriteButtonProps) {
	const { isFollowed = false, click, className, isFetching = false, title, variant = 'link' } = props;

	return (
		<PermissionGate
			permissions={[`api.${webolucioConfig.services.api.scope}.follow`]}
			children={
				<BpnlButton
					className={clsx(isFetching && classes.clickAnimation, className)}
					iconBefore={
						isFollowed ? (
							<Icon name="bpnl-favorite-filled" className={clsx(isFetching && classes.clickAnimation)} />
						) : (
							<Icon name="bpnl-favorite" className={clsx(classes.emptyHeart, isFetching && classes.clickAnimation)} />
						)
					}
					onClick={click}
					title={title}
					variant={variant}
				/>
			}
		/>
	);
}

export default FavoriteButton;
