import { useCallback, useState } from 'react';
import { Modal } from '@webolucio/web';
import Image from '../Image';
import { VenueInfoProps } from './types';
import classes from './index.module.scss';

export default function VenueThumbnails(props: VenueInfoProps) {
	const { thumbnail } = props;
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Modal open={showModal} onClose={() => setShowModal(false)} headerIcon={<></>}>
				<Image file={thumbnail.file} className={classes.imageBg} />
			</Modal>
			<div onClick={() => setShowModal(true)}>
				<Image file={thumbnail.file} className={classes.imageThumbnail} />
			</div>
		</>
	);
}
