import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyBXM0Y-v1SEtcm0wow4h1XjDsKJoNoimAI',
	authDomain: 'srg-bpnl.firebaseapp.com',
	projectId: 'srg-bpnl',
	storageBucket: 'srg-bpnl.appspot.com',
	messagingSenderId: '262664385474',
	appId: '1:262664385474:web:088201ddeb053cbc571c5b',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

// Vapid key
export const vapidKey = 'BC2SlOx-NiD8mFD5oxREOFjeGSW-w86QPc_WWBoGJSsQCW7Hy_ryODiRQISjKXGU-fJBxg4xhcTfLTp9aXcrFjA';
