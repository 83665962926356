import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { uniq } from 'lodash-es';
import { createSlice } from '@reduxjs/toolkit';
// BPNL imports
import { FollowType, QueryStoreMode } from '@bpnl/common';
// Local imports
import { PERFORMER_ENTITY_KEY } from './config';
export var initialPerformersState = {
    data: {},
    queries: {},
    searchedItems: [],
    singleItemFetching: false,
    singleItemErrors: null
};
var performersSlice = createSlice({
    name: PERFORMER_ENTITY_KEY,
    initialState: initialPerformersState,
    reducers: {
        getSearchedPerformers: function getSearchedPerformers(state) {},
        initSearchPerformers: function initSearchPerformers(state, action) {
            state.searchedItems = action.payload;
        },
        storeSearchedPerformer: function storeSearchedPerformer(state, action) {
            state.searchedItems = state.searchedItems.includes(action.payload) ? state.searchedItems : _to_consumable_array(state.searchedItems).concat([
                action.payload
            ]);
        },
        deleteSearchedPerformer: function deleteSearchedPerformer(state, action) {
            var term = action.payload.trim();
            state.searchedItems = state.searchedItems.filter(function(item) {
                return item !== term;
            });
        },
        getPerformerListRequest: {
            prepare: function(payload) {
                if (!payload.queryKey) {
                    payload.queryKey = 'default';
                }
                return {
                    payload: payload
                };
            },
            reducer: function(state, action) {
                var queryKey = action.payload.queryKey || 'default';
                var mode = action.payload.mode || QueryStoreMode.Concat;
                if (!state.queries[queryKey]) {
                    state.queries[queryKey] = {
                        data: [],
                        errors: null,
                        fetching: false,
                        mode: mode,
                        totalCount: 0
                    };
                }
                state.queries[queryKey].fetching = true;
                if (action.payload.mode) {
                    state.queries[queryKey].mode = action.payload.mode;
                }
                if (action.payload.reset) {
                    state.queries[queryKey].data = [];
                    state.queries[queryKey].totalCount = 0;
                }
            }
        },
        getPerformerListFailure: function getPerformerListFailure(state, action) {
            var queryKey = action.payload.queryKey || 'default';
            state.queries[queryKey].fetching = false;
            state.queries[queryKey].errors = action.payload.errors;
        },
        getPerformerListSuccess: function getPerformerListSuccess(state, action) {
            var _state_queries_queryKey, _action_payload_data, _action_payload_data1, _action_payload_data2;
            var queryKey = action.payload.queryKey || 'default';
            var mode = ((_state_queries_queryKey = state.queries[queryKey]) === null || _state_queries_queryKey === void 0 ? void 0 : _state_queries_queryKey.mode) || QueryStoreMode.Concat;
            (_action_payload_data = action.payload.data) === null || _action_payload_data === void 0 ? void 0 : _action_payload_data.forEach(function(item) {
                state.data[item.id] = item;
            });
            state.queries[queryKey] = {
                data: mode === QueryStoreMode.Concat ? uniq(state.queries[queryKey].data.concat(((_action_payload_data1 = action.payload.data) === null || _action_payload_data1 === void 0 ? void 0 : _action_payload_data1.map(function(item) {
                    return item.id;
                })) || [])) : ((_action_payload_data2 = action.payload.data) === null || _action_payload_data2 === void 0 ? void 0 : _action_payload_data2.map(function(item) {
                    return item.id;
                })) || [],
                errors: null,
                fetching: false,
                mode: mode,
                totalCount: action.payload.totalCount || 0
            };
        },
        getPerformerRequest: function getPerformerRequest(state, action) {
            state.singleItemFetching = true;
        },
        getPerformerFailure: function getPerformerFailure(state, action) {
            state.singleItemFetching = initialPerformersState.singleItemFetching;
            state.singleItemErrors = action.payload;
        },
        getPerformerSuccess: function getPerformerSuccess(state, action) {
            state.singleItemErrors = initialPerformersState.singleItemErrors;
            state.singleItemFetching = initialPerformersState.singleItemFetching;
            state.data[action.payload.id] = action.payload;
        },
        updatePerformer: function updatePerformer(state, action) {
            state.data[action.payload.id] = action.payload;
        },
        setFollowed: function setFollowed(state, action) {
            if (action.payload.type === FollowType.Performer) {
                if (state.data[action.payload.id]) {
                    state.data[action.payload.id].is_followed = action.payload.isFollowed;
                    state.data[action.payload.id].count_followers = action.payload.countFollowers;
                }
            }
        }
    }
});
export var performersActions = performersSlice.actions, performersReducer = performersSlice.reducer, performersSliceKey = performersSlice.name;
