import { defineMessages } from 'react-intl';
export var scope = 'bpnl.common';
var messages = defineMessages({
    hu: {
        id: "".concat(scope, ".hu"),
        defaultMessage: 'Magyar'
    },
    en: {
        id: "".concat(scope, ".en"),
        defaultMessage: 'English'
    },
    error: {
        id: "".concat(scope, ".error"),
        defaultMessage: 'Something went wrong. Please try again!'
    },
    monday: {
        id: "".concat(scope, ".monday"),
        defaultMessage: 'monday'
    },
    tuesday: {
        id: "".concat(scope, ".tuesday"),
        defaultMessage: 'tuesday'
    },
    wednesday: {
        id: "".concat(scope, ".wednesday"),
        defaultMessage: 'wednesday'
    },
    thursday: {
        id: "".concat(scope, ".thursday"),
        defaultMessage: 'thursday'
    },
    friday: {
        id: "".concat(scope, ".friday"),
        defaultMessage: 'friday'
    },
    saturday: {
        id: "".concat(scope, ".saturday"),
        defaultMessage: 'saturday'
    },
    sunday: {
        id: "".concat(scope, ".sunday"),
        defaultMessage: 'sunday'
    },
    mondayShort: {
        id: "".concat(scope, ".mondayShort"),
        defaultMessage: 'Mon'
    },
    tuesdayShort: {
        id: "".concat(scope, ".tuesdayShort"),
        defaultMessage: 'Tue'
    },
    wednesdayShort: {
        id: "".concat(scope, ".wednesdayShort"),
        defaultMessage: 'Wed'
    },
    thursdayShort: {
        id: "".concat(scope, ".thursdayShort"),
        defaultMessage: 'Thu'
    },
    fridayShort: {
        id: "".concat(scope, ".fridayShort"),
        defaultMessage: 'Fri'
    },
    saturdayShort: {
        id: "".concat(scope, ".saturdayShort"),
        defaultMessage: 'Sat'
    },
    sundayShort: {
        id: "".concat(scope, ".sundayShort"),
        defaultMessage: 'Sun'
    },
    january: {
        id: "".concat(scope, ".january"),
        defaultMessage: 'January'
    },
    february: {
        id: "".concat(scope, ".february"),
        defaultMessage: 'February'
    },
    march: {
        id: "".concat(scope, ".march"),
        defaultMessage: 'March'
    },
    april: {
        id: "".concat(scope, ".april"),
        defaultMessage: 'April'
    },
    may: {
        id: "".concat(scope, ".may"),
        defaultMessage: 'May'
    },
    june: {
        id: "".concat(scope, ".june"),
        defaultMessage: 'June'
    },
    july: {
        id: "".concat(scope, ".july"),
        defaultMessage: 'July'
    },
    august: {
        id: "".concat(scope, ".august"),
        defaultMessage: 'August'
    },
    september: {
        id: "".concat(scope, ".september"),
        defaultMessage: 'September'
    },
    october: {
        id: "".concat(scope, ".october"),
        defaultMessage: 'October'
    },
    november: {
        id: "".concat(scope, ".november"),
        defaultMessage: 'November'
    },
    december: {
        id: "".concat(scope, ".december"),
        defaultMessage: 'December'
    },
    januaryShort: {
        id: "".concat(scope, ".januaryShort"),
        defaultMessage: 'Jan.'
    },
    februaryShort: {
        id: "".concat(scope, ".februaryShort"),
        defaultMessage: 'Feb.'
    },
    marchShort: {
        id: "".concat(scope, ".marchShort"),
        defaultMessage: 'Mar.'
    },
    aprilShort: {
        id: "".concat(scope, ".aprilShort"),
        defaultMessage: 'Apr.'
    },
    mayShort: {
        id: "".concat(scope, ".mayShort"),
        defaultMessage: 'May'
    },
    juneShort: {
        id: "".concat(scope, ".juneShort"),
        defaultMessage: 'June'
    },
    julyShort: {
        id: "".concat(scope, ".julyShort"),
        defaultMessage: 'July'
    },
    augustShort: {
        id: "".concat(scope, ".augustShort"),
        defaultMessage: 'Aug.'
    },
    septemberShort: {
        id: "".concat(scope, ".septemberShort"),
        defaultMessage: 'Sept.'
    },
    octoberShort: {
        id: "".concat(scope, ".octoberShort"),
        defaultMessage: 'Oct.'
    },
    novemberShort: {
        id: "".concat(scope, ".novemberShort"),
        defaultMessage: 'Nov.'
    },
    decemberShort: {
        id: "".concat(scope, ".decemberShort"),
        defaultMessage: 'Dec.'
    },
    nowHappening: {
        id: "".concat(scope, ".nowHappening"),
        defaultMessage: 'Happening now'
    },
    today: {
        id: "".concat(scope, ".today"),
        defaultMessage: 'Today'
    },
    tomorrow: {
        id: "".concat(scope, ".tomorrow"),
        defaultMessage: 'Tomorrow'
    },
    i: {
        id: "".concat(scope, ".i"),
        defaultMessage: '1'
    },
    ii: {
        id: "".concat(scope, ".ii"),
        defaultMessage: '2'
    },
    iii: {
        id: "".concat(scope, ".iii"),
        defaultMessage: '3'
    },
    iv: {
        id: "".concat(scope, ".iv"),
        defaultMessage: '4'
    },
    v: {
        id: "".concat(scope, ".v"),
        defaultMessage: '5'
    },
    vi: {
        id: "".concat(scope, ".vi"),
        defaultMessage: '6'
    },
    vii: {
        id: "".concat(scope, ".vii"),
        defaultMessage: '7'
    },
    viii: {
        id: "".concat(scope, ".viii"),
        defaultMessage: '8'
    },
    ix: {
        id: "".concat(scope, ".ix"),
        defaultMessage: '9'
    },
    x: {
        id: "".concat(scope, ".x"),
        defaultMessage: '10'
    },
    xi: {
        id: "".concat(scope, ".xi"),
        defaultMessage: '11'
    },
    xii: {
        id: "".concat(scope, ".xii"),
        defaultMessage: '12'
    },
    xiii: {
        id: "".concat(scope, ".xiii"),
        defaultMessage: '13'
    },
    xiv: {
        id: "".concat(scope, ".xiv"),
        defaultMessage: '14'
    },
    xv: {
        id: "".concat(scope, ".xv"),
        defaultMessage: '15'
    },
    xvi: {
        id: "".concat(scope, ".xvi"),
        defaultMessage: '16'
    },
    xvii: {
        id: "".concat(scope, ".xvii"),
        defaultMessage: '17'
    },
    xviii: {
        id: "".concat(scope, ".xviii"),
        defaultMessage: '18'
    },
    xix: {
        id: "".concat(scope, ".xix"),
        defaultMessage: '19'
    },
    xx: {
        id: "".concat(scope, ".xx"),
        defaultMessage: '20'
    },
    xxi: {
        id: "".concat(scope, ".xxi"),
        defaultMessage: '21'
    },
    xxii: {
        id: "".concat(scope, ".xxii"),
        defaultMessage: '22'
    },
    xxiii: {
        id: "".concat(scope, ".xxiii"),
        defaultMessage: '23'
    }
});
export default messages;
