import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { all, call, debounce, put, takeLeading, takeLatest } from '@redux-saga/core/effects';
// Local imports
import { messagesApi } from './api';
import { messagesActions } from './slice';
function getMessageListSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(messagesApi.getMessageList, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(messagesActions.getMessageListFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(messagesActions.getMessageListSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(messagesActions.getMessageListFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function getMessageSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(messagesApi.getMessage, action.payload.id)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(messagesActions.getMessageFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(messagesActions.getMessageSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(messagesActions.getMessageFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function createMessageSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(messagesApi.createMessage, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(messagesActions.createMessageFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(messagesActions.createMessageSuccess(response.data))
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(messagesActions.createMessageFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function createValidateMessageSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    5,
                    ,
                    7
                ]);
                return [
                    4,
                    call(messagesApi.createValidateMessage, action.payload)
                ];
            case 1:
                response = _state.sent();
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    3
                ];
                return [
                    4,
                    put(messagesActions.createValidateMessageFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 2:
                return [
                    2,
                    _state.sent()
                ];
            case 3:
                return [
                    4,
                    put(messagesActions.createValidateMessageSuccess())
                ];
            case 4:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 5:
                err = _state.sent();
                return [
                    4,
                    put(messagesActions.createValidateMessageFailure([
                        'clientError'
                    ]))
                ];
            case 6:
                _state.sent();
                return [
                    3,
                    7
                ];
            case 7:
                return [
                    2
                ];
        }
    });
}
function deleteMessageSaga(action) {
    var response, err;
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                _state.trys.push([
                    0,
                    8,
                    ,
                    10
                ]);
                if (!(action.payload.ids.length > 1)) return [
                    3,
                    2
                ];
                return [
                    4,
                    call(messagesApi.bulkDeleteMessage, action.payload.ids)
                ];
            case 1:
                response = _state.sent();
                return [
                    3,
                    4
                ];
            case 2:
                return [
                    4,
                    call(messagesApi.deleteMessage, action.payload.ids[0])
                ];
            case 3:
                response = _state.sent();
                _state.label = 4;
            case 4:
                if (!(!response.ok || response.data === undefined)) return [
                    3,
                    6
                ];
                return [
                    4,
                    put(messagesActions.deleteMessageFailure(response.errors || [
                        'apiError'
                    ]))
                ];
            case 5:
                return [
                    2,
                    _state.sent()
                ];
            case 6:
                return [
                    4,
                    put(messagesActions.deleteMessageSuccess(action.payload))
                ];
            case 7:
                _state.sent();
                return [
                    3,
                    10
                ];
            case 8:
                err = _state.sent();
                return [
                    4,
                    put(messagesActions.deleteMessageFailure([
                        'clientError'
                    ]))
                ];
            case 9:
                _state.sent();
                return [
                    3,
                    10
                ];
            case 10:
                return [
                    2
                ];
        }
    });
}
export var messagesSagas = {
    getMessageListSaga: getMessageListSaga,
    getMessageSaga: getMessageSaga,
    createMessageSaga: createMessageSaga,
    createValidateMessageSaga: createValidateMessageSaga,
    deleteMessageSaga: deleteMessageSaga
};
export default function messagesSaga() {
    return _ts_generator(this, function(_state) {
        switch(_state.label){
            case 0:
                return [
                    4,
                    all([
                        debounce(1000, messagesActions.getMessageListRequest.type, getMessageListSaga),
                        takeLatest(messagesActions.getMessageRequest.type, getMessageSaga),
                        takeLeading(messagesActions.createMessageRequest.type, createMessageSaga),
                        debounce(500, messagesActions.createValidateMessageRequest.type, createValidateMessageSaga),
                        takeLatest(messagesActions.deleteMessageRequest.type, deleteMessageSaga)
                    ])
                ];
            case 1:
                return [
                    2,
                    _state.sent()
                ];
        }
    });
}
