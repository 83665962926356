import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
// Webolucio imports
import { FilterQuery, SortOrder } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { DetailedEvent, eventsMessages, useEvents } from '@bpnl/events';
// App imports
import EventCard from '../../presentationals/EventCard';
import EventCardSkeleton from '../../presentationals/EventCard/Skeleton';
// Local imports
import classes from './index.module.scss';
import { EventGridProps } from './types';

const CARDS_TO_SHOW = 15;

export default function EventGrid(props: EventGridProps) {
	const { className, emptyMessage, filter, limit = CARDS_TO_SHOW, mode, name, offset, search, sortBy, sortOrder } = props;

	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const { formatMessage } = useIntl();

	const { events, isEventsFetching, eventCount, nextPage } = useEvents({
		mode,
		queryKey: name,
		filter: filter as FilterQuery<DetailedEvent>,
		search,
		sortBy: sortBy || 'date_start',
		sortOrder: sortOrder || SortOrder.ASC,
		offset,
		limit,
	});

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextPage()) {
					setPendingNextPage(false);
				} else {
					if (isEventsFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isEventsFetching, nextPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	useEffect(() => {
		if (!isEventsFetching && pendingNextPage) {
			nextPage();
			setPendingNextPage(false);
		}
	}, [isEventsFetching, nextPage, pendingNextPage]);

	return eventCount === 0 && !isEventsFetching ? (
		<p className={clsx(classes.emptyMessage, 'event-grid-empty-message', className)}>
			{typeof emptyMessage === 'string' ? emptyMessage : formatMessage(emptyMessage || eventsMessages.noEvents)}
		</p>
	) : (
		<div className={clsx(classes.eventGrid, className)}>
			{!events.length && isEventsFetching
				? Array.from({ length: CARDS_TO_SHOW }).map((_, idx) => <EventCardSkeleton key={`event-grid-skeleton-${idx}`} />)
				: events
						.map((event, idx) => (
							<EventCard
								key={`${name}-grid-card-${event.id}`}
								eventId={event.id}
								ref={idx === events.length - 4 || (events.length - 4 < 0 && idx + 1 === events.length) ? itemRef : undefined}
								variant="wide"
							/>
						))
						.concat(
							eventCount > events.length
								? [
										<div className={classes.loader} key="event-grid-loader">
											<LoadOverlay size="medium" />
										</div>,
									]
								: [],
						)}
		</div>
	);
}
