import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
// Webolucio imports
import { SortOrder } from '@webolucio/core';
import { ClickAwayListener, LoadOverlay, ScrollView } from '@webolucio/web';
// BPNL imports
import { notificationsActions, useNotifications } from '@bpnl/notifications';
// App imports
import { Thumbnail } from '../../presentationals/Thumbnail';
// Local imports
import classes from './index.module.scss';
import { NotificationRowProps, NotificationsPanelProps } from './types';

export function NotificationRow(props: NotificationRowProps) {
	const { title, body, tmbLink, ref } = props;
	return (
		<li className={classes.notification} ref={ref}>
			{tmbLink && <Thumbnail className={classes.thumbnail} link={tmbLink} />}
			<span className={classes.title}>{title}</span>
			<span className={classes.body}>{body}</span>
		</li>
	);
}

export default function NotificationsPanel({ className, open, onClose }: NotificationsPanelProps) {
	const rootRef = useRef<HTMLDivElement>(null);
	const [pendingNextPage, setPendingNextPage] = useState(false);
	const dispatch = useDispatch();

	const { notifications, isNotificationsFetching, notificationsCount, refreshNotifications, nextPage } = useNotifications({
		sortBy: 'created_at',
		sortOrder: SortOrder.DESC,
	});

	const handleEndReached = useCallback(
		(inView: boolean) => {
			if (inView) {
				if (nextPage()) {
					setPendingNextPage(false);
				} else {
					if (isNotificationsFetching) {
						setPendingNextPage(true);
					}
				}
			}
		},
		[isNotificationsFetching, nextPage],
	);

	const [itemRef] = useInView({ threshold: 0.1, initialInView: false, root: rootRef.current, onChange: handleEndReached });

	const handleCloseModal = useCallback(() => {
		notifications.forEach((notification) => {
			if (!notification.is_read) {
				dispatch(notificationsActions.patchNotificationRequest({ id: notification.id, data: { is_read: true } }));
				dispatch(notificationsActions.markNotificationAsRead({ id: notification.id }));
			}
		});

		onClose();
	}, [dispatch, notifications, onClose]);

	useEffect(() => {
		if (!isNotificationsFetching && pendingNextPage) {
			nextPage();
			setPendingNextPage(false);
		}
	}, [isNotificationsFetching, nextPage, pendingNextPage]);

	if (!open) {
		return null;
	}

	return (
		<ClickAwayListener onClickAway={handleCloseModal}>
			<ScrollView classes={{ container: clsx(classes.notificationsPanel, className) }} ref={rootRef}>
				<ul className={classes.notificationList}>
					{notifications.map((notification, idx) => (
						<NotificationRow
							key={`notification-${notification.id}`}
							title={notification.title}
							body={notification.body}
							tmbLink={notification.data?.link}
							ref={idx === notifications.length - 1 ? itemRef : undefined}
						/>
					))}
					{notificationsCount > notifications.length ? (
						<li className={classes.loader}>
							<LoadOverlay transparent size="small" />
						</li>
					) : null}
				</ul>
			</ScrollView>
		</ClickAwayListener>
	);
}
