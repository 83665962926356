import clsx from 'clsx';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// BPNL imports
import { commonMessages } from '@bpnl/common';
import { useVenueOpen } from '@bpnl/venues';
// App imports
import BpnlButton from '../BpnlButton';
import LinkOverlay from '../LinkOverlay';
import Map from '../Map';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import { VenueDataProps } from './type';

const daysShort = [
	<FormattedMessage {...commonMessages.mondayShort} />,
	<FormattedMessage {...commonMessages.tuesdayShort} />,
	<FormattedMessage {...commonMessages.wednesdayShort} />,
	<FormattedMessage {...commonMessages.thursdayShort} />,
	<FormattedMessage {...commonMessages.fridayShort} />,
	<FormattedMessage {...commonMessages.saturdayShort} />,
	<FormattedMessage {...commonMessages.sundayShort} />,
];

const days = [
	<FormattedMessage {...commonMessages.monday} />,
	<FormattedMessage {...commonMessages.tuesday} />,
	<FormattedMessage {...commonMessages.wednesday} />,
	<FormattedMessage {...commonMessages.thursday} />,
	<FormattedMessage {...commonMessages.friday} />,
	<FormattedMessage {...commonMessages.saturday} />,
	<FormattedMessage {...commonMessages.sunday} />,
];

function OpeningHour({ venue }: Pick<VenueDataProps, 'venue'>) {
	const { formatMessage } = useIntl();

	const [isOpeningHoursExpanded, setIsOpeningHoursExpanded] = useState<boolean>(false);
	const { isVenueOpen } = useVenueOpen({ id: venue.id });

	const openingHours = Object.keys(venue.opening_hours).map((key) => [venue.opening_hours[key]]);

	return (
		<div className={clsx(classes.VenueDataCardItem, classes['opening-hour'])}>
			<div className={clsx(classes['opening-list'])}>
				<Icon name={'bpnl-clock'} />
				<ul>
					{isOpeningHoursExpanded ? (
						openingHours.map((day, dayIndex) => (
							<li key={`OpeningHourDay-${dayIndex}`}>
								<span>{days[dayIndex]}: </span>
								{day[0].length ? (
									day[0].map((item, idx) => (
										<span key={`OpeningHourItem-${idx}`}>
											{item.time_from} - {item.time_to}
										</span>
									))
								) : (
									<span>{formatMessage(messages.closed)}</span>
								)}
							</li>
						))
					) : isVenueOpen.isOpen ? (
						<li>
							<span className={classes.open}>{formatMessage(messages.open)}</span>
							<span>
								, {formatMessage(messages.close)}: {isVenueOpen.nextClose}
							</span>
						</li>
					) : (
						<li>
							<span className={classes.closed}>{formatMessage(messages.closed)}</span>
							<span>
								, {formatMessage(messages.opening)}:
								{typeof isVenueOpen.nextOpen === 'number' ? daysShort[isVenueOpen.nextOpen] : ` ${isVenueOpen.nextOpen}`}
							</span>
						</li>
					)}
				</ul>
			</div>
			<BpnlButton
				onClick={() => setIsOpeningHoursExpanded(!isOpeningHoursExpanded)}
				title={isOpeningHoursExpanded ? formatMessage(messages.less) : formatMessage(messages.details)}
				variant={'link'}
				iconAfter={'bpnl-arrow-down-double'}
				iconClassName={isOpeningHoursExpanded ? classes.buttonExpanded : ''}
			/>
		</div>
	);
}

function Addresses({ variant, venue }: { variant: string } & Pick<VenueDataProps, 'venue'>) {
	if (!venue?.address) return null;
	const distanceString = venue.distance ? (venue.distance > 1000 ? (venue.distance / 1000).toFixed(2) + 'km - ' : venue.distance + 'm - ') : '';
	const addressString = venue.address ? `${venue.address.zip_code} ${venue.address.city}, ${venue.address.address}` : '';
	const addressLink = venue.address ? `https://www.google.com/maps/search/${venue.address.address_string} ${venue.name}` : null;

	return (
		<div className={clsx(classes.VenueDataCardItem, classes.VenueDataCardItemAddress)}>
			<div>
				<Icon name={'bpnl-pin'} />
				<span>{distanceString && addressString ? `${distanceString}${addressString}` : distanceString + addressString}</span>
			</div>
			{addressLink && variant !== 'card' && (
				<BpnlButton onClick={() => window.open(addressLink, '_blank', 'noreferrer')} title="Térkép" variant={'link'} />
			)}
		</div>
	);
}

function MapContainer({ venue }: Pick<VenueDataProps, 'venue'>) {
	if (!venue?.address) return null;
	return <Map venue={venue} variant={'info'} />;
}

export default function VenueData(props: VenueDataProps) {
	const { venue, variant = 'venue-page' } = props;

	return (
		<div className={clsx(classes.VenueDataCard, classes[variant])}>
			{(variant === 'venue-page' || variant === 'event-page' || variant === 'card') && <Addresses venue={venue} variant={variant} />}
			{(variant === 'venue-page' || variant === 'event-page') && <MapContainer venue={venue} />}
			{variant === 'venue-page' && (
				<>
					<OpeningHour venue={venue} />
					{venue?.phone_number && (
						<div className={classes.VenueDataCardItem}>
							<LinkOverlay to={`tel:+${venue.phone_number.country}${venue.phone_number.number}`} />
							<Icon name={'bpnl-phone'} /> +{venue.phone_number.country}
							<span>{venue.phone_number.number}</span>
						</div>
					)}
					{venue?.email && (
						<div className={classes.VenueDataCardItem}>
							<LinkOverlay to={`mailto:${venue.email}`} />
							<Icon name={'bpnl-email'} />
							<span>{venue.email}</span>
						</div>
					)}
					{venue?.website_url && (
						<div className={classes.VenueDataCardItem}>
							<LinkOverlay to={venue.website_url} target={'_blank'}/>
							<Icon name={'bpnl-website'} />
							<span>{venue.website_url}</span>
						</div>
					)}
				</>
			)}
		</div>
	);
}
