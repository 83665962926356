import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// BPNL imports
import { followMessages, useFollow } from '@bpnl/follow';
import { FollowType } from '@bpnl/common';
// App imports
import Image from '../Image';
import Title from '../Title';
import HeaderButtons from '../HeaderButtons';
// Local imports
import classes from './index.module.scss';
import { PerformerHeaderProps } from './types';

export default function PerformerHeader(props: PerformerHeaderProps) {
	const { id, name, followers, isFollowed, thumbnail } = props;
	const { formatMessage } = useIntl();
	const { deleteFollow, submitFollow, followSavingItems } = useFollow();
	const [isSaving, setIsSaving] = useState(false);

	const handleFollow = useCallback(() => {
		if (isFollowed) {
			deleteFollow({ id: id, type: FollowType.Performer });
		} else {
			submitFollow({ id: id, type: FollowType.Performer });
		}
	}, [id, isFollowed, deleteFollow, submitFollow]);

	useEffect(() => {
		if (followSavingItems?.includes(`performer-${id}`)) {
			setIsSaving(true);
		} else {
			setIsSaving(false);
		}
	}, [followSavingItems, id]);

	return (
		<div className={clsx(classes.performerHeader)}>
			<div className={clsx(classes.HeaderTitleContainer)}>
				<Title title={name} variant={'page'}>
					<span>
						{(followers || 0) +
							` ${(followers || 0) > 1 ? formatMessage(followMessages.followerPlural).toLowerCase() : formatMessage(followMessages.follower).toLowerCase()}`}
					</span>
				</Title>
				{isFollowed !== undefined ? <HeaderButtons isFollowed={isFollowed} isFetching={isSaving} followClick={handleFollow} /> : null}
			</div>
			<div className={classes.images}>{thumbnail && <Image file={thumbnail.file} className={clsx(classes.imagesItem)} />}</div>
		</div>
	);
}
