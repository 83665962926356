import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMountedEffect } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// BPNL imports
import { useVisitEntity } from '@bpnl/common';
import { eventsMessages, useEvent } from '@bpnl/events';
import { selectMyAccount } from '@bpnl/account';
// App imports
import EventHeader from '../../components/presentationals/EventHeader';
import CollapsableText from '../../components/presentationals/CollapsableText';
import Title from '../../components/presentationals/Title';
import Tag from '../../components/presentationals/Tag';
import VenueRow from '../../components/presentationals/VenueRow';
import BpnlButton from '../../components/presentationals/BpnlButton';
import VenueData from '../../components/presentationals/VenueData';
import LinkOverlay from '../../components/presentationals/LinkOverlay';
import DressCode from '../../components/presentationals/DressCode';
import ReportBlock from '../../components/containers/ReportBlock';
import HorizontalSlider from '../../components/containers/HorizontalSlider';
import EventCard from '../../components/presentationals/EventCard';
import Tickets from '../../components/presentationals/Tickets';
import NavSlider from '../../components/containers/NavSlider';
// Local imports
import { commonMessages } from '../../i18n';
import classes from './index.module.scss';
import messages from './messages';
import OrganizerRow from '../../components/presentationals/OrganizerRow';
import JsonHtml from '../../components/presentationals/JsonHtml';
import { exampleJson } from '../../components/presentationals/JsonHtml/config';
import SocialPostsList from '../../components/presentationals/SocialPostsList';

export default function EventPage() {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	const { id } = useParams<{ id: string }>();
	const eventId: number = id ? parseInt(id) : 0;
	const { selectedEvent, isEventsFetching } = useEvent({ id: eventId });
	const account = useSelector(selectMyAccount);
	useVisitEntity({ id: eventId, name: 'event', userId: account?.id });

	useMountedEffect(() => {
		if (!selectedEvent && !isEventsFetching) {
			navigate('/404', { replace: true });
		}
	}, [selectedEvent, isEventsFetching]);

	if (!selectedEvent) {
		return <LoadOverlay />;
	}

	return (
		<>
			<EventHeader event={selectedEvent} />
			<div className={clsx(classes.Content)}>
				<div>
					{selectedEvent?.description && (
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.description)} />
							{selectedEvent.description_components ? (
								<JsonHtml json={selectedEvent.description_components} classes={{ paragraph: classes.descriptionText }} />
							) : (
								<CollapsableText content={selectedEvent.description} />
							)}
						</div>
					)}
					{selectedEvent?.tickets.length ? (
						<div className={classes.block}>
							<Title title={formatMessage(messages.tickets)} />
							<Tickets tickets={selectedEvent.tickets} />
							<div className={classes.ticketButton}>
								{selectedEvent?.ticket_url && (
									<BpnlButton>
										{formatMessage(messages.goToPurchase)}
										<LinkOverlay to={selectedEvent.ticket_url} target={'_blank'} />
									</BpnlButton>
								)}

								{selectedEvent?.ticketswap_url && (
									<BpnlButton iconBefore={'bpnl-ticketswap'}>
										{`${formatMessage(messages.ticketswap)} ${formatMessage(messages.tickets)}`}
										<LinkOverlay to={selectedEvent.ticketswap_url} target={'_blank'} />
									</BpnlButton>
								)}
							</div>
						</div>
					) : null}
				</div>
				<div>
					{selectedEvent?.performers.length ? (
						<div className={clsx(classes.block)}>
							<Title title={formatMessage(messages.performers)} />
							<div className={classes.taglist}>
								{selectedEvent.performers.map((performer) => (
									<Tag label={performer.name} key={`performer-${performer.id}`} onClick={() => navigate(`/performers/${performer.id}`)} />
								))}
							</div>
						</div>
					) : null}
					{selectedEvent?.id ? (
						<div className={clsx(classes.block)}>
							<Title title={formatMessage(messages.fromPerformer)} />
							<SocialPostsList eventId={selectedEvent.id} />
						</div>
					) : null}
					{selectedEvent?.dress_codes.length ? (
						<div className={clsx(classes.block)}>
							<Title title={formatMessage(messages.dressCode)} />
							<DressCode dresscode={selectedEvent.dress_codes} />
						</div>
					) : null}
					{selectedEvent?.venues.length ? (
						<div className={clsx(classes.block)}>
							<Title title={formatMessage(messages.venue)} />
							<VenueRow
								name={selectedEvent.venues[0].name}
								variant={'followersAndButton'}
								isFollowed={selectedEvent.venues[0].is_followed}
								followers={selectedEvent.venues[0].count_followers}
								id={selectedEvent.venues[0].id}
								thumbnail={
									selectedEvent.venues[0].default_medium || (selectedEvent.venues[0].media.length && selectedEvent.venues[0].media[0]) || undefined
								}
							/>

							<VenueData venue={selectedEvent.venues[0]} variant={'event-page'} />
							{selectedEvent?.venues[0].media.length ? <NavSlider items={selectedEvent?.venues[0].media} className={'modalImage'} /> : null}
							<BpnlButton
								title={formatMessage(messages.moreEventsAtTheVenue)}
								onClick={() => navigate(`/events?venue=${selectedEvent.venues[0].id}`)}
								outlined
							/>
						</div>
					) : null}

					{selectedEvent?.organizers.length ? (
						<div className={clsx(classes.block)}>
							<Title title={formatMessage(messages.organizer)} />
							<OrganizerRow
								name={selectedEvent.organizers[0].name}
								variant="followersAndButton"
								isFollowed={selectedEvent.organizers[0].is_followed}
								followers={selectedEvent.organizers[0].count_followers}
								id={selectedEvent.organizers[0].id}
								thumbnail={
									selectedEvent.organizers[0].default_medium ||
									(selectedEvent.organizers[0].media.length && selectedEvent.organizers[0].media[0]) ||
									undefined
								}
							/>
							<BpnlButton title={formatMessage(messages.moreEventsByTheOrganizer)} outlined />
						</div>
					) : null}
				</div>
			</div>

			{selectedEvent?.related_events.length ? (
				<div className={classes.block}>
					<Title title={formatMessage(commonMessages.relatedEvents)} variant={'cards'} />
					<HorizontalSlider slidesToShow={5} swipe>
						{selectedEvent.related_events.map((item, index) => (
							<EventCard eventId={item.id} key={`related-event-${index}`} variant={'narrow'} />
						))}
					</HorizontalSlider>
				</div>
			) : null}

			<ReportBlock title={formatMessage(eventsMessages.reportTitle)} onClick={() => navigate('/feedback', { state: { type: 'event' } })} />
		</>
	);
}
