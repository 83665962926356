import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Enum, useEffectOnMount } from '@webolucio/core';
import Tag from '../Tag';
import { RadioButtonListProps } from './types';

export default function SortingList<Value extends string, Option extends Enum<Value> = Enum<Value>>(props: RadioButtonListProps<Value, Option>) {
	const { initialValue, options, value, onChange, clsName } = props;

	const [useInitialValue, setUseInitialValue] = useState<boolean>(initialValue !== undefined);

	const handleSelect = useCallback(
		(selected: Value) => () => {
			console.log('selected', selected);
			if (value !== selected) {
				onChange(selected as Value & Value[]);
			} else {
				onChange(null);
			}
		},
		[onChange, value],
	);

	useEffectOnMount(() => {
		setUseInitialValue(false);
	});

	return (
		<div className={clsx(clsName)}>
			{options &&
				options.map((item, idx) => (
					<Tag
						key={`sorting-${idx}`}
						label={item.label}
						onClick={handleSelect(item.id)}
						active={
							useInitialValue
								? Array.isArray(initialValue)
									? initialValue.includes(item.id)
									: initialValue === item.id
								: Array.isArray(value)
									? value.includes(item.id)
									: value === item.id
						}
					/>
				))}
		</div>
	);
}
