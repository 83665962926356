import { _ as _async_to_generator } from "@swc/helpers/_/_async_to_generator";
import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import { _ as _ts_values } from "@swc/helpers/_/_ts_values";
import { get as getByPath, isEqual } from 'lodash-es';
import { cancel, fork, take } from '@redux-saga/core/effects';
import { lcFirst, LocalStorageService, ucFirst } from '@webolucio/core';
export function takeEveryUnique(patternOrChannel, worker, uniqueKey) {
    for(var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++){
        args[_key - 3] = arguments[_key];
    }
    var uniqueActions = new Map();
    return fork(function() {
        var _loop;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _loop = function() {
                        var action, uniqueValue, isSameAction, task, task1;
                        return _ts_generator(this, function(_state) {
                            switch(_state.label){
                                case 0:
                                    return [
                                        4,
                                        take(patternOrChannel)
                                    ];
                                case 1:
                                    action = _state.sent();
                                    uniqueValue = getByPath(action, "payload.".concat(uniqueKey));
                                    if (uniqueValue === undefined) {
                                        uniqueValue = getByPath(action, uniqueKey);
                                    }
                                    if (uniqueValue === undefined) {
                                        throw new Error("Path ".concat(uniqueKey, " does not exist in action or action payload!"));
                                    }
                                    if (uniqueValue === null) {
                                        console.warn("Unique value is null in action ".concat(action.type, "!"));
                                        uniqueValue = 'null';
                                    }
                                    uniqueValue = uniqueValue.toString();
                                    if (!uniqueActions.has(uniqueValue)) return [
                                        3,
                                        4
                                    ];
                                    uniqueActions.set(uniqueValue, uniqueActions.get(uniqueValue).filter(function(rec) {
                                        return rec.task.isRunning();
                                    }));
                                    // Compare the rest of the action parameters with the previous actions, filtering out repeated actions that are exactly the same
                                    isSameAction = uniqueActions.get(uniqueValue).some(function(rec) {
                                        return isEqual(action, rec.action);
                                    });
                                    if (!!isSameAction) return [
                                        3,
                                        3
                                    ];
                                    return [
                                        4,
                                        fork.apply(void 0, [
                                            worker
                                        ].concat(_to_consumable_array(args.concat(action))))
                                    ];
                                case 2:
                                    task = _state.sent();
                                    uniqueActions.set(uniqueValue, uniqueActions.get(uniqueValue).concat({
                                        action: action,
                                        task: task
                                    }));
                                    _state.label = 3;
                                case 3:
                                    return [
                                        3,
                                        6
                                    ];
                                case 4:
                                    return [
                                        4,
                                        fork.apply(void 0, [
                                            worker
                                        ].concat(_to_consumable_array(args.concat(action))))
                                    ];
                                case 5:
                                    task1 = _state.sent();
                                    uniqueActions.set(uniqueValue, [
                                        {
                                            action: action,
                                            task: task1
                                        }
                                    ]);
                                    _state.label = 6;
                                case 6:
                                    return [
                                        2
                                    ];
                            }
                        });
                    };
                    _state.label = 1;
                case 1:
                    if (!true) return [
                        3,
                        3
                    ];
                    return [
                        5,
                        _ts_values(_loop())
                    ];
                case 2:
                    _state.sent();
                    return [
                        3,
                        1
                    ];
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
}
export function takeLeadingUnique(patternOrChannel, saga, uniqueKey) {
    for(var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++){
        args[_key - 3] = arguments[_key];
    }
    return fork(function() {
        var uniqueActions, action, uniqueValue, task, task1;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    uniqueActions = new Map();
                    _state.label = 1;
                case 1:
                    if (!true) return [
                        3,
                        8
                    ];
                    return [
                        4,
                        take(patternOrChannel)
                    ];
                case 2:
                    action = _state.sent();
                    uniqueValue = getByPath(action, "payload.".concat(uniqueKey));
                    if (uniqueValue === undefined) {
                        uniqueValue = getByPath(action, uniqueKey);
                    }
                    if (uniqueValue === undefined) {
                        throw new Error("Path ".concat(uniqueKey, " does not exist in action or action payload!"));
                    }
                    uniqueValue = uniqueValue.toString();
                    if (!uniqueActions.has(uniqueValue)) return [
                        3,
                        5
                    ];
                    if (!!uniqueActions.get(uniqueValue).isRunning()) return [
                        3,
                        4
                    ];
                    return [
                        4,
                        fork.apply(void 0, [
                            saga,
                            action
                        ].concat(_to_consumable_array(args)))
                    ];
                case 3:
                    task = _state.sent();
                    uniqueActions.set(uniqueValue, task);
                    _state.label = 4;
                case 4:
                    return [
                        3,
                        7
                    ];
                case 5:
                    return [
                        4,
                        fork.apply(void 0, [
                            saga,
                            action
                        ].concat(_to_consumable_array(args)))
                    ];
                case 6:
                    task1 = _state.sent();
                    uniqueActions.set(uniqueValue, task1);
                    _state.label = 7;
                case 7:
                    return [
                        3,
                        1
                    ];
                case 8:
                    return [
                        2
                    ];
            }
        });
    });
}
export function takeLatestUnique(patternOrChannel, worker, uniqueKey) {
    for(var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++){
        args[_key - 3] = arguments[_key];
    }
    return fork(function() {
        var uniqueActions, action, uniqueValue, task, task1, task2;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    uniqueActions = new Map();
                    _state.label = 1;
                case 1:
                    if (!true) return [
                        3,
                        11
                    ];
                    return [
                        4,
                        take(patternOrChannel)
                    ];
                case 2:
                    action = _state.sent();
                    uniqueValue = getByPath(action, "payload.".concat(uniqueKey));
                    if (uniqueValue === undefined) {
                        uniqueValue = getByPath(action, uniqueKey);
                    }
                    if (uniqueValue === undefined) {
                        throw new Error("Path ".concat(uniqueKey, " does not exist in action or action payload!"));
                    }
                    uniqueValue = uniqueValue.toString();
                    if (!uniqueActions.has(uniqueValue)) return [
                        3,
                        8
                    ];
                    if (!!uniqueActions.get(uniqueValue).isRunning()) return [
                        3,
                        4
                    ];
                    return [
                        4,
                        fork.apply(void 0, [
                            worker,
                            action
                        ].concat(_to_consumable_array(args)))
                    ];
                case 3:
                    task = _state.sent();
                    uniqueActions.set(uniqueValue, task);
                    return [
                        3,
                        7
                    ];
                case 4:
                    return [
                        4,
                        cancel(uniqueActions.get(uniqueValue))
                    ];
                case 5:
                    _state.sent();
                    return [
                        4,
                        fork.apply(void 0, [
                            worker,
                            action
                        ].concat(_to_consumable_array(args)))
                    ];
                case 6:
                    task1 = _state.sent();
                    uniqueActions.set(uniqueValue, task1);
                    _state.label = 7;
                case 7:
                    return [
                        3,
                        10
                    ];
                case 8:
                    return [
                        4,
                        fork.apply(void 0, [
                            worker,
                            action
                        ].concat(_to_consumable_array(args)))
                    ];
                case 9:
                    task2 = _state.sent();
                    uniqueActions.set(uniqueValue, task2);
                    _state.label = 10;
                case 10:
                    return [
                        3,
                        1
                    ];
                case 11:
                    return [
                        2
                    ];
            }
        });
    });
}
export function getVisitedEntityKey(name, userId) {
    return "visited".concat(ucFirst(name), "@").concat(userId);
}
export function getVisitedEntities(name, userId) {
    return _getVisitedEntities.apply(this, arguments);
}
function _getVisitedEntities() {
    _getVisitedEntities = _async_to_generator(function(name, userId) {
        var visitedEntities, visitedEntitiesArray;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        LocalStorageService.getService().getItem(getVisitedEntityKey(name, userId))
                    ];
                case 1:
                    visitedEntities = _state.sent();
                    if (!visitedEntities || typeof visitedEntities !== 'string') {
                        return [
                            2,
                            []
                        ];
                    }
                    try {
                        visitedEntitiesArray = JSON.parse(visitedEntities);
                    } catch (e) {
                        return [
                            2,
                            []
                        ];
                    }
                    if (!Array.isArray(visitedEntitiesArray)) {
                        visitedEntitiesArray = [];
                    }
                    return [
                        2,
                        visitedEntitiesArray.reverse()
                    ];
            }
        });
    });
    return _getVisitedEntities.apply(this, arguments);
}
/**
 * Find numeric sequences in an array of numbers. Duplicate numbers are ignored.
 *
 * For example, [1, 2, 3, 5, 6, 7, 9] will return [{ start: 1, end: 3 }, { start: 5, end: 7 }]
 * @param arr
 */ export function findNumericSequences(arr) {
    var numbers = new Set(arr.sort(function(a, b) {
        return a - b;
    }));
    var sequences = [];
    var start = -1;
    var end = -1;
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = numbers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var num = _step.value;
            if (start === -1) {
                start = num;
                end = num;
            } else if (num === end + 1) {
                end = num;
            } else {
                sequences.push({
                    start: start,
                    end: end
                });
                start = num;
                end = num;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    if (start !== -1) {
        sequences.push({
            start: start,
            end: end
        });
    }
    return sequences;
}
export function getSearchHistoryEntityKey(name, userId) {
    return "".concat(lcFirst(name), "SearchHistory@").concat(userId);
}
export function getStoredSearchHistory(name, userId) {
    return _getStoredSearchHistory.apply(this, arguments);
}
function _getStoredSearchHistory() {
    _getStoredSearchHistory = _async_to_generator(function(name, userId) {
        var searchHistory, searchHistoryItems;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        LocalStorageService.getService().getItem(getSearchHistoryEntityKey(name, userId))
                    ];
                case 1:
                    searchHistory = _state.sent();
                    if (!searchHistory || typeof searchHistory !== 'string') {
                        return [
                            2,
                            []
                        ];
                    }
                    try {
                        searchHistoryItems = JSON.parse(searchHistory);
                    } catch (e) {
                        return [
                            2,
                            []
                        ];
                    }
                    if (!Array.isArray(searchHistoryItems)) {
                        searchHistoryItems = [];
                    }
                    return [
                        2,
                        searchHistoryItems.reverse()
                    ];
            }
        });
    });
    return _getStoredSearchHistory.apply(this, arguments);
}
export function storeSearchHistory(name, userId, searchHistory) {
    return _storeSearchHistory.apply(this, arguments);
}
function _storeSearchHistory() {
    _storeSearchHistory = _async_to_generator(function(name, userId, searchHistory) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                LocalStorageService.getService().setItem(getSearchHistoryEntityKey(name, userId), JSON.stringify(searchHistory))
            ];
        });
    });
    return _storeSearchHistory.apply(this, arguments);
}
export function getPositionRadius(radius, accuracy, windowWidth) {
    return Math.max(Math.min(111000 * 360 / Math.pow(2, Math.log2(360 * (windowWidth / 256 / radius)) + 1) / 16, 500), accuracy);
}
export function getPointByDistance(lat1, lon1, distance, bearing) {
    var R = 6371e3; // metres
    var phi1 = lat1 * Math.PI / 180; // φ, λ in radians
    var lambda1 = lon1 * Math.PI / 180;
    var theta = bearing * Math.PI / 180;
    var delta = distance / R; // angular distance in radians
    var phi2 = Math.asin(Math.sin(phi1) * Math.cos(delta) + Math.cos(phi1) * Math.sin(delta) * Math.cos(theta));
    var lambda2 = lambda1 + Math.atan2(Math.sin(theta) * Math.sin(delta) * Math.cos(phi1), Math.cos(delta) - Math.sin(phi1) * Math.sin(phi2));
    return {
        latitude: phi2 * 180 / Math.PI,
        longitude: lambda2 * 180 / Math.PI
    };
}
export function encodePoint(point) {
    return "".concat(point.latitude.toFixed(12), "_").concat(point.longitude.toFixed(12));
}
