import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.DownloadRedirectPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Download Redirect',
	},
	text: {
		id: `${scope}.text`,
		defaultMessage: 'It seems you are using a mobile device. Please download our application that has been tailored to give you the best possible experience on mobile!',
	},
});
