import Rollbar, { Configuration as RollbarConfiguration } from 'rollbar';
import { webolucioConfig } from '@webolucio/core';

export const rollbarConfig: RollbarConfiguration = {
	accessToken: 'a58bbd87779c41efbdf2584e077eadd3',
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: !webolucioConfig.dev,
	environment: 'staging',
	payload: {
		client: {
			javascript: {
				source_map_enabled: true, // false by default
				code_version: '0.14',

				// Optionally have Rollbar guess which frames the error was
				// thrown from when the browser does not provide line
				// and column numbers.
				guess_uncaught_frames: true,
			},
		},
		server: {
			branch: 'main',
			host: webolucioConfig.serverUrl,
		},
	},
};

export const rollbar: Rollbar = new Rollbar(rollbarConfig);
