import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
// Webolucio imports
import { FieldEvent, useField } from '@webolucio/form';
import { FormControl } from '@webolucio/form-web';
import { Enum } from '@webolucio/core';
// App imports
import TagList from '../../presentationals/TagList';
// Local imports
import { TagListFieldProps } from './types';
import classes from './index.module.scss';

export default function TagListField<Value extends string | number = number, Option extends Enum<Value> = Enum<Value>>(
	props: TagListFieldProps<Value, Option>,
) {
	const { options, multiple, name, label, onChange, onDependencyChange, useEmbeddedIds, classes: externalClasses = {}, ...rest } = props;
	const { error, handleValueChange, initialValue, value } = useField<Value[] | Value | null>({ name, onDependencyChange, useEmbeddedIds });

	const mergedClasses = useMemo(
		() => ({
			...externalClasses,
			formControl: {
				...externalClasses.formControl,
				formControl: clsx(externalClasses.formControl?.formControl, 'field-tag-list'),
				adornmentContainer: clsx(classes.adornmentContainer, externalClasses.formControl?.adornmentContainer),
			},
		}),
		[externalClasses],
	);

	const handleChange = useCallback(
		(newValue: Value[] | Value | null) => {
			if (multiple) {
				if (Array.isArray(newValue)) {
					handleValueChange(newValue, onChange as (event: FieldEvent<Value[] | Value | null>) => boolean);
				} else {
					handleValueChange([], onChange as (event: FieldEvent<Value[] | Value | null>) => boolean);
				}
			} else {
				handleValueChange(newValue, onChange as (event: FieldEvent<Value[] | Value | null>) => boolean);
			}
		},
		[handleValueChange, multiple, onChange],
	);

	return (
		<FormControl classes={mergedClasses.formControl} label={label} error={error}>
			<TagList<Value, Option>
				initialValue={initialValue as any}
				options={options}
				multiple={multiple as false}
				onChange={handleChange}
				value={value as Value}
				classes={externalClasses.tagList}
				{...rest}
			/>
		</FormControl>
	);
}
