import { defineMessages } from 'react-intl';
export var scope = 'bpnl.organizers';
var messages = defineMessages({
    organizer: {
        id: "".concat(scope, ".organizer"),
        defaultMessage: 'Organizer'
    },
    organizers: {
        id: "".concat(scope, ".organizers"),
        defaultMessage: 'Organizers'
    },
    reportTitle: {
        id: "".concat(scope, ".reportTitle"),
        defaultMessage: "Can't find the organizer you're looking for?"
    }
});
export default messages;
