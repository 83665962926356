import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Outlet } from 'react-router-dom';
// Webolucio imports
import { WebolucioRouteObject } from '@webolucio/web';
//BPNL imports
import { eventsSaga, eventsReducer, eventsSliceKey } from '@bpnl/events';
import { performersReducer, performersSaga, performersSliceKey } from '@bpnl/performers';
import { venuesSaga, venuesReducer, venuesSliceKey } from '@bpnl/venues';
// App imports
import { commonMessages } from '../i18n';
import AuthTemplate from '../components/layouts/AuthTemplate';
import MainTemplate from '../components/layouts/MainTemplate';
// Route imports
import { loginRoute } from '../pages/LoginPage/route';
import { registrationAgeRoute } from '../pages/RegistrationAgePage';
import { registrationTypeRoute } from '../pages/RegistrationTypePage';
import { registrationBasicDataRoute } from '../pages/RegistrationBasicDataPage';
import { registrationTasteRoute } from '../pages/RegistrationTastePage';
import { discoverRoute } from '../pages/DiscoverPage';
import { eventsRoute } from '../pages/EventsPage';
import { eventRoute } from '../pages/EventPage';
import { venuesRoute } from '../pages/VenuesPage';
import { venueRoute } from '../pages/VenuePage';
import { DownloadRedirectPage, downloadRedirectRoute } from '../pages/DownloadRedirectPage';
import { performerRoute } from '../pages/PerformerPage';
import { mapsRoute } from '../pages/MapsPage';
import { staticContentRoute } from '../pages/StaticContentPage';
import { feedbackRoute } from '../pages/FeedbackPage';
import { myAccountRoute } from '../pages/MyAccountPage';
import { followingRoute } from '../pages/FollowingPage';
import { notFoundRoute } from '../pages/NotFoundPage/route';
import { forgotPasswordRoute } from '../pages/ForgotPasswordPage';
import { resetPasswordRoute } from '../pages/ResetPasswordPage';
import messages from '../pages/RegistrationBasicDataPage/messages';
// Local imports
import { BpnlRouteHandle } from './types';
import { organizerRoute } from '../pages/OrganizerPage';
import { organizersReducer, organizersSliceKey } from '@bpnl/organizers';

export const redirectRoutes: WebolucioRouteObject[] = [
	{
		id: 'redirect',
		path: '/*',
		Component: DownloadRedirectPage,
		children: [downloadRedirectRoute],
	},
];

const routes: WebolucioRouteObject[] = [
	{
		id: 'resetPassword',
		path: '/password-reset',
		Component: AuthTemplate,
		children: [resetPasswordRoute, notFoundRoute],
	},
	{
		id: 'root',
		path: '/',
		element: <Outlet />,
		children: [
			{
				id: 'main',
				Component: MainTemplate,
				path: '',
				children: [
					discoverRoute,
					{
						Component: () => {
							useInjectReducer({ key: eventsSliceKey, reducer: eventsReducer });
							useInjectSaga({ key: eventsSliceKey, saga: eventsSaga });

							return <Outlet />;
						},
						path: 'events',
						handle: {
							icon: 'bpnl-events',
							title: commonMessages.eventsPageTitle,
						} as BpnlRouteHandle,
						children: [eventsRoute, eventRoute],
					} as WebolucioRouteObject,
					{
						Component: () => {
							useInjectReducer({ key: venuesSliceKey, reducer: venuesReducer });
							useInjectSaga({ key: venuesSliceKey, saga: venuesSaga });

							return <Outlet />;
						},
						path: 'venues',
						handle: {
							icon: 'bpnl-pin',
							title: commonMessages.venuesPageTitle,
						} as BpnlRouteHandle,
						children: [venuesRoute, venueRoute],
					} as WebolucioRouteObject,
					{
						Component: () => {
							useInjectReducer({ key: performersSliceKey, reducer: performersReducer });
							useInjectSaga({ key: performersSliceKey, saga: performersSaga });

							return <Outlet />;
						},
						path: 'performers',
						handle: {
							skipInNavigation: true,
						} as BpnlRouteHandle,
						children: [performerRoute],
					} as WebolucioRouteObject,
					staticContentRoute,
					feedbackRoute,
					mapsRoute,
					myAccountRoute,
					followingRoute,
					notFoundRoute,
					{
						Component: () => {
							useInjectReducer({ key: organizersSliceKey, reducer: organizersReducer });
							useInjectSaga({ key: organizersSliceKey, saga: performersSaga });

							return <Outlet />;
						},
						path: 'organizers',
						handle: {
							skipInNavigation: true,
						} as BpnlRouteHandle,
						children: [organizerRoute],
					} as WebolucioRouteObject,
				],
			},
			{
				id: 'auth',
				element: <AuthTemplate />,
				path: '',
				children: [
					loginRoute,
					{
						id: 'registration',
						path: 'registration',
						element: <Outlet />,
						children: [registrationAgeRoute, registrationTypeRoute, registrationBasicDataRoute, registrationTasteRoute],
						handle: {
							title: messages.pageTitle,
						},
					},
					forgotPasswordRoute,
					notFoundRoute,
				],
			},
		],
	},
];

export default routes;
