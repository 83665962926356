import clsx from 'clsx';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
// Webolucio imports
import { useLocale } from '@webolucio/core';
// Local imports
import classes from './index.module.scss';
import { FancyDateProps } from './type';

export default function FancyDate(props: FancyDateProps) {
	const { start } = props;
	const { locale } = useLocale();

	return (
		<div className={clsx(classes.FancyDate, 'fancy-date')}>
			<div className={classes.FancyDateTop}>
				<span className={classes.month}>{format(start, 'MMM.')}</span>
			</div>
			<span className={classes.Day}>{format(start, 'dd.')}</span>
			<span className={classes.DayName}>{format(start, 'EEE', { locale: locale === 'hu' ? hu : undefined })}</span>
		</div>
	);
}
