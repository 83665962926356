import { memoize } from 'lodash-es';
import { format } from 'date-fns';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialVenuesState, venuesSliceKey } from './slice';
var selectDomain = function(state) {
    return state[venuesSliceKey] || initialVenuesState;
};
export var selectVenuesData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectVenuesQueryResults = createSelector([
    selectDomain
], function(storeState) {
    return storeState.queries;
});
export var selectGetVenueIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.singleItemFetching;
});
export var selectVenueListIsFetching = createSelector([
    selectVenuesQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.fetching) || false;
    });
});
export var selectGetVenueErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.singleItemErrors;
});
export var selectVenueListErrors = createSelector([
    selectVenuesQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.errors) || null;
    });
});
export var selectVenuesCount = createSelector([
    selectVenuesQueryResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.totalCount) || 0;
    });
});
export var selectVenuesList = createSelector([
    selectVenuesData,
    selectVenuesQueryResults
], function(venues, queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return (((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.data) || []).reduce(function(acc, id) {
            var venue = venues[id];
            if (venue) acc.push(venue);
            return acc;
        }, []);
    });
});
export var selectVenue = createSelector([
    selectVenuesData
], function(venues) {
    return memoize(function(id) {
        return venues[id] || null;
    });
});
export var selectVenueIsOpen = createSelector([
    selectVenuesData
], function(venues) {
    return memoize(function(venueId, now) {
        var getHour = function getHour(time) {
            return parseInt(time.split(':')[0]);
        };
        var getMinute = function getMinute(time) {
            return parseInt(time.split(':')[1]);
        };
        var venue = venues[venueId];
        // TODO There must be a simpler way
        var openingHours = Object.keys(venue.opening_hours).map(function(key) {
            return [
                venue.opening_hours[key]
            ];
        });
        var notEmptyOpeningHours = openingHours.filter(function(day) {
            return day[0].length > 0;
        });
        var currentDay = now.getDay() - 1;
        var currentHour = now.getHours() + 2;
        var currentMinute = now.getMinutes();
        var isOpen = false;
        var nextOpen = '--:--';
        var nextClose = '--:--';
        var openTodayButLater = false;
        openingHours.forEach(function(day, dayIndex) {
            if (dayIndex === currentDay) {
                day[0].forEach(function(item) {
                    nextOpen = item.time_from;
                    openTodayButLater = true;
                    if (getHour(item.time_from) <= currentHour && getHour(item.time_to) >= currentHour) {
                        if (getHour(item.time_from) === currentHour && getMinute(item.time_from) > currentMinute) {
                            isOpen = false;
                        } else if (getHour(item.time_to) === currentHour && getMinute(item.time_to) < currentMinute) {
                            isOpen = false;
                            openTodayButLater = false;
                        } else {
                            nextClose = item.time_to;
                            isOpen = true;
                        }
                    }
                });
            }
        });
        if (!isOpen && !openTodayButLater) {
            for(var i = 0; i < openingHours.length; i++){
                if (i > currentDay) {
                    if (openingHours[i][0].length > 0) {
                        nextOpen = i;
                        break;
                    }
                }
                if (notEmptyOpeningHours.length > 0) nextOpen = notEmptyOpeningHours[0][0][0].day_of_week - 1;
            }
        }
        return {
            isOpen: isOpen,
            nextOpen: nextOpen,
            nextClose: nextClose
        };
    }, function(venueId, now) {
        return "".concat(venueId, "-").concat(format(now, 'HH:mm'));
    });
});
export var selectSearchedVenues = createSelector([
    selectDomain
], function(storeState) {
    return storeState.searchedItems;
});
