import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { isArray } from 'lodash-es';
// Webolucio imports
import { FilterComparisonType, FilterQuery, SortOrder, useMountedEffect } from '@webolucio/core';
// BPNL imports
import { venuesMessages, VenueFilters, DetailedVenue } from '@bpnl/venues';
// App imports
import Title from '../../components/presentationals/Title';
import Sorting from '../../components/presentationals/Sorting';
import VenueGrid from '../../components/containers/VenueGrid';
import Search from '../../components/filters/Search';
import LocationFilter from '../../components/filters/LocationFilter';
import MusicStyleFilter from '../../components/filters/MusicStyleFilter';
import ServiceFilter from '../../components/filters/ServicesFilter';
import { QueryNonNullObject } from '../../types';
import FilterButton from '../../components/presentationals/FilterButton';
import FilterOnMobile from '../../components/presentationals/FilterOnMobile';
import useIsDesktop from '../../hooks/useIsDesktop';
import useIsFloating from '../../hooks/useIsFloating';
import clsx from 'clsx';

export default function VenuesPage() {
	const { formatMessage } = useIntl();

	const [formattedFilters, setFormattedFilters] = useState<FilterQuery<VenueFilters>>([]);
	const [activeFilters, setActiveFilters] = useState<Record<string, QueryNonNullObject<VenueFilters>>>({});
	const [activeSorting, setActiveSorting] = useState<keyof DetailedVenue>();
	const [activeSortOrder, setActiveSortOrder] = useState<SortOrder>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [openFilter, setOpenFilter] = useState(false);

	const isDesktop = useIsDesktop();
	const filterFloating = useIsFloating({ thresholdIn: 75, thresholdOut: 170 });

	const handleSorting = useCallback((sortBy, sortOrder) => {
		setActiveSorting(sortBy);
		setActiveSortOrder(sortOrder);
	}, []);

	const handleSearch = useCallback((value) => {
		setSearchTerm(value);
	}, []);

	const handleFilter = useCallback((newFilters, source) => {
		setActiveFilters((prevFilters) => {
			return Object.keys(prevFilters)?.length ? { ...prevFilters, [source]: newFilters } : { [source]: newFilters };
		});
	}, []);

	const handleFilterModalOpen = useCallback(() => {
		setOpenFilter((prev) => !prev);
	}, []);

	useMountedEffect(() => {
		const newFilters: FilterQuery = [];
		const tagIDs: number[] = [];
		Object.values(activeFilters)
			.flat()
			.forEach((filter) => {
				if (!filter.value || (isArray(filter.value) && !filter.value.length)) {
					return;
				} else if (filter.field === 'tag_id') {
					const tagID = parseInt(filter.value.toString());
					if (!isNaN(tagID)) {
						tagIDs.push(tagID);
					}
				} else {
					newFilters.push(filter);
				}
			});
		if (tagIDs.length) {
			newFilters.push({ field: 'tag_id', condition_type: FilterComparisonType.IN, value: tagIDs.flat() });
		}
		setFormattedFilters(newFilters);
	}, [activeFilters]);

	return (
		<div className={clsx('listLayout', filterFloating && 'floating')}>
			<div className={'header'}>
				<Title title={formatMessage(venuesMessages.venues)} variant={'page'} />
				<FilterButton onClick={() => handleFilterModalOpen()} />
				<Sorting onSorting={handleSorting} />
			</div>
			<div className={'filters'}>
				<Search onChange={handleSearch} />
				<FilterOnMobile isMobile={!isDesktop} open={openFilter} close={handleFilterModalOpen}>
					<LocationFilter onChange={handleFilter} />
					<ServiceFilter onChange={handleFilter} />
					<MusicStyleFilter onChange={handleFilter} />
				</FilterOnMobile>
			</div>
			<VenueGrid name="main" filter={formattedFilters} sortBy={activeSorting} sortOrder={activeSortOrder} search={searchTerm} />
		</div>
	);
}
