import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// App imports
import BpnlButton from '../../presentationals/BpnlButton';
// Local imports
import classes from './index.module.scss';
import messages from './messages';
import { ReportBlockProps } from './types';

export default function ReportBlock(props: ReportBlockProps) {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const { className, title, onClick = () => navigate('/feedback') } = props;

	return (
		<div className={clsx(classes.reportBlock, className)}>
			<h3 className={classes.reportBlockTitle}>{title || formatMessage(messages.defaultTitle)}</h3>
			<BpnlButton title={formatMessage(messages.buttonTitle)} outlined onClick={onClick} />
		</div>
	);
}
