import { LocaleService } from '@webolucio/core';
import { CommonMessages } from './lib/i18n/types';

declare module '@webolucio/core' {
	interface WebolucioMessages {
		common: CommonMessages;
	}

	interface BackendAsset {
		sources: Array<{
			height: number | null;
			image_size: string | null;
			image_size_width: number | null;
			mime_type: string;
			url: string;
			width: number | null;
		}>;
	}
}

declare module '@webolucio/web' {
	interface LoadOverlayProps {
		fixed?: boolean;
	}
}

LocaleService.getService().registerTranslations('app-userspace', async function getTranslationsFiles(locale: string) {
	const module = await import(`./i18n/translations/${locale}.json`);
	return module.default || module;
});
