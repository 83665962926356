import clsx from 'clsx';
import { Icon } from '@webolucio/icons';
import { HaderMenuProps } from './types';
import classes from './index.module.scss';

export default function HeaderMenu(props: HaderMenuProps) {
	const { onClick, menuOpen } = props;
	return (
		<div className={clsx(classes.headerMenu, 'header-menu-button')} onClick={onClick}>
			{menuOpen ? <Icon name={'bpnl-close'} /> : <Icon name={'bpnl-menu'} />}
		</div>
	);
}
