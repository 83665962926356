import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.ImageGallery';

export default defineMessages({
	imageCounter: {
		id: `${scope}.imageCounter`,
		defaultMessage: 'All images ({count}) »',
	}
});
