// BPNL imports
import { useSocialPostsEvent } from '@bpnl/social-posts';
// App imports
import YoutubeSongCard from '../YoutubeSongCard';
import SpotifySongCard from '../SpotifySongCard';
// Local imports
import classes from './index.module.scss';
import { SocialPostsListProps } from './types';

export default function SocialPostsList(props: SocialPostsListProps) {
	const { eventId } = props;

	const { socialPosts } = useSocialPostsEvent(
		{
			queryKey: `social-posts-event-${eventId}`,
			limit: 5,
		},
		eventId,
	);

	return (
		<div className={classes.socialPostsContainer}>
			{socialPosts.map((post) => {
				switch (post.type) {
					case 'youtube':
						return <YoutubeSongCard key={post.remote_id} {...post} />;
					case 'spotify':
						return <SpotifySongCard key={post.remote_id} {...post} />;
					default:
						return null;
				}
			})}
		</div>
	);
}
