import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Modal } from '@webolucio/web';
import Image from '../Image';
import Gallery from '../../containers/Gallery';
import messages from './messages';
import classes from './index.module.scss';
import useIsDesktop from '../../../hooks/useIsDesktop';

export default function ImageGallery(props) {
	const { thumbs, count } = props;
	const { formatMessage } = useIntl();
	const [showModal, setShowModal] = useState(false);
	const thumbnailsNotDefault = thumbs.filter((item) => !item.is_default);

	const isDesktop = useIsDesktop();

	return (
		<div className={clsx(classes.imageGallery)}>
			<Modal open={showModal} onClose={() => setShowModal(false)} headerIcon={<></>}>
				<Gallery images={thumbnailsNotDefault} />
			</Modal>
			{isDesktop ? (
				thumbnailsNotDefault.map(
					(image, index) =>
						!image.is_default &&
						index <= 3 && (
							<div onClick={() => setShowModal(true)} key={`images-${index}`} className={clsx(classes.imageItem)}>
								<Image file={image.file} key={index} />
							</div>
						),
				)
			) : (
				<div onClick={() => setShowModal(true)}>
					<Image file={thumbs[0].file} />
				</div>
			)}
			{count > 0 && (
				<span className={classes.imageCounter} onClick={() => setShowModal(true)}>
					{formatMessage(messages.imageCounter, { count: count })}
				</span>
			)}
		</div>
	);
}
