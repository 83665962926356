// Webolucio imports
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';
import messages from './messages';

export const venueRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	path: ':id',
	handle: {
		icon: 'bpnl-pin',
		title: messages.title,
	} as BpnlRouteHandle,
};
