import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.FeedbackPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Give Feedback',
	},
	subject: {
		id: `${scope}.subject`,
		defaultMessage: 'Subject',
	},
	message: {
		id: `${scope}.message`,
		defaultMessage: 'Message',
	},
	send: {
		id: `${scope}.send`,
		defaultMessage: 'Send',
	},
	successMessage: {
		id: `${scope}.successMessage`,
		defaultMessage: 'Thank you for your feedback!',
	},
	loginHelper: {
		id: `${scope}.loginHelper`,
		defaultMessage: 'Please login or register to use our feedback system!',
	},
	login: {
		id: `${scope}.login`,
		defaultMessage: 'Login / Register',
	},
});
