import { memoize } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialNotificationsState, notificationsSliceKey } from './slice';
var selectDomain = function(state) {
    return state[notificationsSliceKey] || initialNotificationsState;
};
export var selectNotificationsData = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectNotificationsResults = createSelector([
    selectDomain
], function(storeState) {
    return storeState.queries;
});
export var selectNotificationListIsFetching = createSelector([
    selectNotificationsResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.fetching) || false;
    });
});
export var selectNotificationListErrors = createSelector([
    selectNotificationsResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.errors) || null;
    });
});
export var selectNotificationListCount = createSelector([
    selectNotificationsResults
], function(queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return ((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.totalCount) || 0;
    });
});
export var selectNotificationsList = createSelector([
    selectNotificationsData,
    selectNotificationsResults
], function(notifications, queryResults) {
    return memoize(function() {
        var key = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'default';
        var _queryResults_key;
        return (((_queryResults_key = queryResults[key]) === null || _queryResults_key === void 0 ? void 0 : _queryResults_key.data) || []).reduce(function(acc, id) {
            var event = notifications[id];
            if (event) acc.push(event);
            return acc;
        }, []);
    });
});
export var selectNotification = createSelector([
    selectNotificationsData
], function(notifications) {
    return memoize(function(id) {
        return notifications[id] || null;
    });
});
export var selectNotificationIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_queryKey;
        var queryKey = "single-".concat(id);
        return ((_storeState_queries_queryKey = storeState.queries[queryKey]) === null || _storeState_queries_queryKey === void 0 ? void 0 : _storeState_queries_queryKey.fetching) || false;
    });
});
export var selectNotificationErrors = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_queryKey;
        var queryKey = "single-".concat(id);
        return ((_storeState_queries_queryKey = storeState.queries[queryKey]) === null || _storeState_queries_queryKey === void 0 ? void 0 : _storeState_queries_queryKey.errors) || null;
    });
});
export var selectNotificationPatching = createSelector([
    selectDomain
], function(storeState) {
    return memoize(function(id) {
        var _storeState_queries_;
        return ((_storeState_queries_ = storeState.queries["single-".concat(id, "-patch")]) === null || _storeState_queries_ === void 0 ? void 0 : _storeState_queries_.fetching) || false;
    });
});
