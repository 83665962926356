import { useCallback, useEffect, useState, forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';
// BPNL imports
import { FollowType } from '@bpnl/common';
import { usePerformer } from '@bpnl/performers';
import { useFollow } from '@bpnl/follow';
// App imports
import Image from '../Image';
import FavoriteButton from '../FavoriteButton';
import LinkOverlay from '../LinkOverlay';
// Local imports
import classes from './index.module.scss';
import { PerformerCardProps } from './type';

function PerformerCard(props: PerformerCardProps, ref: ForwardedRef<HTMLDivElement>) {
	const { performerId } = props;

	const [isSaving, setIsSaving] = useState(false);

	const { performer } = usePerformer({ id: performerId });
	const { deleteFollow, submitFollow, followSavingItems } = useFollow();

	const handleFollow = useCallback(() => {
		if (performer.is_followed) {
			deleteFollow({ id: performerId, type: FollowType.Performer });
		} else {
			submitFollow({ id: performerId, type: FollowType.Performer });
		}
	}, [performerId, performer?.is_followed, deleteFollow, submitFollow]);

	useEffect(() => {
		if (followSavingItems?.includes(`performer-${performerId}`)) {
			setIsSaving(true);
		} else {
			setIsSaving(false);
		}
	}, [followSavingItems, performerId]);

	if (!performer) {
		return;
	}

	return (
		<div className={clsx(classes.performerCard)} ref={ref}>
			<div className={classes.performerCardOverlay} />
			<Image file={performer.default_medium ? performer.default_medium.file : performer.media.length ? performer.media[0].file : null} />
			<h3 className={clsx(classes.performerCardTitle)}>{performer.name}</h3>
			<FavoriteButton isFollowed={performer.is_followed} click={handleFollow} className={clsx(classes.performerCardFollow)} isFetching={isSaving} />
			<LinkOverlay to={`/performers/${performer.id}`} />
		</div>
	);
}

export default forwardRef<HTMLDivElement, PerformerCardProps>(PerformerCard);
