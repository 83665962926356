import { useEffect } from 'react';

/**
 * Hook for locking the main template when loading
 *
 * Use this if you want to present a loading overlay over the whole page
 *
 * @param loading
 */
export default function useLoadingFlag(loading: boolean) {
	useEffect(() => {
		if (loading) {
			document.body.style.setProperty('--load-lock', '1');
		} else {
			document.body.style.setProperty('--load-lock', '0');
		}
	}, [loading]);
}
