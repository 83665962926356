// Webolucio imports
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';
import messages from './messages';

export const mapsRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	path: 'map',
	handle: {
		icon: 'bpnl-map',
		title: messages.title,
	} as BpnlRouteHandle,
};
