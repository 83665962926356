import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Icon } from '@webolucio/icons';
import classes from './index.module.scss';
import { TicketProps } from './types';
import messages from './messages';
export default function Tickets(props: TicketProps) {
	const { tickets } = props;
	const { formatMessage } = useIntl();

	return (
		<div className={classes.ticket}>
			{tickets &&
				tickets.map((item, index) => (
					<div key={`ticket-${index}`} className={clsx(classes.ticketItem, 'ticket-item', item.is_sold_out && classes.soldOut)}>
						<div className={classes.ticketItemName}>
							<Icon name={'bpnl-ticket-outline'} />
							<div className={clsx(classes.description)}>
								{item.name}
								{item.is_sold_out ? <span>{formatMessage(messages.soldOut).toUpperCase()}</span> : <span>{item.description}</span>}
							</div>
						</div>
						<div className={classes.ticketItemPrice}>{formatMessage(messages.currency, { amount: item.price })}</div>
					</div>
				))}
		</div>
	);
}
