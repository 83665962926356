import clsx from 'clsx';
import classes from './index.module.scss';
import { TitleProps } from './types';

export default function Title(props: TitleProps) {
	const { className, title, variant = 'block', children } = props;
	if (!title) return null;
	return (
		<div className={clsx(classes.title, 'title', classes[variant], variant, className)}>
			{variant === 'page' && (
				<h1 className={classes.titleText}>
					{title}
					{children}
				</h1>
			)}
			{variant === 'block' && (
				<h2 className={classes.titleText}>
					{title}
					{children}
				</h2>
			)}
			{variant === 'cards' && (
				<h3 className={classes.titleText}>
					{title}
					{children}
				</h3>
			)}
		</div>
	);
}
