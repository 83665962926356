import { JsonLeaf } from '@bpnl/common';
import { JsonHtmlClasses, JsonHtmlProps } from './types';

const buildLeaf = (leaf: JsonLeaf, classes: JsonHtmlClasses) => {
	const content = leaf.children?.length ? leaf.children?.map((subLeaf) => buildLeaf(subLeaf, classes)) : leaf.content;
	switch (leaf.type) {
		case 'paragraph':
			return <p className={classes.paragraph}>{content}</p>;
		case 'text':
			return content;
		case 'bold':
			return <strong className={classes.boldText}>{content}</strong>;
		case 'italic':
			return <em className={classes.italicText}>{content}</em>;
		case 'newLine':
			return <br />;
		case 'link':
			return (
				<a className={classes.link} href={leaf.link}>
					{content}
				</a>
			);
	}
};

export default function JsonHtml(props: JsonHtmlProps) {
	const { json, classes } = props;

	return json.children.map((leaf) => buildLeaf(leaf, classes || {}));
}
