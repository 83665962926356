// Webolucio imports
import type { WebolucioRouteObject } from '@webolucio/web';
import { BpnlRouteHandle } from '../../app/types';
// Local imports
import LoadablePage from './loadable';
import messages from './messages';

export const forgotPasswordRoute: WebolucioRouteObject = {
	Component: LoadablePage,
	path: 'forgot-password',
	handle: {
		title: messages.pageTitle,
	} as BpnlRouteHandle,
};
