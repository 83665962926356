import { createSlice } from '@reduxjs/toolkit';
export var initialFollowState = {
    errors: null,
    savingItems: []
};
var followSlice = createSlice({
    name: 'follow',
    initialState: initialFollowState,
    reducers: {
        removeSelected: function removeSelected(state, action) {
            state.savingItems.splice(state.savingItems.indexOf("".concat(action.payload.type, "-").concat(action.payload.id)), 1);
        },
        clearErrors: function clearErrors(state) {
            state.errors = initialFollowState.errors;
        },
        postFollowRequest: function postFollowRequest(state, action) {
            state.savingItems.push("".concat(action.payload.type, "-").concat(action.payload.id));
        },
        postFollowFailure: function postFollowFailure(state, action) {
            state.savingItems = initialFollowState.savingItems;
            state.errors = action.payload;
        },
        postFollowSuccess: function postFollowSuccess(state, action) {
            state.errors = initialFollowState.errors;
        },
        deleteFollowRequest: function deleteFollowRequest(state, action) {
            state.savingItems.push("".concat(action.payload.type, "-").concat(action.payload.id));
        },
        deleteFollowFailure: function deleteFollowFailure(state, action) {
            state.savingItems = initialFollowState.savingItems;
            state.errors = action.payload;
        },
        deleteFollowSuccess: function deleteFollowSuccess(state, action) {
            state.errors = initialFollowState.errors;
        }
    }
});
export var followActions = followSlice.actions, followReducer = followSlice.reducer, followSliceKey = followSlice.name;
