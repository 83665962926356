import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.MusicStyleFilter';

export default defineMessages({
	filterTitle: {
		id: `${scope}.filterTitle`,
		defaultMessage: 'Music style',
	},
	numberOfChosen: {
		id: `${scope}.numberOfChosen`,
		defaultMessage: 'chosen',
	},
});
