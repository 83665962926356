import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.FollowingPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Following',
	},
	nextEvents: {
		id: `${scope}.nextEvents`,
		defaultMessage: 'Your next events',
	},
	pastEvents: {
		id: `${scope}.pastEvents`,
		defaultMessage: 'Past events',
	},
	venues: {
		id: `${scope}.venues`,
		defaultMessage: 'Your favourite venues',
	},
	performers: {
		id: `${scope}.performers`,
		defaultMessage: 'Your favourite performers',
	},
});
