import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { webolucioConfig, trimSlashes } from '@webolucio/core';
import { Modal, notificationsActions } from '@webolucio/web';
// BPNL imports
import { followMessages, useFollow } from '@bpnl/follow';
import { FollowType } from '@bpnl/common';
// App imports
import Title from '../Title';
import Image from '../Image';
import Gallery from '../../containers/Gallery';
import { commonMessages } from '../../../i18n';
// Local imports
import { VenueHeaderProps } from './types';
import messages from './messages';
import classes from './index.module.scss';
import HeaderButtons from '../HeaderButtons';
import ImageGallery from '../ImageGallery';
export default function VenueHeader(props: VenueHeaderProps) {
	const { id, name, followers, isFollowed, thumbnails = [] } = props;
	const { formatMessage } = useIntl();
	const { deleteFollow, submitFollow, followSavingItems } = useFollow();
	const dispatch = useDispatch();

	const [isSaving, setIsSaving] = useState(false);

	const handleFollow = useCallback(() => {
		if (isFollowed) {
			deleteFollow({ id: id, type: FollowType.Venue });
		} else {
			submitFollow({ id: id, type: FollowType.Venue });
		}
	}, [id, isFollowed, deleteFollow, submitFollow]);

	const handleCopyToClipboard = useCallback(() => {
		return navigator.clipboard.writeText(`${trimSlashes(webolucioConfig.baseUrl)}/venues/${id}`).then(() => {
			dispatch(notificationsActions.addNotification({ message: formatMessage(commonMessages.clipboard), variant: 'success' }));
		});
	}, [id]);

	useEffect(() => {
		if (followSavingItems?.includes(`venue-${id}`)) {
			setIsSaving(!isSaving);
		}
	}, [followSavingItems, id]);

	const thumbnailsCount = thumbnails.filter((item) => !item.is_default).length;

	return (
		<div className={clsx(classes.venueHeader)}>
			<div className={clsx(classes.HeaderTitleContainer)}>
				<Title title={name} variant={'page'}>
					<span>
						{(followers || 0) +
							` ${(followers || 0) > 1 ? formatMessage(followMessages.followerPlural).toLowerCase() : formatMessage(followMessages.follower).toLowerCase()}`}
					</span>
				</Title>
				{isFollowed !== undefined ? (
					<HeaderButtons isFollowed={isFollowed} isFetching={isSaving} followClick={handleFollow} shareClick={handleCopyToClipboard} />
				) : null}
			</div>
			{thumbnails.length ? <ImageGallery thumbs={thumbnails} count={thumbnailsCount} /> : null}
		</div>
	);
}
