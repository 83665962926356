import clsx from 'clsx';
import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Link, useMatch } from 'react-router-dom';
// Webolucio imports
import { Icon } from '@webolucio/icons';
import { WebolucioRouteObject } from '@webolucio/web';
// Local imports
import classes from './index.module.scss';

export default function NavigationItem({ route, onClick }: { route: WebolucioRouteObject; onClick: (e: MouseEvent<HTMLAnchorElement>) => void }) {
	const { formatMessage } = useIntl();
	const match = useMatch(route.path ? route.path + '/*' : '');

	return (
		<Link
			className={clsx(classes.navigationItem, 'navigation-item', {
				[clsx(classes.active, 'active')]: match && match.pattern.path === route.path + '/*',
				[clsx(classes.home, 'active')]: (match && match.pattern.path === route.path) || route.path === '/',
			})}
			key={'nav-route-' + (route.path || route.id || 'unknown')}
			to={'/' + route.path || ''}
			onClick={onClick}
		>
			{route.handle?.icon ? <Icon name={route.handle.icon} /> : null}
			{route.handle?.title ? <span>{typeof route.handle.title === 'string' ? route.handle.title : formatMessage(route.handle.title)}</span> : null}
		</Link>
	);
}
