import clsx from 'clsx';
import { useCallback, useEffect, useState, forwardRef, ForwardedRef } from 'react';
// Webolucio imports
import { Icon } from '@webolucio/icons';
// BPNL imports
import { FollowType } from '@bpnl/common';
import { useEvent } from '@bpnl/events';
import { useFollow } from '@bpnl/follow';
// App imports
import Image from '../Image';
import Tag from '../Tag';
import FavoriteButton from '../FavoriteButton';
import LinkOverlay from '../LinkOverlay';
import NameOfDate from '../NameOfDate';
import VenueRow from '../VenueRow';
// Local imports
import classes from './index.module.scss';
import { EventCardProps } from './types';

function EventCard(props: EventCardProps, ref: ForwardedRef<HTMLDivElement>) {
	const { eventId, variant = 'narrow' } = props;
	const [isSaving, setIsSaving] = useState(false);
	const { selectedEvent } = useEvent({ id: eventId });
	const { submitFollow, deleteFollow, followSavingItems } = useFollow();

	const handleToggleFollow = useCallback(() => {
		if (selectedEvent?.is_followed) {
			deleteFollow({ id: eventId, type: FollowType.Event });
		} else {
			submitFollow({ id: eventId, type: FollowType.Event });
		}
	}, [deleteFollow, eventId, selectedEvent?.is_followed, submitFollow]);

	useEffect(() => {
		if (followSavingItems?.includes(`event-${eventId}`)) {
			setIsSaving(true);
		} else {
			setIsSaving(false);
		}
	}, [followSavingItems, eventId]);

	if (!selectedEvent) {
		return null;
	}

	return (
		<div className={clsx(classes.eventCard, classes[variant], 'card-event', variant)} ref={ref}>
			<div className="cover-image">
				<Image file={selectedEvent.default_medium?.file || (selectedEvent.media.length && selectedEvent.media[0].file) || null} />
				<FavoriteButton
					click={handleToggleFollow}
					isFetching={isSaving}
					isFollowed={selectedEvent.is_followed}
					className={clsx(classes.eventCardFollow)}
				/>
			</div>
			<div className={clsx(classes.content, 'card-content')}>
				{selectedEvent.music_style_tags.length ? (
					<div className={classes.tags}>
						{selectedEvent.music_style_tags
							.slice(0, selectedEvent.music_style_tags.length >= 2 ? 2 : selectedEvent.music_style_tags.length)
							.map((tag) => (
								<Tag key={`event-card-tag-${tag.id}`} label={tag.name.toLowerCase()} size="small" />
							))}
					</div>
				) : null}
				<span className={clsx(classes.name)}>{selectedEvent.name}</span>
				{selectedEvent?.date_start ? (
					<div className={clsx(classes.date)}>
						<Icon name="bpnl-date" />
						<NameOfDate start={selectedEvent.date_start} end={selectedEvent.date_end} />
					</div>
				) : null}
				<LinkOverlay to={`/events/${eventId}`} />
				{selectedEvent.venues.length ? (
					<VenueRow
						className={classes.venueRow}
						name={selectedEvent.venues[0].name}
						variant="basic"
						id={selectedEvent.venues[0].id}
						thumbnail={
							selectedEvent.venues[0].default_medium || (selectedEvent.venues[0].media.length && selectedEvent.venues[0].media[0]) || undefined
						}
					/>
				) : (
					<div className={classes.venuePlaceholder}></div>
				)}
			</div>
		</div>
	);
}

export default forwardRef<HTMLDivElement, EventCardProps>(EventCard);
