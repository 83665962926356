import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
// Webolucio imports
import { useMountedEffect } from '@webolucio/core';
import { LoadOverlay } from '@webolucio/web';
// App imports
import { useOrganizer } from '@bpnl/organizers';
import { selectMyAccount } from '@bpnl/account';
import { useVisitEntity } from '@bpnl/common';
// External imports
import CollapsableText from '../../components/presentationals/CollapsableText';
import Title from '../../components/presentationals/Title';
import { commonMessages } from '../../i18n';
// Local imports
import classes from './index.module.scss';
import OrganizerHeader from '../../components/presentationals/OrganizerHeader';

export default function OrganizerPage() {
	const { id } = useParams<{ id: string }>();
	const organizerId: number = Number(id);
	const navigate = useNavigate();
	const { selectedOrganizer, isOrganizersFetching } = useOrganizer({ id: organizerId });
	const { formatMessage } = useIntl();
	const account = useSelector(selectMyAccount);

	useVisitEntity({ id: organizerId, name: 'organizer', userId: account?.id });

	useMountedEffect(() => {
		if (!selectedOrganizer && !isOrganizersFetching) {
			navigate('/404', { replace: true });
		}
	}, [selectedOrganizer, isOrganizersFetching]);

	if (!selectedOrganizer) {
		return <LoadOverlay />;
	}
	return selectedOrganizer ? (
		<div>
			<OrganizerHeader
				id={organizerId}
				name={selectedOrganizer.name}
				followers={selectedOrganizer.count_followers}
				isFollowed={selectedOrganizer.is_followed}
				thumbnail={selectedOrganizer.default_medium}
			/>

			<div className={clsx(classes.Content)}>
				<div className={clsx(classes.ContentLeft)}>
					{selectedOrganizer.description ? (
						<div className={classes.block}>
							<Title title={formatMessage(commonMessages.description)} />
							<CollapsableText content={selectedOrganizer.description} />
						</div>
					) : null}
				</div>
			</div>
		</div>
	) : (
		<LoadOverlay />
	);
}
