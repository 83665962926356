import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialMyAccountState, myAccountSliceKey } from './slice';
var selectDomain = function(state) {
    return state[myAccountSliceKey] || initialMyAccountState;
};
export var selectMyAccountIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.fetching;
});
export var selectMyAccountIsSaving = createSelector([
    selectDomain
], function(storeState) {
    return storeState.saving;
});
export var selectMyAccountErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.errors;
});
export var selectMyAccount = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
