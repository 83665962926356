import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { throttle } from 'lodash-es';
// Webolucio imports
import { FilterComparisonType, FilterQuery, SortOrder, useMountedEffect } from '@webolucio/core';
// BPNL imports
import { eventsMessages, DetailedEvent, EventFilters } from '@bpnl/events';
// App imports
import Title from '../../components/presentationals/Title';
import Sorting from '../../components/presentationals/Sorting';
import Search from '../../components/filters/Search';
import DateFilter from '../../components/filters/DateFilter';
import LocationFilter from '../../components/filters/LocationFilter';
import VenueFilter from '../../components/filters/VenueFilters';
import MusicStyleFilter from '../../components/filters/MusicStyleFilter';
import EventGrid from '../../components/containers/EventGrid';
import { InitialFilters } from '../../components/filters/types';
import FilterButton from '../../components/presentationals/FilterButton';
import FilterOnMobile from '../../components/presentationals/FilterOnMobile';
import useIsDesktop from '../../hooks/useIsDesktop';
import useIsFloating from '../../hooks/useIsFloating';
import { QueryNonNullObject } from '../../types';

function getTodayFilter(): InitialFilters {
	return {
		// @ts-ignore
		field: 'date_start',
		condition_type: FilterComparisonType.GREATER_THAN_OR_EQUAL,
		value: format(new Date(), 'yyyy-MM-dd'),
	};
}

function sanitizeParam(param: string | null): number | null {
	if (param && !isNaN(parseInt(param))) {
		return parseInt(param);
	}
	return null;
}

export default function EventsPage() {
	const { formatMessage } = useIntl();
	const [searchParams] = useSearchParams();
	const venueParam = sanitizeParam(searchParams.get('venue'));

	const initialFilters = useMemo<InitialFilters[]>(() => {
		if (venueParam !== null) {
			return [{ field: 'venue_id', condition_type: FilterComparisonType.EQUAL, value: venueParam } as unknown as InitialFilters, getTodayFilter()];
		}
		return [getTodayFilter()];
	}, [venueParam]);

	const [formattedFilters, setFormattedFilters] = useState<FilterQuery<EventFilters>>(initialFilters);
	const [activeFilters, setActiveFilters] = useState<Record<string, QueryNonNullObject<EventFilters>>>(
		venueParam !== null ? { venue_id: { field: 'venue_id', condition_type: FilterComparisonType.EQUAL, value: venueParam } } : {},
	);
	const [activeSorting, setActiveSorting] = useState<keyof DetailedEvent>('date_start');
	const [activeSortOrder, setActiveSortOrder] = useState<SortOrder>(SortOrder.ASC);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [openFilter, setOpenFilter] = useState(false);

	const isDesktop = useIsDesktop();
	const filterFloating = useIsFloating({ thresholdIn: 75, thresholdOut: 170 });

	const handleSorting = useCallback((sortBy, sortOrder) => {
		setActiveSorting(sortBy);
		setActiveSortOrder(sortOrder);
	}, []);

	const handleSearch = useCallback((value) => {
		setSearchTerm(value);
	}, []);

	const handleFilter = useCallback((newFilters, source) => {
		setActiveFilters((prevFilters) => {
			return Object.keys(prevFilters)?.length ? { ...prevFilters, [source]: newFilters } : { [source]: newFilters };
		});
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const handleFilterModalOpen = useCallback(() => {
		setOpenFilter((prev) => !prev);
	}, []);

	useMountedEffect(() => {
		let hasDateFilter = false;
		const newFilters: FilterQuery = [];
		const tagIDs: number[] = [];
		Object.values(activeFilters)
			.flat()
			.forEach((filter) => {
				if (!filter.value || (Array.isArray(filter.value) && !filter.value.length)) {
					return;
				} else if (filter.field === 'tag_id') {
					const tagID = parseInt(filter.value.toString());
					if (!isNaN(tagID)) {
						tagIDs.push(tagID);
					}
				} else {
					newFilters.push(filter);
				}
				// Check if there is a date filter
				hasDateFilter = filter.field === 'date_start';
			});
		if (tagIDs.length) {
			newFilters.push({ field: 'tag_id', condition_type: FilterComparisonType.IN, value: tagIDs.flat() });
		}
		// If there is no date filter, add a default one
		hasDateFilter ? setFormattedFilters(newFilters) : setFormattedFilters([...newFilters, getTodayFilter()]);
	}, [activeFilters]);

	return (
		<div className={clsx('listLayout', filterFloating && 'floating')}>
			<div className="header">
				<Title title={formatMessage(eventsMessages.events)} variant={'page'} />
				<FilterButton onClick={() => handleFilterModalOpen()} />
				<Sorting onSorting={handleSorting} />
			</div>
			<div className="filters">
				<Search onChange={handleSearch} />
				<FilterOnMobile isMobile={!isDesktop} open={openFilter} close={handleFilterModalOpen}>
					<DateFilter onChange={handleFilter} />
					<LocationFilter onChange={handleFilter} />
					<VenueFilter initialFilter={initialFilters.find((f) => (f.field as string) === 'venue_id')} onChange={handleFilter} />
					<MusicStyleFilter onChange={handleFilter} />
				</FilterOnMobile>
			</div>
			<EventGrid name="main" filter={formattedFilters} sortBy={activeSorting} sortOrder={activeSortOrder} search={searchTerm} />
		</div>
	);
}
