import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.DateFilter';

export default defineMessages({
	filterTitle: {
		id: `${scope}.filterTitle`,
		defaultMessage: 'Date',
	},
	dateToday: {
		id: `${scope}.dateToday`,
		defaultMessage: 'Today',
	},
	dateTomorrow: {
		id: `${scope}.dateTomorrow`,
		defaultMessage: 'Tomorrow',
	},
	dateWeekend: {
		id: `${scope}.dateWeekend`,
		defaultMessage: 'Weekend',
	},
});
