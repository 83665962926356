import { createRef, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { usePrevious, type ValidationErrors } from '@webolucio/core';
import { notificationsActions, PageMetas, ReCaptcha, ReCaptchaRef } from '@webolucio/web';
import { FieldEvent } from '@webolucio/form';
import { Form, PasswordField } from '@webolucio/form-web';
// BPNL imports
import {
	registrationActions,
	registrationReducer,
	registrationSaga,
	registrationSliceKey,
	ResetPasswordInput,
	selectRegistrationErrors,
	selectResetPasswordIsFetching,
} from '@bpnl/registration';
// App imports
import BPNLLogo from '../../assets/logo.svg';
import BpnlButton from '../../components/presentationals/BpnlButton';
// Local imports
import messages from './messages';
import classes from './index.module.scss';

const initialValues: ResetPasswordInput = {
	password: null,
	password_confirm: null,
	captcha_response: '',
	token: '',
};

export default function ForgotPasswordPage() {
	// INJECTORS
	useInjectReducer({ key: registrationSliceKey, reducer: registrationReducer });
	useInjectSaga({ key: registrationSliceKey, saga: registrationSaga });

	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const { token } = useParams<Pick<ResetPasswordInput, 'token'>>();

	const recaptchaRef = createRef<ReCaptchaRef>();

	const formErrors = useSelector(selectRegistrationErrors);
	const isSubmitting = useSelector(selectResetPasswordIsFetching);

	const prevSubmitting = usePrevious(isSubmitting);

	const updateToken = useCallback<() => Promise<string | null>>(() => {
		if (!recaptchaRef.current) {
			return Promise.resolve(null);
		}
		return recaptchaRef.current.execute();
	}, [recaptchaRef]);

	const handleSubmit = useCallback(
		(e: FieldEvent<ResetPasswordInput>) => {
			updateToken().then((captchaResponse) => {
				if (captchaResponse) {
					dispatch(registrationActions.resetPasswordPublicRequest({ ...e.target.value, captcha_response: captchaResponse, token: token || '' }));
				} else {
					dispatch(notificationsActions.addNotification({ message: formatMessage(messages.captchaError), variant: 'error' }));
				}
			});
		},
		[dispatch, formatMessage, token, updateToken],
	);

	useEffect(() => {
		if (prevSubmitting && !isSubmitting && !formErrors) {
			dispatch(notificationsActions.addNotification({ message: formatMessage(messages.successMessage), variant: 'success' }));
			setTimeout(() => navigate('/login'), 3000);
		}
	}, [dispatch, formErrors, formatMessage, isSubmitting, navigate, prevSubmitting]);

	// RENDERERS
	return (
		<>
			<PageMetas title={formatMessage(messages.pageTitle)} />
			<div className={classes.content}>
				<div className={classes.loginSection}>
					<Form
						formProps={{ className: classes.formContainer }}
						initialValues={initialValues}
						errors={formErrors as ValidationErrors}
						isSaving={isSubmitting}
						isValidating={isSubmitting}
						onSubmit={handleSubmit}
					>
						<h1 className={classes.heading}>{formatMessage(messages.pageTitle)}</h1>
						<PasswordField name="password" label={formatMessage(messages.password)} />
						<PasswordField name="password_confirm" label={formatMessage(messages.passwordConfirm)} />
						<ReCaptcha ref={recaptchaRef} action="forgotPassword" />
						<BpnlButton className={classes.loginButton} width="fullWidth" size="medium" type="submit">
							{formatMessage(messages.btnChangePassword)}
						</BpnlButton>
					</Form>
				</div>
				<div className={classes.noAccSection}>
					<div className={classes.logo}>
						<BPNLLogo />
					</div>
					<h2 className={classes.heading}>{formatMessage(messages.stepsTitle)}</h2>
					<h3 className={classes.subHeading}>{formatMessage(messages.stepsSubtitle)}</h3>
					<ul>
						<li>{formatMessage(messages.stepOne)}</li>
						<li>{formatMessage(messages.stepTwo)}</li>
						<li>{formatMessage(messages.stepThree, { btnName: formatMessage(messages.btnChangePassword) })}</li>
					</ul>
				</div>
			</div>
		</>
	);
}
