import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _object_without_properties } from "@swc/helpers/_/_object_without_properties";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Webolucio imports
import { SortOrder, usePrevious, useRequest } from '@webolucio/core';
import { DEFAULT_PAGE_SIZE } from '@bpnl/common';
// Local imports
import socialPostsSaga from './saga';
import { selectSocialPost, selectSocialPostErrors, selectSocialPostIsFetching, selectSocialPostListCount, selectSocialPostListErrors, selectSocialPostListIsFetching, selectSocialPostsList } from './selectors';
import { socialPostsActions, socialPostsReducer, socialPostsSliceKey } from './slice';
export function useSocialPosts(_param) {
    var _param_queryKey = _param.queryKey, queryKey = _param_queryKey === void 0 ? 'default' : _param_queryKey, externalRequest = _object_without_properties(_param, [
        "queryKey"
    ]);
    useInjectReducer({
        key: socialPostsSliceKey,
        reducer: socialPostsReducer
    });
    useInjectSaga({
        key: socialPostsSliceKey,
        saga: socialPostsSaga
    });
    var dispatch = useDispatch();
    var prevExternalRequest = usePrevious(externalRequest);
    var tmp = externalRequest.offset, externalOffset = tmp === void 0 ? 0 : tmp, tmp1 = externalRequest.limit, externalLimit = tmp1 === void 0 ? DEFAULT_PAGE_SIZE : tmp1, tmp2 = externalRequest.sortBy, externalSortBy = tmp2 === void 0 ? 'date' : tmp2, tmp3 = externalRequest.sortOrder, externalSortOrder = tmp3 === void 0 ? SortOrder.DESC : tmp3;
    var _useState = _sliced_to_array(useState(Math.floor(externalOffset / externalLimit)), 2), page = _useState[0], setPage = _useState[1];
    var _useRequest = useRequest(_object_spread_props(_object_spread({}, externalRequest), {
        offset: externalOffset,
        limit: externalLimit,
        sortBy: externalSortBy,
        sortOrder: externalSortOrder
    })), request = _useRequest.request, handleChangeRowsPerPage = _useRequest.handleChangeRowsPerPage, handleChangeFilter = _useRequest.handleChangeFilter, handleChangeSorting = _useRequest.handleChangeSorting, handleChangeSearch = _useRequest.handleChangeSearch, handleChangePage = _useRequest.handleChangePage;
    var prevRequest = usePrevious(request);
    var socialPosts = useSelector(selectSocialPostsList)(queryKey);
    var isSocialPostsFetching = useSelector(selectSocialPostListIsFetching)(queryKey);
    var socialPostsErrors = useSelector(selectSocialPostListErrors)(queryKey);
    var socialPostsCount = useSelector(selectSocialPostListCount)(queryKey);
    var refreshSocialPosts = useCallback(function() {
        var reset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        setPage(0);
        handleChangePage(0);
        dispatch(socialPostsActions.getSocialPostListRequest(_object_spread_props(_object_spread({}, request), {
            offset: 0,
            reset: reset,
            queryKey: queryKey
        })));
    }, [
        dispatch,
        handleChangePage,
        queryKey,
        request
    ]);
    var nextPage = useCallback(function() {
        if (!isSocialPostsFetching && (request.offset || 0) + 1 < socialPostsCount) {
            setPage(page + 1);
            handleChangePage(page + 1);
            return true;
        }
        return false;
    }, [
        socialPostsCount,
        handleChangePage,
        isSocialPostsFetching,
        page,
        request.offset
    ]);
    useEffect(function() {
        if (!isEqual(prevRequest, request)) {
            if (prevRequest === undefined) {
                // Mount
                dispatch(socialPostsActions.getSocialPostListRequest(_object_spread_props(_object_spread({}, request), {
                    queryKey: queryKey
                })));
                return;
            }
            var offset = request.offset, rest = _object_without_properties(request, [
                "offset"
            ]);
            var prevOffset = prevRequest.offset, prevRest = _object_without_properties(prevRequest, [
                "offset"
            ]);
            if (!isEqual(rest, prevRest)) {
                dispatch(socialPostsActions.getSocialPostListRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey
                })));
                return;
            }
            dispatch(socialPostsActions.getSocialPostListRequest(_object_spread_props(_object_spread({}, request), {
                queryKey: queryKey
            })));
        }
    }, [
        dispatch,
        handleChangePage,
        prevRequest,
        queryKey,
        request
    ]);
    useEffect(function() {
        if (prevExternalRequest !== undefined) {
            if (!isEqual(prevExternalRequest === null || prevExternalRequest === void 0 ? void 0 : prevExternalRequest.filter, externalRequest === null || externalRequest === void 0 ? void 0 : externalRequest.filter)) {
                setPage(0);
                handleChangePage(0);
                handleChangeFilter(externalRequest.filter);
            }
            if (prevExternalRequest.sortBy !== externalRequest.sortBy || prevExternalRequest.sortOrder !== externalRequest.sortOrder) {
                setPage(0);
                handleChangePage(0);
                handleChangeSorting(externalRequest.sortBy || 'date', externalRequest.sortOrder || SortOrder.DESC);
            }
            if (prevExternalRequest.search !== externalRequest.search || prevExternalRequest.search_in_fields !== externalRequest.search_in_fields) {
                setPage(0);
                handleChangePage(0);
                handleChangeSearch({
                    phrase: externalRequest.search || null,
                    field: externalRequest.search_in_fields || null
                });
            }
        }
    }, [
        externalRequest.filter,
        externalRequest.search,
        externalRequest.search_in_fields,
        externalRequest.sortBy,
        externalRequest.sortOrder,
        handleChangeFilter,
        handleChangePage,
        handleChangeSearch,
        handleChangeSorting,
        prevExternalRequest
    ]);
    return {
        socialPosts: socialPosts,
        isSocialPostsFetching: isSocialPostsFetching,
        handleChangeRowsPerPage: handleChangeRowsPerPage,
        handleChangeFilter: handleChangeFilter,
        handleChangeSorting: handleChangeSorting,
        handleChangeSearch: handleChangeSearch,
        handleChangePage: handleChangePage,
        nextPage: nextPage,
        refreshSocialPosts: refreshSocialPosts,
        socialPostsErrors: socialPostsErrors,
        socialPostsCount: socialPostsCount
    };
}
export function useSocialPost(param) {
    var id = param.remote_id;
    useInjectReducer({
        key: socialPostsSliceKey,
        reducer: socialPostsReducer
    });
    useInjectSaga({
        key: socialPostsSliceKey,
        saga: socialPostsSaga
    });
    var dispatch = useDispatch();
    var socialPostsErrors = useSelector(selectSocialPost)(id);
    var isSocialPostsFetching = useSelector(selectSocialPostIsFetching)(id);
    var selectedSocialPost = useSelector(selectSocialPostErrors)(id);
    useEffect(function() {
        if (id) {
            dispatch(socialPostsActions.getSocialPostRequest({
                id: id
            }));
        }
    }, [
        dispatch,
        id
    ]);
    return {
        isSocialPostsFetching: isSocialPostsFetching,
        selectedSocialPost: selectedSocialPost,
        socialPostsErrors: socialPostsErrors
    };
}
export function useSocialPostsEvent(_param, eventId) {
    var _param_queryKey = _param.queryKey, queryKey = _param_queryKey === void 0 ? 'social-posts-event' : _param_queryKey, externalRequest = _object_without_properties(_param, [
        "queryKey"
    ]);
    useInjectReducer({
        key: socialPostsSliceKey,
        reducer: socialPostsReducer
    });
    useInjectSaga({
        key: socialPostsSliceKey,
        saga: socialPostsSaga
    });
    var dispatch = useDispatch();
    var prevExternalRequest = usePrevious(externalRequest);
    var tmp = externalRequest.offset, externalOffset = tmp === void 0 ? 0 : tmp, tmp1 = externalRequest.limit, externalLimit = tmp1 === void 0 ? DEFAULT_PAGE_SIZE : tmp1, tmp2 = externalRequest.sortBy, externalSortBy = tmp2 === void 0 ? 'date' : tmp2, tmp3 = externalRequest.sortOrder, externalSortOrder = tmp3 === void 0 ? SortOrder.DESC : tmp3;
    var _useState = _sliced_to_array(useState(Math.floor(externalOffset / externalLimit)), 2), page = _useState[0], setPage = _useState[1];
    var _useRequest = useRequest(_object_spread_props(_object_spread({}, externalRequest), {
        offset: externalOffset,
        limit: externalLimit,
        sortBy: externalSortBy,
        sortOrder: externalSortOrder
    })), request = _useRequest.request, handleChangeRowsPerPage = _useRequest.handleChangeRowsPerPage, handleChangeFilter = _useRequest.handleChangeFilter, handleChangeSorting = _useRequest.handleChangeSorting, handleChangeSearch = _useRequest.handleChangeSearch, handleChangePage = _useRequest.handleChangePage;
    var prevRequest = usePrevious(request);
    var socialPosts = useSelector(selectSocialPostsList)(queryKey);
    var isSocialPostsFetching = useSelector(selectSocialPostListIsFetching)(queryKey);
    var socialPostsErrors = useSelector(selectSocialPostListErrors)(queryKey);
    var socialPostsCount = useSelector(selectSocialPostListCount)(queryKey);
    var refreshSocialPosts = useCallback(function() {
        var reset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        setPage(0);
        handleChangePage(0);
        dispatch(socialPostsActions.getSocialPostListRequest(_object_spread_props(_object_spread({}, request), {
            offset: 0,
            reset: reset,
            queryKey: queryKey
        })));
    }, [
        dispatch,
        handleChangePage,
        queryKey,
        request
    ]);
    var nextPage = useCallback(function() {
        if (!isSocialPostsFetching && (request.offset || 0) + 1 < socialPostsCount) {
            setPage(page + 1);
            handleChangePage(page + 1);
            return true;
        }
        return false;
    }, [
        socialPostsCount,
        handleChangePage,
        isSocialPostsFetching,
        page,
        request.offset
    ]);
    useEffect(function() {
        if (!isEqual(prevRequest, request)) {
            if (prevRequest === undefined) {
                // Mount
                dispatch(socialPostsActions.getSocialPostsEventRequest(_object_spread_props(_object_spread({}, request), {
                    queryKey: queryKey,
                    eventId: eventId
                })));
                return;
            }
            var offset = request.offset, rest = _object_without_properties(request, [
                "offset"
            ]);
            var prevOffset = prevRequest.offset, prevRest = _object_without_properties(prevRequest, [
                "offset"
            ]);
            if (!isEqual(rest, prevRest)) {
                dispatch(socialPostsActions.getSocialPostsEventRequest(_object_spread_props(_object_spread({}, request), {
                    reset: true,
                    queryKey: queryKey,
                    eventId: eventId
                })));
                return;
            }
            dispatch(socialPostsActions.getSocialPostsEventRequest(_object_spread_props(_object_spread({}, request), {
                queryKey: queryKey,
                eventId: eventId
            })));
        }
    }, [
        dispatch,
        eventId,
        handleChangePage,
        prevRequest,
        queryKey,
        request
    ]);
    useEffect(function() {
        if (prevExternalRequest !== undefined) {
            if (!isEqual(prevExternalRequest === null || prevExternalRequest === void 0 ? void 0 : prevExternalRequest.filter, externalRequest === null || externalRequest === void 0 ? void 0 : externalRequest.filter)) {
                setPage(0);
                handleChangePage(0);
                handleChangeFilter(externalRequest.filter);
            }
            if (prevExternalRequest.sortBy !== externalRequest.sortBy || prevExternalRequest.sortOrder !== externalRequest.sortOrder) {
                setPage(0);
                handleChangePage(0);
                handleChangeSorting(externalRequest.sortBy || 'date', externalRequest.sortOrder || SortOrder.DESC);
            }
            if (prevExternalRequest.search !== externalRequest.search || prevExternalRequest.search_in_fields !== externalRequest.search_in_fields) {
                setPage(0);
                handleChangePage(0);
                handleChangeSearch({
                    phrase: externalRequest.search || null,
                    field: externalRequest.search_in_fields || null
                });
            }
        }
    }, [
        externalRequest.filter,
        externalRequest.search,
        externalRequest.search_in_fields,
        externalRequest.sortBy,
        externalRequest.sortOrder,
        handleChangeFilter,
        handleChangePage,
        handleChangeSearch,
        handleChangeSorting,
        prevExternalRequest
    ]);
    return {
        socialPosts: socialPosts,
        isSocialPostsFetching: isSocialPostsFetching,
        handleChangeRowsPerPage: handleChangeRowsPerPage,
        handleChangeFilter: handleChangeFilter,
        handleChangeSorting: handleChangeSorting,
        handleChangeSearch: handleChangeSearch,
        handleChangePage: handleChangePage,
        nextPage: nextPage,
        refreshSocialPosts: refreshSocialPosts,
        socialPostsErrors: socialPostsErrors,
        socialPostsCount: socialPostsCount
    };
}
