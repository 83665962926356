// Webolucio imports
import { ApiService } from '@webolucio/core';
// Role
var getOrganizerList = function(param) {
    var filter = param.filter, offset = param.offset, limit = param.limit, sortBy = param.sortBy, sortOrder = param.sortOrder, search = param.search, search_in_fields = param.search_in_fields;
    return ApiService.getService().get("/organizers", {
        filter: filter,
        offset: offset,
        limit: limit,
        orderby_field: sortBy,
        orderby_dir: sortOrder,
        search: search,
        search_in_fields: search_in_fields
    });
};
var getOrganizerContext = function() {
    return ApiService.getService().get("/organizers/table-context");
};
var getOrganizer = function(id) {
    return ApiService.getService().get("/organizers/".concat(id));
};
export var organizersApi = {
    getOrganizerList: getOrganizerList,
    getOrganizerContext: getOrganizerContext,
    getOrganizer: getOrganizer
};
