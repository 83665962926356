import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { BaseComponentProps } from '@webolucio/core';
import { commonMessages } from '../../../i18n';
import classes from './index.module.scss';

function Footer(props: BaseComponentProps, ref: ForwardedRef<HTMLElement>) {
	const { formatMessage } = useIntl();

	return (
		<footer className={clsx(classes.footer, props.className, 'footer')} ref={ref}>
			<div className={classes.footerLinks}>
				<Link to="legal/terms-of-service" className={classes.link}>
					{formatMessage(commonMessages.termsOfService)}
				</Link>
				<Link to="legal/privacy-policy" className={classes.link}>
					{formatMessage(commonMessages.privacyPolicy)}
				</Link>
				<Link to="legal/about-us" className={classes.link}>
					{formatMessage(commonMessages.aboutUs)}
				</Link>
			</div>
		</footer>
	);
}

export default forwardRef<HTMLElement, BaseComponentProps>(Footer);
