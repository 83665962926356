// Webolucio imports
import { ApiService } from '@webolucio/core';
// Role
var getNotificationList = function(param) {
    var filter = param.filter, offset = param.offset, limit = param.limit, sortBy = param.sortBy, sortOrder = param.sortOrder, search = param.search, search_in_fields = param.search_in_fields;
    return ApiService.getService().get("/notifications", {
        filter: filter,
        offset: offset,
        limit: limit,
        orderby_field: sortBy,
        orderby_dir: sortOrder,
        search: search,
        search_in_fields: search_in_fields
    });
};
var getNotificationContext = function() {
    return ApiService.getService().get("/notifications/table-context");
};
var getNotification = function(id) {
    return ApiService.getService().get("/notifications/".concat(id));
};
var updateNotification = function(data) {
    return ApiService.getService().put("/notifications/".concat(data.id), data);
};
var patchNotification = function(id, data) {
    return ApiService.getService().patch("/notifications/".concat(id), data);
};
export var notificationsApi = {
    getNotificationList: getNotificationList,
    getNotificationContext: getNotificationContext,
    getNotification: getNotification,
    updateNotification: updateNotification,
    patchNotification: patchNotification
};
