import { SnackbarProvider } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
// Webolucio imports
import { LanguageProvider, LocaleService, authReducer, authSaga, authSliceKey, selectAuthUserData, useEffectOnMount } from '@webolucio/core';
import { IconDefinitionProvider } from '@webolucio/icons';
import { CookieConsent, Notifier, WebolucioRouterProvider } from '@webolucio/web';
// BPNL imports
import { myAccountSliceKey, myAccountReducer, myAccountSaga, myAccountActions, selectMyAccount } from '@bpnl/account';
// Local imports
import mainRoutes, { redirectRoutes } from './routes';
import appSaga from './saga';

export default function App() {
	useInjectReducer({ key: authSliceKey, reducer: authReducer });
	useInjectSaga({ key: authSliceKey, saga: authSaga });
	useInjectReducer({ key: myAccountSliceKey, reducer: myAccountReducer });
	useInjectSaga({ key: myAccountSliceKey, saga: myAccountSaga });
	useInjectSaga({ key: 'bpnlApp', saga: appSaga });

	const dispatch = useDispatch();

	const userData = useSelector(selectAuthUserData);
	const account = useSelector(selectMyAccount);

	const isTabletOrMobile = useMemo(() => window.innerWidth < 10, []);

	useEffectOnMount(() => {
		dispatch(myAccountActions.silentLogin());
	});

	useEffect(() => {
		if (userData && !account) {
			dispatch(myAccountActions.getMyAccountRequest());
		}
	}, [account, dispatch, userData]);

	return (
		<LanguageProvider translations={LocaleService.getService().translations}>
			<IconDefinitionProvider>
				<SnackbarProvider>
					<WebolucioRouterProvider routes={isTabletOrMobile ? redirectRoutes : mainRoutes} />
					<Notifier />
					<CookieConsent />
				</SnackbarProvider>
			</IconDefinitionProvider>
		</LanguageProvider>
	);
}
