import { defineMessages } from 'react-intl';

export const scope = 'bpnl.pages.StaticContentPage';

export default defineMessages({
	contentNotFound: {
		id: `${scope}.contentNotFound`,
		defaultMessage: 'Content not found',
	},
});
