import { memoize } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
// Local imports
import { initialMessagesState, messagesSliceKey } from './slice';
var selectDomain = function(state) {
    return state[messagesSliceKey] || initialMessagesState;
};
export var selectMessagesIsFetching = createSelector([
    selectDomain
], function(storeState) {
    return storeState.fetching;
});
export var selectMessagesErrors = createSelector([
    selectDomain
], function(storeState) {
    return storeState.errors;
});
export var selectMessagesCount = createSelector([
    selectDomain
], function(storeState) {
    return storeState.totalCount;
});
export var selectMessagesList = createSelector([
    selectDomain
], function(storeState) {
    return storeState.data;
});
export var selectSelectedMessage = createSelector([
    selectDomain
], function(storeState) {
    return storeState.selected;
});
export var selectMessagesIsDeleting = createSelector([
    selectDomain
], function(storeState) {
    return storeState.deleting;
});
export var selectMessagesIsSaving = createSelector([
    selectDomain
], function(storeState) {
    return storeState.saving;
});
export var selectMessagesIsValidating = createSelector([
    selectDomain
], function(storeState) {
    return storeState.validating;
});
export var selectMessageById = createSelector([
    selectMessagesList
], function(messages) {
    return memoize(function(id) {
        return messages.find(function(message) {
            return message.id === id;
        }) || null;
    });
});
