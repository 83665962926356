import { defineMessages } from 'react-intl';

export const scope = 'bpnl.components.ServicesFilter';

export default defineMessages({
	filterTitle: {
		id: `${scope}.filterTitle`,
		defaultMessage: 'Services',
	},
});
