// Webolucio imports
import { ApiService } from '@webolucio/core';
// Role
var postFollow = function(params) {
    return ApiService.getService().post("/follow", params);
};
var deleteFollow = function(params) {
    return ApiService.getService().delete("/follow", undefined, {
        data: params
    });
};
export var followApi = {
    postFollow: postFollow,
    deleteFollow: deleteFollow
};
