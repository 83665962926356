import { throttle } from 'lodash-es';
import { useEffect, useState } from 'react';

export default function useIsFloating({
	thresholdIn,
	thresholdOut = thresholdIn,
	tolerance = 20,
}: {
	thresholdIn: number;
	thresholdOut?: number;
	tolerance?: number;
}) {
	const [floating, setFloating] = useState(false);
	const [lockThresholdIn, setLockThresholdIn] = useState(window.scrollY > thresholdIn);
	const [lockThresholdOut, setLockThresholdOut] = useState(thresholdOut > thresholdIn);

	useEffect(() => {
		const handleScroll = throttle(function () {
			//console.log('ScrollY:', window.scrollY);
			if (lockThresholdOut && window.scrollY > thresholdOut + tolerance) {
				//console.log('Release lock threshold out');
				setLockThresholdOut(false);
			}
			if (lockThresholdIn && window.scrollY <= Math.max(thresholdIn - tolerance, 0)) {
				//console.log('Release lock threshold in');
				setLockThresholdIn(false);
			}

			if (!lockThresholdIn) {
				if (!floating && window.scrollY > thresholdIn) {
					//console.log('Set floating');
					setFloating(true);
					setLockThresholdIn(true);
				}

				if (floating && window.scrollY <= Math.max(thresholdIn - tolerance, 0)) {
					//console.log('Release floating');
					setFloating(false);
				}
			}

			if (!lockThresholdOut && window.scrollY < thresholdOut) {
				if (floating) {
					//console.log('Release floating, set lock threshold out:', thresholdOut > thresholdIn);
					setFloating(false);
					setLockThresholdOut(thresholdOut > thresholdIn);
				}
			}
		}, 150);

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [floating, lockThresholdIn, lockThresholdOut, thresholdIn, thresholdOut, tolerance]);

	return floating;
}
