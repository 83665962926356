import clsx from 'clsx';
import { CSSProperties, ForwardedRef, forwardRef, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Webolucio imports
import { BaseComponentProps, useLocale, selectIsAuthenticated, selectIsAuthFlowRunning } from '@webolucio/core';
import { Icon } from '@webolucio/icons';
import { ClickAwayListener } from '@webolucio/web';
// App imports
import mainRoutes from '../../../app/routes';
import MainNavigation from '../MainNavigation';
import { loginRoute } from '../../../pages/LoginPage/route';
import HeaderMenu from '../../presentationals/HeaderMenu';
import Notifications from '../../containers/Notifications';
import ProfileImage from '../../presentationals/ProfileImage';
import ProfileMenuPanel from '../../containers/ProfileMenuPanel';
import useIsDesktop from '../../../hooks/useIsDesktop';
import { followingRoute } from '../../../pages/FollowingPage';
import Logo from '../../../assets/logo.svg';
// Local imports
import classes from './index.module.scss';

function Header(props: BaseComponentProps, ref: ForwardedRef<HTMLElement>) {
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const { formatMessage } = useIntl();
	const { locale, changeLocale } = useLocale();
	const navigate = useNavigate();
	const isDesktop = useIsDesktop();

	const isAuthenticated = useSelector(selectIsAuthenticated);
	const isAuthFlowRunning = useSelector(selectIsAuthFlowRunning);

	const routes = useMemo(() => {
		const result = mainRoutes[1]?.children?.find((route) => route.id === 'main')?.children || [];

		if (!isAuthenticated) {
			return [
				...result,
				{
					...loginRoute,
					handle: {
						...loginRoute.handle,
						icon: 'login-2',
					},
				},
			];
		}

		return result;
	}, [isAuthenticated]);

	const handleFollowingClick = useCallback(() => {
		navigate('/following');
		setIsMenuOpen((prev) => !prev);
	}, [navigate]);

	const handleToggleProfileMenu = useCallback(() => {
		setProfileMenuOpen((prev) => !prev);
	}, []);

	const handleClickAway = useCallback((e: MouseEvent | TouchEvent) => {
		const target = e.target || e.currentTarget;

		if (
			(target instanceof HTMLElement || target instanceof SVGElement) &&
			(target.closest('.header-menu-button') || target.closest('.header-inner'))
		) {
			return;
		}

		setIsMenuOpen(false);
	}, []);

	const handleNavItemClick = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen]);

	const handleMenuToggle = useCallback(() => setIsMenuOpen((prev) => !prev), []);

	const innerHeader = (
		<>
			<MainNavigation className={classes.navigation} routes={routes} onItemClick={handleNavItemClick} />
			<div className={classes.languages}>
				<span className={clsx(classes.language, locale === 'hu' && classes.active)} onClick={() => changeLocale('hu')}>
					HU
				</span>
				<span className={classes.separator} />
				<span className={clsx(classes.language, locale === 'en' && classes.active)} onClick={() => changeLocale('en')}>
					EN
				</span>
			</div>
			{isAuthenticated && isAuthFlowRunning ? (
				<div className={classes.headerFollowing} onClick={handleFollowingClick}>
					<Icon name="bpnl-favorite" />
					<span className={classes.headerFollowingTitle}>{formatMessage(followingRoute.handle.title)}</span>
				</div>
			) : null}
		</>
	);

	return (
		<header className={clsx(classes.header, props.className, 'header')} ref={ref}>
			<div className={clsx(classes.logo, 'logo')} onClick={() => navigate('/')}>
				<Logo />
			</div>
			{isDesktop ? (
				<div className={classes.headerInner}>{innerHeader}</div>
			) : (
				isMenuOpen && (
					<ClickAwayListener onClickAway={handleClickAway} touchEvent="touchstart">
						<div
							className={clsx(classes.headerInner, classes.mobile, 'header-inner', 'header-mobile')}
							style={isMenuOpen ? ({ '--mobile-menu-open': 'flex' } as CSSProperties) : undefined}
						>
							{innerHeader}
						</div>
					</ClickAwayListener>
				)
			)}
			{isAuthenticated && isAuthFlowRunning ? (
				<div className={classes.serviceNavigation}>
					<Notifications />
					<div className={classes.profile} onClick={handleToggleProfileMenu}>
						<ProfileImage className={classes.profileImage} />
						<Icon name="bpnl-arrow-down-double" className={classes.menu} />
						<ProfileMenuPanel open={profileMenuOpen} onClose={handleToggleProfileMenu} />
					</div>
					{!isDesktop ? <HeaderMenu onClick={handleMenuToggle} menuOpen={isMenuOpen} /> : null}
				</div>
			) : !isDesktop ? (
				<HeaderMenu onClick={handleMenuToggle} menuOpen={isMenuOpen} />
			) : null}
		</header>
	);
}

export default forwardRef<HTMLElement, BaseComponentProps>(Header);
